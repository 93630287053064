<template>
  <div class="mt-2 mb-7 greeks-wrapper">
    <div class="greeks-header">
      <v-tabs height="30px" color="var(--primary-button-color)">
        <v-tab
          style="font-size: var(--font-16)"
          @click="selectedGreeksTab = 'pl'"
          >P&L</v-tab
        >
        <v-tab
          style="font-size: var(--font-16)"
          @click="selectedGreeksTab = 'greeks'"
          >Greeks</v-tab
        >
      </v-tabs>
    </div>

    <!-- Profit and Loss -->
    <div v-if="selectedGreeksTab === 'pl'">
      <div class="greeks-subheader justify-space-between justify-sm-start">
        <div
          style="
            background-color: transparent;
            padding-left: 0;
            font-size: var(--font-16);
            width: 190px;
            border: none;
          "
          class="d-flex align-center sub-header-label"
        >
          Positions Greeks
        </div>
        <div class="d-flex greek-chips"  v-if="!$vuetify.breakpoint.xs">
          <div>Delta: {{ prebuiltStrategyData.total.delta }}</div>
          <div>Theta: {{ prebuiltStrategyData.total.theta }}</div>
          <div>Gamma: {{ prebuiltStrategyData.total.gamma }}</div>
          <div>Vega: {{ prebuiltStrategyData.total.vega }}</div>
        </div>
        <div v-else>
          <div class="d-flex greek-chips justify-end">
            <div>Delta: {{ prebuiltStrategyData.total.delta }}</div>
            <div>Theta: {{ prebuiltStrategyData.total.theta }}</div>
          </div>
          <div class="d-flex greek-chips">
            <div>Gamma: {{ prebuiltStrategyData.total.gamma }}</div>
            <div>Vega: {{ prebuiltStrategyData.total.vega }}</div>
          </div>
        </div>
      </div>

      <!-- Sliders -->
      <div>
        <!-- Sliders -->
        <div class="target-days-section my-5">
          <!-- TARGET DAYS -->
          <div class="my-1 my-sm-2">
            <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
              Target Days
              <span class="target-iv-sublabel"> (0 to {{ targetDays }}) </span>
            </span>
            <v-slider
              v-model="selectedTargetDays"
              hide-details
              color="var(--primary-button-color)"
              always-dirty
              min="0"
              step="1"
              :max="targetDays"
              @change="changeTargetDays()"
              track-color="#e0e0e0"
              prepend-icon="mid-minus"
            >
              <template v-slot:prepend>
                <span class="target-iv-label" v-if="!$vuetify.breakpoint.xs">
                  Target Days
                  <span class="target-iv-sublabel">
                    (0 to {{ targetDays }})
                  </span>
                </span>
                <v-icon
                  color="var(--text-color) !important"
                  @click="
                    selectedTargetDays = selectedTargetDays - 1;
                    changeTargetDays();
                  "
                  :disabled="selectedTargetDays == 0 ? true : false"
                >
                  mdi-minus
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon
                  color="var(--text-color)"
                  @click="
                    selectedTargetDays = selectedTargetDays + 1;
                    changeTargetDays();
                  "
                  :disabled="selectedTargetDays == targetDays ? true : false"
                >
                  mdi-plus
                </v-icon>
                <input
                  class="target-iv-input"
                  v-model="selectedTargetDays"
                  :max="targetDays"
                  @input="inputTargetDays($event)"
                />
              </template>
            </v-slider>
          </div>
          <!-- IV CHANGE -->
          <div class="my-1 my-sm-2">
            <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
              IV% Change
              <span class="target-iv-sublabel"> (-50 to 50) </span>
            </span>
            <v-slider
              color="var(--primary-button-color)"
              always-dirty
              min="-50"
              step="1"
              max="50"
              hide-details
              v-model="perChange"
              track-color="#e0e0e0"
              prepend-icon="mid-minus"
              @change="changeIvPercent()"
            >
              <template v-slot:prepend>
                <span class="target-iv-label" v-if="!$vuetify.breakpoint.xs">
                  IV% Change
                  <span class="target-iv-sublabel"> (-50 to 50) </span>
                </span>
                <v-icon
                  color="var(--text-color)"
                  @click="
                    perChange = perChange - 1;
                    changeIvPercent();
                  "
                  :disabled="perChange == -50 ? true : false"
                >
                  mdi-minus
                </v-icon>
              </template>

              <template v-slot:append>
                <v-icon
                  :ripple="false"
                  color="var(--text-color)"
                  @click="
                    perChange = perChange + 1;
                    changeIvPercent();
                  "
                  :disabled="perChange == 50 ? true : false"
                >
                  mdi-plus
                </v-icon>
                <div style="position: relative">
                  <input
                    class="target-iv-input"
                    v-model="ivInput"
                    @input="inputIvPercent($event)"
                  />
                  <span
                    style="
                      position: absolute;
                      top: 50%;
                      left: 71%;
                      color: var(--text-color);
                      transform: translate(0%, -50%);
                    "
                    >%</span
                  >
                </div>
              </template>
            </v-slider>
          </div>
        </div>
        <!-- DATA -->
        <v-row
          class="justify-space-between stats-bottom-container no-gutters"
          style="margin: -12px"
        >
          <!-- Margin -->
          <div class="col-6 col-sm-4 order-1 order-sm-1">
            <div>
              <span>Margin</span>
              <span>{{ margin.toFixed(0) | integerFormat }}</span>
            </div>
          </div>
          <!-- Margin Benefit -->
          <div class="col-6 col-sm-4 order-2 order-sm-4">
            <div>
              <span v-if="$vuetify.breakpoint.xs">MB</span>
              <span v-else>Margin Benefit</span>
              <span>
                <span>{{ mb | integerFormat }} </span
                ><span class="green-text">
                  ({{ MarginBenefitPercent.toFixed(2) }}%)
                </span>
              </span>
            </div>
          </div>
          <!-- Net Premium -->
          <div class="col-6 col-sm-4 order-5 order-sm-7">
            <div>
              <span v-if="$vuetify.breakpoint.xs">Premium</span>
              <span v-else-if="receivedNetPremium < 0"
                >Net Premium Received</span
              >
              <span v-else>Net Premium Paid</span>
              <span>{{ Math.abs(receivedNetPremium) | integerFormat }}</span>
            </div>
          </div>
          <!-- Max Profit -->
          <div class="col-6 col-sm-4 order-3 order-sm-2">
            <div>
              <span>Max Profit</span>
              <span>
                <span v-if="maxProfit === 'Unlimited'">Unlimited</span>
                <span v-else>
                  {{ parseFloat(maxProfit).toFixed(0) | integerFormat }}
                </span>
                <span v-if="maxProfit !== 'Unlimited'" class="green-text">
                  (+{{ ((maxProfit / margin) * 100).toFixed(1) }}%)
                </span>
              </span>
            </div>
          </div>
          <!-- Max Loss -->
          <div class="col-6 col-sm-4 order-4 order-sm-5">
            <div>
              <span>Max Loss</span>
              <span>
                <span v-if="maxLoss === 'Unlimited'">Unlimited</span>
                <span v-else>
                  {{ parseFloat(maxLoss).toFixed(0) | integerFormat }}
                </span>
                <span v-if="maxLoss !== 'Unlimited'" class="red-text">
                  ({{ ((maxLoss / margin) * 100).toFixed(1) }}%)
                </span>
              </span>
            </div>
          </div>
          <!-- BreakEven -->
          <div class="col-6 col-sm-4 order-6 order-sm-3">
            <div>
              <span>Breakeven</span>
              <span class="d-flex justify-end align-baseline" style="flex-wrap:wrap">
                <span>{{ breakEvenPrice }}</span>
                <span style="font-size: var(--font-14)">
                  <span> (</span>
                  <span>{{ breakEvenPricePercent.split(" - ")[0] }}%</span>
                  <span v-if="breakEvenPricePercent.split(' - ').length > 1">
                    -
                  </span>
                  <span v-if="breakEvenPricePercent.split(' - ').length > 1"
                    >{{ breakEvenPricePercent.split(" - ")[1] }}%</span
                  >
                  <span>)</span>
                </span>
              </span>
            </div>
          </div>
          <!-- Probability -->
          <div class="col-6 col-sm-4 order-7 order-sm-6">
            <div>
              <span>Probability</span>
              <span>{{ popValue }}%</span>
            </div>
          </div>
          <!-- Risk/Reward -->
          <div class="col-6 col-sm-4 order-7 order-sm-8">
            <div>
              <span>Risk/Reward</span>
              <span>{{ riskRewardRatio }}</span>
            </div>
          </div>
        </v-row>
      </div>
    </div>

    <!-- GREEKS -->
    <div style="margin-top: 2rem" v-if="selectedGreeksTab === 'greeks'">
      <v-simple-table>
        <template v-slot:default>
          <thead class="greeks-table-header">
            <tr>
              <th class="text-left">Instrument</th>
              <th class="text-center">Qty</th>
              <th class="text-center">B/S</th>
              <th class="text-center">IV</th>
              <th class="text-center">Delta</th>
              <th class="text-center">Theta</th>
              <th class="text-center">Gamma</th>
              <th class="text-center">Vega</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="greeks-table-rows"
              style="border-bottom: 1px solid var(--border-color)"
              v-for="(greek, index) in prebuiltStrategyData.greeks"
              :key="index"
            >
              <td class="text-left">
                {{ greek.formatted_ins_name }}
              </td>
              <td class="text-center" v-if="selectedInstrument.exchange!= 'MCX' || selectedInstrument.exchange!= 'CDS'">
                {{ parseFloat(greek.quantity) }}
              </td>
              <td v-else>
                {{ parseFloat(greek.quantity)/selectedInstrument.lot_size }}
              </td>
              <td class="text-center">
                {{ greek.buy_sell }}
              </td>
              <td class="text-center">
                {{ parseFloat(greek.iv) }}
              </td>
              <td class="text-center">
                {{ parseFloat(greek.delta) }}
              </td>
              <td class="text-center">
                {{ parseFloat(greek.theta) }}
              </td>
              <td class="text-center">
                {{ parseFloat(greek.gamma) }}
              </td>
              <td class="text-center">
                {{ parseFloat(greek.vega) }}
              </td>
            </tr>
            <tr
              class="greeks-table-rows"
              style="border-bottom: 1px solid var(--border-color)"
            >
              <td class="text-left" style="font-weight: bold">Total</td>
              <td class="text-center"></td>
              <td class="text-center"></td>
              <td class="text-center">
                {{ parseFloat(prebuiltStrategyData.total.iv) }}
              </td>
              <td class="text-center">
                {{ parseFloat(prebuiltStrategyData.total.delta) }}
              </td>
              <td class="text-center">
                {{ parseFloat(prebuiltStrategyData.total.theta) }}
              </td>
              <td class="text-center">
                {{ parseFloat(prebuiltStrategyData.total.gamma) }}
              </td>
              <td class="text-center">
                {{ parseFloat(prebuiltStrategyData.total.vega) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>
<script>
import { calculateTargetDays } from "./dashboardService";
import { EventBus } from "../../main.js";
export default {
  name: "Greeks",
  props: {
    prebuiltStrategyData: null,
    expiryDatesList: [],
    selectedInstrument:null,
  },
  computed:{
    liveLtp() {
      return (el) => {
        let ltp = this.compactMarketDataScripts(el);
        if (
          ltp &&
          ltp["details"] &&
          (ltp["details"]["Last Traded Price"] == 0 ||
            ltp["details"]["Last Traded Price"])
        )
          return ltp["details"]["Last Traded Price"];
        else return null;
      };
    },
    getCompactMarketData() {
      return this.$store.state.compactMarketData;
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  data: () => ({
    selectedGreeksTab: "pl",
    targetDays: 0,
    perChange: 0,
    MarginBenefitPercent: 0,
    margin: 0,
    mb: 0,
    maxLoss: "",
    maxProfit: "",
    riskRewardRatio: "",
    breakEvenPrice: "",
    breakEvenPricePercent: "",
    selectedTargetDays: 0,
    popValue: 0,
    targetDaysTimerId: null,
    targetIvId: null,
    receivedNetPremium: 0,
    ivInput: 0,
    spotPrice:0
  }),
  created() {
    EventBus.$on("changeTargetDays", (days) => {
      this.targetDays = days;
    });
  },
  watch: {
    prebuiltStrategyData() {
      this.loadData();
    },
  },
  methods: {
    calculateTableValues() {
      let totalMargin = this.margin + this.mb;
      this.MarginBenefitPercent = (this.mb / totalMargin) * 100;
      if (
        this.maxProfit === "Undefined" ||
        this.maxProfit === "Unlimited" ||
        this.maxLoss === "Undefined" ||
        this.maxLoss === "Unlimited"
      ) {
        this.riskRewardRatio = "Unlimited";
      } else {
        let risk = parseFloat(this.maxProfit / Math.abs(this.maxLoss)).toFixed(
          1
        );
        if (parseFloat(risk) >= 1) {
          this.riskRewardRatio = risk.toString() + " : 1";
        } else {
          this.riskRewardRatio = risk.toString() + " : 1";
        }
      }
    },
    calculateBreakevenPrice() {
      let x = this.prebuiltStrategyData.data.pl_data.x;
      let y = this.prebuiltStrategyData.data.pl_data.y;
      let yIndex = "";
      let yIndex2 = "";
      if (y[0] < 0) {
        yIndex = this.greatestNegativeIndex(y);
      } else {
        yIndex = this.traversePositiveArray(y);
      }
      let copyY = y;
      let slicedY = copyY.slice(yIndex + 1);
      if (slicedY[0] < 0) {
        yIndex2 = this.greatestNegativeIndex(slicedY);
      } else {
        yIndex2 = this.traversePositiveArray(slicedY);
      }
      if (yIndex2 != "") {
        this.breakEvenPrice =
          parseFloat(x[yIndex]).toFixed(1) +
          " - " +
          parseFloat(x[yIndex2 + yIndex - 1]).toFixed(1);
        this.breakEvenPricePercent =
          (
            (100 * (parseFloat(x[yIndex]) - parseFloat(this.spotPrice))) /
            parseFloat(this.spotPrice)
          ).toFixed(1) +
          " - " +
          (
            (100 *
              (parseFloat(x[yIndex2 + yIndex - 1]) -
                parseFloat(this.spotPrice))) /
            parseFloat(this.spotPrice)
          ).toFixed(1);
      } else {
        this.breakEvenPrice = parseFloat(x[yIndex + 1]).toFixed(1);
        this.breakEvenPricePercent = (
          (100 *
            (parseFloat(this.breakEvenPrice) - parseFloat(this.spotPrice))) /
          parseFloat(this.spotPrice)
        ).toFixed(1);
      }
    },
    greatestNegativeIndex(list) {
      let posIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i] > 0) {
          posIndex = i;
          break;
        }
      }
      if (posIndex < 0) {
        return "";
      } else {
        return posIndex - 1;
      }
    },
    traversePositiveArray(list) {
      let posIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i] < 0) {
          posIndex = i;
          break;
        }
      }
      if (posIndex < 0) {
        return "";
      } else {
        return posIndex - 1;
      }
    },

    calculateNetPremium() {
      let priceLot = [];
      for (let ins of this.prebuiltStrategyData.instrument) {
        if (ins.segment != "fut") {
          priceLot.push(ins.ltp * ins.quantity);
        }
      }
      this.receivedNetPremium = priceLot.reduce(
        (total, current) => total + current,
        0
      );
    },
    loadData() {
      this.margin = this.prebuiltStrategyData.data.margin_data.margin;
      this.mb = this.prebuiltStrategyData.data.margin_data.mb;
      this.maxProfit = this.prebuiltStrategyData.data.pl_data.profit;
      this.maxLoss = this.prebuiltStrategyData.data.pl_data.loss;
      this.popValue = this.prebuiltStrategyData.pop;
      this.calculateTableValues();
      this.calculateBreakevenPrice();
      this.calculateNetPremium();
      this.targetDays = calculateTargetDays(
        this.prebuiltStrategyData.instrument
      );
    },
    changeTargetDays() {
      EventBus.$emit("targetDays", parseFloat(this.selectedTargetDays));
    },
    inputTargetDays(event) {
      if (this.targetDaysTimerId) {
        clearTimeout(this.targetDaysTimerId);
      }
      if (this.selectedTargetDays == "") {
        return;
      } else {
        this.selectedTargetDays = event.target.value;
        this.targetDaysTimerId = setTimeout(() => {
          this.changeTargetDays();
        }, 500);
      }
    },
    changeIvPercent() {
      this.ivInput = this.perChange;
      EventBus.$emit("ivPercent", parseFloat(this.perChange));
    },
    inputIvPercent(event) {
      if (this.targetIvId) {
        clearTimeout(this.targetIvId);
      }
      let value = event.target.value.toString().replace(/[^-?\d]/g, "");
      this.ivInput = value;
      this.targetIvId = setTimeout(() => {
        if (value < -50) {
          this.ivInput = -50;
        } else if (value > 50) {
          this.ivInput = 50;
        } else if (value == "" || value == "-") {
          this.ivInput = 0;
        }
        this.perChange = parseInt(this.ivInput);
        this.changeIvPercent();
      }, 1000);
    },
  },
  mounted() {
    this.spotPrice = this.selectedInstrument == 'NSE'? this.liveLtp(this.selectedInstrument.underlying_code):this.liveLtp(this.selectedInstrument.fut_code);
    this.loadData();
  },
};
</script>
<style lang="scss">
@import "../../assets/css/global.scss";

.greeks-wrapper {
  font-size: var(--font-14);
}

.greeks-header {
  .v-tabs {
    border-bottom: 1px solid var(--border-color);
    .v-tab {
      text-transform: none !important;
    }
  }
}

.greeks-subheader {
  display: flex;
  margin-top: 1.5rem;
  .sub-header-label{
    width:190px;
  }
  .greek-chips  div{
    background-color: var(--chip-background);
    padding: 0.3rem 0.6rem;
    border-radius: 6px;
    margin-right: 8px;
    color: var(--text-color);
    line-height: 25px;
  }
}

.target-days-section {
  color: var(--text-color);
  margin: 1.5rem 0;
}

.stats-bottom-container > div {
  > div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0.7rem 0 !important;
    border-bottom: 1px solid var(--border-color);
    margin: 0 1rem;
    white-space: nowrap;
  }
}

.greeks-table-header {
  background: var(--chip-background);
  margin-top: 1rem;

  th {
    color: var(--text-color) !important;
    font-size: var(--font-14) !important;
    border-bottom: none !important;
    font-family: "Gilroy-SemiBold";
  }

  td {
    font-size: var(--font-14);
    text-align: center;
    color: var(--text-color) !important;
  }
}

.greeks-table-rows td {
  text-align: center;
  color: var(--text-color) !important;
  font-family: "Gilroy-Medium";
  color: var(--text-color) !important;
}

.v-slider--horizontal .v-slider__track-container {
  height: 6px !important;
}

.target-iv-label {
  width: 200px;
  padding-top: 4px;
  color: var(--text-color) !important;
  font-size: var(--font-16);

  .target-iv-sublabel {
    color: #575757;
    font-size: var(--font-14);
    margin-left: 8px;
  }
}

.target-iv-input {
  width: 60px;
  border: 0.6px solid var(--border-color);
  height: 100%;
  border-radius: 2px;
  text-align: center;
  color: var(--text-color) !important;
}

.v-icon.v-icon::after {
  display: none;
}

.v-data-table {
  th {
    height: 36px !important;
  }
}

@media screen and (max-width: 600px) {
  .greeks-subheader {
    .sub-header-label{
      width:auto !important;
    }
    div {
      padding: 0.3rem 0.5rem;
      // margin-right: 0;
    }
  }

  .stats-bottom-container,
  .target-iv-label,
  .greeks-subheader,
  .target-iv-input {
    font-size: 0.875em;
  }

  // Vuetify elements forced css
  .target-days-section {
    .v-input__slot {
      width: 83%;
    }
  }
}

// @media screen and (min-width: 961px) and (max-width: 1850px) {

// }

@media screen and(max-width:400px) {
  .target-days-section {
    .v-input__slot {
      width: 68%;
    }
  }
}

// @media screen and (max-width: 960px) {
//   .greeks-wrapper {
//     font-size: 2.1vw;
//   }
// }

// @media screen and (max-width: 600px) {
//   .greeks-wrapper {
//     font-size: 3.5vw;
//   }
// }
</style>
