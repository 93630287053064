<template>
  <div class="modal-backdrop">
    <vue-draggable-resizable
      drag-handle=".header-modal"
      w="auto"
      h="auto"
      :on-resize="onResizeCallback"
      :on-resize-start="onResizeStartCallback"
    >
      <div class="modal">
        <div class="header-modal">
          <span class="caption-text"
            >{{ data.param[0]["name"] }}
            <img src="@/assets/position-arrow.png" /> {{ trend_name }}</span
          >
          <span class="close-btn"
            ><button @click="close()">
              <img
                height="10px"
                width="10px"
                src="@/assets/close.png"
                alt=""
              /></button
          ></span>
        </div>
        <div class="graph-data">
          <highchart
            @profitLossData="getPlData"
            id="line-chart"
            ref="lineChart"
            :plotdata="plotData"
          >
          </highchart>
        </div>
        <!-- <div v-if="!isMobile()">
          <v-row class="meta-info">
            <v-col cols="2" style="text-align: right; margin-top: 2px"
              >Target Days:</v-col
            >
            <v-col cols="4"
              ><span class="day-slider"
                ><v-slider
                  @change="changeSlider()"
                  thumb-label="always"
                  :thumb-size="25"
                  :max="maxDaySlider"
                  step="1"
                  :min="0"
                  v-model="targetDays"
                ></v-slider></span
            ></v-col>
          </v-row>
        </div>
        <div v-else>
          <v-row class="meta-info">
            <v-col cols="4" style="margin-left: 4px; margin-top: 2px"
              >Target Days:</v-col
            >
            <v-col cols="5"
              ><span class="day-slider"
                ><v-slider
                  @change="changeSlider()"
                  thumb-label="always"
                  :thumb-size="25"
                  :max="maxDaySlider"
                  step="1"
                  :min="0"
                  v-model="targetDays"
                ></v-slider></span
            ></v-col>
          </v-row>
        </div> -->
      </div>
    </vue-draggable-resizable>
  </div>
</template>
<script>
import axios from "axios";
import Highchart from "@/components/Highchart";
import VueDraggableResizable from "vue-draggable-resizable";

import { getGrpahDataCall } from "./getService";
export default {
  components: { Highchart, VueDraggableResizable },
  props: {
    data: {},
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  mounted() {
    setTimeout(() => {
      this.getGraphData();
    }, 1000);
  },
  data() {
    return {
      maxDaySlider: 0,
      onResizeStartCallback: "",
      onResizeStart: "",
      fut_code: "",
      spotPrice: 0,
      targetDays: 0,
      target: "",
      exchange: "",
      plotData: "",
      chart: "",
      loss: "",
      profit: "",
      expiry: "",
      param_list: [],
      param_list1: [],
      trend_name: "",
    };
  },
  name: "GraphModal",
  methods: {
    decreaseValue() {
      this.spotPrice = parseInt(this.spotPrice) - 1;
      this.changeSlider();
    },
    onResizeCallback(handle, x, y, width, height) {
      if (width > 7000 && height > 200000) {
        return false;
      }
    },
    increaseValue() {
      this.spotPrice = parseInt(this.spotPrice) + 1;
      this.changeSlider();
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    getPlData(value) {
      this.target = value;
    },
    getGraphData() {
      this.trend_name = this.data["trend_name"];
      this.fut_code = this.data["fut_code"];
      this.exchange = this.data["exchange"];
      this.position_list = this.data["positions"];
      let segment;
      this.param_list = [];
      let tr_type;
      // console.log(this.data.positions)
      let user_id = localStorage.getItem("user_id");
      this.expiry = this.data.param[0].expiry;
      this.calculateTargetDays();
      console.log(this.data);
      for (var i = 0; i < this.data["param"].length; i++) {
        if (this.data.param[i]["segment"] == "CE") {
          segment = "c";
        }
        if (this.data.param[i]["segment"] == "PE") {
          segment = "p";
        }
        if (this.data.param[i]["quantity"] < 0) {
          tr_type = "s";
        }
        if (this.data.param[i]["quantity"] > 0) {
          tr_type = "b";
        }
        let trading_symbol;
        if (this.data.positions[i].trading_symbol) {
          trading_symbol = this.data.positions[i].trading_symbol;
        } else {
          trading_symbol = this.data.positions[i].tradingSymbol;
        }
        if (this.data.param[i]["segment"] == "FUT") {
          this.param_list.push({
            quantity: Math.abs(this.data.param[i]["quantity"]),
            op_type: "p",
            strike: parseInt(
              this.compactMarketDataScripts(this.data.param[i]["code"])[
                "details"
              ]["Last Traded Price"]
            ),
            tr_type: "b",
            op_pr: 0,
            code: this.data.param[i]["code"],
            trading_symbol: trading_symbol,
          });
          this.param_list.push({
            quantity: Math.abs(this.data.param[i]["quantity"]),
            op_type: "c",
            strike: parseInt(
              this.compactMarketDataScripts(this.data.param[i]["code"])[
                "details"
              ]["Last Traded Price"]
            ),
            tr_type: "s",
            op_pr: 0,
            code: this.data.param[i]["code"],
            trading_symbol: trading_symbol,
          });
        } else {
          this.param_list.push({
            quantity: Math.abs(this.data.param[i]["quantity"]),
            op_type: segment,
            strike: this.data.param[i]["strike"],
            tr_type: tr_type,
            op_pr: parseInt(
              this.compactMarketDataScripts(this.data.param[i]["code"])[
                "details"
              ]["Last Traded Price"]
            ),
            code: this.data.param[i]["code"],
            trading_symbol: trading_symbol,
          });
        }
        //   if(typeof this.compactMarketDataScripts(this.fut_code)['details'] == 'undefined'){
        //   this.getGraphData()
        // }
      }
      this.spotPrice = parseInt(
        this.compactMarketDataScripts(this.fut_code)["details"][
          "Last Traded Price"
        ]
      );
      this.param_list1.push({
        quantity: 50,
        op_type: "p",
        strike: 15200,
        tr_type: "b",
        op_pr: 105.7,
      });
      axios
        .post(getGrpahDataCall, {
          param_list: this.param_list,
          spot_price: this.spotPrice,
          user_id: user_id,
          code: this.fut_code,
          exchange: this.exchange,
        })
        .then((response) => {
          this.chart = response.data.body;
          this.loss = response.data.body.coordinate.loss;
          this.profit = response.data.body.coordinate.profit;
          this.plotData = {
            coordinate: response.data.body.coordinate,
            Ltp: Math.floor(this.spotPrice),
            pl_targeted: response.data.body.pl_targeted,
          };
        });
    },
    getBuySellLtp(instrument) {
      if (
        instrument.actual_average_sell_price ||
        instrument.average_sell_price
      ) {
        if (instrument.actual_average_sell_price) {
          return instrument.actual_average_sell_price;
        } else {
          return instrument.average_sell_price;
        }
      } else {
        if (instrument.actual_average_buy_price) {
          return instrument.actual_average_buy_price;
        } else {
          return instrument.average_buy_price;
        }
      }
    },
    calculateTargetDays() {
      let currentDay;
      currentDay = new Date();
      let expiryDay = new Date(this.expiry);
      var difference = expiryDay.getTime() - currentDay.getTime();
      let days = Math.round(difference) / (1000 * 60 * 60 * 24);
      this.maxDaySlider = days.toFixed(0);
    },
    async changeSlider() {
      await axios
        .post(getGrpahDataCall, {
          param_list: this.param_list,
          spot_price: this.spotPrice,
          user_id: "RC047",
          code: this.fut_code,
          days_to_expire: this.maxDaySlider - this.targetDays,
          target_days: this.targetDays,
          exchange: this.exchange,
        })
        .then((response) => {
          this.chart = response.data.body;
          this.loss = response.data.body.coordinate.loss;
          this.profit = response.data.body.coordinate.profit;
          this.plotData = {
            coordinate: response.data.body.coordinate,
            Ltp: Math.floor(this.spotPrice),
            pl_targeted: response.data.body.pl_targeted,
          };
        });
    },
    closeModal() {
      // this.$router.push({name: 'Position'})
      this.$emit("close");
      this.isDisabled = false;
      this.isSuccessVisible = false;
    },
    close() {
      this.isDisabled = false;
      this.items = [];
      this.data = [];
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 570px) {
  .modal {
    width: 400px !important;
  }
}
.modal {
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  height: 530px;
  width: 600px;
}
#line-chart {
  width: 550px;
  height: 345px;
}
.graph-data {
  padding: 25px;
}
.close-btn {
  float: right;
  /* margin-top: 5px; */
  margin-right: 5px;
}
.caption-text {
  float: left;
  font-size: 16px;
  margin-left: 25px;
  margin-top: 25px;
}
.modal-spot-price,
.target-price {
  font-weight: 600;
}
.modal-spot-price {
  display: inline-block;
}
.meta-info {
  margin: 0 15px !important;
  font-size: 14px;
}

@media screen and (max-width: 570px) {
  #line-chart {
    width: 100%;
  }
  .graph-data {
    padding: 16px;
    margin-bottom: 11px;
  }
  .caption-text[data-v-10baecaf] {
    float: left;
    font-size: 19px;
    margin-left: 25px;
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .highcharts-plot-line-label {
    margin-top: 5px;
  }
}
.price-input {
  border: 1px solid #000;
  border-radius: 2px;
  width: 80px;
}
.value-button {
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  margin: 0px;
  width: 30px;
  height: 24px;
  text-align: center;
  vertical-align: middle;
  padding: 2px 2px;
  background: #eee;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease {
  margin-right: -4px;
  border-radius: 8px 0 0 8px;
}

form #increase {
  margin-left: -4px;
  border-radius: 0 8px 8px 0;
}

form #input-wrap {
  margin: 0px;
  padding: 0px;
}

input#number {
  text-align: center;
  border: 1px solid #000;
  border-radius: 2px;
  margin: 0px;
  width: 50px;
  height: 24px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
