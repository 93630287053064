<template>
  <div class="position-container">
    <ExitOrderModal
      v-if="isModalVisible"
      @close="closeModal"
      :data="modalData"
      :availableFund="availableFund"
    >
      <template v-slot:header> </template>

      <!-- <template v-slot:body>
        {{modalData}}
      </template> -->

      <template v-slot:footer> This is a new modal footer. </template>
    </ExitOrderModal>

    <GraphModal v-if="showDataChart" @close="closeGraph" :data="graphData">
      <template v-slot:header> </template>

      <template v-slot:body>
        {{ modalData }}
      </template>

      <template v-slot:footer> This is a new modal footer. </template>
    </GraphModal>
    <!-- <GraphAndDaysModal
      v-if="showDataChart"
      :visible="showDataChart"
      :strategyData="strategyData"
      :selectedInstrument="stock"
      @close="showDataChart = false"
      :targetDays="targetDays"
    /> -->

    <div class="switch-position">
      Symbols
      <v-switch
        @change="switchChange($event)"
        color="var(--primary-button-color)"
      ></v-switch
      >Hedged
      <v-input style="margin-bottom: 8px"> </v-input>
    </div>
    <div class="title-text">Position</div>
    <div class="main-content">
      <v-row class="container mx-sm-auto ma-0" no-gutters>
        <v-col class="card-area pr-sm-6" cols="12" md="9">
          <!-- HEDGED SECTION -->
          <span v-show="showHedged">Hedged</span>
          <div
            class="no-position"
            v-if="!stockList.hedged.length || !stockList"
            v-show="showHedged"
          >
            <img src="@/assets/no-position.png" alt="" />
            <div class="no-position-text">There are no Hedged Position</div>
          </div>
          <!-- Main Loop -->
          <div
            v-show="showHedged"
            v-for="(stock, main_index) in stockList.hedged"
            :key="'h' + main_index"
            class="card-body"
          >
            <div class="main-instrument">
              <v-container>
                <!-- Row for Header data -->
                <v-row>
                  <v-col cols="1" md="1"></v-col>
                  <v-col cols="11" md="11" class="column-adjust ml-auto">
                    <v-row>
                      <v-col cols="7" sm="6" class="column-adjust">
                        <v-row>
                          <v-col cols="6" class="column-adjust symbol-column">
                            <div class="main-header">Instrument</div>
                            <div class="main-header-data">
                              {{ stock.symbol }}
                              <span v-show="showHedged" class="strategy">
                                <img
                                  src="@/assets/position-arrow.png"
                                  class="item-resize"
                                />
                                {{ stock.strategy }}
                              </span>
                              <!-- <img src="@/assets/position-arrow.png" />  -->
                            </div>
                          </v-col>

                          <v-col cols="3" class="column-adjust">
                            <div class="main-header">Type</div>
                            <div class="main-header-data">
                              {{ stock.prod_type }}
                            </div>
                          </v-col>

                          <v-col cols="3" class="column-adjust">
                            <div class="main-header">Trend Predictor</div>
                            <div
                              class="main-header-data"
                              :class="{
                                'bullish-text':
                                  stock.trend.toLowerCase() == 'bullish',
                                'bearish-text':
                                  stock.trend.toLowerCase() == 'bearish',
                                'neutral-text':
                                  stock.trend.toLowerCase() == 'neutral',
                              }"
                            >
                              {{ stock.trend }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="5" sm="6" class="column-adjust">
                        <v-row style="max-width: 100%">
                          <v-col cols="5" md="3" class="column-adjust">
                            <div class="main-header">Margin</div>
                            <div class="main-header-data">
                              <span v-if="hedgeMargin.length > 0">{{
                                hedgeMargin["main_index"]
                              }}</span>
                              <span v-else>NA</span>
                            </div>
                          </v-col>

                          <v-col
                            cols="5"
                            md="4"
                            class="column-adjust"
                            style="max-width: 115px"
                          >
                            <div class="main-header hidden-sm-and-down">
                              Profit/Loss
                            </div>
                            <div class="main-header hidden-md-and-up">P/L</div>
                            <div class="main-header-data">
                              <span
                                class="sell-text"
                                v-if="getCardProfit(stock) < 0"
                                >{{ getCardProfit(stock) }}</span
                              >
                              <span class="buy-text" v-else>{{
                                getCardProfit(stock)
                              }}</span>
                            </div>
                          </v-col>

                          <v-col
                            cols="5"
                            class="column-adjust hidden-sm-and-down content-center"
                          >
                            <button
                              @click="
                                showChart(
                                  stock.positions,
                                  stock.param_list,
                                  getCardProfit(stock),
                                  stock.strategy
                                    ? stock.strategy
                                    : stock.prod_type,
                                  stock.fut_code,
                                  stock.exchange
                                )
                              "
                              class="rounded-pill elevation-2"
                              style="width: 40%"
                            >
                              <img src="@/assets/show_chart_btn.png" alt="" />
                            </button>
                            <v-btn
                              @click="placeOrder(stock.positions)"
                              small
                              class="text-capitalize red-btn rounded-pill ma-2"
                              style="color: white; width: 40%"
                              color="#EC3B35"
                            >
                              Exit
                            </v-btn>
                            <span
                              class="content-center"
                              v-if="stock.positions.length > 0"
                              style="width: 20%"
                            >
                              <img
                                @click="expand(main_index, 'hedge')"
                                style="margin: auto"
                                v-if="!hExpandFlag[main_index]"
                                src="@/assets/down.png"
                                alt=""
                              />
                              <img
                                @click="compress(main_index, 'hedge')"
                                style="margin: auto"
                                v-if="hExpandFlag[main_index]"
                                src="@/assets/up.png"
                                alt=""
                              />
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- HR tag for seperation -->
                <v-row
                  v-show="hLimit[main_index] > -1"
                  style="opacity: 0.5"
                  class="mt-0"
                >
                  <v-col><hr /></v-col>
                </v-row>

                <div class="desktop-view" v-show="hLimit[main_index] > -1">
                  <!-- Inner headings section  -->
                  <v-row
                    class="main-header text-left"
                    v-show="hLimit[main_index] > -1"
                  >
                    <v-col cols="1" md="1"></v-col>
                    <v-col cols="11" md="11" class="column-adjust ml-auto">
                      <v-row>
                        <v-col cols="6" md="6" class="column-adjust">
                          <v-row>
                            <v-col cols="6" class="column-adjust symbol-column">
                              Instrument
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust lix-col"
                              style="opacity: 0.4"
                            >
                              LIX
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    class="tip-tool"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <img
                                      height="15px"
                                      width="15px"
                                      src="@/assets/black_info.png"
                                      alt=""
                                      class="item-resize"
                                    />
                                  </span>
                                </template>
                                <span class="liquidity-index">
                                  Liquidity Index: Computed using<br />
                                  the market depth of the instrument.</span
                                >
                              </v-tooltip>
                            </v-col>

                            <v-col cols="3" class="column-adjust"> Qty </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" md="6" class="column-adjust">
                          <v-row>
                            <v-col cols="3" class="column-adjust">
                              Buy/Sell
                            </v-col>
                            <v-col cols="3" class="column-adjust"> LTP </v-col>

                            <v-col cols="6" class="column-adjust">
                              <v-row>
                                <v-col cols="6" class="offset-1">P/L</v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Inner loop -->
                  <v-row
                    class="position-data text-left"
                    v-for="(instrument, index) in stock.positions"
                    :key="'hs' + index"
                    v-show="index <= hLimit[main_index]"
                  >
                    <v-col cols="1" md="1" v-show="index <= hLimit[main_index]">
                      <v-checkbox
                        v-model="instrument.selected"
                        class="item-resize item-checkbox"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="11" md="11" class="column-adjust">
                      <v-row>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col
                              cols="6"
                              class="column-adjust symbol-column"
                              v-show="index <= hLimit[main_index]"
                            >
                              {{ instrument.formatted_ins_name }}
                              <span v-if="!stock.in_trend">
                                <img
                                  class="lix-warning item-resize"
                                  height="10px"
                                  width="10px"
                                  src="@/assets/warning.png"
                                  alt=""
                                />
                              </span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= hLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">LIX:</span>  -->
                              <span v-if="hLixValue[main_index]">
                                {{
                                  parseInt(
                                    hLixValue[main_index][index]["lix_value"]
                                  )
                                }}
                                <span
                                  v-if="
                                    hLixValue[main_index][index]['lix_value'] <
                                    5
                                  "
                                >
                                  <img
                                    class="lix-warning item-resize"
                                    height="10px"
                                    width="10px"
                                    src="@/assets/warning.png"
                                    alt=""
                                  />
                                </span>
                              </span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= hLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">Qty:</span> -->
                              <span
                                class="sell-text"
                                v-if="instrument.net_quantity < 0"
                                >{{ instrument.net_quantity }}</span
                              >
                              <span class="buy-text" v-else>{{
                                instrument.net_quantity
                              }}</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= hLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">Buy/Sell:</span> -->
                              <span
                                v-if="
                                  instrument.actual_average_sell_price ||
                                  instrument.average_sell_price
                                "
                                >{{
                                  parseFloat(instrument.average_sell_price)
                                    ? parseFloat(instrument.average_sell_price)
                                    : parseFloat(
                                        instrument.actual_average_sell_price
                                      )
                                }}</span
                              >
                              <span v-else>{{
                                parseFloat(instrument.average_buy_price)
                                  ? parseFloat(instrument.average_buy_price)
                                  : parseFloat(
                                      instrument.actual_average_buy_price
                                    )
                              }}</span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-if="
                                compactMarketDataScripts(
                                  instrument.instrument_token
                                )['details']
                              "
                              v-show="index <= hLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">LTP:</span> -->
                              <span v-if="changePrice">
                                <span
                                  class="sell-text"
                                  v-if="
                                    getChangePer(instrument.instrument_token) <
                                    0
                                  "
                                  >{{
                                    parseFloat(
                                      compactMarketDataScripts(
                                        instrument.instrument_token
                                      )["details"]["Last Traded Price"]
                                    ).toFixed(2)
                                  }}({{
                                    getChangePer(instrument.instrument_token)
                                  }}%)</span
                                >
                                <span class="buy-text" v-else
                                  >{{
                                    parseFloat(
                                      compactMarketDataScripts(
                                        instrument.instrument_token
                                      )["details"]["Last Traded Price"]
                                    ).toFixed(2)
                                  }}({{
                                    getChangePer(instrument.instrument_token)
                                  }}%)</span
                                >
                              </span>
                            </v-col>

                            <v-col cols="6" class="column-adjust">
                              <v-row>
                                <v-col
                                  cols="6"
                                  class="offset-1"
                                  v-show="index <= hLimit[main_index]"
                                >
                                  <!-- <span class="mobile-view">P/L:</span> -->
                                  <!-- comment next line
                                  <span class="sell-text" v-if = "instrument.profitLoss < 0" >{{instrument.profitLoss}}</span> -->
                                  <span
                                    class="sell-text"
                                    v-if="getProfitLoss(instrument) < 0"
                                    >{{ getProfitLoss(instrument) }}</span
                                  >
                                  <span class="buy-text" v-else>{{
                                    getProfitLoss(instrument)
                                  }}</span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <!-- Mobile view section -->
                <div
                  class="mobile-view scrollable-inner-section"
                  v-if="hLimit[main_index] > -1"
                >
                  <v-simple-table class="text-left" dense>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Instrument</th>
                        <th class="lix-col">
                          LIX
                          <v-tooltip top style="opacity: 0.4">
                            <template v-slot:activator="{ on, attrs }">
                              <span class="tip-tool" v-bind="attrs" v-on="on">
                                <img
                                  height="15px"
                                  width="15px"
                                  src="@/assets/black_info.png"
                                  alt=""
                                  class="item-resize"
                                />
                              </span>
                            </template>
                            <span class="liquidity-index">
                              Liquidity Index: Computed using<br />
                              the market depth of the instrument.</span
                            >
                          </v-tooltip>
                        </th>
                        <th>QTY</th>
                        <th>BUY/SELL</th>
                        <th>LTP</th>
                        <th>P/L</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(instrument, index) in stock.positions"
                        :key="'hs' + index"
                        v-show="index <= hLimit[main_index]"
                      >
                        <td>
                          <v-checkbox
                            class="item-resize"
                            v-model="instrument.selected"
                            style="max-height: 12px"
                          ></v-checkbox>
                        </td>

                        <td style="font-size: 10px">
                          {{ instrument.formatted_ins_name }}
                          <span v-if="!stock.in_trend">
                            <img
                              class="lix-warning item-resize"
                              height="10px"
                              width="10px"
                              src="@/assets/warning.png"
                              alt=""
                            />
                          </span>
                        </td>

                        <td style="font-size: 10px">
                          <span v-if="hLixValue[main_index]">
                            {{
                              parseInt(
                                hLixValue[main_index][index]["lix_value"]
                              )
                            }}
                            <span
                              v-if="
                                hLixValue[main_index][index]['lix_value'] < 5
                              "
                            >
                              <img
                                class="lix-warning item-resize"
                                height="10px"
                                width="10px"
                                src="@/assets/warning.png"
                                alt=""
                              />
                            </span>
                          </span>
                        </td>
                        <td style="font-size: 10px">
                          <span
                            class="sell-text"
                            v-if="instrument.net_quantity < 0"
                            >{{ instrument.net_quantity }}</span
                          >
                          <span class="buy-text" v-else>{{
                            instrument.net_quantity
                          }}</span>
                        </td>
                        <td style="font-size: 10px">
                          <span
                            v-if="
                              instrument.actual_average_sell_price ||
                              instrument.average_sell_price
                            "
                            >{{
                              parseFloat(instrument.average_sell_price)
                                ? parseFloat(instrument.average_sell_price)
                                : parseFloat(
                                    instrument.actual_average_sell_price
                                  )
                            }}</span
                          >
                          <span v-else>{{
                            parseFloat(instrument.average_buy_price)
                              ? parseFloat(instrument.average_buy_price)
                              : parseFloat(instrument.actual_average_buy_price)
                          }}</span>
                        </td>
                        <td
                          style="font-size: 10px"
                          v-if="
                            compactMarketDataScripts(
                              instrument.instrument_token
                            )['details']
                          "
                        >
                          <span v-if="changePrice">
                            <span
                              class="sell-text"
                              v-if="
                                getChangePer(instrument.instrument_token) < 0
                              "
                              >{{
                                parseFloat(
                                  compactMarketDataScripts(
                                    instrument.instrument_token
                                  )["details"]["Last Traded Price"]
                                ).toFixed(2)
                              }}({{
                                getChangePer(instrument.instrument_token)
                              }}%)</span
                            >
                            <span class="buy-text" v-else
                              >{{
                                parseFloat(
                                  compactMarketDataScripts(
                                    instrument.instrument_token
                                  )["details"]["Last Traded Price"]
                                ).toFixed(2)
                              }}({{
                                getChangePer(instrument.instrument_token)
                              }}%)</span
                            >
                          </span>
                        </td>
                        <td style="font-size: 10px">
                          <span
                            class="sell-text"
                            v-if="getProfitLoss(instrument) < 0"
                            >{{ getProfitLoss(instrument) }}</span
                          >
                          <span class="buy-text" v-else>{{
                            getProfitLoss(instrument)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                <!-- Mobile view ends -->

                <!-- Exit button for mobile -->
                <v-row mt-1>
                  <v-col
                    cols="6"
                    md="6"
                    class="column-adjust hidden-md-and-up content-center ml-auto py-0 mt-2"
                    style="max-width: 200px"
                  >
                    <button
                      @click="
                        showChart(
                          stock.positions,
                          stock.param_list,
                          getCardProfit(stock),
                          stock.strategy ? stock.strategy : stock.prod_type,
                          stock.fut_code,
                          stock.exchange
                        )
                      "
                      class="rounded-pill elevation-2"
                      style="width: 40%; height: 23px"
                    >
                      <img
                        src="@/assets/show_chart_btn.png"
                        alt=""
                        class="item-resize"
                      />
                    </button>
                    <v-btn
                      @click="placeOrder(stock.positions)"
                      small
                      class="text-capitalize red-btn rounded-pill ma-2"
                      style="
                        color: white;
                        width: 40%;
                        height: 23px;
                        font-size: 10px;
                      "
                      color="#EC3B35"
                    >
                      Exit
                    </v-btn>
                    <span
                      class="content-center"
                      v-if="stock.positions.length > 0"
                      style="width: 20%"
                    >
                      <img
                        @click="expand(main_index, 'hedge')"
                        style="margin: auto"
                        v-if="!hExpandFlag[main_index]"
                        src="@/assets/down.png"
                        alt=""
                      />
                      <img
                        @click="compress(main_index, 'hedge')"
                        style="margin: auto"
                        v-if="hExpandFlag[main_index]"
                        src="@/assets/up.png"
                        alt=""
                      />
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>

          <!-- UNHEDGED SECTION -->
          <span :style="{ opacity: showHedged ? 1 : 0 }"> Unhedged</span>
          <div
            class="no-position"
            v-if="!stockList.unhedged.length || !stockList"
          >
            <img src="@/assets/no-position.png" alt="" />
            <div class="no-position-text">There are no Open Position.</div>
          </div>
          <!-- Main Loop -->
          <div
            v-for="(stock, main_index) in stockList.unhedged"
            :key="'u' + main_index"
            class="card-body"
          >
            <div class="main-instrument">
              <v-container>
                <!-- Row for Header data -->
                <v-row>
                  <v-col cols="1" md="1"></v-col>
                  <v-col cols="11" md="11" class="column-adjust ml-auto">
                    <v-row>
                      <v-col cols="7" sm="6" class="column-adjust">
                        <v-row>
                          <v-col cols="6" class="column-adjust symbol-column">
                            <div class="main-header">Instrument</div>
                            <div class="main-header-data">
                              {{ stock.symbol }}
                              <span v-show="showHedged" class="strategy">
                                <img
                                  src="@/assets/position-arrow.png"
                                  class="item-resize"
                                />
                                {{ stock.strategy }}
                              </span>
                              <!-- <img src="@/assets/position-arrow.png" />  -->
                            </div>
                          </v-col>

                          <v-col cols="3" class="column-adjust">
                            <div class="main-header">Type</div>
                            <div class="main-header-data">
                              {{ stock.prod_type }}
                            </div>
                          </v-col>

                          <v-col cols="3" class="column-adjust">
                            <div class="main-header">Trend Predictor</div>
                            <div
                              class="main-header-data"
                              :class="{
                                'bullish-text':
                                  stock.trend.toLowerCase() == 'bullish',
                                'bearish-text':
                                  stock.trend.toLowerCase() == 'bearish',
                                'neutral-text':
                                  stock.trend.toLowerCase() == 'neutral',
                              }"
                            >
                              {{ stock.trend }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="5" sm="6" class="column-adjust">
                        <v-row style="max-width: 100%">
                          <v-col cols="5" md="3" class="column-adjust">
                            <div class="main-header">Margin</div>
                            <div class="main-header-data">
                              <span v-if="unhedgedMargin.length > 0">{{
                                unhedgedMargin[main_index]
                              }}</span>
                              <span v-else>NA</span>
                            </div>
                          </v-col>

                          <v-col
                            cols="5"
                            md="4"
                            class="column-adjust"
                            style="max-width: 115px"
                          >
                            <div class="main-header hidden-sm-and-down">
                              Profit/Loss
                            </div>
                            <div class="main-header hidden-md-and-up">P/L</div>
                            <div class="main-header-data">
                              <span
                                class="sell-text"
                                v-if="getCardProfit(stock) < 0"
                                >{{ getCardProfit(stock) }}</span
                              >
                              <span class="buy-text" v-else>{{
                                getCardProfit(stock)
                              }}</span>
                            </div>
                          </v-col>

                          <v-col
                            cols="5"
                            class="column-adjust hidden-sm-and-down content-center"
                          >
                            <button
                              @click="
                                showChart(
                                  stock.positions,
                                  stock.param_list,
                                  getCardProfit(stock),
                                  stock.strategy
                                    ? stock.strategy
                                    : stock.prod_type,
                                  stock.fut_code,
                                  stock.exchange
                                )
                              "
                              class="rounded-pill elevation-2"
                              style="width: 40%"
                            >
                              <img src="@/assets/show_chart_btn.png" alt="" />
                            </button>
                            <v-btn
                              @click="placeOrder(stock.positions)"
                              small
                              class="text-capitalize red-btn rounded-pill ma-2"
                              style="color: white; width: 40%"
                              color="#EC3B35"
                            >
                              Exit
                            </v-btn>
                            <span
                              class="content-center"
                              v-if="stock.positions.length > 0"
                              style="width: 20%"
                            >
                              <img
                                @click="expand(main_index, 'unhedge')"
                                style="margin: auto"
                                v-if="!uExpandFlag[main_index]"
                                src="@/assets/down.png"
                                alt=""
                              />
                              <img
                                @click="compress(main_index, 'unhedge')"
                                style="margin: auto"
                                v-if="uExpandFlag[main_index]"
                                src="@/assets/up.png"
                                alt=""
                              />
                            </span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!-- HR tag for seperation -->
                <v-row
                  v-if="uLimit[main_index] > -1"
                  style="opacity: 0.4"
                  class="mt-0"
                >
                  <v-col><hr /></v-col>
                </v-row>

                <!-- Desktop view -->
                <div class="desktop-view" v-if="uLimit[main_index] > -1">
                  <!-- Inner headings section  -->
                  <v-row
                    class="main-header text-left"
                    v-show="uLimit[main_index] > -1"
                  >
                    <v-col cols="1" md="1"></v-col>
                    <v-col cols="11" md="11" class="column-adjust ml-auto">
                      <v-row>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col cols="6" class="column-adjust symbol-column">
                              Instrument
                            </v-col>

                            <v-col cols="3" class="column-adjust lix-col">
                              LIX
                              <v-tooltip top style="opacity: 0.4">
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    class="tip-tool"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <img
                                      height="15px"
                                      width="15px"
                                      src="@/assets/black_info.png"
                                      alt=""
                                      class="item-resize"
                                    />
                                  </span>
                                </template>
                                <span class="liquidity-index">
                                  Liquidity Index: Computed using<br />
                                  the market depth of the instrument.</span
                                >
                              </v-tooltip>
                            </v-col>

                            <v-col cols="3" class="column-adjust"> Qty </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col cols="3" class="column-adjust">
                              Buy/Sell
                            </v-col>
                            <v-col cols="3" class="column-adjust"> LTP </v-col>

                            <v-col cols="6" class="column-adjust">
                              <v-row>
                                <v-col cols="6" class="offset-1">P/L</v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- Inner loop -->
                  <v-row
                    class="position-data text-left"
                    v-for="(instrument, index) in stock.positions"
                    :key="'us' + index"
                    v-show="index <= uLimit[main_index]"
                  >
                    <v-col cols="1" md="1" v-show="index <= uLimit[main_index]">
                      <v-checkbox
                        v-model="instrument.selected"
                        class="item-resize item-checkbox"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="11" md="11" xm-11 class="column-adjust">
                      <v-row>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col
                              cols="6"
                              class="column-adjust symbol-column"
                              v-show="index <= uLimit[main_index]"
                            >
                              {{ instrument.formatted_ins_name }}
                              <span
                                v-if="
                                  (!instrument.in_trend & !showHedged) |
                                    (!stock.in_trend & showHedged)
                                "
                              >
                                <img
                                  class="lix-warning item-resize"
                                  height="10px"
                                  width="10px"
                                  src="@/assets/warning.png"
                                  alt=""
                                />
                              </span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= uLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">LIX:</span>  -->
                              <span v-if="uLixValue[main_index]"
                                >{{
                                  parseInt(
                                    uLixValue[main_index][index]["lix_value"]
                                  )
                                }}
                                <span
                                  v-if="
                                    uLixValue[main_index][index]['lix_value'] <
                                    5
                                  "
                                >
                                  <img
                                    class="lix-warning item-resize"
                                    height="10px"
                                    width="10px"
                                    src="@/assets/warning.png"
                                    alt=""
                                  />
                                </span>
                              </span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= uLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">Qty:</span> -->
                              <span
                                class="sell-text"
                                v-if="instrument.net_quantity < 0"
                                >{{ instrument.net_quantity }}</span
                              >
                              <span class="buy-text" v-else>{{
                                instrument.net_quantity
                              }}</span>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="6" class="column-adjust">
                          <v-row>
                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-show="index <= uLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">Buy/Sell:</span> -->
                              <span
                                v-if="
                                  instrument.actual_average_sell_price ||
                                  instrument.average_sell_price
                                "
                                >{{
                                  parseFloat(instrument.average_sell_price)
                                    ? parseFloat(instrument.average_sell_price)
                                    : parseFloat(
                                        instrument.actual_average_sell_price
                                      )
                                }}</span
                              >
                              <span v-else>{{
                                parseFloat(instrument.average_buy_price)
                                  ? parseFloat(instrument.average_buy_price)
                                  : parseFloat(
                                      instrument.actual_average_buy_price
                                    )
                              }}</span>
                            </v-col>

                            <v-col
                              cols="3"
                              class="column-adjust"
                              v-if="
                                compactMarketDataScripts(
                                  instrument.instrument_token
                                )['details']
                              "
                              v-show="index <= uLimit[main_index]"
                            >
                              <!-- <span class="mobile-view">LTP:</span> -->
                              <span v-if="changePrice">
                                <span
                                  class="sell-text"
                                  v-if="
                                    getChangePer(instrument.instrument_token) <
                                    0
                                  "
                                  >{{
                                    parseFloat(
                                      compactMarketDataScripts(
                                        instrument.instrument_token
                                      )["details"]["Last Traded Price"]
                                    ).toFixed(2)
                                  }}({{
                                    getChangePer(instrument.instrument_token)
                                  }}%)</span
                                >
                                <span class="buy-text" v-else
                                  >{{
                                    parseFloat(
                                      compactMarketDataScripts(
                                        instrument.instrument_token
                                      )["details"]["Last Traded Price"]
                                    ).toFixed(2)
                                  }}({{
                                    getChangePer(instrument.instrument_token)
                                  }}%)</span
                                >
                              </span>
                            </v-col>

                            <v-col cols="6" class="column-adjust">
                              <v-row>
                                <v-col
                                  cols="6"
                                  class="offset-1"
                                  v-show="index <= uLimit[main_index]"
                                >
                                  <!-- <span class="mobile-view">P/L:</span> -->
                                  <!-- comment next line
                                  <span class="sell-text" v-if = "instrument.profitLoss < 0" >{{instrument.profitLoss}}</span> -->
                                  <span
                                    class="sell-text"
                                    v-if="getProfitLoss(instrument) < 0"
                                    >{{ getProfitLoss(instrument) }}</span
                                  >
                                  <span class="buy-text" v-else>{{
                                    getProfitLoss(instrument)
                                  }}</span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>

                <!-- Mobile view -->
                <div
                  class="mobile-view scrollable-inner-section"
                  v-if="uLimit[main_index] > -1"
                >
                  <v-simple-table class="text-left" dense>
                    <thead class="table-mobile-headers">
                      <tr>
                        <th></th>
                        <th>Instrument</th>
                        <th class="lix-col">
                          LIX
                          <v-tooltip top style="opacity: 0.4">
                            <template v-slot:activator="{ on, attrs }">
                              <span class="tip-tool" v-bind="attrs" v-on="on">
                                <img
                                  height="15px"
                                  width="15px"
                                  src="@/assets/black_info.png"
                                  alt=""
                                  class="item-resize"
                                />
                              </span>
                            </template>
                            <span class="liquidity-index">
                              Liquidity Index: Computed using<br />
                              the market depth of the instrument.</span
                            >
                          </v-tooltip>
                        </th>
                        <th>QTY</th>
                        <th>BUY/SELL</th>
                        <th>LTP</th>
                        <th>P/L</th>
                      </tr>
                    </thead>
                    <tbody class="table-mobile-data">
                      <tr
                        v-for="(instrument, index) in stock.positions"
                        :key="'us' + index"
                        v-show="index <= uLimit[main_index]"
                      >
                        <td>
                          <v-checkbox
                            v-model="instrument.selected"
                            class="item-resize"
                            style="max-height: 12px"
                          ></v-checkbox>
                        </td>
                        <td style="font-size: 10px">
                          {{ instrument.formatted_ins_name }}
                          <span
                            v-if="
                              (!instrument.in_trend & !showHedged) |
                                (!stock.in_trend & showHedged)
                            "
                          >
                            <img
                              class="lix-warning item-resize"
                              height="10px"
                              width="10px"
                              src="@/assets/warning.png"
                              alt=""
                            />
                          </span>
                        </td>

                        <td style="font-size: 10px">
                          <span v-if="uLixValue[main_index]"
                            >{{
                              parseInt(
                                uLixValue[main_index][index]["lix_value"]
                              )
                            }}
                            <span
                              v-if="
                                uLixValue[main_index][index]['lix_value'] < 5
                              "
                            >
                              <img
                                class="lix-warning item-resize"
                                height="10px"
                                width="10px"
                                src="@/assets/warning.png"
                                alt=""
                              />
                            </span>
                          </span>
                        </td>

                        <td style="font-size: 10px">
                          <span
                            class="sell-text"
                            v-if="instrument.net_quantity < 0"
                            >{{ instrument.net_quantity }}</span
                          >
                          <span class="buy-text" v-else>{{
                            instrument.net_quantity
                          }}</span>
                        </td>

                        <td style="font-size: 10px">
                          <span
                            v-if="
                              instrument.actual_average_sell_price ||
                              instrument.average_sell_price
                            "
                            >{{
                              parseFloat(instrument.average_sell_price)
                                ? parseFloat(instrument.average_sell_price)
                                : parseFloat(
                                    instrument.actual_average_sell_price
                                  )
                            }}</span
                          >
                          <span v-else>{{
                            parseFloat(instrument.average_buy_price)
                              ? parseFloat(instrument.average_buy_price)
                              : parseFloat(instrument.actual_average_buy_price)
                          }}</span>
                        </td>

                        <td style="font-size: 10px">
                          <span v-if="changePrice">
                            <span
                              class="sell-text"
                              v-if="
                                getChangePer(instrument.instrument_token) < 0
                              "
                              >{{
                                parseFloat(
                                  compactMarketDataScripts(
                                    instrument.instrument_token
                                  )["details"]["Last Traded Price"]
                                ).toFixed(2)
                              }}({{
                                getChangePer(instrument.instrument_token)
                              }}%)</span
                            >
                            <span class="buy-text" v-else
                              >{{
                                parseFloat(
                                  compactMarketDataScripts(
                                    instrument.instrument_token
                                  )["details"]["Last Traded Price"]
                                ).toFixed(2)
                              }}({{
                                getChangePer(instrument.instrument_token)
                              }}%)</span
                            >
                          </span>
                        </td>

                        <td style="font-size: 10px">
                          <span
                            class="sell-text"
                            v-if="getProfitLoss(instrument) < 0"
                            >{{ getProfitLoss(instrument) }}</span
                          >
                          <span class="buy-text" v-else>{{
                            getProfitLoss(instrument)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                <!-- Mobile view section end -->

                <!-- Exit button for mobile -->
                <v-row mt-1>
                  <v-col
                    cols="6"
                    md="6"
                    class="column-adjust hidden-md-and-up ml-auto py-0 mt-2 content-center"
                    style="max-width: 200px"
                  >
                    <button
                      @click="
                        showChart(
                          stock.positions,
                          stock.param_list,
                          getCardProfit(stock),
                          stock.strategy ? stock.strategy : stock.prod_type,
                          stock.fut_code,
                          stock.exchange
                        )
                      "
                      class="rounded-pill elevation-2"
                      style="width: 40%; height: 23px"
                    >
                      <img
                        src="@/assets/show_chart_btn.png"
                        alt=""
                        class="item-resize"
                      />
                    </button>
                    <v-btn
                      @click="placeOrder(stock.positions)"
                      small
                      class="text-capitalize red-btn rounded-pill ma-2"
                      style="
                        color: white;
                        width: 40%;
                        height: 23px;
                        font-size: 10px;
                      "
                      color="#EC3B35"
                    >
                      Exit
                    </v-btn>
                    <span
                      class="content-center"
                      v-if="stock.positions.length > 0"
                      style="width: 20%"
                    >
                      <img
                        @click="expand(main_index, 'unhedge')"
                        style="margin: auto"
                        v-if="!uExpandFlag[main_index]"
                        src="@/assets/down.png"
                        alt=""
                      />
                      <img
                        @click="compress(main_index, 'unhedge')"
                        style="margin: auto"
                        v-if="uExpandFlag[main_index]"
                        src="@/assets/up.png"
                        alt=""
                      />
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </v-col>

        <!-- Graph and warnings -->
        <v-col cols="12" md="3" class="pl-sm-6">
          <!-- Warning section -->
          <div
            class="liquidity-warning position-warnings d-flex align-center pr-1 px-md-2"
            v-if="
              (uLowLiquidityCounter + hLowLiquidityCounter > 0) & close_toast
            "
          >
            <span class="ml-0">
              <img
                class="lix-warning item-resize"
                height="15px"
                width="15px"
                src="@/assets/warning.png"
                alt=""
              />
            </span>
            <span class="ml-3 black--text">
              {{ uLowLiquidityCounter + hLowLiquidityCounter }}
              <span v-if="uLowLiquidityCounter + hLowLiquidityCounter == 1"
                >position is with low liquidity</span
              >
              <span v-else>positions are with Low Liquidity</span>
            </span>
            <span
              class="ml-auto d-flex align-center"
              @click="close_toast = false"
            >
              <img
                class="item-resize"
                height="10px"
                width="10px"
                src="@/assets/close.png"
                alt=""
              />
            </span>
          </div>

          <div
            class="trend-warning position-warnings d-flex align-center pr-1 px-md-2"
            v-if="(againstTrend > 0) & close_another_toast"
          >
            <br />
            <span class="ml-0">
              <img
                class="lix-warning item-resize"
                height="15px"
                width="15px"
                src="@/assets/warning.png"
                alt=""
              />
            </span>
            <span class="ml-3">
              {{ againstTrend }}
              <span v-if="againstTrend == 1"> position is against trend</span>
              <span v-else> positions are against trend</span>
            </span>
            <span
              class="ml-auto d-flex align-center"
              @click="close_another_toast = false"
            >
              <img
                class="item-resize"
                height="10px"
                width="10px"
                src="@/assets/close.png"
                alt=""
              />
            </span>
          </div>
          <!-- Warning section end -->

          <div class="fund-title">Fund Details</div>
          <!-- Fund Card -->
          <v-row class="fund-card content-center">
            <v-col cols="4" md="12" class="chartBox pa-0" v-if="showGraph">
              <canvas id="myChart" class=""> </canvas>
            </v-col>

            <v-col
              cols="8"
              md="12"
              v-if="availableFund == 0"
              class="no-fund content-center"
            >
              <div style="width: 100%">
                <img src="@/assets/no-fund.png" alt="" />
                <div class="oops-text">
                  Oop's You don't have fund. Please
                  <a
                    class="no-fund-link"
                    href="https://ant.aliceblueonline.com/funds"
                    target="_blank"
                  >
                    click here</a
                  >
                  to add funds.
                  <!--Please
                  <button type="button" v-on:click="gotosite(modalDetails)">
                  <a class="no-fund-link"> click here</a>
                  </button> to add funds to your account.-->
                </div>

                <div class="no-fund-text">
                  <span>Available Margin</span>
                  <span class="zero-amount">&#8377;0</span>
                </div>
              </div>
            </v-col>

            <v-col
              cols="8"
              md="12"
              v-if="availableFund > 0"
              class="fund-data mb-md-8"
            >
              <v-row
                class="sell-text warning-section"
                v-if="availableFund / (utilizeFund + availableFund) < 0.1"
              >
                <v-col cols="2" class="data-legend"
                  ><span class="warning-ico"
                    ><img src="@/assets/warning.png" /></span
                ></v-col>
                <v-col cols="8" class="data-legend">
                  Please
                  <a
                    class="no-fund-link"
                    href="https://ant.aliceblueonline.com/funds"
                    target="_blank"
                  >
                    click here</a
                  >
                  to add funds to the account to avoid margin penalty.
                </v-col>
                <v-col></v-col>
              </v-row>

              <v-row>
                <v-col cols="1" md="1" class=""
                  ><span class="avail-dot item-resize"></span
                ></v-col>
                <v-col class="">Available Fund: </v-col>
                <v-col class="amount-section"
                  ><span>{{
                    parseFloat(availableFund).toLocaleString("hi")
                  }}</span></v-col
                >
              </v-row>

              <v-row>
                <v-col cols="1" md="1" class=""
                  ><span class="util-dot item-resize"></span
                ></v-col>
                <v-col class="">Utilized Fund: </v-col>
                <v-col class="amount-section"
                  ><span>{{
                    parseFloat(utilizeFund).toLocaleString("hi")
                  }}</span></v-col
                >
              </v-row>
            </v-col>
          </v-row>

          <!-- Total Profit/Loss -->
          <div class="total-profit">
            <span class="bar-img">
              <img src="@/assets/graph-bar.png" alt="" />
            </span>
            Total Profit/(Loss):
            <span class="sell-text" v-if="getOverAllProfitLoss() < 0">{{
              getOverAllProfitLoss()
            }}</span>
            <span class="buy-text" v-else>{{ getOverAllProfitLoss() }}</span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ExitOrderModal from "./ExitOrderModal";
import GraphModal from "./GraphModal.vue";
import ReconnectingWebSocket from "reconnecting-websocket";
import UserDataMixin from "@/mixins/getUserDataMixin";

// import GraphAndDaysModal from './modals/GraphAndDaysModal.vue';
import {
  getPositions,
  getBalanceCall,
  getPositionCardMargin,
  getLixData,
  getScripQuoteDetails,
} from "./getService";
import Chart from "chart.js/auto";
// import { data } from './sampleData'
import axios from "axios";
export default {
  components: {
    ExitOrderModal,
    GraphModal,
    // GraphAndDaysModal
  },
  mixins:[UserDataMixin],
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    yesterdayPrice: function () {
      return this.$store.getters.yesterdayPrice;
    },
  },

  methods: {
    subscribeNseLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `NSE|${payload}#NSE`,
          t: "d",
          m: "compact_marketdata",
        })
      );
    },
    subscribeMcxLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `MCX|${payload}#MCX`,
          t: "d",
          m: "compact_marketdata",
        })
      );
    },
    subscribeCdsLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `CDS|${payload}#CDS`,
          t: "d",
          m: "compact_marketdata",
        })
      );
    },
    subscribeLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `NFO|${payload}#NFO`,
          t: "d",
          m: "compact_marketdata",
        })
      );
    },
    unsubscribeNseLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `NSE|${payload}#NSE`,
          t: "u",
          m: "compact_marketdata",
        })
      );
    },
    unsubscribeMcxLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `MCX|${payload}#MCX`,
          t: "u",
          m: "compact_marketdata",
        })
      );
    },
    unsubscribeCdsLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `CDS|${payload}#CDS`,
          t: "u",
          m: "compact_marketdata",
        })
      );
    },
    unsubscribeLtp(payload) {
      this.newConnection.send(
        JSON.stringify({
          k: `NFO|${payload}#NFO`,
          t: "u",
          m: "compact_marketdata",
        })
      );
    },
    messageSocket(event) {
      let recv_data = JSON.parse(event.data);
      this.$store.commit("setcompactData", recv_data);
    },
    startWebsoket() {
      let session_token = this.wsSession;
      let user_id = this.userId;
      this.newConnection = new ReconnectingWebSocket(
        "wss://ws1.aliceblueonline.com/NorenWS/"
      );
      this.newConnection.send(
        JSON.stringify({
          susertoken: session_token,
          t: "c",
          actid: user_id + "_API",
          uid: user_id + "_API",
          source: "API",
        })
      );
      this.newConnection.onerror = function () {
        let session_token =this.wsSession;
        let user_id =this.userId;
        this.newConnection = new ReconnectingWebSocket(
          "wss://ws1.aliceblueonline.com/NorenWS/"
        );
        this.newConnection.send(
          JSON.stringify({
            susertoken: session_token,
            t: "c",
            actid: user_id + "_API",
            uid: user_id + "_API",
            source: "API",
          })
        );
        this.newConnection.onmessage = this.messageSocket;
      };
      this.newConnection.onclose = function () {
        this.webSocketConnection = false;
        console.log("Closed");
        let today = new Date();
        if (today.getHours("hi") < 15) {
          setTimeout(() => {
            let session_token =this.wsSession;
            let user_id = this.userId;
            this.newConnection = new ReconnectingWebSocket(
              "wss://ws1.aliceblueonline.com/NorenWS/"
            );
            this.newConnection.send(
              JSON.stringify({
                susertoken: session_token,
                t: "c",
                actid: user_id + "_API",
                uid: user_id + "_API",
                source: "API",
              })
            );
            this.newConnection.onmessage = this.messageSocket;
          }, 1000);
        }
      };
      this.newConnection.addEventListener("open", function () {
        console.log("Open");
      });
      this.newConnection.onmessage = this.messageSocket;
    },
    dragMouseDown: function (event) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },
    elementDrag: function (event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.$refs.draggableContainer.style.top =
        this.$refs.draggableContainer.offsetTop -
        this.positions.movementY +
        "px";
      this.$refs.draggableContainer.style.left =
        this.$refs.draggableContainer.offsetLeft -
        this.positions.movementX +
        "px";
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
    getChangePer(instrument_code) {
      return this.changePrice[String(instrument_code)];
    },
    gotosite(producturl) {
      window.location.href = producturl;
    },
    getOverAllProfitLoss() {
      let totalProfitLoss = 0;
      if (this.stockList.hedged.length > 0) {
        for (let i = 0; i < this.stockList.hedged.length; i++) {
          totalProfitLoss =
            parseFloat(totalProfitLoss) +
            Number(this.getCardProfit(this.stockList.hedged[i]));
        }
      }

      if (this.stockList.unhedged.length > 0) {
        for (let i = 0; i < this.stockList.unhedged.length; i++) {
          totalProfitLoss =
            parseFloat(totalProfitLoss) +
            Number(this.getCardProfit(this.stockList.unhedged[i]));
        }
      }
      return parseFloat(totalProfitLoss).toFixed(2);
    },
    getCardProfit(stock) {
      let cardTotalProfit = 0;
      for (let i = 0; i < stock.positions.length; i++) {
        let profitLoss = this.getProfitLoss(stock.positions[i]);
        cardTotalProfit += parseFloat(profitLoss);
      }
      return parseFloat(cardTotalProfit).toFixed(2);
    },
    expand(index, type) {
      // console.log('expand')
      if (type == "unhedge") {
        this.$set(
          this.uLimit,
          index,
          this.stockList.unhedged[index].positions.length
        );
        this.$set(this.uExpandFlag, index, true);
      }
      if (type == "hedge") {
        this.$set(
          this.hLimit,
          index,
          this.stockList.hedged[index].positions.length
        );
        this.$set(this.hExpandFlag, index, true);
      }
    },
    compress(index, type) {
      // console.log('Compress')
      if (type == "unhedge") {
        this.$set(this.uLimit, index, -1);
        this.$set(this.uExpandFlag, index, false);
        // this.uLimit[index] = this.stockList.unhedged[index].positions.length;
      }
      if (type == "hedge") {
        this.$set(this.hLimit, index, -1);
        this.$set(this.hExpandFlag, index, false);
      }
    },
    async getMargin(param_list) {
      let margin_utilized;
      let margin_data = axios
        .post(getPositionCardMargin, { param_list: param_list })
        .then((res) => {
          return res.data.margin;
        });
      await Promise.allSettled([margin_data]).then((res) => {
        for (let margin of res) {
          margin_utilized = margin.value;
        }
      });
      return margin_utilized;
    },
    getLix(param_list, index, type) {
      let access_token =this.accessToken;
      let ws_token = this.wsSession;
      let user_id = this.userId;
      if (typeof param_list == "undefined") return;
      axios
        .post(getLixData, {
          param_list: param_list,
          access_token,
          ws_token,
          user_id,
        })
        .then((res) => {
          if (type == "unhedged") {
            this.uLowLiquidityCounter = 0;
            this.$set(this.uLixValue, index, res.data.param_list);
            if (this.uLixValue.length > 0) {
              for (let i = 0; i < this.uLixValue.length; i++) {
                for (let j = 0; j < this.uLixValue[i].length; j++) {
                  if (this.uLixValue[i][j]["lix_value"] < 5) {
                    this.uLowLiquidityCounter++;
                  }
                }
              }
            }
          }
          if (type == "hedged") {
            this.hLowLiquidityCounter = 0;
            this.$set(this.hLixValue, index, res.data.param_list);
            if (this.hLixValue.length > 0) {
              for (let i = 0; i < this.hLixValue.length; i++) {
                for (let j = 0; j < this.hLixValue[i].length; j++) {
                  if (this.hLixValue[i][j]["lix_value"] < 5) {
                    this.hLowLiquidityCounter++;
                  }
                }
              }
            }
          }
        });
    },
    createChart() {
      this.graphLoad = true;
      let ctx = document.getElementById("myChart").getContext("2d");
      var gradient = ctx.createLinearGradient(0, 0, 0, 250);
      var gradient1 = ctx.createLinearGradient(0, 0, 0, 250);
      gradient.addColorStop(0.5, "#DF514C");
      gradient.addColorStop(1, "#800400");

      gradient1.addColorStop(0.5, "#4AAF39");
      gradient1.addColorStop(1, "#148800");
      const data = {
        labels: ["Utilized Fund", "Available Fund"],
        datasets: [
          {
            label: [],
            backgroundColor: [gradient, gradient1],
            borderColor: [gradient, gradient1],
            data: [parseInt(this.utilizeFund), parseInt(this.availableFund)],
            cutout: "85%",
            borderRadius: 2,
            // hoverOffset: 10
          },
        ],
      };
      const config = {
        type: "doughnut",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          layout: {
            padding: 30,
          },
        },
      };
      const myChart = new Chart(ctx, config);
      if (this.showGraph) {
        myChart;
        this.graphLoad = false;
      }
    },
    fixToTwo(num) {
      if (num == 0) {
        return "NA";
      }
      return parseFloat(num).toFixed(2);
    },
    getProfitLoss(instrument_obj) {
      let quantity = instrument_obj["net_quantity"];
      if (instrument_obj.symbol == "CRUDEOIL") {
        quantity = quantity * 100;
      }
      if (instrument_obj.symbol == "GOLD") {
        quantity = quantity * 100;
      }
      if (instrument_obj.symbol == "GOLDM") {
        quantity = quantity * 10;
      }
      if (instrument_obj.symbol == "SILVER") {
        quantity = quantity * 30;
      }
      if (instrument_obj.symbol == "SILVERM") {
        quantity = quantity * 5;
      }
      if (instrument_obj.symbol == "NATURALGAS") {
        quantity = quantity * 1250;
      }
      // if(typeof this.compactMarketDataScripts(instrument_obj.instrument_token)['details'] == 'undefined'){
      //   this.getProfitLoss(instrument_obj)
      // }
      let ltp = parseFloat(
        this.compactMarketDataScripts(instrument_obj.instrument_token)[
          "details"
        ]["Last Traded Price"]
      ).toFixed(2);
      let profitLossData = "";
      let avg_price;
      if (quantity < 0) {
        if (instrument_obj["average_sell_price"]) {
          avg_price = instrument_obj["average_sell_price"];
        } else {
          avg_price = instrument_obj["actual_average_sell_price"];
        }
        // avg_price = instrument_obj['average_sell_price'] + instrument_obj['actual_average_sell_price']
        profitLossData = (avg_price - ltp) * Math.abs(quantity);
      } else {
        if (instrument_obj["average_buy_price"]) {
          avg_price = instrument_obj["average_buy_price"];
        } else {
          avg_price = instrument_obj["actual_average_buy_price"];
        }
        // avg_price = instrument_obj['average_buy_price'] + instrument_obj['actual_average_buy_price']
        profitLossData = (ltp - avg_price) * quantity;
      }
      return profitLossData.toFixed(2);
    },
    selectInstrument(event, main_index, index, section) {
      if (event) {
        this.modalData[section][main_index]["positions"][index][
          "selected"
        ] = true;
      } else {
        this.modalData[section][main_index]["positions"][index][
          "selected"
        ] = false;
      }
    },
    async switchChange(event) {
      let accessToken =this.accessToken;
      let user_id =this.userId;
      this.instrument_list = [];
      this.close_toast = true;
      this.close_another_toast = true;
      this.uLowLiquidityCounter = 0;
      this.hLowLiquidityCounter = 0;
      this.sumProfitLoss = 0;
      this.showHedged = event;
      this.againstTrend = 0;
      if (event) {
        this.positionPayload["filter_type"] = "Hedged";
        axios
          .post(getPositions, this.positionPayload)
          .then(async (response) => {
            this.stockList = response.data;
            // this.stockList = this.sampleData
            // console.log(this.stockList)
            if (this.stockList.hedged.length > 0) {
              this.stockList.hedged.forEach((item) => {
                if (!item.in_trend) {
                  this.againstTrend += item.positions.length;
                }
                this.hExpandFlag.push(true);
                this.hLimit.push(item.positions.length);
                const insList=[]
                item.positions.forEach((instrument) => {
                  this.instrument_list.push(instrument.instrument_token);
                  insList.push({
                    code:instrument.instrument_token,
                    exchange:instrument.exchange,
                    loc: this.$route.name
                  })
                  // if (instrument.exchange == "NFO") {
                  //   this.subscribeLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "NSE") {
                  //   this.subscribeNseLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "MCX") {
                  //   this.subscribeMcxLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "CDS") {
                  //   this.subscribeCdsLtp(String(instrument.instrument_token));
                  // }
                });
                this.$store.dispatch("subscribeToInstruments",insList)
              });
            }
            if (this.stockList.unhedged.length > 0) {
              this.stockList.unhedged.forEach((item) => {
                this.uExpandFlag.push(true);
                this.uLimit.push(item.positions.length);
                if (!item.in_trend) {
                  this.againstTrend += item.positions.length;
                }
                // let totalProfitLoss = 0
                const insList=[]
                item.positions.forEach((instrument) => {
                  this.instrument_list.push(instrument.instrument_token);
                  insList.push({
                    code:instrument.instrument_token,
                    exchange:instrument.exchange,
                    loc: this.$route.name
                  })
                  // if (instrument.exchange == "NFO") {
                  //   console.log(instrument.instrument_token);
                  //   this.subscribeLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "NSE") {
                  //   this.subscribeNseLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "MCX") {
                  //   this.subscribeMcxLtp(String(instrument.instrument_token));
                  // }
                  // if (instrument.exchange == "CDS") {
                  //   this.subscribeCdsLtp(String(instrument.instrument_token));
                  // }
                });
                this.$store.dispatch("subscribeToInstruments",insList)
              });
            }
            let header = { Authorization: `Bearer ${user_id} ${accessToken}` };
            for (let item of this.instrument_list) {
              let payload = { exch: "NFO", symbol: String(item) };
              axios
                .post(getScripQuoteDetails, payload, { headers: header })
                .then((res) => {
                  this.$set(this.changePrice, String(item), res.data.PerChange);
                  this.changePrice[String(item)] = res.data.PerChange;
                });
            }
            let index = 0;
            for (let item of this.stockList.unhedged) {
              let margin = await this.getMargin(item.param_list);
              await this.getLix(item.param_list, index, "unhedged");
              index += 1;
              this.unhedgedMargin.push(margin);
              console.log(this.unhedgedMargin);
            }
            index = 0;
            for (let item of this.stockList.hedged) {
              let margin = await this.getMargin(item.param_list);
              console.log(margin);
              await this.getLix(item.param_list, index, "hedged");
              index += 1;
              this.hedgeMargin.push(margin);
            }
          });
      } else {
        this.positionPayload["filter_type"] = "Symbols";
        axios
          .post(getPositions, this.positionPayload)
          .then(async (response) => {
            this.stockList = response.data;
            console.log(this.stockList);
            // console.log(this.stockList)
            if (this.stockList.unhedged.length > 0) {
              this.stockList.unhedged.forEach((item) => {
                this.uExpandFlag.push(true);
                this.uLimit.push(1);
                let totalProfitLoss = 0;
                item.positions.forEach((instrument) => {
                  this.instrument_list.push(instrument.instrument_token);
                  instrument["profitLoss"] = this.getProfitLoss(instrument);
                  totalProfitLoss += parseFloat(instrument["profitLoss"]);
                  if (!instrument["in_trend"]) {
                    this.againstTrend += 1;
                  }
                });
                item["total_profit_loss"] = totalProfitLoss.toFixed(2);
                this.sumProfitLoss += parseFloat(totalProfitLoss);
              });
            }
            let header = { Authorization: `Bearer ${user_id} ${accessToken}` };
            for (let item of this.instrument_list) {
              let payload = { exch: "NFO", symbol: String(item) };
              axios
                .post(getScripQuoteDetails, payload, { headers: header })
                .then((res) => {
                  this.$set(this.changePrice, String(item), res.data.PerChange);
                  this.changePrice[String(item)] = res.data.PerChange;
                });
            }
            let index = 0;
            for (let item of this.stockList.unhedged) {
              let margin = await this.getMargin(item.param_list);
              await this.getLix(item.param_list, index, "unhedged");
              index += 1;
              this.unhedgedMargin.push(margin);
              console.log(this.unhedgedMargin);
            }
          });
      }
    },
    placeOrder(data) {
      this.modalData = data;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    closeGraph() {
      this.showDataChart = false;
    },
    showChart(
      positions,
      data,
      targetProfitLoss,
      trend_name,
      fut_code,
      exchange
    ) {
      this.showDataChart = true;
      this.graphData["exchange"] = exchange;
      this.graphData["targetProfitLoss"] = targetProfitLoss;
      this.graphData["param"] = data;
      this.graphData["positions"] = positions;
      this.graphData["trend_name"] = trend_name;
      this.graphData["fut_code"] = fut_code;
      console.log("graphdata", this.graphData);
    },
    unsubscribeData() {
      for (let ins of this.$store.state.compactMarketData) {
        this.unsubscribeLtp(String(ins.code));
      }
      this.$store.state.compactMarketData = [];
    },
  },
  mounted() {
    console.log(this.$store.state.socket.status);
    this.startWebsoket();
    // this.unsubscribeData()
    let accessToken =this.accessToken;
    let user_id = this.userId;
    let payload = { auth_code: accessToken, userId: user_id };
    this.positionPayload = { access_token: accessToken, user_id: user_id };
    axios.post(getBalanceCall, payload).then((res) => {
      if (res.data.status) {
        this.availableFund = res.data.data.net;
        this.utilizeFund = res.data.data.debits;
        if (this.availableFund > 0) {
          this.showGraph = true;
        } else {
          this.showGraph = false;
        }
        this.createChart();
      } else {
        localStorage.clear();
        this.$router.push({ path: "/" }).then(() => {
          this.$router.go();
        });
      }
      if (this.availableFund > 0) {
        this.showGraph = true;
        this.graphLoad = false;
      } else {
        this.showGraph = false;
        this.graphLoad = true;
      }
    });
    this.positionPayload["filter_type"] = "Symbols";
    axios.post(getPositions, this.positionPayload).then(async (response) => {
      this.stockList = response.data;
      console.log(response.data);
      if (response.data.stat) {
        if (response.data.stat == "Not_Ok") {
          this.$router.push({ name: "Home" });
        }
      }
      if (this.stockList.unhedged?.length > 0) {
        const insList=[]
        this.stockList.unhedged.forEach((item) => {
          this.uExpandFlag.push(true);
          // Initial unhedge section list limit
          this.uLimit.push(item.positions.length);
          insList.push({
            code:item.fut_code,
            exchange:item.positions[0]["exchange"],
            loc: this.$route.name
          })
          // if (item.positions[0]["exchange"] == "NSE") {
          //   this.subscribeNseLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "NFO") {
          //   this.subscribeLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "MCX") {
          //   this.subscribeMcxLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "CDS") {
          //   this.subscribeCdsLtp(String(item.fut_code));
          // }
          item.positions.forEach((instrument) => {
            if (!instrument["in_trend"]) {
              this.againstTrend += 1;
            }
            this.instrument_list.push(instrument.instrument_token);

            insList.push({
              code:instrument.instrument_token,
              exchange:instrument.exchange,
              loc: this.$route.name
            })
            // if (instrument.exchange == "NSE") {
            //   this.subscribeNseLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "NFO") {
            //   this.subscribeLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "MCX") {
            //   this.subscribeMcxLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "CDS") {
            //   this.subscribeCdsLtp(String(instrument.instrument_token));
            // }
          });
        });
        this.$store.dispatch("subscribeToInstruments",insList)
      }
      if (this.stockList.hedged?.length > 0) {
        const insList=[]
        this.stockList.hedged.forEach((item) => {
          this.hExpandFlag.push(true);
          // Initial hedge section list limit
          this.hLimit.push(item.positions.length);
          insList.push({
            code:item.fut_code,
            exchange:item.positions[0]["exchange"],
            loc: this.$route.name
          })
          // if (item.positions[0]["exchange"] == "NSE") {
          //   this.subscribeNseLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "NFO") {
          //   this.subscribeLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "MCX") {
          //   this.subscribeMcxLtp(String(item.fut_code));
          // }
          // if (item.positions[0]["exchange"] == "CDS") {
          //   this.subscribeCdsLtp(String(item.fut_code));
          // }
          item.positions.forEach((instrument) => {
            this.instrument_list.push(instrument.instrument_token);

            insList.push({
              code:instrument.instrument_token,
              exchange:instrument.exchange,
              loc: this.$route.name
            })
            // if (instrument.exchange == "NSE") {
            //   this.subscribeNseLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "NFO") {
            //   this.subscribeLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "MCX") {
            //   this.subscribeMcxLtp(String(instrument.instrument_token));
            // }
            // if (instrument.exchange == "CDS") {
            //   this.subscribeCdsLtp(String(instrument.instrument_token));
            // }
          });
        });
        this.$store.dispatch("subscribeToInstruments",insList)
      }
      let header = { Authorization: `Bearer ${user_id} ${accessToken}` };
      for (let item of this.instrument_list) {
        let payload = { exch: "NFO", symbol: String(item) };
        axios
          .post(getScripQuoteDetails, payload, { headers: header })
          .then((res) => {
            this.$set(this.changePrice, String(item), res.data.PerChange);
            this.changePrice[String(item)] = res.data.PerChange;
          });
      }
      let index = 0;
      if (this.stockList.unhedged) {
        for (let item of this.stockList.unhedged) {
          let margin = await this.getMargin(item.param_list);
          this.getLix(item.param_list, index, "unhedged");
          index += 1;
          this.unhedgedMargin.push(margin);
        }
      }
      index = 0;
      if (this.stockList.hedged) {
        for (let item of this.stockList.hedged) {
          let margin = await this.getMargin(item.param_list);
          this.getLix(item.param_list, index, "hedged");
          index += 1;
          this.hedgeMargin.push(margin);
        }
      }
    });
  },
  data() {
    return {
      newConnection: "",
      changePrice: {},
      instrument_list: [],
      yesterdayPrice1: 0,
      againstTrend: 0,
      close_toast: true,
      graphLoad: true,
      close_another_toast: true,
      uLowLiquidityCounter: 0,
      hLowLiquidityCounter: 0,
      hLixValue: [],
      uLixValue: [],
      graphData: {},
      showDataChart: false,
      cardProfitLoss: 0,
      uExpandFlag: [],
      hExpandFlag: [],
      uLimit: [],
      hLimit: [],
      hedgeMargin: [],
      unhedgedMargin: [],
      chartCounter: 0,
      ctx: null,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      card_hover: [],
      positionPayload: "",
      showGraph: true,
      availableFund: 0,
      utilizeFund: 0,
      modalDetails:
        "https://funds.aliceblueonline.com/?token=" +
        localStorage.getItem("accessTokenAnt") +
        "&user_id=" +
        localStorage.getItem("user_id"),
      getPositions: "http://1lyoptions.inuvest.tech/get_positions_from_broker/",
      accessToken: "",
      showHedged: false,
      modalData: "",
      isModalVisible: false,
      stockList: { hedged: [], unhedged: [] },
      sumProfitLoss: 0,
    };
  },
};
</script>

<style>
@import "../assets/css/global.scss";
div
  .mobile-view.scrollable-inner-section
  > div
  > div
  > table
  > thead
  > tr
  > th {
  font-size: 8px !important;
  font-weight: 600 !important;
  color: var(--option-row-text) !important;
}
div
  .mobile-view.scrollable-inner-section
  > div
  > div
  > table
  > tbody
  > tr
  > td {
  font-size: 9px !important;
  color: var(--text-color) !important;
  font-weight: 500 !important;
}
.v-input--selection-controls {
  margin-top: -5px;
  margin-left: 10px;
}
.content {
  font-size: 30px;
  margin: auto 20%;
}
.switch-position {
  display: flex;
  float: right;
}
.position-title {
  font-size: 25px;
  font-weight: 600;
  float: left;
  display: contents;
}
.position-container {
  font-size: 16px;
  font-weight: 400;
  margin: 0 80px;
  font-family: "Roboto";
}
v-progress-circular {
  transform: rotate(0deg);
  margin-top: -61px;
  margin-left: -132px;
}
.main-content {
  margin-top: 70px;
  /* display: contents; */
  /* float: left; */
}
.card-body {
  /* padding: 0 5px; */
  background-color: var(--cards-background-color);
  border-radius: 12px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 22px;
  /* box-shadow:1px 1px 10px #2196F3; */
  box-shadow: rgba(0, 0, 0, 0.198) 0px 5px 15px;
}
.main-instrument {
  /* padding-bottom: 15px; */
  font-size: 16px;
  padding-bottom: 6px;
  /* border-bottom: #C5C5C5 solid 1px; */
}
.trend-text-title {
  font-size: 14px;
  float: right;
  padding-top: 5px;
  margin-right: 30px;
}
.trend-text {
  color: #2196f3;
}
.heading {
  font-weight: 600;
  font-size: 13px;
  color: #c5c5c5;
  text-align: left;
}
.prod-type {
  font-size: 12px;
  color: #979797;
  font-weight: 400;
}
.position-data {
  /* margin-top: 5px; */
  /* text-align: center; */
  font-family: "Open Sans";
  font-size: 12px;
  color: var(--text-color);
  font-weight: 600;
  /* margin-bottom: -20px; */
}
.lix-warning {
  margin-left: 5px;
}
.extra-info {
  padding: 10px;
  margin-top: 20px;
  font-size: 14px;
}
.margin-data {
  margin: 0 20px;
}
.profit-label {
  margin-left: 20px;
  color: #979797;
  font-size: 14px;
}
.profit-data {
  font-size: 14px;
}
.exit-btn {
  display: flex;
  margin: 0 15px;
  font-size: 14px;
  float: right;
}
.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.position-warnings {
  height: 30px;
  font-size: 12px;
  font-weight: 400;
  background-color: #ffe4e4;
  border: 1px solid #df514c;
  border-radius: 5px;
  position: absolute;
  width: 22%;
}
.liquidity-warning {
  top: 40px;
}
.trend-warning {
  top: 80px;
}
.liquidity-icon {
  margin-right: 10px;
}

.liquidity-close {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 2px;
  margin-left: 5px;
  float: right;
}
.fund-title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
.fund-card {
  background: var(--cards-background-color);
  /* box-shadow: 1px 1px 10px #2196f3; */
  box-shadow: rgba(0, 0, 0, 0.198) 0px 5px 15px;
  border-radius: 12px;
}

.draw-chart {
  text-align: -webkit-center;
  margin-top: 10px;
  padding-top: 20px;
}
.chartBox {
  max-width: 280px;
  margin: auto;
}
.util-dot {
  margin-left: 10px;
  height: 10px;
  width: 10px;
  background-color: var(--red-text);
  border-radius: 50%;
  display: inline-block;
}
.avail-dot {
  margin-left: 10px;
  height: 10px;
  width: 10px;
  background-color: var(--green-text);
  border-radius: 50%;
  display: inline-block;
}
.fund-data {
  font-size: 16px;
  font-weight: 400;
}
.data-legend {
  text-align: center;
}
.buy-text {
  color: var(--green-text);
}
.sell-text {
  color: var(--red-text);
}
.warning-ico {
  margin-left: 117%;
}
.no-fund-link {
  color: var(--primary-button-color) !important;
}
.no-fund-link:hover {
  text-decoration: underline !important;
}
.warning-section {
  margin-bottom: 35px;
  margin-top: 32px;
}
.total-profit {
  font-size: 14px;
  font-weight: 400;
  margin-top: 30px;
}
.bar-img {
  margin-top: 5px;
}
.no-position {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 40px;
}
.no-position-text {
  color: #979797;
  font-size: 16px;
}
.no-fund {
  padding: 15px;
  font-size: 16px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.zero-amount {
  margin-left: 10px;
}
.oops-text {
  font-family: Roboto;
  color: #979797;
  margin-top: 37px;
  font-size: 16px;
}
.no-fund-text {
  margin-top: 16px;
  font-size: 16px;
}
.amount-section {
  font-weight: 600;
  text-align: end;
}
.change-icon {
  cursor: pointer;
  margin-top: 10px;
  /* margin-right: -25px; */
  padding-left: 10px;
}
.show-chart-btn {
  width: 82px;
  margin-top: 8px;
  margin-right: 5%;
  color: #fff;
  padding: 0px 15px;
  border-radius: 6px;
  background-color: #fff;
  height: 28px;
  box-shadow: 1px 0px 2px 0px #000;
}
.red-btn {
  width: 82px;
}
.mobile-screen {
  display: flex;
}
.align-cemter-class {
  text-align: center;
}
.tip-tool {
  position: relative;
  top: 3px;
  color: #979797;
}
.progress-loader {
  z-index: 1;
  position: absolute;
  margin: 200px 255px;
}
/*.v-slider--horizontal {
    min-height: 32px;
    margin-left: -62px;
    margin-right: -130px;
}*/
@media screen and (max-width: 570px) {
  .position-title {
    float: left;
    display: contents;
    font-weight: 500;
  }

  .fund-data {
    font-size: 11px;
  }
  .chartBox {
    width: 30% !important;
  }
  .change-icon {
    cursor: pointer;
    margin-top: 10px;
    margin-right: -15px;
    padding-left: 10px;
  }
  .position-warnings {
    height: 23px;
    font-size: 9px;
    width: 50%;
    left: 47%;
  }
  .liquidity-warning {
    top: 40px;
  }
  .trend-warning {
    top: 70px;
  }
  .mobile-screen {
    display: none;
  }
  .progress-loader {
    z-index: 1;
    position: absolute;
    margin: 170px 148px;
  }
  .position-container {
    margin: 0 0;
  }
  .position-data {
    /* border-bottom: 2px solid #ededed; */
    font-size: 11px;
  }
  #lineChart {
    width: 300px !important;
  }

  .modal__body_marginChip1 {
    margin-bottom: 11px;
    margin-left: -24px;
  }
  .v-slider--horizontal {
    min-height: 32px;
    margin-left: 8px;
    margin-right: -46px;
  }

  /*  .v-slider--horizontal {
    min-height: 32px;
    margin-left: 16px;
    margin-right: -73px;
}*/
}
</style>

<style scoped>
.main-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #b1b1b1;
}
.row + .row {
  margin-top: 0px;
}
.main-header-data {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: var(--text-color);
  line-height: 2;
}
.main-instrument .col {
  padding-left: 6px;
  padding-right: 6px;
}
/* .column-adjust{
  padding-left: 6px;
} */
.mobile-view {
  display: none;
}
.strategy {
  font-size: 12px;
}
.scrollable-inner-section {
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
}
.inner-table {
  border: #000;
}
.symbol-column {
  max-width: 200px;
}
@media screen and (max-width: 570px) {
  /* Condition to remove bottom border from inner table in mobile view */
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
  /* .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th , */
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: none;
  }
  .tip-tool {
    top: 5px;
  }
  .strategy {
    font-size: 8px;
  }
  .strategy > img {
    max-height: 8px;
  }
  .row + .row {
    margin-top: 0px;
  }
  .v-input--selection-controls {
    margin-top: -7px;
    margin-left: 10px;
  }
  .item-checkbox {
    margin-top: -15px;
    margin-left: -5px;
  }
  .lix-col {
    margin-top: -7px;
  }
  .liquidity-index {
    font-size: 10px;
  }
  .item-resize {
    transform: scale(0.6);
  }
  .main-header {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    white-space: nowrap;
  }
  .main-header-data {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: var(--text-color);
  }
  .mobile-view {
    display: inline !important;
  }
  .desktop-view {
    display: none;
  }

  .modal-bottom-margin {
    margin-bottom: 27px;
    margin-left: -22px;
    width: 100%;
    justify-content: center;
  }
  .profit-label {
    margin-left: 10px;
  }
  #myChart {
    margin: auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .show-chart-btn {
    width: 50px;
    margin-right: 5%;
    color: #fff;
    padding: 0px 15px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 0px 2px 0px #000;
  }
  .extra-info {
    padding: 10px;
    margin-top: 30px;
    font-size: 14px;
    height: 115px;
  }

  .text-capitalize {
    background-color: var(--red-text);
    border-color: var(--red-text);
    color: #fff;
    width: 20px;
  }
  /* .col-3{
    margin-right:8px;
  } */

  .main-instrument {
    /* padding-bottom: 27px; */
    font-size: 8px;
    /* padding-left: 14px; */
    /* padding-top: 14px; */
    padding: 0px;
  }
  .buy-text[data-v-29d7fcd9] {
    margin-left: 1px;
  }
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-left: -5px;
  }
  .modal-bottom-margin[data-v-29d7fcd9] {
    margin-bottom: 27px;
    margin-left: -33px;
    width: 100%;
    width: 207px;
    justify-content: center;
  }
  .modal-bottom-margin[data-v-29d7fcd9][data-v-29d7fcd9] {
    margin-bottom: 27px;
    margin-left: -33px;
    width: 100%;
    width: 207px;
    margin-right: 48px;
    justify-content: center;
  }

  .graph-data[data-v-10baecaf] {
    padding: 22px;
    margin-bottom: -55px;
  }
  .caption-text[data-v-10baecaf] {
    float: left;
    font-size: 16px;
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  .v-slider--horizontal {
    min-height: 32px;
    margin-left: 8px;
    margin-right: -36px;
  }
  .profit-label[data-v-29d7fcd9] {
    margin-left: 6px;
  }
}

@media only screen and (min-device-width: 570px) and (max-device-width: 1024px) and (orientation: portrait) {
  .show-chart-btn {
    width: 49px;
    /* margin-top: 20px; */
    margin-right: 5%;
    color: #fff;
    padding: 0px 15px;
    border-radius: 4px;
    background-color: #fff;
    height: 27px;
    box-shadow: 1px 0px 2px 0px #000;
  }

  .v-btn:not(.v-btn--round).v-size--small {
    height: 28px;
    /* min-width: 50px; */
    width: 44px;
    padding: 0 12.4444444444px;
  }
  /* For portrait layouts only */
}
</style>
