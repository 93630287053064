import { render, staticRenderFns } from "./DefaultPositions.vue?vue&type=template&id=634a53f8&scoped=true&lang=html&"
import script from "./DefaultPositions.vue?vue&type=script&lang=js&"
export * from "./DefaultPositions.vue?vue&type=script&lang=js&"
import style0 from "./DefaultPositions.vue?vue&type=style&index=0&id=634a53f8&lang=scss&scoped=true&"
import style1 from "./DefaultPositions.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634a53f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VCheckbox,VCol,VDataTable,VDivider,VIcon,VRow,VTooltip})
