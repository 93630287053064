<template>
  <div class="symbol-hedge-wrapper">
    <div style="font-weight:600" v-if="selectedPositionFilter.value == 'Hedged'" class="mb-2 hedged-unhedged-heading">
      Hedged
    </div>
    <div
      class="symbol-hedge-cards"
      v-for="(card, index) in hedgedPositionsList"
      :key="index"
    >
      <HedgedUnhedged
        :selectedPositionFilter="selectedPositionFilter"
        :card="card"
        :positionAnalyticsData="positionAnalyticsData"
      />
    </div>
    <div
      v-if="
        hedgedPositionsList.length == 0 &&
        selectedPositionFilter.value == 'Hedged'
      "
      class="no-positions"
    >
      Sorry! No Hedged Positions available for now.
    </div>

    <!-- This will load the data in case of unhedged positions and analyze by symbol positions -->
    <div  v-if="selectedPositionFilter.value == 'Hedged' || selectedPositionFilter.value == 'Symbols'">
      <div  style="font-weight:600" v-if="selectedPositionFilter.value == 'Hedged'" class="mb-2 hedged-unhedged-heading">
        Unhedged
      </div>
      <div
        class="symbol-hedge-cards"
        v-for="(card, index) in unhedgedPositionsList"
        :key="index"
      >
        <HedgedUnhedged
          :selectedPositionFilter="selectedPositionFilter"
          :card="card"
          :positionAnalyticsData="positionAnalyticsData"
        />
      </div>
      <div
        v-if="
          unhedgedPositionsList.length == 0 &&
          selectedPositionFilter.value == 'Hedged'
        "
        class="no-positions"
      >
        Sorry! No Unhedged Positions available for now.
      </div>
      <div
        v-if="
          unhedgedPositionsList.length == 0 &&
          selectedPositionFilter.value !== 'Hedged'
        "
        class="no-positions"
      >
        Sorry! No Positions available for now.
      </div>
    </div>

    <div v-if="selectedPositionFilter.value == 'Expiry'" >
      <div
      class="symbol-hedge-cards"
      v-for="(card, index) in unhedgedPositionsList"
      :key="index"
    >
      <PositionExpiry
        :selectedPositionFilter="selectedPositionFilter"
        :card="card"
        :positionAnalyticsData="positionAnalyticsData"
      />
    </div>
    <div
      v-if="
        unhedgedPositionsList.length == 0 &&
        selectedPositionFilter.value == 'Expiry'
      "
      class="no-positions"
    >
      Sorry! No Positions available for now.
    </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import { getMarginUtilizedFromListUrl } from "../getService.js";
import { getLixData } from "../getService";
import HedgedUnhedged from "./HedgedUnhedged.vue";
import PositionExpiry from "./PositionExpiry.vue";
import moment from "moment";
export default {
  name: "PositionSymbolHedge",
  watch: {
    symbolHedgePositionList() {
      this.loadSymbolHedgeCards();
    },
  },
  components: {
    HedgedUnhedged,
    PositionExpiry
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  props: {
    selectedPositionFilter: null,
    symbolHedgePositionList: [],
    positionAnalyticsData: null,
  },
  data() {
    return {
      hedgedPositionsList: [],
      unhedgedPositionsList: [],
    };
  },
  methods: {
    loadSymbolHedgeCards() {
      this.hedgedPositionsList = this.symbolHedgePositionList.hedged;
      this.unhedgedPositionsList = this.symbolHedgePositionList.unhedged;
      for (let i = 0; i < this.hedgedPositionsList.length; i++) {
        let index = 0;
        for (let param of this.hedgedPositionsList[i].param_list){
          param.lot_size = this.hedgedPositionsList[i].positions[0].lot_size;
          param.op_pr = param.quantity<0? this.hedgedPositionsList[i].positions[index].sell_amount:this.hedgedPositionsList[i].positions[index].buy_amount;
          index+=1
        }
        this.getMarginUtilizedFromList(
          "hedged",
          i,
          this.hedgedPositionsList[i].param_list
        );
        this.$set(this.hedgedPositionsList[i], "showInstrumentTable", false);
        this.$set(this.hedgedPositionsList[i], "selectedPositions", this.hedgedPositionsList[i].positions);
        this.getCardLixValue(
          "hedged",
          i,
          this.hedgedPositionsList[i].positions
        ); 
      }
      for (let i = 0; i < this.unhedgedPositionsList.length; i++) {
        for (let param of this.unhedgedPositionsList[i].param_list){
          let index =0;
          param.lot_size = this.unhedgedPositionsList[i].positions[0].lot_size;
          param.op_pr = param.quantity<0? this.unhedgedPositionsList[i].positions[index].sell_amount:this.unhedgedPositionsList[i].positions[index].buy_amount;
          index+=1
        }
        this.getMarginUtilizedFromList(
          "unhedged",
          i,
          this.unhedgedPositionsList[i].param_list
        );
        this.$set(this.unhedgedPositionsList[i], "formatted_expiry", moment(this.unhedgedPositionsList[i].expiry_date).format("DD MMM YYYY", "YYYY-MM-DD"));
        this.$set(this.unhedgedPositionsList[i], "showInstrumentTable", false);
        this.$set(this.unhedgedPositionsList[i], "selectedPositions", this.unhedgedPositionsList[i].positions);
        this.getCardLixValue(
          "unhedged",
          i,
          this.unhedgedPositionsList[i].positions
        );
      }
    },

    /** @function getMarginUtilizedFromList is used to get the margin value for all the positions */
    getMarginUtilizedFromList(key, index, param_list) {
      for(let param of param_list) {
        param.quantity = param.exchange == 'MCX' || param.exchange == 
        'CDS'?param.quantity/param.lot_size:param.quantity;
      }
      axios
        .post(getMarginUtilizedFromListUrl, { param_list: param_list })
        .then((res) => {
          if (key == "hedged") {
            this.$set(
              this.hedgedPositionsList[index],
              "margin_utilized",
              res.data.margin
            );
          } else if (key == "unhedged") {
            this.$set(
              this.unhedgedPositionsList[index],
              "margin_utilized",
              res.data.margin
            );
          }
        });
    },

    /** @function getCardLixValue is used to get the lix value for all the positions */
    getCardLixValue(key, index, position) {
      for (let i = 0; i < position.length; i++) {
        let payload = {
          user_id: localStorage.getItem("user_id"),
          access_token: localStorage.getItem("accessTokenAnt"),
          ws_token: localStorage.getItem("ws_session"),
          param_list: [position[i].param_list],
        };
        axios.post(getLixData, payload).then((res) => {
          if (key == "hedged") {
            this.$set(
              this.hedgedPositionsList[index].positions[i],
              "lix_value",
              res.data.param_list[0].lix_value
            );
          } else if (key == "unhedged") {
            this.$set(
              this.unhedgedPositionsList[index].positions[i],
              "lix_value",
              res.data.param_list[0].lix_value
            );
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.symbol-hedge-wrapper {
  height:72%;
  width: 100%;
  overflow-x: auto;

  .symbol-hedge-cards {
    background-color: transparent;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    padding: 1rem;
    margin-bottom: 24px;
  }

  .hedged-unhedged-heading{
    font-size: 1em;
  }

  .no-positions{
    font-size: 1em;
  }
}

.symbol-hedge-wrapper::-webkit-scrollbar,
.symbol-hedge-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* set the width of the scrollbar */
}
.symbol-hedge-wrapper::-webkit-scrollbar-thumb,
.symbol-hedge-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* set the color of the scrollbar thumb */
  border-radius: 5px; /* set the border radius of the scrollbar thumb */
}
.symbol-hedge-wrapper,
.symbol-hedge-wrapper {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* set the color of the scrollbar track */
  }
}

@media only screen and (max-width: 600px) {
  .symbol-hedge-cards {
    min-width: 400px !important;
  }
  .hedged-unhedged-heading, .no-positions{
    font-size: 0.75em !important;
  }
}
@media only screen and (max-width: 400px) {
  .symbol-hedge-cards {
    padding: 10px !important;
    min-width: 340px !important;
  }
}
</style>
