<template>
  <div>
    <v-row no-gutters class="dashboard-wrapper">
      <v-col
        cols="12"
        md="4"
        class="dashboard-left pr-2"
        :class="{ 'allow-scroll': $vuetify.breakpoint.width >= 960 || true }"
      >
        <div
          :class="{ 'dashboard-left-header': $vuetify.breakpoint.width >= 960 }"
        >
          <!-- Main Instrument, Expiry, LTPs -->
          <div>
            <div v-if="isLoading" style="height: 60px; width: 100%">
              <SkeletonLoader />
            </div>
            <div
              v-else
              class="white-card instrument-section d-flex justify-space-between px-3"
              style="overflow: hidden"
            >
              <!-- Component handles Instrument selection -->
              <div style="cursor: pointer">
                <SelectInstrument
                  @change-instrument="handleSelectedInstrument"
                  :instrumentList="instrumentList"
                  :selectedInstrument="selectedInstrument"
                  :pageName="'dashboard'"
                />
              </div>

              <div class="expiry-input" style="width: 5rem; min-width: 100px">
                <v-select
                  hide-details
                  :items="expiryDatesList"
                  :editable="false"
                  v-model="selectedExpiry"
                  @change="handleSelectedExpiry(selectedExpiry)"
                  outlined
                  dense
                  no-filter
                >
                  <template #selection="{ item }">
                    <v-container>
                      <span style="pointer-events: none">
                        {{ item.split(" ")[0] + " " + item.split(" ")[1] }}
                      </span>
                    </v-container>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          >{{ data.item.split(" ")[0] }}
                          {{ data.item.split(" ")[1] }}</span
                        >
                        <!-- <span>{{data.item}}</span> -->
                        <span class="target-labels">{{
                          targetDaysList[expiryDatesList.indexOf(data.item)]
                        }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div>
              <div
                class="d-flex align-center justify-end flex-wrap ltp-fut-section"
              >
                <!-- SPOT PRICE / INSTRUMENT PRICE -->
                <template
                  v-if="
                    selectedInstrument.exchange == 'NSE' ||
                    selectedInstrument.exchange == 'BFO'
                  "
                >
                  <div
                    v-if="
                      liveLtp(selectedInstrument.underlying_code) != null &&
                      liveChange(selectedInstrument.underlying_code) != null
                    "
                    class="text-no-wrap d-block d-sm-inline d-md-block d-lg-inline mr-2"
                  >
                    <span
                      >{{
                        liveLtp(selectedInstrument.underlying_code)
                          | float(2)
                          | currencyWithDecimal
                      }}
                    </span>
                    <span
                      :class="
                        liveChange(selectedInstrument.underlying_code) >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      class="sub-text-change"
                    >
                      {{
                        liveChange(selectedInstrument.underlying_code)
                          | float(2)
                      }}%
                    </span>
                  </div>
                  <div
                    v-else
                    class="text-no-wrap d-block d-sm-inline d-md-block d-lg-inline mr-2"
                  >
                    <span
                      >{{
                        selectedInstrument.ltp | float(2) | currencyWithDecimal
                      }}
                    </span>
                    <span
                      :class="
                        selectedInstrument.change_percent >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      class="sub-text-change"
                    >
                      {{ selectedInstrument.change_percent | float(2) }}%
                    </span>
                  </div>
                </template>

                <!-- FUT PRICE AND CHANGE -->
                <div
                  v-if="
                    liveLtp(selectedInstrument.fut_code) != null &&
                    liveChange(selectedInstrument.fut_code) != null
                  "
                  class="text-no-wrap d-block d-sm-inline d-md-block d-lg-inline"
                >
                  <span>
                    {{
                      liveLtp(selectedInstrument.fut_code)
                        | float(2)
                        | currencyWithDecimal
                    }}
                  </span>
                  <span
                    :class="
                      liveChange(selectedInstrument.fut_code) >= 0
                        ? 'green-text'
                        : 'red-text'
                    "
                    class="sub-text-change"
                  >
                    {{ liveChange(selectedInstrument.fut_code) | float(2) }}%
                  </span>
                  <span
                    style="color: var(--primary-color)"
                    class="sub-text-change"
                    >FUT</span
                  >
                </div>
                <div
                  v-else
                  class="text-no-wrap d-block d-sm-inline d-md-block d-lg-inline"
                >
                  <span>
                    {{
                      selectedInstrument.fut_ltp
                        | float(2)
                        | currencyWithDecimal
                    }}
                  </span>
                  <span
                    :class="
                      selectedInstrument.fut_change_percent >= 0
                        ? 'green-text'
                        : 'red-text'
                    "
                    class="sub-text-change"
                  >
                    {{ selectedInstrument.fut_change_percent | float(2) }}%
                  </span>
                  <span
                    style="color: var(--primary-color)"
                    class="sub-text-change"
                    >FUT</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Trend and Updated Time -->
          <div>
            <div v-if="isLoading" style="height: 50px; width: 100%">
              <SkeletonLoader />
            </div>
            <div v-else class="white-card trend-label">
              <div>
                <span style="margin-right: 8px">Trend</span>
                <span>
                  <v-tooltip bottom color="#3f7ac8" dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" right
                        >mdi-help-circle-outline</v-icon
                      >
                    </template>
                    <span>
                      Trend predicted by system, updates every 15 mins!</span
                    >
                  </v-tooltip>
                </span>
                <span
                  :class="
                    symbolTrend === 'Bullish'
                      ? 'bullish-text'
                      : symbolTrend === 'Bearish'
                      ? 'bearish-text'
                      : 'neutral-text'
                  "
                  >{{ symbolTrend }}</span
                >
                <span v-if="symbolTrend == ''">N/A</span>
              </div>
              <div class="updated-label">
                <span>Updated on: {{ trendUpdatedOn }}</span>
                <span v-if="trendUpdatedOn != ''" style="margin-right: 0">
                  <v-icon
                    right
                    class="refresh-icon"
                    @click="getTrendFromSymbol(true)"
                  >
                    mdi-refresh
                  </v-icon>
                </span>
                <span v-if="trendUpdatedOn == ''">N/A</span>
              </div>
            </div>
          </div>

          <!-- Trend Selection -->
          <div class="white-card tab-names d-flex justify-space-between">
            <button
              :class="
                selectedTab === 'Bullish' ? 'selected-tab' : 'unselected-tab'
              "
              @click="selectedTab = 'Bullish'"
            >
              Bullish
            </button>
            <button
              :class="
                selectedTab === 'Bearish' ? 'selected-tab' : 'unselected-tab'
              "
              @click="selectedTab = 'Bearish'"
            >
              Bearish
            </button>
            <button
              :class="
                selectedTab === 'Neutral' ? 'selected-tab' : 'unselected-tab'
              "
              @click="selectedTab = 'Neutral'"
            >
              Neutral
            </button>
          </div>
        </div>

        <div
          :class="{
            'dashboard-left-section': $vuetify.breakpoint.width >= 960,
          }"
        >
          <!-- Component for prebuit stratefy and saved stratefies -->
          <PrebuiltStrategy
            :selectedTab="selectedTab"
            :selectedExpiry="selectedExpiry"
            @selectedStrategy="getSelectedStrategy"
            :prebuiltStrategyData="prebuiltStrategyData"
            :selectedInstrument="selectedInstrument"
            @changePrebuiltStrategyDataSaved="getStrategyDataSaved"
            :isPrebuiltStrategyDialog="false"
          />
          <div
            v-if="isPrebuiltStrategyLoading"
            style="height: 250px; width: 100%"
          >
            <SkeletonLoader />
          </div>
          <div ref="targetComponent" v-else>
            <Strategy
              v-if="prebuiltStrategyData != null"
              :prebuiltStrategyData="prebuiltStrategyData"
              :selectedExpiry="selectedExpiry"
              :selectedInstrument="selectedInstrument"
              :isPrebuiltStrategyCalled="isPrebuiltStrategyCalled"
              :selectedTab="selectedTab"
              @changePrebuiltStrategyData="getStrategyData"
              @isCustomStrategyApiCalled="getCustomData"
            />
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="dashboard-right"
        :class="{ 'allow-scroll': $vuetify.breakpoint.width >= 960 || true }"
      >
        <div class="white-card px-2 px-sm-3">
          <MarginBenefit
            :ins="selectedInstrument"
            :selectedTab="selectedTab"
            :selectedExpiry="selectedExpiry"
            :selectedInstrument="selectedInstrument"
            @changePrebuiltStrategyDataBenefit="getStrategyDataBenefit"
          />
        </div>
        <div
          v-if="isPrebuiltStrategyLoading"
          style="height: 460px; width: 100%"
        >
          <SkeletonLoader />
        </div>
        <div
          v-else
          class="white-card px-2 px-sm-3"
          id="graph"
          ref="graphComponent"
        >
          <Graph
            v-if="prebuiltStrategyData !== null && isStandardDeviationCalled"
            :prebuiltStrategyData="prebuiltStrategyData"
            :selectedInstrument="selectedInstrument"
            :standardDeviation="standardDeviation"
          />
        </div>
        <div
          v-if="isPrebuiltStrategyLoading"
          style="height: 350px; width: 100%"
        >
          <SkeletonLoader />
        </div>
        <div v-else class="white-card greeks">
          <Greeks
            v-if="prebuiltStrategyData !== null"
            :prebuiltStrategyData="prebuiltStrategyData"
            :expiryDatesList="expiryDatesList"
            :selectedInstrument="selectedInstrument"
            :spotPrice="
              selectedInstrument.exchange == 'NSE'
                ? selectedInstrument.ltp
                : selectedInstrument.fut_ltp
            "
          />
        </div>
      </v-col>
      <v-col cols="12">
        <div ref="compareStrategyComponent">
          <CompareStrategy
            :selectedTab="selectedTab"
            :selectedExpiry="selectedExpiry"
            :prebuiltStrategyData="prebuiltStrategyData"
            :selectedInstrument="selectedInstrument"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import { computed } from 'vue'
import axios from "axios";
import { EventBus } from "../../main";
// import ReconnectingWebSocket from "reconnecting-websocket";

import UserDataMixin from "@/mixins/getUserDataMixin";
import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";

import MarginBenefit from "./MarginBenefit.vue";
import Graph from "./Graph.vue";
import Greeks from "./Greeks.vue";
import Strategy from "./Strategy.vue";
import SelectInstrument from "../modals/SelectInstrument.vue";
import SkeletonLoader from "../modals/SkeletonLoader.vue";
import CompareStrategy from "./CompareStrategy.vue";
import PrebuiltStrategy from "./PrebuiltStrategy.vue";


import {
  getTrendsAndSymbolsUrl,
  getTrendFromSymbolUrl,
  // getBalanceCall,
  getPrebuiltStrategyUrl,
  getStandardDeviationUrl,
} from "../getService.js";

// import { POSITION_CACHE_NAME } from "@/utils/constants";
import {
  getFilteredExpiry,
  getSelectedExpiry,
  // apiLtp,
  getTargetDaysList,
} from "./dashboardService.js";
import {
  BULLISH_STRATEGY,
  BEARISH_STRATEGY,
  NEUTRAL_STRATEGY,
} from "./dashboardConstants";
export default {
  created() {
    this.$root.$refs.Dashboard = this;
    EventBus.$off("scrollTop");
    EventBus.$on("scrollTop", (event) => {
      const container = document.querySelector("#graph");
      container.scrollTop = event.top;
    });
    EventBus.$off("changePrebuiltStrategyDataGraph");
    EventBus.$on("changePrebuiltStrategyDataGraph", (value) => {
      this.isPrebuiltStrategyCalled = true;
      this.prebuiltStrategyData = value;
    });
    EventBus.$on("user_settings", (value) => {
      this.userSettings = value;
    });
  },
  destroyed() {
    this.unsubscribeFromLocation(this.$route.name)//function form websocketMethodsMixin
  },
  name: "Dashboard",
  mixins: [UserDataMixin,WebsocketMethodsMixin],
  components: {
    Strategy,
    MarginBenefit,
    Graph,
    Greeks,
    PrebuiltStrategy,
    SelectInstrument,
    CompareStrategy,
    SkeletonLoader,
  },
  data: () => ({
    isLoading: true,
    isPrebuiltStrategyLoading: true,
    invokedMilliseconds: new Date().getTime(),
    newConnection: "",
    selectedInstrument: {},
    instrumentList: [],
    selectedExpiry: "",
    expiryDatesList: [],
    symbolTrend: "",
    trendUpdatedOn: "",
    selectedTab: "Bullish",
    prebuiltStrategyData: null,
    selectedStrategy: "",
    optionChainFutureLtp: "",
    targetDaysList: [],
    standardDeviation: 0,
    customStrategyLoad: null,
    isPrebuiltStrategyCalled: false,
    isStandardDeviationCalled: false,
    callTrendsAndSymbols: true,
    userSettings: JSON.parse(localStorage.getItem("user_settings")),
  }),
  computed: {
    liveLtp() {
      return (el) => {
        let ltp = this.compactMarketDataScripts(el);
        if (
          ltp &&
          ltp["details"] &&
          (ltp["details"]["Last Traded Price"] == 0 ||
            ltp["details"]["Last Traded Price"])
        )
          return ltp["details"]["Last Traded Price"];
        else return null;
      };
    },
    liveChange() {
      return (el) => {
        let change = this.compactMarketDataScripts(el);
        if (
          change &&
          change["details"] &&
          (change["details"]["Change"] == 0 || change["details"]["Change"])
        )
          return change["details"]["Change"];
        else return null;
      };
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  methods: {
    //Subscribing and unsubscribing data
    // unsubscribeNfoLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `NFO|${payload}#NFO`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeNseLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `NSE|${payload}#NSE`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeMcxLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `MCX|${payload}#MCX`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeCdsLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `CDS|${payload}#CDS`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeBfoLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `BFO|${payload}#BFO`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeBseLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `BSE|${payload}#BSE`,
    //       t: "u",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // subscribeAll() {
    //   this.subscribeNseLtp(this.selectedInstrument.underlying_code);
    //   for (let ins of this.$store.state.compactMarketDataScripts) {
    //     if (this.selectedInstrument.exchange == "NSE") {
    //       this.subscribeNseLtp(String(ins.code));
    //       this.subscribeNfoLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "MCX") {
    //       this.subscribeMcxLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "CDS") {
    //       this.subscribeCdsLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "BFO") {
    //       this.subscribeBfoLtp(String(ins.code));
    //       this.subscribeBSeLtp(String(ins.code));
    //     }
    //   }
    // },
    // unsubscribeData() {
    //   for (let ins of this.$store.state.compactMarketDataScripts) {
    //     if (this.selectedInstrument.exchange == "NSE") {
    //       this.unsubscribeNseLtp(String(ins.code));
    //       this.unsubscribeNfoLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "MCX") {
    //       this.unsubscribeMcxLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "CDS") {
    //       this.unsubscribeCdsLtp(String(ins.code));
    //     } else if (this.selectedInstrument.exchange == "BFO") {
    //       this.unsubscribeBfoLtp(String(ins.code));
    //       this.unsubscribeBseLtp(String(ins.code));
    //     }
    //   }
    // },
    // subscribeNseLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `NSE|${payload}#NSE`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "NSE");
    // },
    // subscribeNfoLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `NFO|${payload}#NFO`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "NFO");
    // },
    // subscribeMcxLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `MCX|${payload}#MCX`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "MCX");
    // },
    // subscribeCdsLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `CDS|${payload}#CDS`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "CDS");
    // },
    // subscribeBfoLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `BFO|${payload}#BFO`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "CDS");
    // },
    // subscribeBseLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `BSE|${payload}#BSE`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    //   // apiLtp(payload, "CDS");
    // },
    // /**
    //  * @function starts the socket
    //  */
    // startWebSocket() {
    //   this.newConnection = new ReconnectingWebSocket(
    //     "wss://f5zy.aliceblueonline.com/"
    //   );
    //   this.newConnection.send(
    //     JSON.stringify({
    //       susertoken: this.wsSessionToken,
    //       t: "c",
    //       actid: this.userId + "_API",
    //       uid: this.userId + "_API",
    //       source: "API",
    //     })
    //   );
    //   this.newConnection.onmessage = this.messageSocket;
    //   this.newConnection.onerror = function () {
    //     // this.newConnection.close();
    //   };
    //   this.newConnection.onclose = function () {};
    // },
    // messageSocket(event) {
    //   this.invokedMilliseconds = new Date().getTime();
    //   let recv_data = JSON.parse(event.data);
    //   this.$store.commit("setcompactData", recv_data);
    // },

    /**
     * @function gets the list of the stocks and their respective expiry dates
     */
    getTrendsAndSymbols() {
      let payload = {
        path: "dashboard_symbols",
        broker: this.broker,
        user_id: this.userId.toString(),
        access_token:this.accessToken,
        gscid: this.gscid,
        gcid: this.gcid,
      };
      axios
        .post(getTrendsAndSymbolsUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          let newInsList = [];
          // ROUTE query for default instrument selection
          let defaultInsName = this.$route.query.trading_symbol?.toUpperCase();
          const isInsInQuery = !!defaultInsName;
          if (!(defaultInsName && res.data.find((el) => el.name == defaultInsName)))
            defaultInsName = this.userSettings.dashboard_symbol;

          for (let ins of res.data) {
            if (
              ins.name === "NIFTY" ||
              ins.name === "FINNIFTY" ||
              ins.name === "BANKNIFTY" ||
              ins.name == "MIDCPNIFTY"
            ) {
              newInsList.push(ins);
            } else {
              this.instrumentList.push(ins);
            }
            if (ins.name === defaultInsName) {
              this.selectedInstrument = ins;

              // fix for instrument not getting subscribed on route redirection
              if(isInsInQuery)
              setTimeout(() => {
                //function form websocketMethodsMixin
                this.subscribeToInstruments([{ 
                  code:ins.exchange=="MCX"||ins.exchange=="CDS"?ins.fut_code:ins.underlying_code, 
                  exchange:ins.exchange, 
                  loc:this.$route.name }]);
                this.handleSelectedInstrument(ins);
                }, 500);
              this.expiryDatesList = getFilteredExpiry(ins.option_expiry_dates);
              this.targetDaysList = getTargetDaysList(this.expiryDatesList);

              // Check if expiry is present in route query
              let expiryParam = null;
              if (this.$route.query.expiry) {
                const dt = new Date(this.$route.query.expiry);
                expiryParam = this.expiryDatesList.find((el) => {
                  const dt2 = new Date(el);
                  if (
                    dt2.getDate() == dt.getDate() &&
                    dt2.getMonth() == dt.getMonth() &&
                    dt2.getFullYear() == dt.getFullYear()
                  )
                    return el;
                });
              }
              if (expiryParam) {
                this.selectedExpiry = expiryParam;
              } else if (this.userSettings.dashboard_expiry == "Current Week") {
                this.selectedExpiry = this.expiryDatesList[0];
              } else {
                this.selectedExpiry = getSelectedExpiry(this.expiryDatesList);
              }

              this.getStandardDeviation(
                this.targetDaysList[
                  this.expiryDatesList.indexOf(this.selectedExpiry)
                ]
              );
              this.symbolTrend = ins.trend;
              this.trendUpdatedOn = ins.trend_updated_on;
            }
          }
          this.isLoading = false;

          //function form websocketMethodsMixin
          this.subscribeToInstruments( [
            { code:this.selectedInstrument.underlying_code, exchange:"NSE", loc:this.$route.name },
            { code:this.selectedInstrument.fut_code, exchange:"NFO", loc:this.$route.name }
          ]);

          // this.subscribeNseLtp(this.selectedInstrument.underlying_code);
          // this.subscribeNfoLtp(this.selectedInstrument.fut_code);
          this.instrumentList = newInsList.concat(this.instrumentList);
          this.getSelectedTab();
          this.getPrebuiltStrategies();
        });
    },

    getSelectedTab() {
      let strategy = this.userSettings.dashboard_strategy;
      if (BULLISH_STRATEGY.find((str) => str.value === strategy)) {
        this.selectedTab = "Bullish";
      } else if (BEARISH_STRATEGY.find((str) => str.value === strategy)) {
        this.selectedTab = "Bearish";
      } else if (NEUTRAL_STRATEGY.find((str) => str.value === strategy)) {
        this.selectedTab = "Neutral";
      }
    },

    /**
     * @function gets the prebuilt strategies
     */
    getPrebuiltStrategies() {
      let payload = {
        exchange: this.selectedInstrument.exchange,
        path: "prebuilt_strategy",
        broker: this.broker,
        access_token: this.accessToken,
        user_id: this.userId,
        expiry: this.selectedExpiry,
        direction: this.selectedTab.toLowerCase(),
        strategy:
          this.selectedStrategy != ""
            ? this.selectedStrategy.value
            : this.userSettings.dashboard_strategy == "Buy Call"
            ? "buy_call"
            : this.userSettings.dashboard_strategy,
        name: this.selectedInstrument.name,
        code:
          this.selectedInstrument.exchange === "NSE"
            ? this.selectedInstrument.underlying_code
            : this.selectedInstrument.fut_code,
        ltp: this.selectedInstrument.ltp,
        target_days: 0,
        iv_percent: 0,
        gscid: this.gscid,
        gcid: this.gcid,
      };
      axios
        .post(getPrebuiltStrategyUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.isPrebuiltStrategyCalled = true;
          this.isPrebuiltStrategyLoading = false;
          this.prebuiltStrategyData = res.data;
          this.customStrategyLoad = res.data;
        });
    },

    /**
     * @function gets the trend for each instrument
     */
    getTrendFromSymbol(showToaster) {
      let payload = {
        path: "trend",
        name: this.selectedInstrument.name,
      };
      axios
        .post(getTrendFromSymbolUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.symbolTrend = res.data.trend;
          this.trendUpdatedOn = res.data.updated_on;
          if (showToaster) {
            this.$notify({
              group: "success",
              type: "success",
              title: "Success",
              text: "Trend has been updated successfully!",
            });
          }
        });
    },

    getStandardDeviation(days_to_expire) {
      this.isStandardDeviationCalled = false;
      let payload = {
        path: "standard_deviation",
        name: this.selectedInstrument.name,
        days_to_expire: days_to_expire,
        access_token: this.accessToken,
        user_id: this.userId,
        gscid: this.gscid,
        gcid: this.gcid,
      };
      axios
        .post(getStandardDeviationUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.standardDeviation = res.data.standard_deviation;
          this.isStandardDeviationCalled = true;
        });
    },
    handleSelectedInstrument(row) {
      this.selectedInstrument = row;
      const dateToCompare = new Date(this.selectedExpiry);
      const dateWithCompare = new Date("27 Jul 2023");
      if (
        this.selectedInstrument.name == "BANKNIFTY" &&
        dateToCompare >= dateWithCompare
      ) {
        this.selectedInstrument.lot_size = 15;
      } else if (this.selectedInstrument.name == "BANKNIFTY") {
        this.selectedInstrument.lot_size = 25;
      }

      const ins=this.selectedInstrument;
      if (ins.exchange == "NSE" || ins.exchange == "BSE" || ins.exchange == "BFO") {
          //function form websocketMethodsMixin
          this.subscribeToInstruments([
            { code:ins.underlying_code, exchange:ins.exchange, loc:this.$route.name },
            { code:ins.fut_code, exchange:ins.exchange, loc:this.$route.name }
          ]);
      } else if (ins.exchange == "MCX" || ins.exchange == "CDS") {
        //function form websocketMethodsMixin
        this.subscribeToInstruments([{ code:ins.fut_code, exchange:ins.exchange, loc:this.$route.name }]);
      } 
      // if (this.selectedInstrument.exchange == "NSE") {
      //   this.subscribeNseLtp(this.selectedInstrument.underlying_code);
      //   this.subscribeNfoLtp(this.selectedInstrument.fut_code);
      // } else if (this.selectedInstrument.exchange == "MCX") {
      //   this.subscribeMcxLtp(this.selectedInstrument.fut_code);
      // } else if (this.selectedInstrument.exchange == "CDS") {
      //   this.subscribeCdsLtp(this.selectedInstrument.fut_code);
      // } else if (this.selectedInstrument.exchange == "BFO") {
      //   this.subscribeBseLtp(this.selectedInstrument.underlying_code);
      //   this.subscribeBfoLtp(this.selectedInstrument.fut_code);
      // }

      this.getTrendFromSymbol(false);
      this.expiryDatesList = getFilteredExpiry(
        this.selectedInstrument.option_expiry_dates
      );
      if (
        this.expiryDatesList.indexOf(this.selectedExpiry) == -1 &&
        this.userSettings.dashboard_expiry == "Current Week"
      ) {
        this.selectedExpiry = this.expiryDatesList[0];
      } else if (this.expiryDatesList.indexOf(this.selectedExpiry) == -1) {
        this.selectedExpiry = getSelectedExpiry(this.expiryDatesList);
      }
      this.changeInstrumentStrategyData();
      this.targetDaysList = getTargetDaysList(this.expiryDatesList);
      this.getStandardDeviation(
        this.targetDaysList[this.expiryDatesList.indexOf(this.selectedExpiry)]
      );
    },

    handleSelectedExpiry(expiry) {
      if (expiry == null) {
        this.selectedExpiry = getSelectedExpiry(this.expiryDatesList);
        this.$nextTick(() => {
          this.$refs.autocomplete.$refs.select.text = getSelectedExpiry(
            this.expiryDatesList
          );
        });
      } else {
        this.selectedExpiry = expiry;
        this.getStandardDeviation(
          this.targetDaysList[this.expiryDatesList.indexOf(this.selectedExpiry)]
        );
      }
      const dateToCompare = new Date(this.selectedExpiry);
      const dateWithCompare = new Date("27 Jul 2023");
      if (
        this.selectedInstrument.name == "BANKNIFTY" &&
        dateToCompare >= dateWithCompare
      ) {
        this.selectedInstrument.lot_size = 15;
      } else if (this.selectedInstrument.name == "BANKNIFTY") {
        this.selectedInstrument.lot_size = 25;
      }
    },
    changeInstrumentStrategyData() {
      if (
        !this.isCustomStrategyApiCalled ||
        this.isCustomStrategyApiCalled == undefined
      ) {
        this.getPrebuiltStrategies();
      } else {
        this.isPrebuiltStrategyCalled = false;
        this.prebuiltStrategyData = this.customStrategyLoad;
      }
    },
    getSelectedStrategy(value) {
      this.selectedStrategy = value;
      this.getPrebuiltStrategies();
    },
    getStrategyDataSaved(value) {
      this.isPrebuiltStrategyCalled = true;

      this.prebuiltStrategyData = value;
    },
    getStrategyData(value) {
      this.customStrategyLoad = value;
      this.changeInstrumentStrategyData();
    },
    getCustomData(value) {
      this.isCustomStrategyApiCalled = value;
    },
    getStrategyDataBenefit(value) {
      let data = {
        data: {
          margin_data: value.margin_data,
          pl_data: value.pl_data,
          pl_targeted: value.pl_targeted,
        },
        instrument: value.cards,
        greeks: value.greeks,
        total: value.total,
        pop: value.pop,
      };
      this.isPrebuiltStrategyCalled = true;
      this.prebuiltStrategyData = data;
    },
    colorSearchInstrumentItems(item) {
      if (item.trend === "Bearish") {
        return "bearish-text";
      } else if (item.trend === "Bullish") {
        return "bullish-text";
      } else {
        return "neutral-text";
      }
    },
  },
  //works like a constructor
  mounted() {
    // if (this.startWebSocket() != null) {
    //   this.startWebSocket();
    // }
    // setInterval(() => {
    //   let current_time = new Date();
    //   let currentMilliseconds = current_time.getTime();
    //   if (
    //     this.selectedInstrument.exchange == "CDS" ||
    //     this.selectedInstrument.exchange == "MCX"
    //   ) {
    //     if (currentMilliseconds - this.invokedMilliseconds > 10000) {
    //       if (this.newConnection) {
    //         this.newConnection.close();
    //       }
    //       this.unsubscribeData();
    //       if (this.startWebSocket() != null) {
    //         this.startWebSocket();
    //       }
    //       this.subscribeAll();
    //     }
    //   } else if (
    //     this.selectedInstrument.exchange == "NSE" ||
    //     this.selectedInstrument.exchange == "NFO"
    //   ) {
    //     if (current_time.getHours() < 15 && current_time.getMinutes() < 30) {
    //       if (currentMilliseconds - this.invokedMilliseconds > 10000) {
    //         if (this.newConnection) {
    //           this.newConnection.close();
    //         }
    //         this.unsubscribeData();
    //         if (this.startWebSocket() != null) {
    //           this.startWebSocket();
    //         }
    //         this.subscribeAll();
    //       }
    //     }
    //   }
    //   if (this.newConnection.readyState == 1) {
    //     this.newConnection.send(JSON.stringify({ k: ``, t: "h" }));
    //   }
    //   if (this.newConnection.readyState == 3) {
    //     this.startWebSocket();
    //     this.subscribeAll();
    //   }
    // }, 1000);
    setTimeout(() => {
      if (this.callTrendsAndSymbols) {
        this.getTrendsAndSymbols();
      }
      this.callTrendsAndSymbols = false;
    }, 1200);
    if (window.location.pathname == "/dashboardV2") {
      EventBus.$emit("isDashboardV2", true);
    } else {
      EventBus.$emit("isDashboardV2", false);
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/font.scss";
@import "../../assets/css/global.scss";

.dashboard-wrapper {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  width: 100%;
  color: var(--text-color);
  max-width: 2500px;
  margin: auto;
  font-size: var(--font-16);
}

.sub-text-change {
  font-size: var(--font-14);
}

.dashboard-left {
  position: relative;

  .dashboard-left-header {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--app-background-color);
  }
}

.instrument-section {
  height: 65px;

  .expiry-input .v-select * {
    padding: 0 !important;
  }
}

.target-labels {
  margin-left: 0.6rem;
  padding: 4px;
  background-color: var(--chip-background);
  border-radius: 8px;
  font-size: var(--font-10);
  color: var(--text-color);
}

.trend-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    margin-right: 0.4rem;
  }

  .updated-label {
    color: #b3b3b3;
  }
}

.tab-names button {
  width: 32%;
  max-width: 220px;
  height: 34px;
  border-radius: 4px;
}

.unselected-tab {
  background-color: var(--chip-background);
  color: var(--text-color);
  border: 1px solid var(--border-color);
}

.selected-tab {
  color: var(--primary-button-color);
  border: 2px solid var(--primary-button-color);
}

.v-icon.v-icon {
  font-size: var(--font-16);
  margin-left: 0;
  color: var(--text-color);
  cursor: pointer;
}

.refresh-icon {
  color: #b3b3b3 !important;
}

.instrument-section {
  .expiry-input {
    .expiry-input {
      padding: 12px 6px;
    }

    .v-input {
      font-size: var(--font-14);
    }
  }
}

.dashboard-left {
  overflow: hidden;
  max-height: 90vh;
}

.dashboard-right {
  overflow: hidden;
  max-height: 90vh;
}

.allow-scroll.dashboard-left:hover,
.allow-scroll.dashboard-right:hover {
  overflow-y: scroll;
}

.allow-scroll.dashboard-right::-webkit-scrollbar,
.allow-scroll.dashboard-left::-webkit-scrollbar {
  width: 5px;
  /* set the width of the scrollbar */
}

.allow-scroll.dashboard-left::-webkit-scrollbar-thumb,
.allow-scroll.dashboard-right::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* set the color of the scrollbar thumb */
  border-radius: 5px;
  /* set the border radius of the scrollbar thumb */
}

.allow-scroll.dashboard-left,
.allow-scroll.dashboard-right {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* set the color of the scrollbar track */
  }
}

@media only screen and (max-width: 1330px) {
  .dashboard-left,
  .greeks {
    zoom: 0.8;
  }

  .dashboard-left {
    max-height: 9vh;
  }
}

@media only screen and (max-width: 1480px) {
  .dashboard-left,
  .greeks {
    zoom: 0.9;
  }

  .dashboard-left {
    max-height: 100vh;
  }
}

@media screen and (max-width: 960px) {
  .dashboard-left,
  .dashboard-right {
    overflow: hidden;
    max-height: none !important;
  }
}
</style>
<style lang="scss">
.dashboard-wrapper {
  .v-text-field--outline.v-input--has-state
    > .v-input__control
    > .v-input__slot,
  .v-text-field--outline.v-input--is-focused
    > .v-input__control
    > .v-input__slot,
  .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:hover {
    border: 1px solid !important;
  }

  .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
    border: 1px solid !important;
  }

  .expiry-input .v-text-field--outlined > .v-input__control > .v-input__slot {
    color: var(--table-row-hover) !important;
    padding: 0 8px;
  }
}
</style>
