<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xs"
      v-model="isExitPositionsClicked"
      width="auto"
    >
      <template>
        <v-card class="pb-5" style="position: relative">
          <v-card-text>
            <v-row no-gutters style="padding: 1rem">
              <v-col>
                <div class="exit-position-label">Exit Positions</div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <v-icon
                  style="cursor: pointer"
                  @click="isExitPositionsClicked = false"
                  >mdi-close</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-data-table
                v-if="!$vuetify.breakpoint.xs"
                class="exit-positions-table"
                ref="exitPosTable"
                :items="selectedPositions"
                :hide-default-footer="true"
                :rows-per-page="-1"
              >
                <template v-slot:header>
                  <tr>
                    <th>
                      <v-checkbox
                        :input-value="selectAllValue"
                        @click="toggleSelectAll"
                        color="var(--primary-button-color)"
                        hide-details
                      ></v-checkbox>
                    </th>
                    <th>Instrument</th>
                    <th>Type</th>
                    <th>Qty</th>
                    <th>Buy Avg</th>
                    <th>Sell Avg</th>
                  </tr>
                </template>
                <template v-slot:item="data">
                  <tr>
                    <td>
                      <v-checkbox
                        :input-value="
                          data.selected ||
                          selectedExitPositions.includes(data.item)
                        "
                        @click="toggleSelection(data.item)"
                        color="var(--primary-button-color)"
                        :disabled="data.item.net_quantity == 0"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td>{{ data.item.trading_symbol }}</td>
                    <td>
                      <span
                        class="product-type"
                        :class="data.item.prod_type == 'NRML' ? 'nrml' : ''"
                        >{{ data.item.prod_type }}</span
                      >
                    </td>
                    <td>
                      <span v-if="data.item.net_quantity < 0" class="green-text"
                        >+</span
                      >
                      <span
                        :class="
                          data.item.net_quantity < 0 ? 'green-text' : 'red-text'
                        "
                        >{{ Math.abs(data.item.net_quantity) }}</span
                      >
                    </td>
                    <td>{{ data.item.buy_amount.toFixed(2) }}</td>
                    <td>{{ data.item.sell_amount.toFixed(2) }}</td>
                  </tr>
                </template>
              </v-data-table>

              <!-- Mobile design -->
              <div v-else style="width: 100%">
                <div
                  class="mobile-instrument-rows pt-4"
                  v-for="(item, index) of selectedPositions"
                  :key="index"
                  :class="item.net_quantity == 0 ? 'disable-row' : ''"
                >
                  <v-row no-gutters class="mb-4 justify-space-between">
                    <v-col cols="1" class="mr-6">
                      <v-checkbox
                        :input-value="
                          item.selected || selectedPositions.includes(item)
                        "
                        @click="toggleSelection(item)"
                        color="var(--primary-button-color)"
                        :disabled="item.net_quantity == 0"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      {{ item.formatted_ins_name }}
                    </v-col>
                    <v-col cols="4" class="text-right pr-2">
                      <span> Buy Avg: </span>
                      <span>{{ item.buy_amount }}</span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="mb-4">
                    <v-col cols="4" class="text-left pl-10">
                      <span
                        :class="
                          item.prod_type == 'NRML'
                            ? 'nrml-mobile'
                            : 'mis-mobile'
                        "
                      >
                        {{ item.prod_type }}
                      </span>
                    </v-col>
                    <v-col cols="4">
                      <span> Qty: </span>
                      <span v-if="item.net_quantity < 0" class="green-text"
                        >+</span
                      >
                      <span
                        :class="
                          item.net_quantity < 0 ? 'green-text' : 'red-text'
                        "
                        >{{ Math.abs(item.net_quantity) }}</span
                      >
                    </v-col>
                    <v-col cols="4" class="text-right pr-2">
                      <span>Sell Avg: </span>
                      <span>{{ item.sell_amount }}</span>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </div>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#b3b3b3"
              flat
              outlined=""
              @click.stop="isExitPositionsClicked = false"
              >Cancel</v-btn
            >
            <v-btn
              color="var(--primary-button-color)"
              dark
              flat
              @click="getPlaceOrderApi()"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { EventBus } from "../../../main";
import { exitOrderUrl, saveOrderResponse } from "../../getService.js";
export default {
  name: "ExitPositionsModal",
  props: {
    isExitPositionsVisible: Boolean,
    selectedPositions: null,
  },
  computed: {
    isExitPositionsClicked: {
      get() {
        return this.isExitPositionsVisible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      selectedExitPositions: [],
      selectAllValue: false,
      orderList: [],
    };
  },
  methods: {
    toggleSelection(item) {
      const index = this.selectedExitPositions.indexOf(item);
      if (index > -1) {
        this.selectedExitPositions.splice(index, 1);
      } else {
        this.selectedExitPositions.push(item);
      }
      console.log(this.selectedPositions);
      this.selectAllValue =
        this.selectedExitPositions.length == this.selectedPositions.length
          ? true
          : false;
    },
    toggleSelectAll() {
      this.selectAllValue = !this.selectAllValue;
      if (this.selectAllValue) {
        for (let item of this.selectedPositions) {
          if (
            item.net_quantity !== 0 &&
            !this.selectedExitPositions.includes(item)
          )
            this.selectedExitPositions.push(item);
        }
      } else {
        this.selectedExitPositions = [];
      }
    },
    getExitOrderApi() {
      console.log(this.selectedExitPositions);
      let user_id = localStorage.getItem("user_id");
      let access_token = localStorage.getItem("accessTokenAnt");
      let header = { Authorization: "Bearer " + user_id + " " + access_token };
      let payload = [];
      for (let item of this.selectedExitPositions) {
        payload.push({
          exchSeg: "mcx_fo",
          pCode: item.prod_type,
          netQty: Math.abs(item.net_quantity).toString(),
          tockenNo: item.token.toString(),
          symbol: item.symbol,
        });
      }
      console.log(payload);
      axios
        .post(exitOrderUrl, payload, {
          headers: header,
        })
        .then((res) => {
          console.log(res);
        });
    },

    /** @function getPlaceOrderApi to get confirm the orders on clicking exit */
    getPlaceOrderApi() {
      let user_id = localStorage.getItem("user_id");
      let access_token = localStorage.getItem("accessTokenAnt");
      let header = { Authorization: "Bearer " + user_id + " " + access_token };
      let placeOrderUrl =
        "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/executePlaceOrder";
      let buyPayload = [];
      let sellPayload = [];
      for (let order of this.selectedExitPositions) {
        if (order.net_quantity > 0 && order.segment != "fut") {
          buyPayload.push({
            complexty: "regular",
            exch: order.exchange,
            symbol_id: order.code,
            price: order.prctyp === "LIMIT" ? order.ltp.toString() : "",
            transtype: "SELL",
            prctyp: order.prctyp == "MKT" ? "MKT" : "L",
            pCode: order.prod_type,
            qty: Math.abs(order.net_quantity).toString(),
            ret: "DAY",
            trading_symbol: order.trading_symbol,
          });
        } else {
          sellPayload.push({
            complexty: "regular",
            exch: order.exchange,
            symbol_id: order.code,
            price: order.prctyp === "LIMIT" ? order.ltp.toString() : "",
            transtype: "BUY",
            prctyp: order.prctyp == "MKT" ? "MKT" : "L",
            pCode: order.prod_type,
            qty: Math.abs(order.net_quantity).toString(),
            ret: "DAY",
            trading_symbol: order.trading_symbol,
          });
        }
      }
      axios
        .post(placeOrderUrl, buyPayload, {
          headers: header,
        })
        .then((res) => {
          // this.show = false;
          this.count = 0;
          this.columnLength += 1;
          console.log("buy", buyPayload);
          for (let i = 0; i < buyPayload.length; i++) {
            this.selectedExitPositions[i].status = "sent";
            this.getOrderHistoryAPI(res.data[i].NOrdNo, header);
          }
        });
      setTimeout(() => {
        axios
          .post(placeOrderUrl, sellPayload, {
            headers: header,
          })
          .then((res) => {
            // this.show = false;
            this.count = 0;
            this.columnLength += 1;
            for (let i = 0; i < sellPayload.length; i++) {
              this.selectedExitPositions[buyPayload.length + i].status = "sent";
              this.getOrderHistoryAPI(res.data[i].NOrdNo, header);
            }
          });
      }, 400);
    },

    getOrderHistoryAPI(order_no, header) {
      let orderHistoryUrl =
        "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/orderHistory";
      let payload = { nestOrderNumber: order_no };
      axios.post(orderHistoryUrl, payload, { headers: header }).then((res) => {
        this.orderList.push({
          response: res.data[0],
          trading_symbol: res.data[0].Trsym,
        });
        this.count = this.count + 1;
        if (this.count == this.selectedExitPositions.length) {
          this.getSavedOrderAPI();
        }
      });
    },

    getSavedOrderAPI() {
      let payload = {
        order_list: this.orderList,
        user_id: localStorage.getItem("user_id"),
      };
      axios.post(saveOrderResponse, payload).then((res) => {
        if (res.data.status != 200) {
          this.$notify({
            group: "success",
            type: "error",
            title: "Error",
            text: "Order is rejected",
          });
        } else {
          this.$notify({
            group: "success",
            type: "success",
            title: "Success",
            text: res.data.status_message,
          });
        }
        this.isExitPositionsClicked = false;
        EventBus.$emit("refreshPositionsScreen", true);
      });
    },
  },
  mounted() {
    for (let item of this.selectedPositions) {
      this.selectedExitPositions.push(item);
    }
    this.selectAllValue = true;
  },
};
</script>
<style lang="scss" scoped>
.exit-position-label {
  font-size: 1.5em;
  font-family: "Gilroy-semibold";
  color: var(--text-color);
}
.exit-positions-table.v-data-table {
  width: 100%;

  td,
  th > span {
    font-size: 1em !important;
    color: var(--text-color);
  }

  table > tr > th {
    font-size: 1em !important;
    color: var(--text-color);
    background-color: var(--chip-background) !important;
    font-family: "Gilroy-medium";
    text-align: left;
    padding: 0 16px;
    font-weight: normal;
    height: 40px !important;
    white-space: nowrap;
  }
  .product-type {
    padding: 4px 8px;
    border-radius: 4px;
  }

  .nrml {
    color: var(--option-strike-text);
    background-color: var(--option-strike-bg);
  }
}
@media only screen and (max-width: 600px) {
  .exit-position-label {
    font-size: 1em;
  }
  .exit-positions-table.v-data-table {
    td,
    th > span {
      font-size: 0.75em !important;
      height: 34px;
    }

    table > tr > th {
      font-size: 0.75em !important;
      height: 30px !important;
    }
  }

  .mobile-instrument-rows {
    font-size: 0.75em;
    color: var(--text-color);

    .nrml-mobile {
      color: var(--option-strike-text);
    }

    .mis-mobile {
      color: var(--mis-label-text);
    }
  }
}
@media only screen and (max-width: 400px) {
  .mobile-instrument-rows {
    font-size: 0.625em !important;
  }
}
</style>
