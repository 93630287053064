<template>
  <div class="styled-scroll verifiedpl-component pa-2" :class="{'hidden-card':hidden}">
    <v-card 
      class="mx-auto" 
      :ripple="false"
      elevation="0"
    >
      <div 
        :id="hidden?'tweet-image-box':'smthing'" 
        class="share-to-twitter-wrapper"
        >
        <!-- TOP -->
        <div class="d-flex justify-space-between align-center pt-3 px-6">
          <div style="position: relative;">
            <img :src="require('@/assets/1lyoptions.png')" alt="IMG" style="max-width: 14em;">
            <div class="date-text-on-img" v-if="generatedTime">
              on @
              {{ generatedTime.toDateString() }}, 
              {{ generatedTime.toLocaleTimeString('en-US',{hour12:true,hour:'numeric',minute:'numeric'}) }}
            </div>
          </div>
          <div class="d-flex">
            <div class="mx-2">
              <img :src="require('@/assets/shield-icon.png')" alt="Safe" style="max-width: 2.8em;">
            </div>
            <div style="white-space: nowrap;">Please click on this image to<br>go to the website and verify</div>
          </div>
        </div>
        <!-- MIDDLE -->
        <div class="px-6 pt-6">
          <div class="text-center" style="color:#2A2A2A; font-size: 1.5em;font-weight: 600;">Total P&L</div>
          <div 
            class="text-center" 
            :class="totalPL<0?'red-text':'green-text'"
            style="color:#2A2A2A; font-size: 2.5em;font-weight: 500;"
          >
            {{ totalPL>=0?'+':'' }}{{ totalPL | integerFormat }}
          </div>
        </div>
        <!-- BOTTOM -->
        <div class="row no-gutters justify-space-between align-center pb-3 px-6" >
          <div class="col-6">
            <div style="color:#2A2A2A;font-weight: 500;font-size: 1.2em;">Username:</div>
            <div class="id-data">{{ '@'+hideUser(userId) }}</div>
          </div>
          <div class="d-flex align-center">
            <div class="pt-5 pr-2" style="font-size: 1.2em;">Broker:</div>
            <img :src="require('@/assets/aliceblue-logo.png')" alt="IMG" style="max-width: 6.5em;">
          </div>
        </div>

      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props:{
    generatedTime:{
      type:Date,
      required:true,
      default:()=>{return null}
    },
    totalPL:{
      required:true,
      default:()=>{return 0}
    },
    hidden:{
      type:Boolean,
      default:()=>{return false}
    },
    showUser:{
      type:Boolean,
      default:()=>{return true}
    }
  },
  data(){
    return{
    }
  },
  computed:{
    userId(){
      return this.$store.getters.getUserId;
    }
  },
  methods:{
    hideUser(userId){
      if(this.showUser){
        return userId
      }else{
        return userId.slice(0,2)+'*******'
      }
    }
  }
}
</script>
<style scoped lang="scss">
.hidden-card{
  position:absolute; 
  top:0; 
  left: 0;
  z-index:-2;
  font-size: 16px !important;
  .share-to-twitter-wrapper{
    box-shadow: none !important;
    width: 600px;
    height: 300px;
  }
}
.verifiedpl-component{
  overflow: auto; 
  display: inline-block;
  max-width: 100%;
}
.share-to-twitter-wrapper{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color:white !important;
  color:#2A2A2A !important;
  min-width: 400px;
  >div{
    background-color:white !important;
  }
}
.date-text-on-img{
  // position: absolute;
  // top: 87%;
  // left: 12px;
  margin-left: 1em;
  white-space: nowrap;
}

.id-data{
  background-color:#F7F7F7;
  padding: 8px;
  border-radius:5px;
  // width: 100%;
}
</style>