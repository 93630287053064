<template>
  <div class="white-card add-strike-wrapper">
    <v-row no-gutters class="strategy-label"> Strategies </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <SelectInstrument
          @change-instrument="handleSelectedInstrument"
          :instrumentList="filteredInstrumentList"
          :selectedInstrument="selectedInstrument"
          :pageName="'strikeChart'"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="8">
        <img
          class="ins-name-icon"
          :src="
            !darkMode
              ? require('@/assets/MultiStrike/LightMode/ins-name.svg')
              : require('@/assets/MultiStrike/DarkMode/ins-name.svg')
          "
          alt=""
        />
        <span class="ins-name-label ml-1">
          {{ selectedInstrument ? selectedInstrument.name : "" }}
        </span>
      </v-col>
      <v-col
        cols="4"
        class="clear-all-label"
        @click="clearAllValues()"
        :style="{
          pointerEvents: strikeList.length == 0 ? 'none' : 'auto',
        }"
      >
        Clear All
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-3">
      <v-col cols="10" md="12" class="position-label header">
        <v-tabs height="35px" color="var(--primary-color)">
          <v-tab @click="selectedStrikeTab = 'strike'">Strike</v-tab>
          <v-tab @click="selectedStrikeTab = 'moneyness'">Moneyness</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row no-gutters class="strike-wrapper" style="height: 75%">
      <div style="height: 100%; width: 100%; overflow-y: auto">
        <v-col v-if="strikeList.length == 0" class="text-center pt-15">
          <img
            :src="
              !darkMode
                ? require('@/assets/MultiStrike/LightMode/MultiStrikeLightMode.svg')
                : require('@/assets/MultiStrike/DarkMode/MultiStrikeDarkMode.svg')
            "
          />
          <div>Please Add Strike/Strategy</div>
        </v-col>
        <v-col
          v-else
          cols="12"
          v-for="(ins, index) of strikeList"
          :key="index"
          class="strike-rows"
          :class="
            !multiStrikeChartData[index].visible ? 'disable-strike-row' : ''
          "
        >
          <v-row no-gutters class="mt-3">
            <v-col cols="6">
              <div
                class="color-box"
                :style="{ backgroundColor: `var(--color-${ins.colorNumber})` }"
              ></div>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-icon
                class="eye-icon mr-2"
                @click="
                  isDeleteStrategy = true;
                  indexStrategy = index;
                "
                >mdi-trash-can</v-icon
              >
              <v-icon
                class="eye-icon"
                v-if="multiStrikeChartData[index].visible"
                @click="hideChartLine(index)"
                >mdi-eye</v-icon
              >
              <v-icon class="eye-icon" v-else>mdi-eye-off</v-icon>
            </v-col>
          </v-row>
          <v-row no-gutters @click="hideChartLine(index)">
            <v-col cols="8">
              <v-row
                no-gutters
                class="mb-3"
                v-for="(item, idx) of ins.data.instrument"
                :key="idx"
              >
                <v-col cols="6">{{
                  selectedStrikeTab == "strike"
                    ? item.splitted_name
                    : item.splitted_atm_name
                }}</v-col>
                <v-col cols="3">Qty: {{ item.quantity }}</v-col>
                <v-col cols="3" class="buy-sell-chips">
                  <v-chip
                    v-if="item.quantity > 0"
                    label
                    small
                    color="var(--buy-background)"
                    text-color="var(--primary-color)"
                  >
                    Buy
                  </v-chip>
                  <v-chip
                    v-else
                    label
                    small
                    color="var(--put-background)"
                    text-color="var(--red-text)"
                  >
                    Sell
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="d-flex align-center justify-end">
              <v-chip small class="mr-3 strategy-name-chip">
                {{ ins.name }}
              </v-chip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </div>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="add-strike-button-wrapper">
          <button
            class="primary-button add-strategy"
            @click="isPrebuiltStrategy = true"
          >
            + Add Strategy
          </button>
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="1300px"
            :fullscreen="$vuetify.breakpoint.xs"
            content-class="dashboard-option-chain-popup"
            overlay-opacity="0"
            width="50%"
          >
            <template v-slot:activator="{ on, attrs }">
              <button
                class="outlined-button select-strike"
                @click="
                  isOptionChain = true;
                  strategyCreatorList = [];
                "
                v-bind="attrs"
                v-on="on"
              >
                Select Strike
              </button>
            </template>
            <template v-slot:default="dialog">
              <div style="position: relative">
                <div
                  class="option-chain-close-btn"
                  @click="
                    dialog.value = false;
                    "
                >
                  <v-icon>mdi-close</v-icon>
                </div>
                <OptionChain
                  :instrumentFromDashboard="selectedInstrument"
                  :expiryFromDashboard="selectedExpiry"
                  :strategyCreatorList="strategyCreatorList"
                  :loadingListInDashboard="false"
                  :modalState="dialog.value"
                  :isMultiStrikeChart="true"
                  @add-instrument="addValFromOptionChain"
                  @change-instrument-quantity="incDecInstrumentValues"
                  @remove-instrument-at-index="deleteStrategyRow"
                />
              </div>
              <div class="d-flex justify-end px-6 py-2" style="background-color:var(--cards-background-color)">
                <button
                  class="outlined-button add-strategy mr-3"
                  @click="dialog.value = false"
                >
                  Cancel
                </button>
                <button
                  class="primary-button add-strategy"
                  @click="
                    dialog.value = false;
                    fillStrikesData(customStrategyData, 'optionChain');
                  "
                >
                  Confirm
                </button>
              </div>
            </template>
          </v-dialog>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      class="delete-strategy-dialog"
      v-model="isDeleteStrategy"
      width="400px"
    >
      <template>
        <v-card>
          <v-toolbar color="var(--app-background-color)" light>
            <v-toolbar-title style="color: var(--text-color)">
              Delete Strategy/Strike
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.stop="isDeleteStrategy = false">
              <v-icon style="color: var(--text-color)">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div
              style="
                margin-top: 1rem;
                font-size: var(--font-16);
                color: var(--option-row-text);
              "
            >
              Are you sure you want to delete this Strategy/Strike?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#B3B3B3" flat @click.stop="isDeleteStrategy = false"
              >Cancel</v-btn
            >
            <v-btn color="var(--red-text)" dark flat @click="deleteStrikeRow()"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <PrebuiltStrategy
      v-if="isPrebuiltStrategy"
      :isPrebuiltStrategyDialog="true"
      :dialogOpen="isPrebuiltStrategy"
      @close="isPrebuiltStrategy = false"
      :selectedTab="selectedTab"
      :selectedExpiry="selectedExpiry"
      :selectedInstrument="selectedInstrument"
      :isMultiStrikeList="true"
      :expiryDatesList="expiryDatesList"
    />
  </div>
</template>
<script>
import axios from "axios";
import SelectInstrument from "../modals/SelectInstrument.vue";
import PrebuiltStrategy from "../Dashboard/PrebuiltStrategy.vue";
import OptionChain from "../option-chain/OptionChain.vue";
import { EventBus } from "../../main.js";
import {
  getExpiredDays,
  getFilteredExpiry,
} from "../Dashboard/dashboardService.js";
import UserDataMixin from "@/mixins/getUserDataMixin";
import {
  getStrikeChartDataUrl,
  getStrikePriceListV2,
  getCustomStrategyUrl,
  getOptionStrikes,
} from "../getService";

export default {
  name: "MultiStrikeList",
  mixins: [UserDataMixin],
  components: {
    SelectInstrument,
    PrebuiltStrategy,
    OptionChain,
  },
  props: {
    instrumentList: [],
  },
  data() {
    return {
      filteredInstrumentList: [],
      selectedInstrument: null,
      userSettings: JSON.parse(localStorage.getItem("user_settings")),
      isPrebuiltStrategy: false,
      selectedTab: "Bullish",
      selectedStrikeTab: "strike",
      strikeList: [],
      selectedNumbers: [],
      isOptionChain: false,
      optionStrikeList: [],
      strategyCreatorList: [],
      strategyCreatorStrikes: [],
      ceObj: [],
      ceExpiry: [],
      peObj: [],
      peExpiry: [],
      futObj: [],
      futExpiry: [],
      customStrategyData: null,
      multiStrikeChartData: [],
      deltaChartData: [],
      expiryDatesList: [],
      resolution: "1",
      isDeleteStrategy: false,
      indexStrategy: null,
    };
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  created() {
    EventBus.$on("addToCompareStrategiesFromSaved", (value) => {
      this.fillStrikesData(value, "saved");
    });
    EventBus.$on("addToCompareStrategiesFromCreator", (value) => {
      this.fillStrikesData(value, "creator");
    });
    EventBus.$on("selectedInterval", (value) => {
      this.changeResolution(value);
    });
  },
  methods: {
    getRandomNumberWithoutRepeats() {
      if (this.selectedNumbers.length === 20) {
        return null; // Return null if all numbers are already selected.
      }
      let randomNumber;
      do {
        randomNumber = Math.floor(Math.random() * 20) + 1;
      } while (this.selectedNumbers.includes(randomNumber)); // Keep generating if the number is already selected.
      this.selectedNumbers.push(randomNumber);
      return randomNumber;
    },

    filterInstrumentList() {
      let newInsList = [];
      for (let ins of this.instrumentList) {
        if (ins.name == "NIFTY") {
          this.selectedInstrument = ins;
          this.$emit("selectedInstrument", this.selectedInstrument);
          this.expiryDatesList = getFilteredExpiry(ins.option_expiry_dates);
          this.selectedExpiry = this.expiryDatesList[0];
          this.getStrikeChartDataFromList(false);
          this.getStrikesList();
          this.getOptionStrikesApi();
        }
        if (
          ins.name === "NIFTY" ||
          ins.name === "FINNIFTY" ||
          ins.name === "BANKNIFTY"
        ) {
          newInsList.push(ins);
        } else {
          this.filteredInstrumentList.push(ins);
        }
      }
      this.filteredInstrumentList = newInsList.concat(
        this.filteredInstrumentList
      );
    },
    handleSelectedInstrument(value) {
      this.selectedInstrument = value;
      this.selectedExpiry = this.selectedInstrument.expiry_dates[0];
      this.$emit("selectedInstrument", this.selectedInstrument);
      this.strikeList = [];
      this.getStrikeChartDataFromList(true);
      this.getStrikesList();
      this.getOptionStrikesApi();
    },

    fillStrikesData(value, key) {
      value.key = key;
      this.strikeList.push(value);
      const randomNumber = this.getRandomNumberWithoutRepeats();
      if (randomNumber !== null) {
        value.colorNumber = randomNumber;
      } else {
        console.error("All numbers have been selected.");
      }
      for (let ins of this.strikeList) {
        let index = 0;
        for (let item of ins.data.instrument) {
          let elements = item.formatted_ins_name.split(" ");
          elements.shift();
          item.splitted_name = elements.join(" ");
          if (ins.key == "creator") {
            item.splitted_atm_name =
              elements[0] + " " + ins.selectedStrategy[index].value;
          } else if (ins.key == "saved") {
            item.splitted_atm_name =
              elements[0] +
              " " +
              ins.selectedStrategy[index].strike_options +
              " " +
              ins.selectedStrategy[index].strike_limit;
          } else if (ins.key == "optionChain") {
            console.log(index);
          }
          index += 1;
        }
      }
      this.getStrikeChartDataFromList(false);
    },

    getStrikesList() {
      const expiry = this.reformatDate(this.selectedExpiry);

      const diff = Math.abs(new Date(expiry) - new Date());
      const timeForExp = (diff + 36000000) / (24 * 3600 * 1000) / 365;
      const payload = {
        name: this.selectedInstrument.name,
        expiry: expiry,
        limit: "100",
        exchange: this.selectedInstrument.exchange,
        access_token: this.accessToken,
        user_id: this.userId,
        days_to_expire: timeForExp,
        ws_token: this.wsSession,
      };
      axios.post(getStrikePriceListV2, payload).then((res) => {
        for (let i = 0; i < res.data.strike_list.length; i++) {
          this.optionStrikeList.push({
            ceCode: res.data.strike_list_ce[i].code,
            peCode: res.data.strike_list_pe[i].code,
            strike: res.data.strike_list[i],
          });
        }
      });
    },

    getStrikeChartDataFromList(isEdit) {
      let strategy = [];
      for (let strike of this.strikeList) {
        let codes = [];
        let quantity = [];
        let insList = strike;
        for (let ins of insList.data.instrument) {
          codes.push(ins.code);
          quantity.push(ins.quantity);
        }
        strategy.push({
          name: strike.colorNumber,
          combinations: {
            codes: codes,
            quantity: quantity,
          },
        });
      }
      let payload = {
        user_id: this.userId,
        access_token: this.accessToken,
        strategy: strategy,
        lot_size: this.selectedInstrument.lot_size,
        exchange: this.selectedInstrument.exchange,
        resolution: this.resolution,
        symbol: this.selectedInstrument.name,
        underlying_code: this.selectedInstrument.underlying_code,
      };
      if (isEdit) {
        this.multiStrikeChartData = [];
      }
      axios
        .post(getStrikeChartDataUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.multiStrikeChartData = [];
          this.deltaChartData = [];
          let instrument_data = res.data.underlying;
          this.instrumentChartData = instrument_data.map((item) => [
            new Date(item.date).getTime(),
            item.Close,
          ]);
          EventBus.$emit("instrumentChartData", this.instrumentChartData);

          if (res.data.strategy.data.length > 0) {
            let index = 0;
            for (let data of res.data.strategy.data) {
              this.multiStrikeChartData.push({
                data: data.map((item) => [
                  new Date(item.time).getTime(),
                  item.ltp,
                ]),
                delta: res.data.strategy.delta[index].map((item) => [
                  new Date(item.time).getTime(),
                  item.delta,
                ]),
                theta:res.data.strategy.theta>0? res.data.strategy.theta[index].map((item) => [
                  new Date(item.time).getTime(),
                  item.theta,
                ]):0,
                gamma: res.data.strategy.gamma>0? res.data.strategy.gamma[index].map((item) => [
                  new Date(item.time).getTime(),
                  item.gamma,
                ]):0,
                vega:res.data.strategy.vega>0? res.data.strategy.vega[index].map((item) => [
                  new Date(item.time).getTime(),
                  item.vega,
                ]):0,
                oi:res.data.strategy.oi>0? res.data.strategy.oi[index].map((item) => [
                  new Date(item.time).getTime(),
                  item.oi,
                ]):0,
                name: this.strikeList[index].name,
                colorNumber: this.strikeList[index].colorNumber,
                visible: true,
              });
              index += 1;
            }
          }
          console.log(this.multiStrikeChartData);
          EventBus.$emit("multiStrikeChartData", this.multiStrikeChartData);
        });
    },

    changeResolution(value) {
      this.resolution = value;
      this.getStrikeChartDataFromList(true);
    },
    clearAllValues() {
      this.strikeList = [];
      this.multiStrikeChartData = [];
      // this.deltaChartData = [];
      EventBus.$emit("multiStrikeChartData", this.multiStrikeChartData);
      // EventBus.$emit("deltaChartData", this.deltaChartData);
    },

    hideChartLine(index) {
      this.multiStrikeChartData[index].visible =
        !this.multiStrikeChartData[index].visible;
      EventBus.$emit("multiStrikeChartData", this.multiStrikeChartData);
      // this.deltaChartData[index].visible =
      //   !this.deltaChartData[index].visible;
      // EventBus.$emit("deltaChartData", this.deltaChartData);
    },
    reformatDate(dateStr) {
      let d = new Date(dateStr);
      const month = (d.getMonth() < 9 ? "0" : "") + (d.getMonth() + 1);
      const year = d.getFullYear();
      const date = (d.getDate() <= 9 ? "0" : "") + d.getDate();
      const ans = [year, month, date].join("-");
      return ans;
    },

    getOptionStrikesApi() {
      const payload = {
        path: "option_strikes",
        broker: "aliceblue",
        name: this.selectedInstrument.name,
        user_id: this.userId,
        access_token: this.accessToken,
      };
      axios
        .post(getOptionStrikes, payload, {
          headers: {
            "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
          },
        })
        .then((res) => {
          //INSTRUMENT CE PE FUT DATA PREPROCESSING
          this.ceObj = this.getCePeFutObject(res.data.ce);
          this.ceExpiry = getFilteredExpiry(Object.keys(this.ceObj));
          this.peObj = this.getCePeFutObject(res.data.pe);
          this.peExpiry = getFilteredExpiry(Object.keys(this.peObj));
          this.futObj = this.getCePeFutObject(res.data.fut);
          this.futExpiry = getFilteredExpiry(Object.keys(this.futObj));
        });
    },

    getCePeFutObject(result) {
      let obj = {};
      result.forEach((el) => {
        let key = Object.keys(el)[0];
        obj[key] = el[key];
      });
      return obj;
    },
    addValFromOptionChain(ins) {
      let row = {
        code: ins.code,
        expiry: ins.expiry,
        ltp: "",
        name: ins.name,
        op_pr: "",
        quantity: ins.quantity,
        segment: ins.segment,
        strike: ins.strike,
        exchange: ins.exchange,
        trading_symbol: ins.trading_symbol,
        formatted_ins_name: ins.formatted_ins_name,
      };
      this.strategyCreatorList.push(row);
      this.getStrikesDropdown(row);
      this.addValues(row, this.strategyCreatorList.length - 1, true);
    },
    getStrikesDropdown(instrument) {
      if (instrument.segment == "c" || instrument.segment == "CE") {
        this.strategyCreatorStrikes.push({
          strikes: this.ceObj[instrument.expiry].strike.map(String),
          atmLevels: this.getAtmLevels(
            this.ceObj[instrument.expiry].strike.map(String),
            instrument.segment
          ),
          trading_symbol: this.ceObj[instrument.expiry].trading_symbol,
          formatted_ins_name: this.ceObj[instrument.expiry].formatted_ins_name,
          code: this.ceObj[instrument.expiry].code,
        });
      } else if (instrument.segment == "p" || instrument.segment == "PE") {
        this.strategyCreatorStrikes.push({
          strikes: this.peObj[instrument.expiry].strike.map(String),
          atmLevels: this.getAtmLevels(
            this.peObj[instrument.expiry].strike.map(String),
            instrument.segment
          ),
          trading_symbol: this.peObj[instrument.expiry].trading_symbol,
          formatted_ins_name: this.peObj[instrument.expiry].formatted_ins_name,
          code: this.peObj[instrument.expiry].code,
        });
      } else {
        this.strategyCreatorStrikes.push({
          strikes: [],
          trading_symbol: this.futObj[instrument.expiry].trading_symbol[0],
          formatted_ins_name:
            this.futObj[instrument.expiry].formatted_ins_name[0],
          code: this.futObj[instrument.expiry].code[0],
        });
      }
    },

    getAtmLevels(strikeList, segment) {
      let atmLevels = [];
      let zeroIndex = strikeList.indexOf(this.selectedStrike);
      let strikeLength = strikeList.length;
      if (segment == "c") {
        for (let i = 0; i < zeroIndex; i++) {
          atmLevels.push(i - zeroIndex);
        }
        for (let i = 0; i < strikeLength - zeroIndex; i++) {
          atmLevels.push(i);
        }
      } else {
        for (let i = 0; i < zeroIndex; i++) {
          atmLevels.push(-(i - zeroIndex));
        }
        for (let i = 0; i < strikeLength - zeroIndex; i++) {
          atmLevels.push(-i);
        }
      }
      return atmLevels;
    },
    addValues(row, index, isRowChanged) {
      let expiry = !isRowChanged ? this.selectedExpiry : row.expiry;
      let isFutSegment =
        row.segment == "fut" || row.segment == "FUT" ? true : false;
      let isPeSegment =
        row.segment == "p" || row.segment == "PE" ? true : false;
      let isCeSegment =
        row.segment == "c" || row.segment == "CE" ? true : false;
      let strikesList = this.strategyCreatorStrikes[index].strikes;
      let codeIndex = strikesList.indexOf(row.strike);
      let atmIndex = isFutSegment
        ? 0
        : this.strategyCreatorStrikes[index].atmLevels[codeIndex];
      if (isFutSegment && !this.futExpiry.includes(expiry)) {
        this.strategyCreatorList[index].expiry = this.futExpiry[0];
        expiry = this.futExpiry[0];
      } else if (
        (isPeSegment || isCeSegment) &&
        !this.peExpiry.includes(expiry)
      ) {
        this.strategyCreatorList[index].expiry = this.peExpiry[0];
        expiry = this.peExpiry[0];
      } else {
        this.strategyCreatorList[index].expiry = expiry;
      }
      if (isCeSegment) {
        this.strategyCreatorStrikes[index].strikes =
          this.ceObj[expiry].strike.map(String);
        this.strategyCreatorStrikes[index].atmLevels = this.getAtmLevels(
          this.ceObj[expiry].strike.map(String),
          row.segment
        );
        this.strategyCreatorStrikes[index].trading_symbol =
          this.ceObj[expiry].trading_symbol;
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.ceObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.ceObj[expiry].code;
      } else if (isPeSegment) {
        this.strategyCreatorStrikes[index].strikes =
          this.peObj[expiry].strike.map(String);
        this.strategyCreatorStrikes[index].atmLevels = this.getAtmLevels(
          this.peObj[expiry].strike.map(String),
          row.segment
        );
        this.strategyCreatorStrikes[index].trading_symbol =
          this.peObj[expiry].trading_symbol;
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.peObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.peObj[expiry].code;
      } else {
        this.strategyCreatorStrikes[index].strikes = [];
        this.strategyCreatorStrikes[index].trading_symbol =
          this.futObj[expiry].trading_symbol[0];
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.futObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.futObj[expiry].code[0];
      }
      if (!isFutSegment) {
        if (!this.strategyCreatorStrikes[index].atmLevels.includes(atmIndex)) {
          this.strategyCreatorList[index].strike =
            this.strategyCreatorStrikes[index].strikes[
              this.strategyCreatorStrikes[index].strikes.length - 1
            ];
          this.strategyCreatorList[index].trading_symbol =
            this.strategyCreatorStrikes[index].trading_symbol[
              this.strategyCreatorStrikes[index].trading_symbol.length - 1
            ];
          this.strategyCreatorList[index].formatted_ins_name =
            this.strategyCreatorStrikes[index].formatted_ins_name[
              this.strategyCreatorStrikes[index].formatted_ins_name.length - 1
            ];
          this.strategyCreatorList[index].code =
            this.strategyCreatorStrikes[index].code[
              this.strategyCreatorStrikes[index].code.length - 1
            ];
        } else {
          if (!isRowChanged) {
            this.strategyCreatorList[index].strike =
              this.strategyCreatorStrikes[index].strikes[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].trading_symbol =
              this.strategyCreatorStrikes[index].trading_symbol[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].formatted_ins_name =
              this.strategyCreatorStrikes[index].formatted_ins_name[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].code =
              this.strategyCreatorStrikes[index].code[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
          } else {
            let strikeIndex = this.strategyCreatorStrikes[
              index
            ].strikes.indexOf(this.strategyCreatorList[index].strike);
            this.strategyCreatorList[index].trading_symbol =
              this.strategyCreatorStrikes[index].trading_symbol[strikeIndex];
            this.strategyCreatorList[index].formatted_ins_name =
              this.strategyCreatorStrikes[index].formatted_ins_name[
                strikeIndex
              ];
            this.strategyCreatorList[index].code =
              this.strategyCreatorStrikes[index].code[strikeIndex];
          }
        }
      } else {
        this.strategyCreatorList[index].trading_symbol =
          this.strategyCreatorStrikes[index].trading_symbol;
        this.strategyCreatorList[index].formatted_ins_name =
          this.strategyCreatorStrikes[index].formatted_ins_name[0];
        this.strategyCreatorList[index].code =
          this.strategyCreatorStrikes[index].code;
        this.strategyCreatorList[index].strike = 0;
      }
      this.strategyCreatorList[index].ltp = row.ltp;
      this.strategyCreatorList[index].op_pr =
        this.strategyCreatorList[index].ltp;
      if (index == this.strategyCreatorList.length - 1 || isRowChanged)
        this.customStrategyApi();
    },

    customStrategyApi() {
      let payload = this.addCustomStrategyPayload();
      axios
        .post(getCustomStrategyUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.customStrategyData = res;
          this.customStrategyData.name = "Custom";
        });
    },
    addCustomStrategyPayload() {
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.compactMarketDataScripts(
              this.selectedInstrument.underlying_code
            )["details"]
            ? this.compactMarketDataScripts(
                this.selectedInstrument.underlying_code
              )["details"]["Last Traded Price"]
            : 0
          : this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]
          ? this.compactMarketDataScripts(this.selectedInstrument.fut_code)[
              "details"
            ]["Last Traded Price"]
          : 0;
      if (spot_price == 0) {
        spot_price =
          this.selectedInstrument.exchange == "NSE"
            ? this.selectedInstrument.ltp
            : this.selectedInstrument.fut_ltp;
      }
      return {
        path: "custom_strategy",
        broker: "aliceblue",
        name: this.selectedInstrument.name,
        spot_price: spot_price,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        lot_size: this.selectedInstrument.lot_size,
        days_to_expire: getExpiredDays(this.selectedExpiry),
        target_days: 0,
        iv_percent: 0,
        user_id: this.userId,
        access_token: this.accessToken,
        ws_session: this.wsSession,
        param_list: this.strategyCreatorList,
      };
    },
    incDecInstrumentValues(change, value, index, isIncremented) {
      if (value == 0 && isIncremented) {
        this.strategyCreatorList[index][change] =
          this.selectedInstrument.lot_size;
      } else if (value == 0 && !isIncremented) {
        this.strategyCreatorList[index][change] =
          -this.selectedInstrument.lot_size;
      } else {
        this.strategyCreatorList[index][change] = value;
      }
      this.customStrategyApi();
    },
    deleteStrategyRow(index) {
      this.strategyCreatorList.splice(index, 1);
      this.customStrategyApi();
    },
    deleteStrikeRow() {
      this.isDeleteStrategy = false;
      this.multiStrikeChartData.splice(this.indexStrategy, 1);
      this.strikeList.splice(this.indexStrategy, 1);
      EventBus.$emit("multiStrikeChartData", this.multiStrikeChartData);
    },
  },
  mounted() {
    this.filterInstrumentList();
  },
};
</script>
<style lang="scss" scoped>
.add-strike-wrapper {
  height: 90vh;
  position: relative;
  font-size: var(--font-16);
}

.strike-rows {
  cursor: pointer;
  padding-bottom: 0;
}

.disable-strike-row {
  background-color: var(--option-even-row) !important;
  color: var(--disabled-text) !important;
}
.strategy-label {
  font-size: var(--font-24);
  font-family: Gilroy-SemiBold;
  color: var(--text-color);
}

.ins-name-icon {
  width: 25px;
}

.ins-name-label {
  font-size: var(--font-20);
}

.clear-all-label {
  font-size: var(--font-20);
  display: flex;
  justify-content: flex-end;
  color: var(--primary-color);
  text-decoration: underline;
  vertical-align: middle;
  cursor: pointer;
}

.add-strike-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 12px 12px;
  bottom: 0;
  left: 0;
  button {
    font-size: var(--font-20);
    width: 48%;
  }
}

.header {
  font-size: var(--font-20);

  .v-tabs {
    border-bottom: 1px solid var(--border-color);
    .v-tab {
      width: 50%;
      text-transform: none !important;
      letter-spacing: inherit;
      font-size: var(--font-20);
    }
  }
}

.color-box {
  width: 14px;
  height: 11px;
  border-radius: 2px;
}

.buy-sell-chips {
  .v-chip .v-chip__content {
    word-break: break !important;
  }
}

.eye-icon.v-icon.v-icon {
  font-size: max(var(--font-20), 16px);
}
.option-chain-close-btn {
  background-color: transparent;
  cursor: pointer;
  right: 0;
  top: 0;
  position: absolute;
  padding: 8px;
}

@media only screen and (max-width: 960px) {
  .strike-wrapper {
    height: auto;
    padding-bottom: 8rem;
  }
  .add-strike-wrapper {
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .add-strike-wrapper {
    font-size: var(--font-12) !important;
  }
  .strategy-label {
    font-size: var(--font-20) !important;
  }

  .ins-name-label,
  .clear-all-label {
    font-size: var(--font-18) !important;
  }

  .add-strike-button-wrapper {
    button {
      font-size: var(--font-16);
    }
  }
  .header {
    font-size: var(--font-18) !important;

    .v-tabs {
      .v-tab {
        font-size: var(--font-18) !important;
      }
    }
  }
}
</style>
<style lang="scss">
.strategy-name-chip.v-chip.v-size--small {
  border-radius: 4px !important;
  word-wrap: break-word;
  color: var(--text-color);
  background: var(--app-background-color);
  border: 1px solid var(--border-color);
  padding: 0 6px;
  white-space: normal !important;
  height: auto !important;
  text-align: right;
}
</style>
