<template>
<div class="main-container">
  <div class="feedback-continer d-flex flex-column justify-sm-center align-center py-3 px-4">
    <div v-if="showSubmittedMsg" class="submit-feedback-message">
      Thank you for the feedback. Your feedback is submitted.
    </div>
    <form>
      <div class="my-3 feedback-header">Feel free to drop us your feedback</div>

      <!-- <label for="fname">Name</label>
      <input type="text" id="fname" name="firstname" placeholder="Your name.." v-model="name"> -->

      <label for="fname">Email</label>
      <input type="email" id="fname" name="firstname" placeholder="Your email.." v-model="email">

      <label for="subject">What do you think about our application ?</label>
      <textarea id="subject" name="subject" placeholder="Write something.." style="height:200px" v-model="message"></textarea>

      <input @click.prevent="sendFeedback()" type="submit" value="Submit">

    </form>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import UserDataMixin from "@/mixins/getUserDataMixin";
import { sendFeedback } from './getService'
export default {
  mixins:[UserDataMixin],
  data(){
      return{
        showSubmittedMsg: false,
        email: "",
        message: ""
    }
  },
  methods:{
    sendFeedback(){
      if(!this.email||this.message){
        console.error("Fields Can not be empty");
        return;
      }
      let payload = {"email":this.email, "message":this.message,"user_id":this.userId}
      axios.post(sendFeedback,payload)
      .then((res) => {
        if (!res.data.success) throw res;
        this.showSubmittedMsg = true
        this.email='';
        this.message='';
      })

    }
  }
}
</script>

<style>
@import "../assets/css/global.scss";
.feedback-header{
  font-size: 1.5rem;
  color:var(--text-color);
  text-align: center;
}
.submit-feedback-message{
  font-size: 30px;
  text-align: center;
}
.main-container{
    font-size: 16px;
    font-weight: 400;
    margin: 0 100px;
}
.feedback-continer{
    border-radius: 2px;
    background: var(--cards-background-color);
    max-width: 800px;
    margin: 0 auto;
}
.feedback-continer input[type=text], select, textarea, input[type=email] {
  width: 100%; /* Full width */
  padding: 12px; /* Some padding */ 
  border: 1px solid #ccc; /* Gray border */
  border-radius: 4px; /* Rounded borders */
  box-sizing: border-box; /* Make sure that padding and width stays in place */
  margin-top: 6px; /* Add a top margin */
  margin-bottom: 16px; /* Bottom margin */
  resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
}

/* Style the submit button with a specific background color etc */
.feedback-continer input[type=submit] {
  background-color: #04AA6D;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
.feedback-continer input[type=submit]:hover {
  background-color: var(--green-text);
}
@media screen and (max-width:600px){

  .feedback-continer {
      border-radius: 2px;
      background: #fff;
  } 



  .feedback-continer input[type=submit] {
      background-color: #04aa6d;
      color: #fff;
      margin-left: 136px;
      padding: 12px 20px;
      margin-bottom: 24px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
  }
  .main-container {
      font-size: 16px;
      font-weight: 400;
      margin: 0 0 !important;
  }

.feedback-header{
  font-size: 1.2rem;
}
  
}
</style>