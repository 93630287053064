export const TOKEN_EXPIRATION = "23:30:00"; //token expires in at 11:30 // in Store Auth module
export const WEBSOCKET_CRASH_LIMIT = 500;
export const POSITION_CACHE_NAME = "position_search";
export const AICEBLUE_HEARTBEAT_INTERVAL = 10000;

export const AB_INDEXES_CODE = {
  NIFTY50: "26000",
  BANKNIFTY: "26009",
};

// Greeksoft constants
export const GS_INDEXES_CODE = {
  NIFTY50: "101999957", //"101026000",
  BANKNIFTY: "101999972", //"101026009",
};
export const GS_INDEXES_NAME = ["Nifty Bank", "Nifty 50"];

export const USE_SAMPLE = false;
export const J_LOGIN_PASSWORD = "t@2222222222";
export const GREKSOFT_HEARTBEAT_INTERVAL = 10000;
