<template>
  <v-container> Redirecting.... </v-container>
</template>

<script>
export default {
  name: "LoginSuccess",

  // sample: http://localhost:8080/loginsuccess?authCode=fasdfadsfdsdf&userId=fdsaf545
  mounted() {
    const authCode= this.$route.query["authCode"];
    const userId= this.$route.query["userId"];
    this.$store.dispatch("login", {authCode:authCode, userId:userId, broker:'aliceblue'} );
  },
};
</script>
