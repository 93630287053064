import axios from 'axios';
import {Router} from '@/main';
import { TOKEN_EXPIRATION,POSITION_CACHE_NAME } from '../../../utils/constants';
import { getAccessToken } from '../../../utils/aliceBlueUrls';
import greeksoftLoginActions from './greeksoftLoginActions';
let timer;
export default {
  state () {
    return{
      user:null,
    }
  },
  getters: {
    isLoggedOut: state => !state.user,
    isAnonymous: state => !state.user,
    isLoggedIn: state => !!state.user,
    getUser: state => state.user,
    getAccessToken: state => state.user ? state.user.accessToken : null,
    getUserId: state => state.user ? state.user.userId.toString() : null,
    getWsSession: state => state.user ? state.user.wsSession : null,
    getBroker: state => state.user ? state.user.broker : null,

    isAliceblue: state => state.user ? state.user.broker=='aliceblue' : false,
    isGreeksoft: state => state.user ? state.user.broker=='greeksoft' : false,

    getGSCID: (state,getters)=>getters.isGreeksoft ? state.user.gscid : null,
    getGCID: (state,getters)=>getters.isGreeksoft ? state.user.gcid.toString() : null,
  },
  mutations: {
    setUser(state, user){
      state.user = user
      localStorage.setItem('userObj', JSON.stringify(user));
    },
    updateUser(state, payload){
      const user = {...state.user, ...payload};
      localStorage.setItem('userObj', JSON.stringify(user));
      state.user = user;
    },
    setUserKeysInLocal(state,data){
      const user=data;
      localStorage.setItem('accessTokenAnt', user.accessToken);
      localStorage.setItem('user_id', user.userId);
      localStorage.setItem('ws_session', user.wsSession);

      if(user.authCode)
      localStorage.setItem('auth_code', user.authCode);
    },
  },
  actions:{
    ...greeksoftLoginActions,
    login({dispatch, getters},data){
      if(getters.isLoggedIn) {
        alert('already logged in\nUser:'+getters.getUser.userId+"\nBroker:"+getters.getUser.broker);
        return;
      }
      if(data.broker=='aliceblue'){
        dispatch('aliceblueLogin',data);
      }
      else if(data.broker=='greeksoft'){
        dispatch('greeksoftLogin',data);
      }
    },
    /** 
    *  @description Main login funciton for alice blue receives authCode and userId from login success component
    *  and sends it to backend to get access token and ws session
    *  -Sets up the user object in store and local storage
    *  -Sets up the token expiration time
    *  -Sets up the auto logout timer
    */
    async aliceblueLogin({commit,dispatch}, data){
      axios
      .post(getAccessToken, {
        authCode: data.authCode,
        userId: data.userId,
      })
      .then((response) => {
        const res=response.data.body;
        const userObj={
          userId:res.user_id,
          accessToken:res.access_token,
          wsSession:res.ws_access_token,
          authCode:res.auth_code,
          broker:'aliceblue',
        }
        // set user data in store and local storage
        commit('setUser',userObj);
        commit('setUserKeysInLocal',userObj);

        // After login actions (present in store/index.js)
        dispatch('actionsAfterLogin', { root: true });
      })
    }, 
    /**
     * @description This function is used to set the token expiration time
     */ 
    async setTokenExpiration({dispatch}){
      const expirationTime=new Date(new Date().toDateString()+" "+TOKEN_EXPIRATION).getTime();
      const expiresIn=expirationTime-new Date().getTime();//token expires in at 11:30 PM
      localStorage.setItem('tokenExpiration',expirationTime);
      timer=setTimeout(()=>{
        dispatch('logout');
      },expiresIn);
    },
    /**
     * @description This function is used to load user from local storage and setup expiration timer
     */
    autoLogin({commit,dispatch}){
      const user=JSON.parse(localStorage.getItem('userObj'));
      const expirationTime=localStorage.getItem('tokenExpiration');
      const expiresIn=+expirationTime-new Date().getTime();
      if(!user) return;
      if(!expirationTime){
        dispatch("setTokenExpiration");
      }
      else if(expiresIn<0){
        dispatch('logout');
        return;
      }
      else{
        timer=setTimeout(()=>{
          dispatch('logout');
          console.log('auto logout from token expiration')
        },expiresIn);
      }

      commit('setUser',user);
      dispatch('startWebsocket', { root: true });
    },
    /**
     * @description This function is used to logout the user and clear the local storage
     * items mentioned seperately to be readable whats being removed from local storage on logout
     */
    logout({commit,dispatch}){
      dispatch('actionsOnLogout', { root: true });
      commit('setUser',null);
      localStorage.removeItem('userObj');
      localStorage.removeItem('accessTokenAnt');
      localStorage.removeItem('user_id');
      localStorage.removeItem('ws_session');
      localStorage.removeItem('auth_code');
      localStorage.removeItem('tokenExpiration');

      localStorage.removeItem('user_settings');
      localStorage.removeItem('darkMode');
      localStorage.removeItem('path');
      localStorage.removeItem('balance');
      localStorage.removeItem('seen_notification');
      caches.delete(POSITION_CACHE_NAME);

      clearTimeout(timer);
      if(Router.currentRoute.name!="Home") Router.push({name:'Home'});
    }
  },
}