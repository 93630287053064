<template>
  <div class="open-interest-content">
    <!-- Uppper section -->

    <loaderModal :value="progressValue" v-if="showLoader">
      <template v-slot:body>
        <div class="info-text my-2">
          <b>Hang on! </b> While we fetch the data.
        </div>
        <v-progress-linear :value="progressValue"></v-progress-linear>
      </template>
    </loaderModal>

    <div
      class="main_container"
      :class="{ main_container: $vuetify.breakpoint.smAndUp }"
    >
      <v-row>
        <v-col class="title-text pr-16 pr-xs-0 mb-8">Futures OI Analysis</v-col>
      </v-row>
      <v-row no-gutters style="margin-bottom: -45px">
        <v-col
          class="open-interest-filter d-flex mb-8 justify-space-between"
          cols="12"
          lg="8"
        >
          <v-select
            class="default-font pr-1 option-types"
            v-model="selectedOption"
            :items="optionTypes"
            label="CM"
            multiple
            @change="handleSelection"
            solo
            dense
            style="width: 350px; min-width: 350px; max-width: 350px"
          ></v-select>
          <div class="d-flex align-center">
            <span class="date-box" style="max-width: 250px">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="filterDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filterDate"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                    dense
                    class="input-date default-font"
                    style=""
                    @input="filterOITable"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterDate"
                  no-title
                  scrollable
                  :min="minDate"
                  @change="filterOITable"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(filterDate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </span>
          </div>
          <div class="d-none d-md-flex">
            <div style="position: relative" class="mr-5">
              <div class="helper-heading">
                <span class="hidden-md-and-up">ST Outlook</span>
                <span class="hidden-sm-and-down">Short Term Outlook</span>
              </div>
              <v-text-field
                class="default-font px-1"
                label="Short Term Outlook"
                hide-details
                type="number"
                v-model="filterShort"
                @input="filterOITable"
                solo
                dense
                style="max-width: 100px"
              ></v-text-field>
            </div>

            <div style="position: relative">
              <div class="helper-heading">
                <span class="hidden-md-and-up">LT Outlook</span>
                <span class="hidden-sm-and-down">Long Term Outlook</span>
              </div>
              <v-text-field
                class="default-font px-1"
                label="Long Term Outlook"
                hide-details
                type="number"
                v-model="filterLong"
                @input="filterOITable"
                solo
                dense
                style="max-width: 100px"
              ></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col
          class="d-flex justify-md-end justify-space-between mb-8"
          cols="12"
          lg="4"
        >
          <div class="d-flex d-md-none">
            <div style="position: relative" class="mr-5">
              <div class="helper-heading">
                <span class="hidden-md-and-up">ST Outlook</span>
                <span class="hidden-sm-and-down">Short Term Outlook</span>
              </div>
              <v-text-field
                class="default-font px-1"
                label="Short Term Outlook"
                hide-details
                type="number"
                v-model="filterShort"
                @input="filterOITable"
                solo
                dense
                style="max-width: 100px"
              ></v-text-field>
            </div>

            <div style="position: relative">
              <div class="helper-heading">
                <span class="hidden-md-and-up">LT Outlook</span>
                <span class="hidden-sm-and-down">Long Term Outlook</span>
              </div>
              <v-text-field
                class="default-font px-1"
                label="Long Term Outlook"
                hide-details
                type="number"
                v-model="filterLong"
                @input="filterOITable"
                solo
                dense
                style="max-width: 100px"
              ></v-text-field>
            </div>
          </div>
          <div
            class="checkbox-wrapper"
            style="display: flex; flex-direction: row"
          >
            <v-checkbox
              @change="showNiftyFiftyFilter($event)"
              label="NIFTY 50"
              class="pr-4 nifty50 default-font"
              color="var(--primary-button-color)"
            ></v-checkbox>
            <span order="last" class="clear-btn">
              <v-btn
                class="default-font px-0 px-sm-4 clear_box filter-font"
                @click="clearFIlters()"
                :disabled="!showClear"
                dense
                outlined
                color="var(--text-color)"
                >Clear Filters</v-btn
              >
            </span>
          </div>
        </v-col>
      </v-row>

      <!-- upper section ends -->

      <!-- Data Table section -->
      <div class="open-interest-table mt-6 mt-sm-5">
        <v-data-table
          :headers="headers"
          :items="filteredShowList"
          class="open-interest"
          multi-sort
          disable-pagination
          mobile-breakpoint="0"
          hide-default-footer
          fixed-header
        >
          <!-- Symbol -->
          <template v-slot:header.name="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              transition="slide-y-transition"
              left
              fixed
              style="position: absolute; right: 0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup">
                <v-list>
                  <v-list-item>
                    <v-autocomplete
                      multiple
                      dense
                      auto-select-first
                      clearable
                      small-chips
                      color="light-blue lighten-3"
                      :items="itemsSymbols"
                      append-icon="mdi-tune"
                      v-model="filters['name']"
                      label="Symbols"
                      hide-details
                      label-class="label-custom"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0" size="small">
                          <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text">
                          (+{{ filters["name"].length - 1 }} others)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <!-- ST Outlook -->
          <template v-slot:header.st_outlook="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-if="header.cellClass === 'tb-outlook'"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup">
                <v-list>
                  <v-list-item>
                    <v-autocomplete
                      multiple
                      dense
                      auto-select-first
                      clearable
                      color="light-blue lighten-3"
                      :items="outlookFilters"
                      append-icon="mdi-tune"
                      v-model="filters['st_outlook']"
                      label="Symbols"
                      hide-details
                      label-class="label-custom"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text">
                          (+{{ filters["st_outlook"].length - 1 }} others)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <!-- LT Outlook -->
          <template v-slot:header.lt_outlook="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-if="header.cellClass === 'tb-outlook'"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup">
                <v-list>
                  <v-list-item>
                    <v-autocomplete
                      multiple
                      dense
                      auto-select-first
                      clearable
                      small-chips
                      color="light-blue lighten-3"
                      :items="outlookFilters"
                      append-icon="mdi-tune"
                      v-model="filters['lt_outlook']"
                      label="Symbols"
                      hide-details
                      label-class="label-custom"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text">
                          (+{{ filters["lt_outlook"].length - 1 }} others)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-list-item>
                </v-list>
              </div>
            </v-menu>
          </template>

          <!-- LT OI Change -->
          <template v-slot:header.long_term_oi_change_price="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-model="menuf['long_term_oi_change_price']"
              v-if="header.cellClass === 'tb-oichange'"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup filter-popup">
                <v-card>
                  <v-list>
                    <v-list-item style="margin-bottom: -20px">
                      <v-select
                        class="menu-pop"
                        v-model="filterNumberOption"
                        :items="optionsNumberfilter"
                        :menu-props="{
                          minWidth: '50px',
                          maxWidth: '80px',
                          offsetX: true,
                        }"
                        label="Filter condition"
                      >
                      </v-select>
                    </v-list-item>
                    <v-list-item style="margin-bottom: -20px">
                      <v-text-field
                        class="menu-input"
                        v-model="filters['long_term_oi_change_price']"
                        placeholder="Value"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="
                        filters['long_term_oi_change_price'] = null;
                        filterNumberOption = null;
                      "
                      style="color: var(--text-color)"
                    >
                      Clear
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="menuf['long_term_oi_change_price'] = false"
                      style="color: var(--text-color)"
                    >
                      Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-menu>
          </template>

          <!-- LT Price Change -->
          <template v-slot:header.long_term_change_price="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-model="menuf['long_term_change_price']"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup filter-popup">
                <v-card>
                  <v-list>
                    <v-list-item style="margin-bottom: -20px">
                      <v-select
                        class="menu-pop"
                        v-model="filterLTPriceOption"
                        :items="optionsNumberfilter"
                        :menu-props="{
                          minWidth: '50px',
                          maxWidth: '80px',
                          offsetX: true,
                        }"
                        label="Filter condition"
                      >
                      </v-select>
                    </v-list-item>
                    <v-list-item style="margin-bottom: -20px">
                      <v-text-field
                        class="menu-input"
                        v-model="filters['long_term_change_price']"
                        placeholder="Value"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="
                        filters['long_term_change_price'] = null;
                        filterLTPriceOption = null;
                      "
                      style="color: var(--text-color)"
                    >
                      Clear
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="menuf['long_term_change_price'] = false"
                      style="color: var(--text-color)"
                      >Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-menu>
          </template>

          <!-- ST OI Change -->
          <template v-slot:header.short_term_oi_change_price="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-model="menuf['short_term_oi_change_price']"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup filter-popup">
                <v-card>
                  <v-list>
                    <v-list-item style="margin-bottom: -20px">
                      <v-select
                        class="menu-pop"
                        v-model="filterSTOIOption"
                        :items="optionsNumberfilter"
                        :menu-props="{
                          minWidth: '50px',
                          maxWidth: '80px',
                          offsetX: true,
                        }"
                        label="Filter condition"
                      >
                      </v-select>
                    </v-list-item>
                    <v-list-item style="margin-bottom: -20px">
                      <v-text-field
                        class="menu-input"
                        v-model="filters['short_term_oi_change_price']"
                        placeholder="Value"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="
                        filters['short_term_oi_change_price'] = null;
                        filterSTOIOption = null;
                      "
                      style="color: var(--text-color)"
                    >
                      Clear
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="menuf['short_term_oi_change_price'] = false"
                      style="color: var(--text-color)"
                      >Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-menu>
          </template>

          <!-- ST Price Change -->
          <template v-slot:header.short_term_change_price="{ header }">
            <span class="header-font">{{ header.text }}</span>
            <v-menu
              v-model="menuf['short_term_change_price']"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="icon-resize">
                  <v-icon small> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              <div style="background-color: white" class="popup filter-popup">
                <v-card>
                  <v-list>
                    <v-list-item style="margin-bottom: -20px">
                      <v-select
                        class="menu-pop"
                        v-model="filterSTPriceOption"
                        :items="optionsNumberfilter"
                        :menu-props="{
                          minWidth: '50px',
                          maxWidth: '80px',
                          offsetX: true,
                        }"
                        label="Filter condition"
                        style="width: 80px"
                      >
                      </v-select>
                    </v-list-item>
                    <v-list-item style="margin-bottom: -20px">
                      <v-text-field
                        class="menu-input"
                        v-model="filters['short_term_change_price']"
                        placeholder="Value"
                      ></v-text-field>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-btn
                      text
                      @click="
                        filters['short_term_change_price'] = null;
                        filterSTPriceOption = null;
                      "
                      style="color: var(--text-color)"
                    >
                      Clear
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="menuf['short_term_change_price'] = false"
                      style="color: var(--text-color)"
                      >Done
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </v-menu>
          </template>

          <!-- Support -->
          <template v-slot:header.pe_price_x_x="{ header }">
            <div class="content-center">
              <v-spacer
                ><span class="header-font">{{ header.text }}</span></v-spacer
              >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="tooltip content-center item-resize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-information-outline </v-icon>
                    <!-- <svg style="width: 16px; height: 16px" viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg> -->
                  </span>
                </template>
                <span class="tooltip-content"> Put Strike with highest OI</span>
              </v-tooltip>
            </div>
          </template>

          <!-- Resistance -->
          <template v-slot:header.ce_price_x_x="{ header }">
            <div class="content-center">
              <v-spacer
                ><span class="header-font">{{ header.text }}</span></v-spacer
              >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="tooltip content-center item-resize"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-information-outline </v-icon>
                    <!-- <svg style="width: 16px; height: 16px" viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg> -->
                  </span>
                </template>
                <span class="tooltip-content">
                  Call Strike with highest OI</span
                >
              </v-tooltip>
            </div>
          </template>

          <!-- **********DATA ROW********** -->
          <template v-slot:item="{ item }">
            <tr>
              <td
                class="tb-symbol"
                style="
                  font-size: 0.7625em;
                  font-family: 'Gilroy-Medium';
                  color: var(--text-color);
                  padding-left: 11px;
                "
              >
                {{ item.name }}
                <span class="actionButtons">
                  <button
                    style="background-color: var(--optn-background) !important"
                    class="dashboard-optn-btn mx-1"
                    @click="redirectToInstrumentURL(item.name, 'dashboard')"
                  >
                    <v-icon
                      small
                      style="
                        color: var(--optionchain-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >mdi-view-dashboard-outline</v-icon
                    >
                  </button>
                  <button
                    style="background-color: var(--optn-background) !important"
                    class="dashboard-optn-btn"
                    @click="redirectToInstrumentURL(item.name, 'option-chain')"
                  >
                    <v-icon
                      small
                      style="
                        color: var(--optionchain-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >mdi-link-variant</v-icon
                    >
                  </button>
                </span>
              </td>
              <td class="tb-price">
                {{ parseFloat(item.price_x_x).toFixed(2) }}
              </td>
              <td class="tb-order">
                <span class="text-black">
                  {{ item.pe_price_x_x }}
                </span>
              </td>
              <td class="tb-resistance">
                <span class="text-black">
                  {{ item.ce_price_x_x }}
                </span>
              </td>
              <td
                class="tb-outlook"
                style="background: var(--outlook-background)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-red"
                        v-if="item.st_outlook === 'Short buildup'"
                      >
                        Short buildup
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Decrease in price and increase in OI indicating bearish
                    view.
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-orange"
                        v-if="item.st_outlook === 'Short covering'"
                      >
                        Short covering
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Increase in price and decrease in OI indicating closure of
                    bearish positions.</span
                  >
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-blue"
                        v-if="item.st_outlook === 'Long unwinding'"
                      >
                        Long unwinding
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Decrease in price and decrease in OI indicating closure of
                    bullish positions.</span
                  >
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-green"
                        v-if="item.st_outlook === 'Long buildup'"
                      >
                        Long buildup
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Increase in price and increase in OI indicating bullish
                    view.
                  </span>
                </v-tooltip>

                <span class="text-watermark" v-if="item.st_outlook === 'days'">
                  days
                </span>
              </td>
              <td class="tb-pricechange">
                <span
                  class="decrease-icon"
                  v-if="item.short_term_change_price < 0"
                >
                  {{ parseFloat(item.short_term_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-down"
                    style="color: var(--red-text)"
                  ></i>
                </span>
                <span class="increase-icon" v-else>
                  {{ parseFloat(item.short_term_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-up"
                    style="color: var(--green-text)"
                  ></i>
                </span>
              </td>
              <td class="tb-oichange">
                <span
                  class="decrease-icon"
                  v-if="item.short_term_oi_change_price < 0"
                >
                  {{ parseFloat(item.short_term_oi_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-down"
                    style="color: var(--red-text)"
                  ></i>
                </span>
                <span class="increase-icon" v-else>
                  {{ parseFloat(item.short_term_oi_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-up"
                    style="color: var(--green-text)"
                  ></i>
                </span>
              </td>
              <td
                class="tb-outlook"
                style="background: var(--outlook-background)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-red"
                        v-if="item.lt_outlook === 'Short buildup'"
                      >
                        Short buildup
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Decrease in price and increase in OI indicating bearish
                    view.
                  </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-orange"
                        v-if="item.lt_outlook === 'Short covering'"
                      >
                        Short covering
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Increase in price and decrease in OI indicating closure of
                    bearish positions.</span
                  >
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-blue"
                        v-if="item.lt_outlook === 'Long unwinding'"
                      >
                        Long unwinding
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Decrease in price and decrease in OI indicating closure of
                    bullish positions.</span
                  >
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="tooltip content-center item-resize"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span
                        class="text-green"
                        v-if="item.lt_outlook === 'Long buildup'"
                      >
                        Long buildup
                      </span>
                    </span>
                  </template>
                  <span class="tooltip-data">
                    Increase in price and increase in OI indicating bullish
                    view.
                  </span>
                </v-tooltip>
                <span class="text-watermark" v-if="item.lt_outlook === 'days'">
                  days
                </span>
              </td>
              <td class="tb-pricechange">
                <span
                  class="decrease-icon"
                  v-if="item.long_term_change_price < 0"
                  >{{ parseFloat(item.long_term_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-down"
                    style="color: var(--red-text)"
                  ></i>
                </span>
                <span class="increase-icon" v-else
                  >{{ parseFloat(item.long_term_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-up"
                    style="color: var(--green-text)"
                  ></i>
                </span>
              </td>
              <td class="tb-oichange">
                <span
                  class="decrease-icon"
                  v-if="item.long_term_oi_change_price < 0"
                  >{{ parseFloat(item.long_term_oi_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-down"
                    style="color: var(--red-text)"
                  ></i>
                </span>
                <span class="increase-icon" v-else
                  >{{ parseFloat(item.long_term_oi_change_price).toFixed(2) }}%
                  <i
                    class="fas fa-caret-up"
                    style="color: var(--green-text)"
                  ></i>
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getFutureOIDate, getOpenInterestTable } from "@/components/getService";
import loaderModal from "./loaderModal.vue";
export default {
  data: () => ({
    minDate: "2021-06-02",
    filterLong: 5,
    filterShort: 1,
    filterDate: null,
    isCM: true,
    isCM1: true,
    isCM2: true,
    menu: false,
    showClear: false,
    menuf: {
      name: false,
      short_term_change_price: false,
      short_term_oi_change_price: false,
      st_outlook: false,
      lt_outlook: false,
      long_term_change_price: false,
      long_term_oi_change_price: false,
    },
    showTable: true,
    sorryMessage: false,
    access_token: "",
    currentRow: null,
    interestList: [],
    selectedSymbols: [],
    selectedSTOutlook: [],
    searchSymbol: "",
    filterSymbol: "",
    searchSTOutlook: "",
    itemsSymbols: [],
    cancelData: null,
    show_list: [],
    optionTypes: ["CM(Current Month)", "CM+1", "CM+2"],
    selectedOption: ["CM(Current Month)", "CM+1", "CM+2"],
    filters: {
      name: [],
      short_term_change_price: null,
      short_term_oi_change_price: null,
      st_outlook: [],
      lt_outlook: [],
      long_term_change_price: null,
      long_term_oi_change_price: null,
    },
    headers: [
      {
        text: "Symbol",
        sortable: true,
        value: "name",
        class: "th-head md-header ",
        cellClass: "tb-symbol",
      },
      {
        text: "Price",
        value: "price_x_x",
        sortable: false,
        class: "th-head",
        cellClass: "tb-price",
      },
      {
        text: "Support",
        value: "pe_price_x_x",
        sortable: false,
        class: "th-head",
        cellClass: "tb-order",
      },
      {
        text: "Resistance",
        value: "ce_price_x_x",
        sortable: false,
        class: "th-head",
        cellClass: "tb-resistance",
      },
      {
        text: "ST Outlook",
        value: "st_outlook",
        sortable: false,
        class: "th-head md-header ",
        cellClass: "tb-outlook",
      },
      {
        text: "ST Price Change",
        value: "short_term_change_price",
        sortable: true,
        class: "th-head lg-header",
        cellClass: "tb-pricechange",
      },
      {
        text: "ST OI Change",
        value: "short_term_oi_change_price",
        sortable: true,
        class: "th-head lg-header",
        cellClass: "tb-oichange",
      },
      {
        text: "LT Outlook",
        value: "lt_outlook",
        sortable: false,
        class: "th-head md-header",
        cellClass: "tb-outlook",
      },
      {
        text: "LT Price Change",
        value: "long_term_change_price",
        sortable: true,
        class: "th-head lg-header",
        cellClass: "tb-pricechange",
      },
      {
        text: "LT OI Change",
        value: "long_term_oi_change_price",
        sortable: true,
        class: "th-head lg-header",
        cellClass: "tb-oichange",
      },
    ],
    outlookFilters: [
      "Short buildup",
      "Short covering",
      "Long unwinding",
      "Long buildup",
    ],
    optionsNumberfilter: ["=", ">", "<", ">=", "=<"],
    filterNumberOption: null,
    filterLTPriceOption: null,
    filterSTPriceOption: null,
    filterSTOIOption: null,
    inputNumber: null,
    showLoader: true,
    progressValue: 15,
  }),
  name: "OpenInterestFilter.vue",
  components: {
    loaderModal,
  },
  methods: {
    redirectToInstrumentURL(ins, page) {
      let url = window.location.origin + `/${page}?trading_symbol=${ins}`;
      // if(ins.expiry) url+=`&expiry=${ins.expiry}`;
      window.open(url, "_blank");
    },
    handleSelection() {
      if (this.selectedOption.length != 0) {
        this.isCM = false;
        this.isCM1 = false;
        this.isCM2 = false;
        for (let i = 0; i <= this.selectedOption.length; i++) {
          if (this.selectedOption[i] === "CM(Current Month)") {
            this.isCM = true;
          } else if (this.selectedOption[i] === "CM+1") {
            this.isCM1 = true;
          } else if (this.selectedOption[i] === "CM+2") {
            this.isCM2 = true;
          }
        }
      } else {
        this.isCM = false;
        this.isCM1 = false;
        this.isCM2 = false;
      }
      this.filterOITable();
    },

    filterOITable() {
      axios
        .post(getOpenInterestTable, {
          date: this.filterDate,
          cm: this.isCM,
          cm_1: this.isCM1,
          cm_2: this.isCM2,
          short: this.filterShort,
          long: this.filterLong,
        })
        .then((res) => {
          this.progressValue = 100;
          if (res.data.success === true) {
            this.interestList =
              res.data.data[0]["response"][0]["interest_table"];
            let nifty_list = this.interestList.filter(
              (data) =>
                (data.name.toLowerCase() == "NIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "BANKNIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "FINNIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "MIDCPNIFTY".toLowerCase())
            );
            let others_list = this.interestList.filter(
              (data) =>
                (data.name != "NIFTY") &
                (data.name != "BANKNIFTY") &
                (data.name != "FINNIFTY") &
                (data.name != "MIDCPNIFTY")
            );
            this.interestList = [...nifty_list, ...others_list];
            this.show_list = this.interestList;
            if (this.show_list.length > 1) {
              for (let i = 0; i <= this.show_list.length; i++) {
                this.show_list[i]["st_outlook"] = this.outlookcreation(
                  this.show_list[i]
                );
                this.show_list[i]["lt_outlook"] = this.ltoutlookcreation(
                  this.show_list[i]
                );
                this.itemsSymbols.push(this.show_list[i]["name"]);
              }
            }
            this.showLoader = false;
          } else {
            this.showLoader = false;
          }
        });
    },
    filterOutlook(value, row) {
      let valueToCompare = "";
      if (
        row.short_term_oi_change_price > 0 &&
        row.short_term_change_price < 0
      ) {
        valueToCompare = "Short buildup";
      } else if (
        row.short_term_oi_change_price < 0 &&
        row.short_term_change_price > 0
      ) {
        valueToCompare = "Short covering";
      } else if (
        row.short_term_oi_change_price < 0 &&
        row.short_term_change_price < 0
      ) {
        valueToCompare = "Long unwinding";
      } else if (
        row.short_term_oi_change_price >= 0 &&
        row.short_term_change_price >= 0
      ) {
        valueToCompare = "Long buildup";
      }

      if (value === valueToCompare) {
        return row;
      }
    },
    filterLongOutlook(value, row) {
      let valueToCompare = "";
      if (row.long_term_oi_change_price > 0 && row.long_term_change_price < 0) {
        valueToCompare = "Short buildup";
      } else if (
        row.long_term_oi_change_price < 0 &&
        row.long_term_change_price > 0
      ) {
        valueToCompare = "Short covering";
      } else if (
        row.long_term_oi_change_price < 0 &&
        row.long_term_change_price < 0
      ) {
        valueToCompare = "Long unwinding";
      } else if (
        row.long_term_oi_change_price >= 0 &&
        row.long_term_change_price >= 0
      ) {
        valueToCompare = "Long buildup";
      }

      if (value === valueToCompare) {
        return row;
      }
    },
    showNiftyFiftyFilter(event) {
      if (event) {
        this.show_list = this.interestList.filter(
          (data) =>
            data.nifty_50 == true ||
            data.name == "NIFTY" ||
            data.name == "BANKNIFTY" ||
            data.name == "FINNIFTY" ||
            data.name == "MIDCPNIFTY"
        );
        this.itemsSymbols = this.show_list.map(({ name }) => name);
      } else {
        this.show_list = this.interestList;
        this.itemsSymbols = this.show_list.map(({ name }) => name);
      }
    },
    classChecker({ column }) {
      if (column.label == "ST Outlook") {
        return "outlook";
      }
      if (column.label == "LT Outlook") {
        return "outlook";
      }
    },
    filterSymbols(item) {
      if (this.filteredSymbols.length > 0) {
        return item.name
          .toLowerCase()
          .includes(this.filterSymbol.toLowerCase());
      }
    },
    setFilterSymbol(item) {
      this.filterSymbol = item;
    },
    outlookcreation(row) {
      var shortValue = "";
      if (
        row.short_term_oi_change_price > 0 &&
        row.short_term_change_price < 0
      ) {
        shortValue = "Short buildup";
      } else if (
        row.short_term_oi_change_price < 0 &&
        row.short_term_change_price > 0
      ) {
        shortValue = "Short covering";
      } else if (
        row.short_term_oi_change_price < 0 &&
        row.short_term_change_price < 0
      ) {
        shortValue = "Long unwinding";
      } else if (
        row.short_term_oi_change_price >= 0 &&
        row.short_term_change_price >= 0
      ) {
        shortValue = "Long buildup";
      } else {
        shortValue = "days";
      }
      return shortValue;
    },
    ltoutlookcreation(item) {
      var valueToCompare = "";
      if (
        item.long_term_oi_change_price > 0 &&
        item.long_term_change_price < 0
      ) {
        valueToCompare = "Short buildup";
      } else if (
        item.long_term_oi_change_price < 0 &&
        item.long_term_change_price > 0
      ) {
        valueToCompare = "Short covering";
      } else if (
        item.long_term_oi_change_price < 0 &&
        item.long_term_change_price < 0
      ) {
        valueToCompare = "Long unwinding";
      } else if (
        item.long_term_oi_change_price >= 0 &&
        item.long_term_change_price >= 0
      ) {
        valueToCompare = "Long buildup";
      } else {
        valueToCompare = "days";
      }
      return valueToCompare;
    },
    round(num) {
      var m = Number((Math.abs(num) * 100).toPrecision(15));
      return (Math.round(m) / 100) * Math.sign(num);
    },
    clearFIlters() {
      this.filters = {
        name: [],
        short_term_change_price: null,
        short_term_oi_change_price: null,
        st_outlook: [],
        lt_outlook: [],
        long_term_change_price: null,
        long_term_oi_change_price: null,
      };
    },
    showClearButton() {
      var result = false;
      var keys = Object.keys(this.filters);
      if (keys.length > 1) {
        for (let i = 0; i <= keys.length; i++) {
          if (
            keys[i] === "long_term_oi_change_price" ||
            keys[i] === "short_term_oi_change_price" ||
            keys[i] === "short_term_change_price" ||
            keys[i] === "long_term_change_price"
          ) {
            if (this.filters[keys[i]] !== null) {
              result = true;
              break;
            }
          } else {
            if (this.filters[keys[i]].length > 1) {
              result = true;
              break;
            }
          }
        }
      }
      return result;
    },
  },
  computed: {
    filteredShowList() {
      return this.show_list.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          if (
            this.filters["long_term_change_price"] != null ||
            this.filters["long_term_oi_change_price"] != null ||
            this.filters["lt_outlook"]?.length > 0 ||
            this.filters["short_term_change_price"] != null ||
            this.filters["short_term_oi_change_price"] != null ||
            this.filters["st_outlook"]?.length > 0
          ) {
            this.showClear = true;
          } else if (
            this.filters["long_term_change_price"] == null &&
            this.filters["long_term_oi_change_price"] == null &&
            this.filters["lt_outlook"]?.length == 0 &&
            this.filters["short_term_change_price"] == null &&
            this.filters["short_term_oi_change_price"] == null &&
            this.filters["st_outlook"]?.length == 0
          ) {
            this.showClear = false;
          }

          if (f === "long_term_oi_change_price") {
            if (this.filters[f] === null) {
              // this.showClear = false
              return true;
            } else {
              // this.showClear = true
              if (this.filterNumberOption === "=") {
                return this.round(this.filters[f]) === this.round(d[f]);
              } else if (this.filterNumberOption === ">") {
                return this.round(this.filters[f]) < this.round(d[f]);
              } else if (this.filterNumberOption === "<") {
                return this.round(this.filters[f]) > this.round(d[f]);
              } else if (this.filterNumberOption === ">=") {
                return this.round(this.filters[f]) <= this.round(d[f]);
              } else if (this.filterNumberOption === "=<") {
                return this.round(this.filters[f]) >= this.round(d[f]);
              }
            }
          } else if (f === "long_term_change_price") {
            if (this.filters[f] === null) {
              // this.showClear = false
              return true;
            } else {
              // this.showClear = true
              if (this.filterLTPriceOption === "=") {
                return this.round(this.filters[f]) === this.round(d[f]);
              } else if (this.filterLTPriceOption === ">") {
                return this.round(this.filters[f]) <= this.round(d[f]);
              } else if (this.filterLTPriceOption === "<") {
                return this.round(this.filters[f]) >= this.round(d[f]);
              }
            }
          } else if (f === "short_term_change_price") {
            if (this.filters[f] === null) {
              // this.showClear = false
              return true;
            } else {
              // this.showClear = true

              if (this.filterSTPriceOption === "=") {
                return this.round(this.filters[f]) === this.round(d[f]);
              } else if (this.filterSTPriceOption === ">") {
                return this.round(this.filters[f]) < this.round(d[f]);
              } else if (this.filterSTPriceOption === "<") {
                return this.round(this.filters[f]) > this.round(d[f]);
              } else if (this.filterSTPriceOption === ">=") {
                return this.round(this.filters[f]) <= this.round(d[f]);
              } else if (this.filterSTPriceOption === "=<") {
                return this.round(this.filters[f]) >= this.round(d[f]);
              }
            }
          } else if (f === "short_term_oi_change_price") {
            if (this.filters[f] === null) {
              // this.showClear = false
              return true;
            } else {
              // this.showClear= true
              if (this.filterSTOIOption === "=") {
                return this.round(this.filters[f]) === this.round(d[f]);
              } else if (this.filterSTOIOption === ">") {
                return this.round(this.filters[f]) < this.round(d[f]);
              } else if (this.filterSTOIOption === "<") {
                return this.round(this.filters[f]) > this.round(d[f]);
              } else if (this.filterSTOIOption === ">=") {
                return this.round(this.filters[f]) <= this.round(d[f]);
              } else if (this.filterSTOIOption === "=<") {
                return this.round(this.filters[f]) >= this.round(d[f]);
              }
            }
          } else {
            // if(this.filters['name'].length >0  || this.filters['st_outlook'].length > 0 || this.filters['lt_outlook'].length > 0){
            //   this.showClear = true
            // }
            // else {
            //   this.showClear = false
            // }
            return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
          }
        });
      });
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  mounted() {
    // this.showLoader=true;
    axios.get(getFutureOIDate).then((res) => {
      this.filterDate = res.data.last_date;
      this.progressValue = 70;
      axios
        .post(getOpenInterestTable, {
          date: this.filterDate,
          cm: this.isCM,
          cm_1: this.isCM1,
          cm_2: this.isCM2,
          short: this.filterShort,
          long: this.filterLong,
        })
        .then((res) => {
          this.progressValue = 100;
          this.showLoader = false;
          if (res.data.success === true) {
            this.interestList =
              res.data.data[0]["response"][0]["interest_table"];
            let nifty_list = this.interestList.filter(
              (data) =>
                (data.name.toLowerCase() == "NIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "BANKNIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "FINNIFTY".toLowerCase()) |
                (data.name.toLowerCase() == "MIDCPNIFTY".toLowerCase())
            );
            let others_list = this.interestList.filter(
              (data) =>
                (data.name != "NIFTY") &
                (data.name != "BANKNIFTY") &
                (data.name != "FINNIFTY") &
                (data.name != "MIDCPNIFTY")
            );
            this.interestList = [...nifty_list, ...others_list];
            this.show_list = this.interestList;
            if (this.show_list.length > 1) {
              for (let i = 0; i <= this.show_list.length; i++) {
                this.show_list[i]["st_outlook"] = this.outlookcreation(
                  this.show_list[i]
                );
                this.show_list[i]["lt_outlook"] = this.ltoutlookcreation(
                  this.show_list[i]
                );
                this.itemsSymbols.push(this.show_list[i]["name"]);
              }
            }
            this.showLoader = false;
          } else {
            this.showLoader = false;
          }
        });
    });
  },
};
</script>

<style lang="scss">
@import "../assets/css/global.scss";

.open-interest-table {
  .th-head {
    text-align: center !important;
    padding: 0 10px !important;

    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 0.75em !important;
    line-height: 16px !important;
    height: 36px !important;
  }

  thead {
    .th-head,
    button,
    i,
    span {
      color: var(--text-color) !important;
      background-color: var(--table-header-background) !important;
    }
  }
}

.nifty50.v-input--selection-controls.v-input {
  align-items: center !important;
}
</style>

<style lang="scss" scoped>
.v-select.v-input--is-dirty input {
  display: none;
}

.v-select__selection {
  max-width: none;
}

// span.decrease-icon,
// span.increase-icon,
// td{
//   text-align: center;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 12px !important;
//   line-height: 14px;
// }

.tb-symbol {
  text-align: left !important;
  // font-style: normal;
  // font-weight: 300;
  // font-size: 12px;
  // line-height: 14px;
}

// .open-interest-table th {
//   font-size: 14px !important;
//   padding: 10px;
//   text-align: center;
// }
// span{
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
// }

.tb-outlook {
  background: #e6f4ff;
}

.v-data-table-header {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 19px;
  background-color: var(--table-row-hover);
  color: #979797;
  text-align: center;
}

// Main section
.open-interest-table th {
  font-family: "Gilroy-Medium" !important;
  font-weight: 200 !important;
  font-size: 1em !important;
  line-height: 14px !important;
}

.open-interest-table td {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 0.875em !important;
  line-height: 14px !important;
  padding: 0 10px !important;
  text-align: center;
  height: 36px !important;
  color: var(--option-row-text);
}

.open-interest-table td.tb-outlook span {
  font-weight: 400 !important;
}

.input-date {
  // font-style: normal;
  // font-weight: 400;
  // font-size: 16px;
  // line-height: 22px;
  // max-width: 250px !important;
  // max-height: 40px !important;
  // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  // border-radius: 0px;

  /* Filter */

  // box-sizing: border-box;

  /* Auto layout */

  // display: flex;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  // gap: 32px;

  width: 100%;
  height: 38px;

  /* Header BG */

  background: var(--cards-background-color);
}

// Main section ends

.text-blue {
  // font-style: normal;
  // font-weight: 400;
  // font-size: 12px;
  // line-height: 14px;
  color: var(--blue-text) !important;
  text-transform: uppercase;
}

.text-red {
  color: var(--red-text) !important;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 14px;
  text-transform: uppercase;
}

.text-green {
  color: var(--green-text);
  // font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 14px;
  text-transform: uppercase;
}

.text-orange {
  color: var(--orange-text);
  // font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 14px;
  text-transform: uppercase;
}

.text-warning {
  color: var(--warning-text) !important;
  // font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 14px;
  text-transform: uppercase;
}

// .text-black{
//   font-style: normal;
//   font-weight: 300;
//   font-size: 12px;
//   line-height: 14px;
// }
.text-watermark {
  // font-style: normal;
  // font-weight: 500;
  // font-size: 12px;
  // line-height: 14px;
  text-transform: uppercase;
  opacity: 30%;
}

.style-chooser {
  margin: 6px;
  max-width: 280px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 22px;
  display: inline-flex;

  .select-box {
    max-width: 140px;
  }
}

.menu-pop {
  margin: 6px;
  max-width: 140px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 22px;
  display: inline-flex;

  .select-box {
    max-width: 120px;
  }
}

.menu-input {
  margin: 6px;
  max-width: 140px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75em;
  line-height: 22px;
  display: inline-flex;

  .select-box {
    max-width: 120px;
  }
}

.input-outlook {
  margin: 6px;
  max-width: 140px !important;
  max-height: 32px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875em;
  line-height: 18px;
}

.input-outlook fieldset {
  background-color: var(--dialog-header-text);
}

open-table table {
  table-layout: auto;
  width: 100%;
}

.open-table thead th {
  padding: 1px 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 19px;
  background-color: var(--table-row-hover);
  color: #979797;
  border: 1px solid #ddd;
  text-align: center;
}

.open-table tbody {
  font-size: 0.875em;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 14px !important;
}

.open-table tbody td {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 0.75em !important;
  line-height: 14px !important;
  padding: 5px 0 !important;
}

.open-table tbody tr:hover td {
  color: var(--option-row-text) !important;
}

.date-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 22px;
}

.default-font {
  // font-family: 'Open Sans';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 12px;
  // line-height: 16px;
  // color: var(--text-color)

  // width: 96px;
  // height: 22px;

  /* Gilroy-Medium-18

Header
*/
  font-family: "Gilroy-Medium";
  font-size: 1.125em;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* subtext */

  color: var(--text-color) !important;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-top {
  max-height: 48px;
}

.helper-heading {
  position: absolute;
  width: 76%;
  height: 26px;
  left: 8px;
  top: -45%;
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-size: 0.5em;
  line-height: 11px;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.main_container {
  margin: 0px;
  border: 1.46049px solid var(--border-color);
  border-radius: 20px;
  background: var(--cards-background-color);
  padding: 24px;
  max-width: 1850px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: none;
  order: 2;
  flex-grow: 0;
}

.title-text {
  font-family: "Gilroy-SemiBold";
  font-size: 1.5em;
  line-height: 28px;
  color: var(--text-color);
  // flex: none;
  order: 0;
  // flex-grow: 0;
  width: 208px;
  height: 29px;
  width: auto !important;
}

.hidden-sm-and-down {
  position: absolute;
  width: 107px;
  height: 15px;
  left: 0px;
  top: 0px;

  /* Gilroy-SemiBold-12

Prebuilt Strategies
*/
  font-family: "Gilroy-SemiBold";
  font-size: 1.5em;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* subtext */

  color: var(--text-color);
}

.outlook_box {
  /* Filter */

  // box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 32px;

  width: 123.6px;
  height: 38px;

  /* Header BG */

  background: var(--cards-background-color);
  /* Stroke */

  // border: 1.46049px solid var(--border-color);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.clear_box {
  /* Filter */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 12px;

  width: 100%;
  height: 38px;

  /* Header BG */

  background: var(--cards-background-color);
  /* Stroke */

  border: 1.46049px solid #e0e0e0;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

tr:nth-child(even) {
  background-color: var(--table-row-color-even);
}

tr:nth-child(odd) {
  background-color: var(--table-row-color-odd);
}

.tooltip-content {
  font-family: "Gilroy-Medium";
  font-size: 0.75em;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Sub-text */

  color: white;
  // width: 137px;
  // height: 15px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.tooltip-data {
  width: 211px;
  height: 30px;

  /* Gilroy-Medium-12

Margin Benefit
*/
  font-family: "Gilroy-Medium";
  font-size: 0.75em;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;

  /* Sub-text */

  color: white;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.filter-font {
  /* Gilroy-Medium-18

Header
*/
  font-family: "Gilroy-Medium";
  font-size: 1.125em;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Sub-text */

  color: var(--clear-filter-color);
  text-transform: capitalize;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.label-custom {
  /* Custom styles for the label */
  font-family: "Gilroy-Medium";
  font-size: 1em;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* subtext */

  color: var(--text-color);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.actionButtons {
  white-space: nowrap;
  position: absolute;
  top: 1;
}

tr .actionButtons {
  visibility: hidden;
}

tr:hover .actionButtons {
  visibility: visible;
}
.header-font {
  font-family: "Gilroy-Medium" !important;
  color: var(--text-color);
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: none !important;
}

@media screen and (min-width: 961px) and (max-width: 1850px) {
  .open-interest-content {
    font-size: 1rem;
  }
}

@media (max-width: 1220px) {
  .default-font {
    font-size: 0.875em;
    color: var(--text-color) !important;
  }
}

@media (max-width: 1110px) {
  .default-font {
    font-size: 0.75em;
    color: var(--text-color) !important;
  }

  .helper-heading {
    font-size: 0.5625em;
    // line-height: 9px;
  }
}

@media screen and (max-width: 700px) {
  .option-types {
    max-width: 220px !important;
    width: 220px !important;
    min-width: 225px !important;
  }
}

@media screen and (max-width: 600px) {
  /* Apply styles to the button for screens smaller than 600px */

  .nifty50 {
    font-size: 0.75em;
  }

  .date-title {
    font-size: 0.5625em;
    line-height: 12px;
  }

  .default-font {
    font-family: "Gilroy-Medium";
    color: var(--text-color) !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.5625em;
    line-height: 12px;
    height: 38px !important;
  }

  .item-resize {
    scale: 0.6;
  }

  .helper-heading {
    font-size: 0.5em;
    line-height: 9px;
  }

  // .open-interest-content {
  //   font-size: 3.5rem;
  // }

  .clear-btn {
    text-align: center;
  }

  .clear_box {
    width: 100%;
    margin: 0;
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: center;
  }

  .nifty50 {
    margin-right: 0;
  }

  .v-input--selection-controls__ripple {
    height: 16px;
    width: 16px;
  }
}

@media screen and (max-width: 600px) {
  // class for all type of filter data
  .popup {
    // popup icons and heading
    .v-icon,
    div.v-select__slot > div.v-select__selections > input {
      font-size: 0.75em;
    }

    div.v-select__slot > label,
    div.v-select__slot > div.v-select__selections > input,
    span,
    div.v-select__selections,
    div.v-input__slot > div > input[type="text"] {
      font-size: 0.5em;
    }
  }

  // class for numerical filtering data
  .filter-popup {
    .v-card__actions,
    .v-list-item {
      padding: 0px 2px;
    }

    .v-card__actions {
      padding-bottom: 5px;
    }

    .v-list-item {
      .v-input {
        padding: 0px;
      }
    }
  }

  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 41px !important;
  }

  //popup's list
  // .v-menu__content.menuable__content__active.v-autocomplete__content{
  //   max-width: 150px;
  //   min-width: 140px !important;
  //   .v-list-item__action{
  //     margin: 0 0;
  //     margin-right: 10px;

  //     .v-icon{
  //       font-size: 12px;
  //     }
  //   }
  //   .v-list-item__title{
  //     font-size: 8px;
  //   }
  // }

  // Header CM pop up
  .v-menu__content.theme--light.menuable__content__active {
    .v-list-item__action {
      margin: 0 0;
      margin-right: 10px;

      .v-icon {
        font-size: 0.75em;
      }
    }

    // .v-list-item__title{
    //   font-size: 8px;
    // }
  }

  // Main section
  .open-interest-table .th-head {
    padding: 0 10px !important;
    font-size: 0.5em;
    line-height: 11px !important;
    padding: 0px 5px !important;

    // Icons inside table headers
    .v-icon {
      font-size: 0.625em !important;
    }

    .icon-resize {
      max-height: 20px;
      width: auto;
    }

    span.v-data-table-header__sort-badge {
      max-height: 10px !important;
      max-width: 10px !important;
      height: 10px !important;
      width: 10px !important;
    }
  }

  .md-header {
    min-width: 100px;
  }

  .lg-header {
    min-width: 115px;
  }

  .open-interest-filter {
    .v-input__slot {
      height: 38px;
      padding: 0 5px !important;

      label {
        font-size: 0.5625em;
      }

      .v-select__selection--comma {
        margin: 0;
        margin-right: 2px;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .input-date {
    // input{
    //   padding: 0 !important;
    // }
    .v-input__slot {
      min-height: auto;
      max-height: 53px !important;
      max-width: 220px !important;
      padding: 0 5px !important;
      margin-bottom: 0 !important;
    }

    .v-icon {
      font-size: 0.875em;
    }
  }

  .nifty50 {
    /* Gilroy Medium 20 */
    font-family: "Gilroy-Medium";
    font-size: 1.25em !important;
    line-height: 23px;
    display: flex;
    align-items: center;

    /* subtext */

    color: var(--text-color);

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    .v-icon {
      font-size: 0.75em;
    }

    .v-input--selection-controls .v-input__slot > .v-label,
    .v-input--selection-controls .v-radio > .v-label {
      font-size: 0.75em !important;
    }

    .v-input--selection-controls__ripple {
      display: none;
    }
  }

  // clear button dimention adjust
  .clear-btn .v-btn:not(.v-btn--round).v-size--default {
    height: 20px;
    min-width: 58px;
  }

  .clear-btn {
    .v-btn__content {
      font-size: 0.5em;
    }
  }

  .clear-btn {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    height: 38px;

    /* Header BG */

    background: var(--cards-background-color);
    /* Stroke */

    // border: 1.46049px solid #e0e0e0;
    border-radius: 8px;
    margin-left: 8px;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}
</style>
<style scoped>
.dashboard-optn-btn {
  /* flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 8px;
  width: 20.83px;
  height: 20.83px;
  border-radius: 3.33333px;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4); */
  /* Auto layout */

  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 8px;

  width: 20.83px;
  height: 20.83px;

  /* Header BG */

  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  border-radius: 3.33333px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.dashboard-optn-btn {
  opacity: 1;
}
</style>
