<template>
  <div
    style="position: relative; height: 100%"
    :class="{ 'hc-dashboard': isDashboard }"
  >
    <!-- Expand Button -->
    <div class="expand-btn" v-if="fullScreen && $vuetify.breakpoint.xs">
      <v-icon color="#2A2A2A" @click="fullscreenEnabled = true"
        >mdi-arrow-expand</v-icon
      >
    </div>

    <highcharts
      style="height: 100%"
      class="hc"
      :class="{ 'hc-dashboard': isDashboard }"
      :options="chartOptions"
      ref="chart"
      v-if="chartOptions"
    >
    </highcharts>

    <!-- Fullscreen Graph -->
    <div v-if="$vuetify.breakpoint.xs && fullScreen">
      <v-dialog :fullscreen="true" v-model="fullscreenEnabled">
        <template>
          <v-card class="pb-5" style="position: relative">
            <highcharts
              class="fullscreen-chart"
              :options="fullChart"
              ref="fixedChart"
            ></highcharts>
            <div class="close-graph-icon">
              <v-icon @click="fullscreenEnabled = false">mdi-close</v-icon>
            </div>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts);
var spotLoss = 0;
var spotProfit = -1;
var expiryPLval = 0;
export default {
  props: {
    plotdata: null,
    fullScreen: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    selectedInstrument: null,
  },
  computed: {
    isDashboard() {
      return this.$route.name == "Dashboard";
    },
  },
  created() {
    window.addEventListener("resize", this.redrawChart);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.redrawChart);
  },
  components: {
    highcharts: Chart,
  },
  mounted() {
    this.setCharts();
  },
  data() {
    return {
      fullscreenEnabled: false,
      fullChart: null,
      chartOptions: null,
    };
  },
  watch: {
    fullscreenEnabled() {
      this.setChartData();
    },
    plotdata: {
      handler() {
        this.setCharts();
      },
      deep: true,
    },
  },
  methods: {
    redrawChart() {
      this.$refs.chart.chart.reflow();
      setTimeout(() => {
        this.$refs.chart.chart.reflow();
      }, 500);
    },
    setCharts() {
      this.chartOptions = this.setChartData(false);
      this.fullChart = this.setChartData(true);
    },
    /**
      @function set_chart_Data
      Doesn't set the data in chart
      Sets up the object that later is merged to form a complete chart
    */
    setChartData(inverse) {
      const pltData = this.plotdata;
      let chart = this.getChartObjectWithoutData(inverse);
      let plotingNegativeXY;
      let plotingXY;
      let plotingPLTargeted;
      let expiryPL;
      // let nearest_spot = [];
      spotLoss = 0;
      spotProfit = -1;
      const spotPrice = pltData
        ? this.selectedInstrument.name != "USDINR"
          ? Math.floor(pltData.Ltp)
          : pltData.Ltp.toFixed(4)
        : 0;
      if (
        pltData &&
        pltData.coordinate.y.length &&
        pltData.coordinate.x.length
      ) {
        plotingNegativeXY = [];
        plotingXY = [];
        plotingPLTargeted = [];
        expiryPL = [];
        if (pltData.pl_targeted.length) {
          for (let i = 0; i < pltData.pl_targeted.length; i++) {
            let a = [pltData.coordinate.x[i], pltData.pl_targeted[i]];
            plotingPLTargeted.push(a);
          }
          chart.series[2].data = plotingPLTargeted;
          // dataObj.series[2].data = plotingPLTargeted;// New
        }
        for (let i = 0; i < pltData.coordinate.x.length; i++) {
          expiryPL.push(pltData.coordinate.y[i]);
          if (pltData.coordinate.y[i] < 0) {
            let a = [pltData.coordinate.x[i], pltData.coordinate.y[i]];
            plotingNegativeXY.push(a);
          } else {
            let a = [pltData.coordinate.x[i], pltData.coordinate.y[i]];
            plotingXY.push(a);
          }
        }
        let plTargeted = pltData.coordinate.x.map((num) =>
          parseFloat(num.toFixed(2))
        );
        let plIndex = this.findIndexWithMinDiff(
          plTargeted,
          spotPrice
        );
        if (plIndex == plTargeted.length) {
          plIndex -= 1;
        }
        expiryPLval = expiryPL[plIndex].toFixed(2)
        if (pltData.pl_targeted[plIndex] < 0) {
          spotLoss = pltData.pl_targeted[plIndex].toFixed(2);
        } else {
          spotProfit = pltData.pl_targeted[plIndex].toFixed(2);
        }
      }
      chart.series[0].data = plotingXY;
      chart.series[1].data = plotingNegativeXY;
      chart.xAxis[0].plotLines[0].value = pltData ? pltData.Ltp : 0;
      chart.xAxis[0].plotLines[1].value = pltData ? pltData.Ltp : 0;
      chart.xAxis[0].plotLines[0].label.text = "Current Price: " + spotPrice;

      if (pltData && pltData.standardDeviation !== undefined) {
        chart.xAxis[0].plotBands[0].from =
          spotPrice - pltData.standardDeviation;
        chart.xAxis[0].plotBands[0].to = spotPrice;
        chart.xAxis[0].plotBands[1].from =
          chart.xAxis[0].plotBands[0].from - pltData.standardDeviation;
        chart.xAxis[0].plotBands[1].to = chart.xAxis[0].plotBands[0].from;
        (chart.xAxis[0].plotBands[2].from = spotPrice),
          (chart.xAxis[0].plotBands[2].to =
            spotPrice + pltData.standardDeviation);
        chart.xAxis[0].plotBands[3].from = chart.xAxis[0].plotBands[2].to;
        chart.xAxis[0].plotBands[3].to =
          chart.xAxis[0].plotBands[2].to + pltData.standardDeviation;
        chart.xAxis[1].tickPositions[0] = chart.xAxis[0].plotBands[0].from;
        chart.xAxis[1].tickPositions[1] = chart.xAxis[0].plotBands[1].from;
        chart.xAxis[1].tickPositions[2] = spotPrice;
        chart.xAxis[1].tickPositions[3] = chart.xAxis[0].plotBands[3].from;
        chart.xAxis[1].tickPositions[4] = chart.xAxis[0].plotBands[3].to;
        chart.xAxis[1].categories[2] = "";
      } else {
        // const xAxis = this.$refs.chart.chart.xAxis[0];
        // const plotBands = xAxis.plotLinesAndBands;
        // // remove existing plot bands
        // for (let i = plotBands.length - 1; i >= 0; i--) {
        //   plotBands[i].destroy();
        // }
        // NEW
        // dataObj.xAxis[0].plotBands=[]
      }

      if (spotProfit >= 0) {
        chart.xAxis[0].plotLines[1].label.text =
          "Target Profit: <span style='color:green'>" + spotProfit + "</span>";
        if (expiryPLval >= 0) {
          chart.xAxis[0].plotLines[1].label.text +=
            "| Expiry Profit: <span style='color:green'>" +
            expiryPLval +
            "</span>";
        } else {
          chart.xAxis[0].plotLines[1].label.text +=
            " | Expiry Loss: <span style='color:red'>" +
            expiryPLval +
            "</span>";
        }
        this.$emit("profitLossData", spotProfit);
      } else {
        chart.xAxis[0].plotLines[1].label.text =
          "Target Loss: <span style='color:red'>" + spotLoss + "</span>";
        if (expiryPLval >= 0) {
          chart.xAxis[0].plotLines[1].label.text +=
            "| Expiry Profit: <span style='color:green'>" +
            expiryPLval +
            "</span>";
        } else {
          chart.xAxis[0].plotLines[1].label.text +=
            " | Expiry Loss: <span style='color:red'>" +
            expiryPLval +
            "</span>";
        }
        this.$emit("expiryProfitLoss", expiryPLval);
      }
      return chart;
    },

    findIndexWithMinDiff(arr, value) {
      let left = 0;
      let right = arr.length - 1;
      let mid;

      while (left <= right) {
        mid = Math.floor((left + right) / 2);

        if (Math.abs(arr[mid]) === value) {
          return mid;
        } else if (Math.abs(arr[mid]) < value) {
          left = mid + 1;
        } else {
          right = mid - 1;
        }
      }

      // at this point, the value isn't in the array, so find the closest index
      if (arr[mid] < value) {
        return mid + 1;
      } else if (arr[mid] > value && mid > 0) {
        const diff1 = value - arr[mid - 1];
        const diff2 = arr[mid] - value;
        return diff1 < diff2 ? mid - 1 : mid;
      } else {
        return mid;
      }
    },
    /**
      @function set_chart_layout
      Sets the basic layout of the chart color, spacing, inverse etc
      Doesn't set the data in chart
    */
    getChartObjectWithoutData(inverse = false) {
      const isInverted = inverse;
      const spotPrice = this.plotdata
        ? this.selectedInstrument.name != "USDINR"
          ? Math.floor(this.plotdata.Ltp)
          : this.plotdata.Ltp.toFixed(4)
        : 0;
      const allowZoom = this.isDashboard;
      return {
        lang: {
          noData: "Loading..",
        },
        title: {
          text: null,
        },
        credits: {
          enabled: false,
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 300,
              },
              chartOptions: {
                legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal",
                },
                yAxis: {
                  labels: {
                    align: "left",
                    x: 0,
                    y: -6,
                    color: "var(--text-color)",
                  },
                  title: {
                    text: null,
                  },
                },
                subtitle: {
                  text: null,
                },
                credits: {
                  enabled: false,
                },
              },
            },
          ],
        },
        series: [
          {
            name: "Expiry Profit",
            type: "area",
            zoomType: "xy",
            data: [],
            color: "#00ff65",
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          {
            name: "Expiry Loss",
            type: "area",
            zoomType: "xy",
            data: [],
            color: "#fc5857",
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          {
            name: "Target-Profit/Loss",
            type: "spline",
            data: [],
            dashStyle: "shortdot",
            color: "#00c4ff",
            states: {
              inactive: {
                opacity: 1,
              },
            },
            marker: {
              lineWidth: 2,
              lineColor: "#00c4ff",
              fillColor: "white",
            },
          },
        ],
        chart: {
          marginTop: 30, // make room for custom axis labels
          backgroundColor: "transparent",
          inverted: isInverted,
          zoomBySingleTouch: true,
          zoomType: allowZoom ? "x" : "",
          zooming: {
            resetButton: {
              theme: {
                fill: "var(--cards-background-color)",
                states: {
                  hover: {
                    fill: "var(--chip-background)",
                  },
                },
                style: {
                  color: "var(--text-color)",
                },
              },
            },
          },
        },
        xAxis: [
          {
            labels: {
              rotation: isInverted ? 90 : 0,
              formatter: function () {
                // var plotBands = this.axis.plotLinesAndBands;
                // for (var i = 0; i < plotBands.length; i++) {
                // 	var plotBand = plotBands[i];
                // 	if (
                // 		plotBand.options.from <= this.value &&
                // 		plotBand.options.to >= this.value
                // 	) {
                // 		return plotBand.options.label.text;
                // 	}
                // }
                return this.value;
              },
              style: {
                color: "var(--text-color)",
              },
            },
            minRange: 1,
            minPadding: 0,
            maxPadding: 0,
            lineColor: "var(--line-color)",
            plotLines: [
              {
                color: "var(--green-text)",
                value: 0,
                width: 1,
                dashStyle: "shortdash",
                zIndex: -4,
                label: {
                  y: isInverted ? 0 : 30,
                  // x:isInverted ? -20 : 0,
                  verticalAlign: "bottom",
                  align: "left",
                  textAlign: "center",
                  rotation: isInverted ? 90 : 0,
                  style: {
                    color: "var(--text-color)",
                    // rotation: 90,
                  },
                },
              },
              {
                color: "",
                value: 0,
                width: 1,
                dashStyle: "shortdash",
                zIndex: -4,
                label: {
                  y: isInverted ? 0 : -21,
                  x: isInverted ? -35 : 0,
                  align: isInverted ? "right" : "left",
                  verticalAlign: "top",
                  textAlign: "center",
                  rotation: isInverted ? 90 : 0,
                  style: {
                    color: "var(--text-color)",
                    // rotation: 90,
                    fontSize: "0.9em",
                  },
                },
              },
            ],
            plotBands: [
              {
                from: 0,
                to: 0,
                color: "var(--dark-plotband-color)",
                zIndex: -5,
              },
              {
                from: 0,
                to: 0,
                color: "var(--light-plotband-color)",
                zIndex: -5,
              },
              {
                from: 0,
                to: 0,
                color: "var(--dark-plotband-color)",
                zIndex: -5,
              },
              {
                from: 0,
                to: 0,
                color: "var(--light-plotband-color)",
                zIndex: -5,
              },
            ],
            title: {},
            tickInterval: 1000,
          },
          {
            categories: ["-1σ", "-2σ", "", "+1σ ", "+2σ"],
            opposite: true,
            offset: 0,
            linkedTo: 0,
            lineColor: "var(--line-color)",
            tickPositions: [0, 0, 0, 0, 0],
            labels: {
              rotation: isInverted ? 90 : 0,
              formatter: function () {
                var categories = this.axis.categories;
                var tickPositions = this.axis.tickPositions;
                if (this.pos == tickPositions[0]) {
                  return categories[0];
                } else if (this.pos == tickPositions[1]) {
                  return categories[1];
                } else if (this.pos == tickPositions[2]) {
                  return categories[2];
                } else if (this.pos == tickPositions[3]) {
                  return categories[3];
                } else if (this.pos == tickPositions[4]) {
                  return categories[4];
                }
              },
              y: 5,
              style: {
                fontSize: "0.75em",
                color: "var(--text-color)",
              },
            },
          },
        ],
        yAxis: [
          {
            opposite: isInverted,
            minPadding: 0,
            maxPadding: 0,
            lineWidth: 1,
            lineColor: "var(--line-color)",
            gridLineWidth: 0,
            title: null,
            tickWidth: 1,
            tickLength: 7,
            // tickPosition: 'inside',
            endOnTick: true,
            labels: {
              align: isInverted ? "center" : "right",
              x: isInverted ? 0 : -10,
              y: isInverted ? -10 : 0,
              rotation: isInverted ? 90 : 0,
              style: {
                fontSize: "0.9em",
                color: "var(--text-color)",
              },
            },
          },
        ],
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            fillOpacity: 0.2,
            dataLabels: { enabled: false },
            lineWidth: 1,
            step: "center",
          },
          // series: {
          //   enableMouseTracking: false
          // }
        },
        tooltip: {
          useHTML: true,
          backgroundColor: "var(--cards-background-color)",
          style: {
            color: "var(--text-color)",
          },
          shared: true,
          formatter: function () {
            return this.points.reduce(function (s, point) {
              return (
                s +
                "<br/>" +
                point.series.name +
                ": " +
                parseFloat(point.y).toFixed(2)
              );
            }, "Price:<b>" +
              parseFloat(this.x).toFixed(2) +
              "</b>(" +
              parseFloat(
                ((parseFloat(this.x).toFixed(2) - spotPrice) / spotPrice) * 100
              ).toFixed(2) +
              "%)");
          },
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
.expand-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
@media screen and (min-width: 960px) {
  .hc-dashboard {
    min-height: 460px;
  }
}
.fullscreen-chart {
  padding: 10px;
  height: 100vh;
  background: var(--cards-background-color);
}
.close-graph-icon {
  position: absolute;
  padding: 10px;
  bottom: 0;
  right: 0;
}
</style>
