<template>
  <div class="strategy-wrapper">
    <div>
      <PlaceOrderModal
        v-if="showPlaceOrderDialog"
        :visible="showPlaceOrderDialog"
        @close="showPlaceOrderDialog = false"
        :strategyCreator="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :order_location="order_location"
      />
    </div>
    <div class="white-card strategy-creator-section">
      <div class="strategy-creator-header">
        <div class="strategy-creator-label">Strategy Creator</div>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <div
            class="mr-2"
            style="cursor: pointer"
            @click="refreshStrategyLtps()"
          >
            <v-tooltip bottom v-if="!$vuetify.breakpoint.xs">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="info-icon">
                  <v-icon>mdi-refresh</v-icon>
                </span>
              </template>
              <span>Refresh LTPs</span>
            </v-tooltip>
            <span v-else  class="info-icon">
              <v-icon>mdi-refresh</v-icon>
            </span>
          </div>
          <!-- Option chain dialog box -->
          <div>
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="1300px"
              :fullscreen="$vuetify.breakpoint.xs"
              content-class="dashboard-option-chain-popup"
              overlay-opacity="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="option-chain-button primary-button"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-link-variant</v-icon> Option Chain
                </button>
              </template>
              <template v-slot:default="dialog">
                <div style="position: relative">
                  <div
                    class="option-chain-close-btn"
                    @click="dialog.value = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </div>
                  <OptionChain
                    :instrumentFromDashboard="selectedInstrument"
                    :expiryFromDashboard="selectedExpiry"
                    :strategyCreatorList="strategyCreatorList"
                    @add-instrument="addValFromOptionChain"
                    @change-instrument-quantity="incDecInstrumentValues"
                    @remove-instrument-at-index="deleteStrategyRow"
                    :loadingListInDashboard="customStrategyLoading"
                    :modalState="dialog.value"
                  />
                </div>
              </template>
            </v-dialog>
          </div>
        </div>
      </div>
      <div class="strategy-creator-content">
        <!-- BUTTONS TO ADD OR REMOVE OPTIONS -->
        <div class="top-btn-group my-3 d-flex">
          <v-chip
            :disabled="customStrategyLoading"
            small
            class="mr-3"
            v-for="(bs, index) in buy_sell"
            :key="index"
            @click="addRow(bs.label, bs.type)"
          >
            <span
              class="mr-1"
              :class="bs.type == 'buy' ? 'green-text' : 'red-text'"
              >{{ bs.type.charAt(0).toUpperCase() }}</span
            >
            <span>{{ bs.name }}</span>
          </v-chip>
        </div>

        <!-- MAIN STRATEGY CREATOR SECTION -->

        <v-simple-table
          v-if="$vuetify.breakpoint.xs"
          dense
          class="strategy-creator-table"
        >
          <template v-slot:default>
            <thead>
              <v-row no-gutters class="mobile-instrument-header">
                <v-col cols="11">
                  <v-row no-gutters>
                    <v-col cols="3"> Type </v-col>
                    <v-col cols="5"> Strike </v-col>
                    <v-col cols="4"> Expiry </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3"> Buy/Sell </v-col>
                    <v-col cols="5">Qty</v-col>
                    <v-col cols="4">LTP </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
            </thead>
            <tbody>
              <v-row
                no-gutters
                v-for="(ins, index) in strategyCreatorList"
                :key="index"
                class="mobile-instrument-rows"
              >
                <v-col cols="12">
                  <v-row no-gutters class="my-2">
                    <v-col cols="11">
                      <v-row no-gutters>
                        <v-col cols="3" style="cursor: pointer">
                          <v-chip
                            v-if="ins.segment === 'c'"
                            label
                            small
                            color="var(--call-background)"
                            text-color="var(--green-text)"
                            :disabled="customStrategyLoading"
                            @click="
                              changeInstrumentValues('segment', 'p', index)
                            "
                          >
                            Call
                          </v-chip>
                          <v-chip
                            v-if="ins.segment === 'p'"
                            label
                            small
                            color="var(--put-background)"
                            text-color="var(--red-text)"
                            :disabled="customStrategyLoading"
                            @click="
                              changeInstrumentValues('segment', 'c', index)
                            "
                          >
                            Put
                          </v-chip>
                          <v-chip
                            v-if="ins.segment === 'fut'"
                            label
                            small
                            color="var(--buy-background)"
                            text-color="var(--primary-color)"
                          >
                            Fut
                          </v-chip>
                        </v-col>
                        <v-col
                          cols="5"
                          class="text-center"
                          style="position: relative"
                        >
                          <div
                            style="display: flex; align-items: center"
                            v-if="ins.segment != 'fut'"
                          >
                            <span
                              style="position: absolute; z-index: 1"
                              class="minus"
                              :style="{
                                pointerEvents: customStrategyLoading
                                  ? 'none'
                                  : 'auto',
                              }"
                              @click="
                                handleStrikeClicks(
                                  'strike',
                                  strategyCreatorStrikes[index].strikes[
                                    strategyCreatorStrikes[
                                      index
                                    ].strikes.indexOf(ins.strike) - 1
                                  ],
                                  index
                                );
                                changeInstrumentValues(
                                  'strike',
                                  ins.strike,
                                  index
                                );
                              "
                              >-</span
                            >
                            <v-autocomplete
                              style="font-size: var(--font-14)"
                              v-if="strategyCreatorStrikes.length != 0"
                              class="strikes-dropdown strategy-input mobile-input"
                              label
                              outlined
                              dense
                              background-color="var(--cards-background-color)"
                              v-model="ins.strike"
                              :items="strategyCreatorStrikes[index].strikes"
                              menu-props="auto"
                              @change="
                                changeInstrumentValues(
                                  'strike',
                                  ins.strike,
                                  index
                                )
                              "
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span>{{ data.item }}</span>
                                    <span class="atm-labels">
                                      <span
                                        v-if="
                                          strategyCreatorStrikes[index]
                                            .atmLevels[
                                            strategyCreatorStrikes[
                                              index
                                            ].strikes.indexOf(data.item)
                                          ] > 0
                                        "
                                        >OTM</span
                                      >
                                      <span
                                        v-else-if="
                                          strategyCreatorStrikes[index]
                                            .atmLevels[
                                            strategyCreatorStrikes[
                                              index
                                            ].strikes.indexOf(data.item)
                                          ] < 0
                                        "
                                        >ITM</span
                                      >
                                      <span v-else>ATM</span>
                                      {{
                                        strategyCreatorStrikes[index].atmLevels[
                                          strategyCreatorStrikes[
                                            index
                                          ].strikes.indexOf(data.item)
                                        ]
                                      }}
                                    </span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                            <span
                              style="position: absolute; right: 25px"
                              class="plus"
                              :style="{
                                pointerEvents: customStrategyLoading
                                  ? 'none'
                                  : 'auto',
                              }"
                              @click="
                                handleStrikeClicks(
                                  'strike',
                                  strategyCreatorStrikes[index].strikes[
                                    strategyCreatorStrikes[
                                      index
                                    ].strikes.indexOf(ins.strike) + 1
                                  ],
                                  index
                                );
                                changeInstrumentValues(
                                  'strike',
                                  ins.strike,
                                  index
                                );
                              "
                              >+</span
                            >
                          </div>
                        </v-col>
                        <v-col cols="4" style="position: relative">
                          <v-autocomplete
                            style="font-size: var(--font-14)"
                            class="expiry-input strategy-input"
                            label
                            outlined
                            dense
                            background-color="var(--cards-background-color)"
                            menu-props="auto"
                            v-model="ins.expiry"
                            :items="
                              ins.segment == 'c'
                                ? ceExpiry
                                : ins.segment == 'p'
                                ? peExpiry
                                : futExpiry
                            "
                            @change="
                              changeInstrumentValues(
                                'expiry',
                                ins.expiry,
                                index
                              )
                            "
                          >
                            <template #selection="{ item }">
                              <span style="pointer-events: none">
                                {{
                                  item.split(" ")[0] + " " + item.split(" ")[1]
                                }}
                              </span>
                            </template>
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <span
                                    >{{ data.item.split(" ")[0] }}
                                    {{ data.item.split(" ")[1] }}</span
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row no-gutters class="mt-1">
                        <v-col cols="3" style="cursor: pointer">
                          <v-chip
                            v-if="ins.quantity < 0"
                            label
                            small
                            color="var(--put-background)"
                            text-color="var(--red-text)"
                            :disabled="customStrategyLoading"
                            @click="
                              changeInstrumentValues(
                                'quantity',
                                -ins.quantity,
                                index
                              )
                            "
                          >
                            Sell
                          </v-chip>
                          <v-chip
                            v-else
                            label
                            small
                            color="var(--buy-background)"
                            text-color="var(--primary-color)"
                            :disabled="customStrategyLoading"
                            @click="
                              changeInstrumentValues(
                                'quantity',
                                -ins.quantity,
                                index
                              )
                            "
                          >
                            Buy
                          </v-chip>
                        </v-col>
                        <v-col cols="5">
                          <div class="plus-minus-field">
                            <span
                              class="minus"
                              :style="{
                                pointerEvents: customStrategyLoading
                                  ? 'none'
                                  : 'auto',
                              }"
                              @click="
                                incDecInstrumentValues(
                                  'quantity',
                                  ins.quantity - selectedInstrument.lot_size,
                                  index,
                                  false
                                )
                              "
                              >-</span
                            >
                            <input
                              style="font-size: var(--font-14)"
                              class="strategy-input"
                              type="text"
                              :value="
                                selectedInstrument.exchange != 'CDS' ||
                                selectedInstrument.exchange != 'MCX'
                                  ? ins.quantity
                                  : ins.quantity / selectedInstrument.lot_size
                              "
                              @keydown="
                                handleQuantityInput($event, 'quantity', index)
                              "
                              @blur="
                                handleQuantityInput($event, 'quantity', index)
                              "
                            />
                            <span
                              class="plus"
                              :style="{
                                pointerEvents: customStrategyLoading
                                  ? 'none'
                                  : 'auto',
                              }"
                              @click="
                                incDecInstrumentValues(
                                  'quantity',
                                  ins.quantity + selectedInstrument.lot_size,
                                  index,
                                  true
                                )
                              "
                              >+</span
                            >
                          </div>
                        </v-col>
                        <v-col cols="4" class="d-flex">
                          <input
                            style="
                              width: 4rem;
                              font-size: var(--font-14);
                              justify-content: center;
                            "
                            class="ltp-input strategy-input"
                            v-model="ins.ltp"
                            @keydown="clearTimeout($event, 'ltp', index)"
                            @blur="clearTimeout($event, 'ltp', index)"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1" class="mobile-trash">
                      <v-icon
                        class="trash-row mobile"
                        v-if="strategyCreatorList.length !== 1"
                        small
                        slot="prepend"
                        color="#57575"
                        :disabled="customStrategyLoading"
                        @click="deleteStrategyRow(index)"
                      >
                        mdi-trash-can
                      </v-icon>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table dense class="strategy-creator-table" v-else>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Type</th>
                <th class="text-center">Expiry</th>
                <th class="text-center">Strike</th>
                <th class="text-center">B/S</th>
                <th class="text-center">Qty</th>
                <th class="text-center" style="width: 60px">LTP</th>
                <th class="text-center" style="width: 30px"></th>
              </tr>
            </thead>
            <tbody>
              <!--  Array section -->
              <tr v-for="(ins, index) in strategyCreatorList" :key="index">
                <td style="cursor: pointer">
                  <v-chip
                    v-if="ins.segment === 'c'"
                    label
                    small
                    color="var(--call-background)"
                    text-color="var(--green-text)"
                    :disabled="customStrategyLoading"
                    @click="changeInstrumentValues('segment', 'p', index)"
                  >
                    Call
                  </v-chip>
                  <v-chip
                    v-if="ins.segment === 'p'"
                    label
                    small
                    color="var(--put-background)"
                    text-color="var(--red-text)"
                    :disabled="customStrategyLoading"
                    @click="changeInstrumentValues('segment', 'c', index)"
                  >
                    Put
                  </v-chip>
                  <v-chip
                    v-if="ins.segment === 'fut'"
                    label
                    small
                    color="var(--buy-background)"
                    text-color="var(--primary-color)"
                  >
                    Fut
                  </v-chip>
                </td>
                <td class="text-center">
                  <v-autocomplete
                    style="font-size: var(--font-14)"
                    class="expiry-input strategy-input"
                    label
                    outlined
                    dense
                    background-color="var(--cards-background-color)"
                    menu-props="auto"
                    v-model="ins.expiry"
                    :items="
                      ins.segment == 'c'
                        ? ceExpiry
                        : ins.segment == 'p'
                        ? peExpiry
                        : futExpiry
                    "
                    @change="
                      changeInstrumentValues('expiry', ins.expiry, index)
                    "
                  >
                    <template #selection="{ item }">
                      <span style="pointer-events: none">
                        {{ item.split(" ")[0] + " " + item.split(" ")[1] }}
                      </span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          <span
                            >{{ data.item.split(" ")[0] }}
                            {{ data.item.split(" ")[1] }}</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </td>
                <td style="position: relative">
                  <div
                    style="display: flex; align-items: center"
                    v-if="ins.segment != 'fut'"
                  >
                    <span
                      style="position: absolute; left: 0; z-index: 1"
                      class="minus"
                      :style="{
                        pointerEvents: customStrategyLoading ? 'none' : 'auto',
                      }"
                      @click="
                        handleStrikeClicks(
                          'strike',
                          strategyCreatorStrikes[index].strikes[
                            strategyCreatorStrikes[index].strikes.indexOf(
                              ins.strike
                            ) - 1
                          ],
                          index
                        );
                        changeInstrumentValues('strike', ins.strike, index);
                      "
                      >-</span
                    >
                    <v-autocomplete
                      style="font-size: var(--font-14)"
                      v-if="strategyCreatorStrikes.length != 0"
                      class="strikes-dropdown strategy-input"
                      label
                      outlined
                      dense
                      background-color="var(--cards-background-color)"
                      v-model="ins.strike"
                      :items="strategyCreatorStrikes[index].strikes"
                      menu-props="auto"
                      @change="
                        changeInstrumentValues('strike', ins.strike, index)
                      "
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span>{{ data.item }}</span>
                            <span class="atm-labels">
                              <span
                                v-if="
                                  strategyCreatorStrikes[index].atmLevels[
                                    strategyCreatorStrikes[
                                      index
                                    ].strikes.indexOf(data.item)
                                  ] > 0
                                "
                                >OTM</span
                              >
                              <span
                                v-else-if="
                                  strategyCreatorStrikes[index].atmLevels[
                                    strategyCreatorStrikes[
                                      index
                                    ].strikes.indexOf(data.item)
                                  ] < 0
                                "
                                >ITM</span
                              >
                              <span v-else>ATM</span>
                              {{
                                strategyCreatorStrikes[index].atmLevels[
                                  strategyCreatorStrikes[index].strikes.indexOf(
                                    data.item
                                  )
                                ]
                              }}
                            </span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <span
                      style="position: absolute; right: 5%"
                      class="plus"
                      :style="{
                        pointerEvents: customStrategyLoading ? 'none' : 'auto',
                      }"
                      @click="
                        handleStrikeClicks(
                          'strike',
                          strategyCreatorStrikes[index].strikes[
                            strategyCreatorStrikes[index].strikes.indexOf(
                              ins.strike
                            ) + 1
                          ],
                          index
                        );
                        changeInstrumentValues('strike', ins.strike, index);
                      "
                      >+</span
                    >
                  </div>
                </td>
                <td style="cursor: pointer">
                  <v-chip
                    v-if="ins.quantity < 0"
                    label
                    small
                    color="var(--put-background)"
                    text-color="var(--red-text)"
                    :disabled="customStrategyLoading"
                    @click="
                      changeInstrumentValues('quantity', -ins.quantity, index)
                    "
                  >
                    S
                  </v-chip>
                  <v-chip
                    v-else
                    label
                    small
                    color="var(--buy-background)"
                    text-color="var(--primary-color)"
                    :disabled="customStrategyLoading"
                    @click="
                      changeInstrumentValues('quantity', -ins.quantity, index)
                    "
                  >
                    B
                  </v-chip>
                </td>
                <td>
                  <div class="plus-minus-field">
                    <span
                      class="minus"
                      :style="{
                        pointerEvents: customStrategyLoading ? 'none' : 'auto',
                      }"
                      @click="
                        incDecInstrumentValues(
                          'quantity',
                          ins.quantity - selectedInstrument.lot_size,
                          index,
                          false
                        )
                      "
                      >-</span
                    >
                    <input
                      style="font-size: var(--font-14)"
                      class="strategy-input"
                      type="text"
                      :value="
                        selectedInstrument.exchange != 'CDS' ||
                        selectedInstrument.exchange != 'MCX'
                          ? ins.quantity
                          : ins.quantity / selectedInstrument.lot_size
                      "
                      @keydown="handleQuantityInput($event, 'quantity', index)"
                      @blur="handleQuantityInput($event, 'quantity', index)"
                    />
                    <span
                      class="plus"
                      :style="{
                        pointerEvents: customStrategyLoading ? 'none' : 'auto',
                      }"
                      @click="
                        incDecInstrumentValues(
                          'quantity',
                          ins.quantity + selectedInstrument.lot_size,
                          index,
                          true
                        )
                      "
                      >+</span
                    >
                  </div>
                </td>
                <td>
                  <input
                    style="
                      width: 4rem;
                      font-size: var(--font-14);
                      justify-content: center;
                    "
                    class="ltp-input strategy-input"
                    v-model="ins.ltp"
                    @keydown="clearTimeout($event, 'ltp', index)"
                    @blur="clearTimeout($event, 'ltp', index)"
                  />
                </td>
                <td>
                  <v-icon
                    class="trash-row"
                    v-if="strategyCreatorList.length !== 1"
                    small
                    slot="prepend"
                    color="#57575"
                    :disabled="customStrategyLoading"
                    @click="deleteStrategyRow(index)"
                  >
                    mdi-trash-can
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="strategy-creator-footer d-flex">
        <button>
          <img
            icon
            @click="isSavedStrategyClicked = true"
            class="save-compare-icons"
            :src="
              !darkMode
                ? require('@/assets/LightMode/save.svg')
                : require('@/assets/DarkMode/save.svg')
            "
            title="Save Strategy"
            alt=""
          />
        </button>
        <button>
          <img
            icon
            @click="deleteAllRows()"
            title="Clear All"
            class="save-compare-icons"
            :src="
              !darkMode
                ? require('@/assets/LightMode/delete.svg')
                : require('@/assets/DarkMode/delete.svg')
            "
            alt=""
            style="pointer-events: strategyCreatorList.length==1?'none':'auto'"
          />
        </button>
        <button>
          <img
            @click="getCompareStrategy()"
            class="save-compare-icons"
            :src="
              !darkMode
                ? require('@/assets/LightMode/compare.svg')
                : require('@/assets/DarkMode/compare.svg')
            "
            title="Compare Strategy"
            alt=""
          />
        </button>
        <v-spacer></v-spacer>
        <button
          class="primary-button"
          @click.stop="showPlaceOrderDialog = true"
        >
          Place Order
        </button>
      </div>
      <SaveStrategy
        v-if="strategyCreatorStrikes.length != 0 && isSavedStrategyClicked"
        :saveStrategyVisible="isSavedStrategyClicked"
        @close="isSavedStrategyClicked = false"
        :strategyCreatorList="strategyCreatorList"
        :selectedInstrument="selectedInstrument"
        :strategyCreatorStrikes="strategyCreatorStrikes"
        :peExpiry="peExpiry"
        :ceExpiry="ceExpiry"
        :futExpiry="futExpiry"
      ></SaveStrategy>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
import { getOptionStrikes, getCustomStrategyUrl } from "../getService";
import { getExpiredDays, getFilteredExpiry } from "./dashboardService.js";
import { EventBus } from "../../main.js";
import OptionChain from "../option-chain/OptionChain.vue";
import SaveStrategy from "../modals/SaveStrategy.vue";
import { BUY_SELL_CHIPS } from "./dashboardConstants";
import moment from "moment";
import UserDataMixin from "@/mixins/getUserDataMixin";

export default {
  name: "Strategy",
  components: {
    PlaceOrderModal,
    SaveStrategy,
    OptionChain,
  },
  mixins:[UserDataMixin],
  created() {
    EventBus.$on("targetDays", (days) => {
      this.targetDays = days;
    });
    EventBus.$on("ivPercent", (percent) => {
      this.ivPercent = percent;
    });
    EventBus.$on("strategyCreatorList", (list) => {
      this.strategyCreatorList = list;
    });
  },

  props: {
    prebuiltStrategyData: null,
    selectedInstrument: null,
    selectedExpiry: String,
    isPrebuiltStrategyCalled: Boolean,
    selectedTab: String,
  },
  data: () => ({
    buy_sell: BUY_SELL_CHIPS,
    showPlaceOrderDialog: false,
    instrumentChanged: false,
    isEditStrategy: false,
    // STRATEGY CREATOR VARS
    strategyCreatorList: [],
    strategyCreatorStrikes: [],
    order_location: "strategy_creator",
    timerId: null,
    customStrategyLoading: false,
    ceExpiry: [],
    ceObj: {},
    peExpiry: [],
    peObj: {},
    futExpiry: [],
    futObj: {},
    days_to_expire: 0,
    changePrebuiltStrategyData: [],
    selectedStrike: "",
    targetDays: 0,
    ivPercent: 0,
    isSavedStrategyClicked: false,
    isCustomStrategyApiCalled: false,
    oldInstrument: null,
    newInstrument: null,
    customStrategyPayload: null,
    isCompareButtonClicked: false,
    compareStrategyData: null,
    ltpRefreshed: false,
  }),
  watch: {
    selectedInstrument() {
      this.instrumentChanged = true;
      this.getOptionStrikesApi();
    },
    selectedExpiry() {
      if (!this.instrumentChanged) {
        this.setCustomStrategyPayload();
      }
    },
    targetDays() {
      this.customStrategyApi();
    },
    ivPercent() {
      this.customStrategyApi();
    },
    prebuiltStrategyData() {
      if (this.isPrebuiltStrategyCalled) this.getStrategyCreatorData();
    },
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    liveLtp() {
      return (el) => {
        let ltp = this.compactMarketDataScripts(el);
        if (
          ltp &&
          ltp["details"] &&
          (ltp["details"]["Last Traded Price"] == 0 ||
            ltp["details"]["Last Traded Price"])
        )
          return ltp["details"]["Last Traded Price"];
        else return null;
      };
    },
    liveChange() {
      return (el) => {
        let change = this.compactMarketDataScripts(el);
        if (
          change &&
          change["details"] &&
          (change["details"]["Change"] == 0 || change["details"]["Change"])
        )
          return change["details"]["Change"];
        else return null;
      };
    },
  },
  methods: {
    deleteAllRows() {
      this.strategyCreatorList.splice(1);
      this.strategyCreatorStrikes.splice(1);
      this.customStrategyApi();
    },
    getStrategyCreatorData() {
      this.strategyCreatorList = [];
      this.strategyCreatorStrikes = [];
      this.compareStrategyData = this.prebuiltStrategyData;
      this.instrumentChanged = false;
      for (let instrument of this.prebuiltStrategyData.instrument) {
        instrument.expiry = moment(instrument.expiry).format("DD MMM YYYY");
        instrument.strike = instrument.strike.toString();
        this.strategyCreatorList.push(instrument);
        this.getStrikesDropdown(instrument);
      }
      this.days_to_expire = getExpiredDays(this.selectedExpiry);
      this.customStrategyPayload = this.addCustomStrategyPayload();
    },
    //Function to fetch option strikes
    getOptionStrikesApi() {
      const payload = {
        path: "option_strikes",
        broker: this.broker,
        name: this.selectedInstrument.name,
        user_id:this.userId,
        access_token: this.accessToken,
        gscid: this.gscid,
        gcid: this.gcid,
      };
      axios
        .post(getOptionStrikes, payload, {
          headers: {
            "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
          },
        })
        .then((res) => {
          //INSTRUMENT CE PE FUT DATA PREPROCESSING
          this.ceObj = this.getCePeFutObject(res.data.ce);
          this.ceExpiry = getFilteredExpiry(Object.keys(this.ceObj));
          this.peObj = this.getCePeFutObject(res.data.pe);
          this.peExpiry = getFilteredExpiry(Object.keys(this.peObj));
          this.futObj = this.getCePeFutObject(res.data.fut);
          this.futExpiry = getFilteredExpiry(Object.keys(this.futObj));
          this.selectedStrike = res.data.selected_strike;
          setTimeout(() => {
            if (
              this.isPrebuiltStrategyCalled &&
              !this.isCustomStrategyApiCalled
            ) {
              this.getStrategyCreatorData();
            } else {
              this.setCustomStrategyPayload();
            }
          }, 300);
        });
    },
    getCePeFutObject(result) {
      let obj = {};
      result.forEach((el) => {
        let key = Object.keys(el)[0];
        obj[key] = el[key];
      });
      return obj;
    },
    
    setCustomStrategyPayload() {
      this.instrumentChanged = false;

      for (let index in this.strategyCreatorList) {
        let instrument = this.strategyCreatorList[index];

        this.strategyCreatorList[index].name = this.selectedInstrument.name;
        this.strategyCreatorList[index].ltp = 0;
        this.strategyCreatorList[index].op_pr = 0;
        if (instrument.quantity < 0) {
          this.strategyCreatorList[index].quantity =
            -this.selectedInstrument.lot_size;
        } else {
          this.strategyCreatorList[index].quantity =
            this.selectedInstrument.lot_size;
        }
        this.strategyCreatorList[index].exchange =
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange;
        this.days_to_expire = getExpiredDays(this.selectedExpiry);
        this.addValues(instrument, index, false);
      }
    },

    getStrikesDropdown(instrument) {
      if (instrument.segment == "c" || instrument.segment == "CE") {
        this.strategyCreatorStrikes.push({
          strikes: this.ceObj[instrument.expiry].strike.map(String),
          atmLevels: this.getAtmLevels(
            this.ceObj[instrument.expiry].strike.map(String),
            instrument.segment
          ),
          trading_symbol: this.ceObj[instrument.expiry].trading_symbol,
          formatted_ins_name: this.ceObj[instrument.expiry].formatted_ins_name,
          code: this.ceObj[instrument.expiry].code,
        });
      } else if (instrument.segment == "p" || instrument.segment == "PE") {
        this.strategyCreatorStrikes.push({
          strikes: this.peObj[instrument.expiry].strike.map(String),
          atmLevels: this.getAtmLevels(
            this.peObj[instrument.expiry].strike.map(String),
            instrument.segment
          ),
          trading_symbol: this.peObj[instrument.expiry].trading_symbol,
          formatted_ins_name: this.peObj[instrument.expiry].formatted_ins_name,
          code: this.peObj[instrument.expiry].code,
        });
      } else {
        this.strategyCreatorStrikes.push({
          strikes: [],
          trading_symbol: this.futObj[instrument.expiry].trading_symbol[0],
          formatted_ins_name:
            this.futObj[instrument.expiry].formatted_ins_name[0],
          code: this.futObj[instrument.expiry].code[0],
        });
      }
    },

    getAtmLevels(strikeList, segment) {
      let atmLevels = [];
      let zeroIndex = strikeList.indexOf(this.selectedStrike);
      let strikeLength = strikeList.length;
      if (segment == "c") {
        for (let i = 0; i < zeroIndex; i++) {
          atmLevels.push(i - zeroIndex);
        }
        for (let i = 0; i < strikeLength - zeroIndex; i++) {
          atmLevels.push(i);
        }
      } else {
        for (let i = 0; i < zeroIndex; i++) {
          atmLevels.push(-(i - zeroIndex));
        }
        for (let i = 0; i < strikeLength - zeroIndex; i++) {
          atmLevels.push(-i);
        }
      }
      return atmLevels;
    },
    // Function to add a row in strategy creator
    addRow(type, b_s = "buy") {
      // this.customStrategyLoading = true;

      let expiry;
      if (
        (type == "c" || type == "p") &&
        !this.peExpiry.includes(this.selectedExpiry)
      ) {
        expiry = this.peExpiry[0];
      } else if (
        type == "fut" &&
        !this.futExpiry.includes(this.selectedExpiry)
      ) {
        expiry = this.futExpiry[0];
      } else {
        expiry = this.selectedExpiry;
      }
      let row = {
        code: "",
        expiry: expiry,
        ltp: 0,
        name: this.selectedInstrument.name,
        op_pr: 0,
        quantity:
          b_s === "buy"
            ? this.selectedInstrument.lot_size
            : -this.selectedInstrument.lot_size,
        segment: type,
        strike: this.selectedStrike,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        trading_symbol: "",
        formatted_ins_name: "",
      };
      row.op_pr = row.ltp;
      this.strategyCreatorList.push(row);
      this.getStrikesDropdown(row);
      this.addValues(row, this.strategyCreatorList.length - 1, true);
    },
    // Handles row addition from option chain
    addValFromOptionChain(ins) {
      let row = {
        code: ins.code,
        expiry: ins.expiry,
        ltp: "",
        name: ins.name,
        op_pr: "",
        quantity: ins.quantity,
        segment: ins.segment,
        strike: ins.strike,
        exchange: ins.exchange,
        trading_symbol: ins.trading_symbol,
        formatted_ins_name: ins.formatted_ins_name,
      };
      this.strategyCreatorList.push(row);
      this.getStrikesDropdown(row);
      this.addValues(row, this.strategyCreatorList.length - 1, true);
    },
    addValues(row, index, isRowChanged) {
      let expiry = !isRowChanged ? this.selectedExpiry : row.expiry;
      let isFutSegment =
        row.segment == "fut" || row.segment == "FUT" ? true : false;
      let isPeSegment =
        row.segment == "p" || row.segment == "PE" ? true : false;
      let isCeSegment =
        row.segment == "c" || row.segment == "CE" ? true : false;
      let strikesList = this.strategyCreatorStrikes[index].strikes;
      let codeIndex = strikesList.indexOf(row.strike);

      let atmIndex = isFutSegment
        ? 0
        : this.strategyCreatorStrikes[index].atmLevels[codeIndex];
      if (isFutSegment && !this.futExpiry.includes(expiry)) {
        this.strategyCreatorList[index].expiry = this.futExpiry[0];
        expiry = this.futExpiry[0];
      } else if (
        (isPeSegment || isCeSegment) &&
        !this.peExpiry.includes(expiry)
      ) {
        this.strategyCreatorList[index].expiry = this.peExpiry[0];
        expiry = this.peExpiry[0];
      } else {
        this.strategyCreatorList[index].expiry = expiry;
      }
      if (isCeSegment) {
        this.strategyCreatorStrikes[index].strikes =
          this.ceObj[expiry].strike.map(String);
        this.strategyCreatorStrikes[index].atmLevels = this.getAtmLevels(
          this.ceObj[expiry].strike.map(String),
          row.segment
        );
        this.strategyCreatorStrikes[index].trading_symbol =
          this.ceObj[expiry].trading_symbol;
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.ceObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.ceObj[expiry].code;
      } else if (isPeSegment) {
        this.strategyCreatorStrikes[index].strikes =
          this.peObj[expiry].strike.map(String);
        this.strategyCreatorStrikes[index].atmLevels = this.getAtmLevels(
          this.peObj[expiry].strike.map(String),
          row.segment
        );
        this.strategyCreatorStrikes[index].trading_symbol =
          this.peObj[expiry].trading_symbol;
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.peObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.peObj[expiry].code;
      } else {
        this.strategyCreatorStrikes[index].strikes = [];
        this.strategyCreatorStrikes[index].trading_symbol =
          this.futObj[expiry].trading_symbol[0];
        this.strategyCreatorStrikes[index].formatted_ins_name =
          this.futObj[expiry].formatted_ins_name;
        this.strategyCreatorStrikes[index].code = this.futObj[expiry].code[0];
      }
      if (!isFutSegment) {
        if (!this.strategyCreatorStrikes[index].atmLevels.includes(atmIndex)) {
          this.strategyCreatorList[index].strike =
            this.strategyCreatorStrikes[index].strikes[
              this.strategyCreatorStrikes[index].strikes.length - 1
            ];
          this.strategyCreatorList[index].trading_symbol =
            this.strategyCreatorStrikes[index].trading_symbol[
              this.strategyCreatorStrikes[index].trading_symbol.length - 1
            ];
          this.strategyCreatorList[index].formatted_ins_name =
            this.strategyCreatorStrikes[index].formatted_ins_name[
              this.strategyCreatorStrikes[index].formatted_ins_name.length - 1
            ];
          this.strategyCreatorList[index].code =
            this.strategyCreatorStrikes[index].code[
              this.strategyCreatorStrikes[index].code.length - 1
            ];
        } else {
          if (!isRowChanged) {
            this.strategyCreatorList[index].strike =
              this.strategyCreatorStrikes[index].strikes[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].trading_symbol =
              this.strategyCreatorStrikes[index].trading_symbol[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].formatted_ins_name =
              this.strategyCreatorStrikes[index].formatted_ins_name[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
            this.strategyCreatorList[index].code =
              this.strategyCreatorStrikes[index].code[
                this.strategyCreatorStrikes[index].atmLevels.indexOf(atmIndex)
              ];
          } else {
            let strikeIndex = this.strategyCreatorStrikes[
              index
            ].strikes.indexOf(this.strategyCreatorList[index].strike);
            this.strategyCreatorList[index].trading_symbol =
              this.strategyCreatorStrikes[index].trading_symbol[strikeIndex];
            this.strategyCreatorList[index].formatted_ins_name =
              this.strategyCreatorStrikes[index].formatted_ins_name[
                strikeIndex
              ];
            this.strategyCreatorList[index].code =
              this.strategyCreatorStrikes[index].code[strikeIndex];
          }
        }
      } else {
        this.strategyCreatorList[index].trading_symbol =
          this.strategyCreatorStrikes[index].trading_symbol;
        this.strategyCreatorList[index].formatted_ins_name =
          this.strategyCreatorStrikes[index].formatted_ins_name[0];
        this.strategyCreatorList[index].code =
          this.strategyCreatorStrikes[index].code;
        this.strategyCreatorList[index].strike = 0;
      }
      this.strategyCreatorList[index].ltp = row.ltp;
      this.strategyCreatorList[index].op_pr =
        this.strategyCreatorList[index].ltp;
      if (index == this.strategyCreatorList.length - 1 || isRowChanged)
        this.customStrategyApi();
    },
    deleteStrategyRow(index) {
      this.strategyCreatorList.splice(index, 1);
      this.customStrategyApi();
    },
    incDecInstrumentValues(change, value, index, isIncremented) {
      if (value == 0 && isIncremented) {
        this.strategyCreatorList[index][change] =
          this.selectedInstrument.lot_size;
      } else if (value == 0 && !isIncremented) {
        this.strategyCreatorList[index][change] =
          -this.selectedInstrument.lot_size;
      } else {
        this.strategyCreatorList[index][change] = value;
      }
      this.customStrategyApi();
    },

    handleQuantityInput(event, change, index) {
      if (event.key == "Enter" || event.type == "blur") {
        if (
          event.target.value == "" ||
          isNaN(event.target.value) ||
          event.target.value == "0"
        ) {
          this.strategyCreatorList[index].quantity =
            this.selectedInstrument.lot_size;
        } else {
          this.strategyCreatorList[index].quantity = this.strategyCreatorList[
            index
          ].quantity
            .toString()
            .replace(/[^\d]/g, "");
          let qty = Math.ceil(
            parseInt(event.target.value) / this.selectedInstrument.lot_size
          );
          if (qty == 0) {
            this.strategyCreatorList[index][change] =
              this.selectedInstrument.lot_size;
          } else {
            this.strategyCreatorList[index][change] =
              qty * this.selectedInstrument.lot_size;
          }
        }
        this.customStrategyApi();
      }
    },

    handleStrikeClicks(change, value, index) {
      this.strategyCreatorList[index][change] = value;
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.addValues(this.strategyCreatorList[index], index, true);
    },
    clearTimeout(event, change, index) {
      if (event.key == "Enter" || event.type == "blur") {
        if (this.strategyCreatorList[index].ltp == "") {
          this.strategyCreatorList[index].ltp = parseFloat(
            this.liveLtp(this.strategyCreatorList[index].code)
          );
        } else {
          this.strategyCreatorList[index][change] = parseFloat(
            event.target.value
          );
        }
        this.strategyCreatorList[index].op_pr =
          this.strategyCreatorList[index].ltp;
        this.customStrategyApi();
      }
    },
    changeInstrumentValues(change, value, index) {
      this.customStrategyLoading = true;
      this.strategyCreatorList[index][change] = value;
      if (change == "expiry") {
        this.days_to_expire = getExpiredDays(value);
        let segment = this.strategyCreatorList[index].segment;
        if (segment == "c") {
          this.strategyCreatorStrikes[index].strikes =
            this.ceObj[value].strike.map(String);
        } else if (segment == "p") {
          this.strategyCreatorStrikes[index].strikes =
            this.peObj[value].strike.map(String);
        }
      }
      this.strategyCreatorList[index].op_pr =
        this.strategyCreatorList[index].ltp;
      if (change == "segment" || change == "expiry" || change == "strike") {
        this.strategyCreatorList[index].ltp = 0;
        this.addValues(this.strategyCreatorList[index], index, true);
      } else if (change == "strike" || change == "quantity") {
        this.customStrategyApi();
      }
    },
    refreshStrategyLtps() {
      for (let ins of this.strategyCreatorList) {
        ins.ltp = 0;
      }
      this.ltpRefreshed = true;
      this.customStrategyApi();
    },
    customStrategyApi() {
      this.customStrategyLoading = false;
      let payload = this.addCustomStrategyPayload();
      this.customStrategyPayload = payload;
      axios
        .post(getCustomStrategyUrl, payload, {
          headers: { "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3" },
        })
        .then((res) => {
          this.isCustomStrategyApiCalled = true;
          this.$emit(
            "isCustomStrategyApiCalled",
            this.isCustomStrategyApiCalled
          );
          this.customStrategyLoading = false;
          this.changePrebuiltStrategyData = res.data;
          this.$emit(
            "changePrebuiltStrategyData",
            this.changePrebuiltStrategyData
          );
          this.compareStrategyData = this.changePrebuiltStrategyData;
          this.fillLtpValues(res.data.instrument);
        });
    },
    addCustomStrategyPayload() {
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.liveLtp(this.selectedInstrument.underlying_code)
          : this.liveLtp(this.selectedInstrument.fut_code);
      if (spot_price == 0 || spot_price == null) {
        spot_price =
          this.selectedInstrument.exchange == "NSE"
            ? this.selectedInstrument.ltp
            : this.selectedInstrument.fut_ltp;
      }
      return {
        path: "custom_strategy",
        broker: "aliceblue",
        name: this.selectedInstrument.name,
        spot_price: spot_price,
        exchange:
          this.selectedInstrument.exchange == "NSE"
            ? "NFO"
            : this.selectedInstrument.exchange,
        lot_size: this.selectedInstrument.lot_size,
        days_to_expire: this.days_to_expire,
        target_days: this.targetDays,
        iv_percent: this.ivPercent,
        user_id: this.userId,
        access_token: this.accessToken,
        ws_session:this.wsSession,
        param_list: this.strategyCreatorList,
        gscid: this.gscid,
        gcid: this.gcid,
      };
    },  

    fillLtpValues(instrumentList) {
      for (let i = 0; i < instrumentList.length; i++) {
        this.strategyCreatorList[i].ltp = instrumentList[i].ltp;
        // this.strategyCreatorList[i].quantity = instrumentList[i].lot_size;
      }
      if (this.ltpRefreshed) {
        this.$notify({
          group: "success",
          type: "success",
          title: "Success",
          text: "LTPs has been updated successfully!",
        });
      }
    },
    getCompareStrategy() {
      this.isCompareButtonClicked = true;
      this.$parent.$refs.compareStrategyComponent.scrollIntoView({
        behavior: "smooth",
      });
      EventBus.$emit("addToCompareStrategiesFromCreator", {
        selectedInstrument: this.selectedInstrument,
        selectedTab: this.selectedTab,
        payload: this.customStrategyPayload,
        name: "Current Strategy",
        data: this.compareStrategyData,
      });
    },
  },
  mounted() {
    this.getOptionStrikesApi();
    this.oldInstrument = this.selectedInstrument;
    this.newInstrument = this.selectedInstrument;
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/global.scss";

.trash-row.v-icon.v-icon {
  color: var(--text-color) !important;
}

.strategy-wrapper {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  font-size: var(--font-16);
}

.mobile-instrument-header {
  background-color: var(--chip-background);
  padding: 4px 20px 4px 8px;
  color: var(--sub-text-color);
  font-size: var(--font-14);
}

.mobile-instrument-rows {
  color: var(--text-color);

  v-col {
    padding: 0 4px !important;
  }

  .mobile-trash {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .mobile {
    font-size: var(--font-14) !important;

    .limit-input {
      padding: 3px !important;
    }
  }
}

.strategy-creator-section {
  position: relative;
  min-height: 480px;
  padding-top: 2rem;

  .strategy-creator-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: var(--font-24);
    line-height: 28px;
  }

  .clear-all-button {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 0.8rem;
  }

  .clear-all-button.v-icon.v-icon {
    color: var(--red-text) !important;
  }

  .save-compare-icons {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 0.8rem;
  }

  .strategy-creator-content {
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    .top-btn-group {
      .v-chip {
        background-color: var(--chip-background);
        color: var(--text-color);
        cursor: pointer;
        transition: none !important;
        border-radius: 4px !important;
      }

      .v-chip:hover {
        background-color: #d4d4d5;
        color: var(--text-color);
      }
    }

    .v-chip.v-size--small {
      font-size: var(--font-14) !important;
    }

    .strategy-creator-table {
      margin: auto;

      .strategy-creator-label {
        font-family: "Gilroy-semibold";
        border-bottom: 1px solid var(--border-color);
      }

      .expiry-input.v-autocomplete {
        min-width: 90px !important;
      }

      .strategy-creator-table table {
        min-height: 270px;

        tbody > tr:nth-child(even) {
          background-color: #f8f8f8;
        }
      }

      th {
        background: var(--chip-background);
        color: var(--text-color);
      }

      td,
      th {
        padding: 0.3rem 6px;
        border: none !important;
      }

      td {
        padding: 0.5rem 6px;
      }

      .v-autocomplete {
        border: 1px solid var(--border-color);
        min-width: 90px;
        height: 30px;
      }

      .strategy-creator-section .v-autocomplete * {
        cursor: pointer;
        height: 30px;
      }

      .plus-minus-field {
        max-width: 90px;
        display: flex;

        span {
          cursor: pointer;
        }

        input {
          width: 100%;
          height: 30px;
          text-align: center;
          border: 1px solid var(--plus-minus-background);
          outline: none;
        }
      }

      .minus,
      .plus {
        cursor: pointer;
        width: 25px;
        height: 30px;
        background: var(--plus-minus-background);
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-top: 7px;
      }

      .minus {
        border-radius: 4px 0 0 4px;
      }

      .plus {
        border-radius: 0 4px 4px 0;
      }
    }
  }

  .strategy-creator-label {
    font-family: "Gilroy-semibold";
    width: 50%;
    border-bottom: 1px solid var(--border-color);
  }

  .option-chain-button {
    font-weight: normal;
    padding: 0.3rem 0.5rem;

    .v-icon.v-icon {
      color: var(--primary-buttontext--color);
      font-size: var(--font-16);
    }
  }
}

.option-chain-close-btn {
  background-color: transparent;
  cursor: pointer;
  right: 0;
  top: 0;
  position: absolute;
  padding: 8px;
}

.saved-strategy-content::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.saved-strategy-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.v-data-table {
  color: var(--text-color);

  td {
    border-bottom: none !important;
    font-size: var(--font-16);
    transition: none !important;
  }
}

.v-input--checkbox::v-deep {
  .theme--light.v-label::v-deep {
    color: var(--primary-button-color);
  }
}

.atm-labels {
  margin-left: 0.6rem;
  padding: 4px;
  background-color: var(--chip-background);
  border-radius: 8px;
  font-size: var(--font-14);
  color: var(--text-color);
}

.ltp-input {
  border: none;
  border-bottom: 1px solid var(--text-color);
}

.ltp-input:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .strategy-input {
    font-size: var(--font-14) !important;
  }
}

@media only screen and (max-width: 1390px) {
  .strategy-creator-section
    .strategy-creator-content
    .strategy-creator-table
    .v-autocomplete {
    min-width: 90px !important;
  }

  .expiry-input .v-autocomplete {
    min-width: 80px !important;
  }
}
@media only screen and (max-width: 600px) {
  .strategy-creator-section
    .strategy-creator-content
    .strategy-creator-table
    .v-autocomplete {
    min-width: 60px !important;
    max-width: 110px !important;
  }

  .expiry-input .v-autocomplete {
    min-width: 80px !important;
  }
}
</style>
<style>
.strategy-creator-table
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  padding: 0 6px !important;
}

.strategy-creator-table .v-chip.v-size--small {
  height: 30px !important;
}

.dashboard-option-chain-popup.v-dialog {
  border-radius: 12px;
}

.strategy-creator-section .v-data-table__wrapper {
  min-height: 270px !important;
}

.strategy-input.v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense
  .v-select__selections {
  padding: 6px 0 !important;
}

.strategy-creator-section .v-autocomplete * {
  height: 30px;
}

.strategy-creator-section .strikes-dropdown .v-input__append-inner {
  display: none !important;
}

.strategy-creator-section
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: none !important;
}

.strategy-creator-section .expiry-input input[id^="input-"] {
  position: absolute !important;
  left: 50%;
  transform: translate(-55%, 0%);
  text-align: center;
  opacity: 0;
}

.strategy-creator-section .strikes-dropdown input[id^="input-"] {
  position: absolute !important;
  left: 50%;
  transform: translate(-55%, 0%);
  text-align: center;
}

.strategy-wrapper
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__append-inner {
  margin-top: 0 !important;
}

.strategy-wrapper .v-text-field--outlined.v-input--is-focused fieldset {
  border: none !important;
}
</style>
