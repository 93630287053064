<template>
  <div class="holdings-wrapper">
    <!-- Realized and Unrealized P&L section -->
    <v-row no-gutters class="my-2">
      <v-col cols="12" md="6" class="text-center header-labels my-3">
        <v-row>
          <v-col cols="6">
            Total Investment
            <div class="header-values">
              {{ getTotalHeaderValues("totalInvestment") | integerFormat }}
            </div>
          </v-col>
          <v-divider class="d-none d-md-block" vertical></v-divider>
          <v-col cols="6">
            Current Value
            <div class="header-values">
              {{ getTotalHeaderValues("currentValue") | integerFormat }}
            </div>
          </v-col>
          <v-divider class="d-none d-md-block" vertical></v-divider>
        </v-row>
      </v-col>
      <v-divider
        class="d-sm-block d-md-none horizontal-divider"
        inset
      ></v-divider>
      <v-col cols="12" md="6" class="text-center header-labels my-3">
        <v-row>
          <v-col cols="6">
            Day's P&L
            <div class="green-text header-values">
              <span
                v-if="getTotalHeaderValues('holdingsDaysPL') > 0"
                class="green-text"
                >+</span
              >
              <span
                :class="
                  getTotalHeaderValues('holdingsDaysPL') >= 0
                    ? 'green-text'
                    : 'red-text'
                "
              >
                {{ getTotalHeaderValues("holdingsDaysPL") | integerFormat }}
              </span>
            </div>
          </v-col>
          <v-divider class="d-none d-md-block" vertical></v-divider>
          <v-col cols="6">
            Total P&L
            <div class="green-text header-values">
              <span
                v-if="getTotalHeaderValues('holdingsTotalPL') > 0"
                class="green-text"
                >+</span
              >
              <span
                :class="
                  getTotalHeaderValues('holdingsTotalPL') >= 0
                    ? 'green-text'
                    : 'red-text'
                "
                >{{ getTotalHeaderValues("holdingsTotalPL") | integerFormat }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6">
        <div v-if="$vuetify.breakpoint.xs">
          <div style="position: relative" class="d-flex">
            <span>
              <span class="ml-1 mt-2">
                <v-badge
                :content="filteredInstrumentList.length"
                color="primary"
                class="filter-badge"
                v-if="filteredInstrumentList.length != 0"
              >
                <v-icon
                size="x-large"
                  @click="showInstrumentFilter = !showInstrumentFilter"
                  style="
                    font-size: 0.8rem;
                    cursor: pointer;
                    color: var(--primary-color);
                  "
                >
                  mdi-filter
                </v-icon>
              </v-badge>
              <v-icon
                v-else
                @click="showInstrumentFilter = !showInstrumentFilter"
                style="font-size: 0.8rem; cursor: pointer"
                >mdi-filter-outline</v-icon
              >
              </span>
              <div class="instrument-filter" v-if="showInstrumentFilter">
                <FilterInstrument
                  :menuVisible="showInstrumentFilter"
                  :instrumentList="instrumentList"
                  @findfilteredInstrumentList="getFilteredInstrumentData"
                  :filteredInstrumentList="filteredInstrumentList"
                  @close="showInstrumentFilter = false"
                  @clickedReset="showAllPositions"
                />
              </div>
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-tooltip bottom v-if="!$vuetify.breakpoint.xs">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" >
              <v-icon class="info-icon" @click="getHoldingsApi()"> mdi-refresh </v-icon>
            </span>
          </template>
          <span>Refresh Holdings</span>
        </v-tooltip>
        <span v-else>
          <v-icon class="info-icon" @click="getHoldingsApi()"> mdi-refresh </v-icon>
        </span>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      style="height: 80%; margin-top: 1rem; overflow-y: auto"
      class="styled-scroll"
    >
    <v-col class="text-center mt-7 no-positions" v-if="filteredHoldings.length==0">
      <div>
        <img
          style="width: 50%"
          class="mr-1"
          :src="
            !darkMode
              ? require('@/assets/Positions/no-positions.svg')
              : require('@/assets/Positions/no-positions-dark.svg')
          "
        />
      </div>
      <div class="no-position-text">No Holdings Found</div>
    </v-col>
      <div class="default-position-wrapper" v-else>
        <div>
          <v-data-table
            v-if="!$vuetify.breakpoint.xs"
            class="default-positions-table default-positions--desktop-table"
            ref="defPosTable"
            style="margin-top: 0.5rem; height: 100%"
            :items="filteredHoldings"
            :hide-default-footer="true"
            :items-per-page="-1"
          >
            <template v-slot:header>
              <tr>
                <th
                  style="position: relative"
                  class="d-flex align-center justify-space-between"
                >
                  Instrument
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="ml-1 d-flex">
                        <v-badge
                        :content="filteredInstrumentList.length"
                        color="primary"
                        class="filter-badge"
                        v-if="filteredInstrumentList.length != 0"
                      >
                        <v-icon
                        size="x-large"
                          @click="showInstrumentFilter = !showInstrumentFilter"
                          style="
                            font-size: 1.4rem;
                            cursor: pointer;
                            color: var(--primary-color);
                          "
                        >
                          mdi-filter
                        </v-icon>
                      </v-badge>
                      <v-icon
                        v-else
                        @click="showInstrumentFilter = !showInstrumentFilter"
                        style="font-size: 1.4rem; cursor: pointer"
                        >mdi-filter-outline</v-icon
                      >
                      </span>
                    </template>
                    <span> Filter Instrument</span>
                  </v-tooltip>
                  <div class="instrument-filter" v-if="showInstrumentFilter">
                    <FilterInstrument
                      :menuVisible="showInstrumentFilter"
                      :instrumentList="instrumentList"
                      @findfilteredInstrumentList="getFilteredInstrumentData"
                      :filteredInstrumentList="filteredInstrumentList"
                      @close="showInstrumentFilter = false"
                      @clickedReset="showAllPositions"
                    />
                  </div>
                </th>
                <th class="text-center">Qty</th>
                <th class="text-center">Buy Avg</th>
                <th class="text-center">LTP</th>
                <th class="text-center">Buy Value</th>
                <th class="text-center">P&L</th>
                <th class="text-center">Net Chg.</th>
              </tr>
            </template>
            <template v-slot:item="data">
              <v-hover>
                <template #default="{ hover }">
                  <tr :key="data.item.id">
                    <td
                      style="white-space: nowrap"
                      class="d-flex align-center"
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      <span v-if="data.item.ExchSeg1 != null">
                        {{ data.item.Nsetsym }}
                      </span>
                      <span v-else>
                        {{ data.item.Bsetsym }}
                      </span>
                    </td>
                    <td
                      @click="showMobileExitAddDialog(data.item)"
                      class="text-center"
                    >
                      {{ data.item.SellableQty }}
                    </td>
                    <td
                      class="text-center"
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      {{ data.item.Price }}
                    </td>
                    <td
                      class="text-center"
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      <span
                        v-if="
                          data.item.ExchSeg1 != null && insSub(data.item.Token1)
                        "
                      >
                        {{ insSub(data.item.Token1).ltp }}
                      </span>
                      <span v-else-if="insSub(data.item.Token2)">
                        {{ insSub(data.item.Token2).ltp }}
                      </span>
                    </td>
                    <td
                      class="text-center"
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      {{
                        getTableValues("buyValue", data.item)
                          | currencyWithoutFormat
                      }}
                    </td>
                    <td
                      class="text-center"
                      :class="
                        getTableValues('pl', data.item) > 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      {{
                        getTableValues("pl", data.item) | currencyWithoutFormat
                      }}
                    </td>
                    <td
                      class="text-center"
                      :class="
                        getTableValues('chg', data.item) > 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      @click="showMobileExitAddDialog(data.item)"
                    >
                      {{ getTableValues("chg", data.item) }}%
                    </td>
                    <td
                      v-if="
                        hover &&
                        !$vuetify.breakpoint.sm &&
                        !$vuetify.breakpoint.xs
                      "
                      class="add-exit-buttons"
                    >
                      <div v-if="hover">
                        <button
                          class="primary-button"
                          style="
                            white-space: nowrap;
                            background-color: var(--primary-color);
                          "
                          @click="showPlaceOrderDialogPopUp(data.item, 'add')"
                        >
                          Add
                        </button>
                        <button
                          class="primary-button"
                          style="
                            white-space: nowrap;
                            background-color: var(--red-text);
                          "
                          @click="showPlaceOrderDialogPopUp(data.item, 'exit')"
                        >
                          Exit
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-hover>
            </template>
            <template v-slot:no-data>
              <div
                class="custom-empty-wrapper"
                style="color: var(--text-color); position: absolute"
              >
                No holdings available
                <!-- Add your custom content here -->
              </div>
            </template>
          </v-data-table>
          <!-- Mobile responsive design -->
          <div v-else>
            <div>
              <div
                class="mobile-instrument-default-rows pt-4"
                v-for="(item, index) of filteredHoldings"
                :key="index"
              >
                <v-row no-gutters class="pb-3 justify-space-between">
                  <v-col cols="8" class="d-flex align-center">
                    <span @click="showMobileExitAddDialog(item)">
                      <span v-if="item.ExchSeg1 != null">
                        {{ item.Nsetsym }}
                      </span>
                      <span v-else>
                        {{ item.Bsetsym }}
                      </span>
                    </span>
                  </v-col>
                  <v-col
                    cols="4"
                    class="text-right pr-2"
                    @click="showMobileExitAddDialog(item)"
                  >
                    <span>P&L: </span>
                    <span
                      :class="
                        getTableValues('pl', item) > 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      >{{
                        getTableValues("pl", item) | currencyWithoutFormat
                      }}</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="pb-3"
                  @click="showMobileExitAddDialog(item)"
                >
                  <v-col cols="6">
                    <span> Qty: </span>
                    <span>{{ item.SellableQty }}</span>
                  </v-col>
                  <v-col cols="6" class="text-right pr-2">
                    <span>Buy Value: </span>
                    <span>{{
                      getTableValues("buyValue", item) | currencyWithoutFormat
                    }}</span>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="pb-3"
                  @click="showMobileExitAddDialog(item)"
                >
                  <v-col cols="4" class="text-left">
                    <span> LTP: </span>
                    <span v-if="item.ExchSeg1 != null && insSub(item.Token1)">
                      {{ insSub(item.Token1).ltp }}
                    </span>
                    <span v-else-if="insSub(item.Token2)">
                      {{ insSub(item.Token2).ltp }}
                    </span>
                  </v-col>
                  <v-col cols="4">
                    <span> Buy Avg: </span>
                    <span>{{ item.Price }}</span>
                  </v-col>
                  <v-col cols="4" class="text-right pr-2">
                    <span>Net Chg.: </span>
                    <span
                      :class="
                        getTableValues('chg', item) > 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      >{{ getTableValues("chg", item) }}%</span
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
        <v-dialog
          v-if="showAddExitDialog"
          v-model="showAddExitDialog"
          content-class="add-exit-overlay"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-card-text>
              <v-row no-gutters>
                <div style="color: var(--text-color)">
                  {{
                    clickedPosition.ExchSeg1 != null
                      ? clickedPosition.Nsetsym
                      : clickedPosition.Bsetsym
                  }}
                </div>
              </v-row>
              <v-row
                no-gutters
                class="mt-4"
                :justify-xs="'space-between'"
                :justify-sm="'center'"
              >
                <v-col cols="6" sm="4">
                  <button
                    class="primary-button"
                    style="white-space: nowrap; width: 90%"
                    @click="showPlaceOrderDialogPopUp(clickedPosition, 'add')"
                  >
                    Add
                  </button>
                </v-col>
                <v-col cols="6" sm="4" class="text-right">
                  <button
                    class="primary-button"
                    style="
                      white-space: nowrap;
                      width: 90%;
                      background-color: var(--red-text);
                    "
                    @click="showPlaceOrderDialogPopUp(clickedPosition, 'exit')"
                  >
                    Exit
                  </button>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <PlaceOrderModal
          v-if="showPlaceOrderDialog"
          :visible="showPlaceOrderDialog"
          @close="
            showPlaceOrderDialog = false;
            refreshPositions();
          "
          :strategyCreator="strategyCreatorList"
          :selectedInstrument="selectedInstrument"
          :isPositionGraph="false"
          :isHoldingsSection="true"
        ></PlaceOrderModal>
      </div>
    </v-row>
  </div>
</template>
<script>
import FilterInstrument from "./modals/FilterInstrument.vue";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
export default {
  name: "PositionHoldings",
  components: {
    FilterInstrument,
    PlaceOrderModal,
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  props: {
    // instrumentList: {
    //   type: Array,
    //   default: () => [],
    // },
    holdingsData: null,
  },
  data() {
    return {
      instrumentList:[],
      totalInvestment: 0,
      currentValue: 0,
      holdingsDaysPL: 0,
      holdingsTotalPL: 0,
      holdingsList: [],
      selectedHoldings: [],
      showInstrumentFilter: false,
      filteredInstrumentList: [],
      filteredHoldings: [],
      showPlaceOrderDialog: false,
      selectedInstrument: null,
      strategyCreatorList: [],
      showAddExitDialog: false,
      clickedPosition: null,
    };
  },
  methods: {
    showPlaceOrderDialogPopUp(item, type) {
      this.showPlaceOrderDialog = true;
      let net_quantity =
        type == "add" ? item.lot_size : -parseFloat(item.SellableQty);
      this.strategyCreatorList = this.getPlaceOrderData(
        item,
        net_quantity,
        "creator"
      );
      this.selectedInstrument = this.getPlaceOrderData(
        item,
        net_quantity,
        "instrument"
      );
      console.log(this.strategyCreatorList, this.selectedInstrument);
    },
    getHoldingsApi() {
      this.$emit("refreshHoldings", true);
    },
    getPlaceOrderData(item, net_quantity, value) {
      if (value == "creator") {
        let strategyCreatorList = [];
        strategyCreatorList.push({
          trading_symbol: item.ExchSeg1 != null ? item.Nsetsym : item.Bsetsym,
          formatted_ins_name:
            item.ExchSeg1 != null ? item.Nsetsym : item.Bsetsym,
          strike_price: "",
          strike: "",
          segment: "",
          name: item.Bsetsym,
          exchange: item.ExchSeg1 !== null ? item.ExchSeg1 : item.ExchSeg2,
          ltp: item.Ltp,
          expiry: "",
          quantity: net_quantity,
          code: item.ExchSeg1 !== null ? item.Token1 : item.Token2,
        });
        return strategyCreatorList;
      } else {
        let selectedInstrument = {
          name: item.Bsetsym,
          exchange: item.ExchSeg1 !== null ? item.ExchSeg1 : item.ExchSeg2,
          lot_size: item.lot_size,
          tick_size: item.tick_size,
          underlying_code: item.ExchSeg1 !== null ? item.Token1 : item.Token2,
          fut_code: "",
        };
        return selectedInstrument;
      }
    },
    createInstrumentList() {
      for (let ins of this.holdingsList) {
        if (!this.instrumentList.includes(ins.Bsetsym)) {
          this.instrumentList.push(ins.Bsetsym);
        }
      }
    },
    getFilteredInstrumentData(value) {
      this.filteredInstrumentList = value;
      this.filteredHoldings = this.holdingsList.reduce((result, obj1) => {
        const obj2 = this.filteredInstrumentList.find(
          (obj2) => obj2 === obj1.Bsetsym
        );
        if (obj2) {
          result.push(obj1);
        }
        return result;
      }, []);
    },
    showAllPositions() {
      this.filteredHoldings = this.holdingsList;
    },

    getTableValues(key, item) {
      let buyValue = (
        parseFloat(item.Price) * parseFloat(item.SellableQty)
      ).toFixed(2);
      let ltp =
        item.ExchSeg1 != null
          ? this.insSub(item.Token1).ltp
          : this.insSub(item.Token2).ltp;
      let pl = (
        (ltp - parseFloat(item.Price)) *
        parseFloat(item.SellableQty)
      ).toFixed(2);
      let currentValue = ltp * parseFloat(item.SellableQty);
      let daysPL = (ltp - item.pdc) * parseFloat(item.SellableQty);
      let netChg = buyValue != 0 ? ((pl / buyValue) * 100).toFixed(2) : 0;
      return key == "buyValue"
        ? buyValue
        : key == "pl"
        ? pl
        : key == "currentValue"
        ? currentValue
        : key == "daysPL"
        ? daysPL
        : netChg;
    },

    getTotalHeaderValues(key) {
      let totalInvestment = 0;
      let currentValue = 0;
      let holdingsDaysPL = 0;
      // let holdingsTotalPL = 0
      for (let holding of this.holdingsList) {
        totalInvestment =
          parseFloat(totalInvestment) +
          parseFloat(this.getTableValues("buyValue", holding));
        currentValue =
          parseFloat(currentValue) +
          parseFloat(this.getTableValues("currentValue", holding));
        holdingsDaysPL =
          parseFloat(holdingsDaysPL) +
          parseFloat(this.getTableValues("daysPL", holding));
        // holdingsTotalPL = parseFloat(holdingsTotalPL) + parseFloat(this.getTableValues('pl', holding))
      }
      let holdingsTotalPL = currentValue - totalInvestment;
      return key == "totalInvestment"
        ? totalInvestment
        : key == "currentValue"
        ? currentValue
        : key == "holdingsTotalPL"
        ? holdingsTotalPL
        : holdingsDaysPL;
    },
  },
  mounted() {
    this.holdingsList = this.holdingsData;
    for (let item of this.holdingsList) {
      this.filteredHoldings.push(item);
    }
    this.createInstrumentList();
  },
};
</script>
<style lang="scss">
@import "../../assets/css/global.scss";
.holdings-wrapper {
  height: 97%;
  min-height: 600px;
  overflow: hidden;
  .header-labels {
    font-size: 1em;
  }
  .header-values {
    font-size: 1.4em;
  }
}

.default-position-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.default-position-wrapper:hover {
  overflow-y: auto !important;
}

.filter-badge .v-badge__badge{
  font-size: 14px !important;
  height:15px;
  min-width: 15px;
  padding:3px 4px;
}

.default-positions-table.v-data-table {
  width: 100%;

  tr {
    position: relative;
  }
  .add-exit-buttons {
    position: absolute;
    top: 0;
    left: 18%;
    padding: 0 16px;
    button {
      width: 68px;
      height: 30px;
      margin-right: 1rem;
      padding: 4px 16px;
      margin-top: 8px;
    }
  }
  td,
  th > span {
    font-size: 1em !important;
    color: var(--text-color);
  }

  table > tr > th {
    font-size: 1em !important;
    color: var(--text-color);
    background-color: var(--chip-background) !important;
    font-family: "Gilroy-medium";
    text-align: left;
    padding: 0 1em;
    font-weight: normal;
    white-space: nowrap;
    height: 40px !important;
  }
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 1em;
}

.v-input--selection-controls,
.v-application--is-ltr .v-input--selection-controls__input {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.instrument-filter {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  z-index: 1;
}
.product-type {
  padding: 4px 8px;
  border-radius: 4px;
}

.nrml {
  color: var(--option-strike-text);
  background-color: var(--option-strike-bg);
}

.nrml-disable {
  color: var(--disable-nrml-text);
  background-color: var(--option-strike-bg);
}
.mis {
  color: var(--mis-label-text);
  background-color: var(--mis-label-bg);
}

.mis-disable {
  color: var(--disable-mis-text);
  background-color: var(--mis-label-bg);
}
.mobile-checkbox.v-icon {
  font-size: 1em !important;
}
.alert-icons {
  .v-icon.v-icon {
    font-size: 1em;
    color: var(--red-text);
  }
}

.info-icon.v-icon.v-icon {
    font-size: var(--font-22);
  }


.add-exit-buttons {
  button {
    width: 68px;
    height: 30px;
    margin-right: 1rem;
    padding: 4px 16px;
    margin-top: 8px;
  }
}
.add-exit-overlay.v-dialog {
  width: 100vw;
  position: absolute !important;
  bottom: 0px;
  margin: 0;
}
.add-exit-overlay.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
}

.disabled-icon {
  .v-icon.v-icon {
    color: var(--disable-red-text) !important;
  }
}
.disable-row {
  background-color: var(--option-even-row) !important;
  td {
    border: none !important;
    color: var(--disabled-text) !important;
  }
}
.default-position-wrapper::-webkit-scrollbar,
.default-position-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* set the width of the scrollbar */
}
.default-position-wrapper::-webkit-scrollbar-thumb,
.default-position-wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc; /* set the color of the scrollbar thumb */
  border-radius: 5px; /* set the border radius of the scrollbar thumb */
}
.default-position-wrapper,
.default-position-wrapper {
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* set the color of the scrollbar track */
  }
}
// @media only screen and (max-width: 1500px) {
//   .default-position-wrapper {
//   }
// }
// @media only screen and (max-width: 1250px) {
//   .default-position-wrapper {
//   }
// }
@media only screen and (max-width: 600px) {
  .header-labels {
    font-size: 0.75em !important;
  }
  .header-values {
    font-size: 1.4em !important;
  }
  .filter-badge .v-badge__badge{
    font-size: 10px !important;
    height:10px;
    min-width: 10px;
    padding:1px 4px;
  }
  .default-positions-table.v-data-table {
    td,
    th > span {
      font-size: 0.75em !important;
      height: 34px;
    }

    table > tr > th {
      font-size: 0.75em !important;
      height: 30px !important;
    }
  }

  .mobile-instrument-default-rows {
    font-size: 0.75em;
    color: var(--text-color);

    .nrml-mobile {
      color: var(--option-strike-text);
    }

    .nrml-mobile-disable {
      color: var(--disable-nrml-text);
    }

    .mis-mobile {
      color: var(--mis-label-text);
    }
    .mis-mobile-disable {
      color: var(--disable-mis-text);
    }
  }

  .mobile-instrument-header {
    background-color: var(--chip-background);
    padding: 4px 20px 4px 8px;
    color: var(--sub-text-color);
    font-size: 0.75em;
  }

  .mobile-instrument-rows {
    padding: 4px 20px 4px 8px;
    color: var(--text-color);
    font-size: 0.75em;
  }
  .alert-icons {
    .v-icon.v-icon {
      font-size: 0.75em;
      color: var(--red-text);
    }
  }

  .info-icon.v-icon.v-icon {
    font-size: var(--font-18) !important;
  }
}

@media only screen and (max-width: 400px) {
  .mobile-instrument-rows {
    font-size: 0.625em !important;
  }
  // .mobile-instrument-header {
  //   font-size: 0.625em !important;
  // }
}
</style>
<style lang="scss">
.add-exit-overlay.v-dialog {
  width: 100vw;
  position: absolute !important;
  bottom: 0px;
  margin: 0;
}
.add-exit-overlay.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
}
.mobile-checkbox .v-icon {
  font-size: 1.2em !important;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0 !important;
}

@media only screen and (max-width: 600px) {
  .mobile-checkbox.v-input--selection-controls .v-input__slot > .v-label {
    font-size: 0.875em !important;
    margin-left: 1rem !important;
  }

  .mobile-checkbox .v-icon {
    font-size: 1em !important;
  }
  .mobile-checkbox.v-application--is-ltr .v-input--selection-controls__input {
    font-size: 1em !important;
    margin-right: 1rem !important;
  }
}
.default-positions--desktop-table .v-data-table__wrapper {
  min-height: 400px !important;
}
</style>
