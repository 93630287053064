import ReconnectingWebSocket from "reconnecting-websocket";

import { aliceblueWebsocketUrl } from '@/utils/aliceBlueUrls';
import { greeksoftWSURL } from "@/utils/greeksoftUrls.js";
import { 
  GREKSOFT_HEARTBEAT_INTERVAL as gsHeartbeatInterval,
  AICEBLUE_HEARTBEAT_INTERVAL as abHeartbeatInterval,
  GS_INDEXES_CODE,
  GS_INDEXES_NAME
} from "@/utils/constants";


import GetSunscribePayload from "./subscribeABPayload";
import GetUnsubscribePatload from "./unsubscribeABPayload";

export default {
  state() {
    return {
      // websocket vars
      ws: null,
      heartbeatInterval: null,
      subscriptionRecord: {},
    };
  },
  getters: {
    getWebsocket: (state) => state.ws,
    isWSConnected: (state) => !!state.ws,
    getHeartbeatTime:(state, getters, rootState, rootGetters)=>{
      return rootGetters.isAliceblue ? abHeartbeatInterval : gsHeartbeatInterval;
    },
    getWebsocketUrl:(state, getters, rootState, rootGetters)=>{
      return rootGetters.isAliceblue ? aliceblueWebsocketUrl : greeksoftWSURL;
    },
    /**
     * @returns the initial payload for websocket to start streaming
     */
    getInitialPayload: (state, getters, rootState, rootGetters) => {
      if(rootGetters.isAliceblue){
        const wsSession = rootGetters.getWsSession;
        const userId = rootGetters.getUserId;
        return JSON.stringify({
          susertoken: wsSession,
          t: "c",
          actid: userId + "_API",
          uid: userId + "_API",
          source: "API",
        })
      }
      else{
        const gscid = rootGetters.getGSCID;
        const gcid = rootGetters.getGCID;
        const wsSession = rootGetters.getWsSession;
        const payload={
          request: {
            data: {
              gscid: gscid,
              gcid: gcid,
              sessionId: wsSession,
              device_type: "0",
            },
            response_format: "json",
            request_type: "subscribe",
            streaming_type: "login",
          },
        };
        return btoa(JSON.stringify(payload));
      }
    },
    getHeartbeatPayload: (state, getters, rootState, rootGetters) => {
      if(rootGetters.isAliceblue){
        return JSON.stringify({ k: ``, t: "h" });
      }
      else{
        const gcid = rootGetters.getGCID;
        const wsSession = rootGetters.getWsSession;
        const payload ={
          request: {
            data: {
              gcid: gcid,
              sessionId: wsSession,
            },
            response_format: "json",
            request_type: "subscribe",
            streaming_type: "HeartBeat",
          },
        };
        return btoa(JSON.stringify(payload));
      }
    },
    /**
     * @description For greeksoft the payload has to be in a paticular format and encoded in base64
     * @returns encoded string of the payload for GREEKSOFT subscribing to instruments
     */
    getGreeksoftSubscribeInsPayload:(state, getters, rootState, rootGetters) => (tokenList) => {
      const gscid = rootGetters.getGSCID;
      const gcid = rootGetters.getGCID;
      let payload = {
        request: {
          data: {
            symbols: tokenList,
          },
          response_format: "json",
          gscid: gscid,
          gcid: gcid,
          request_type: "subscribe",
          streaming_type: "marketPicture",
        },
      };
      return btoa(JSON.stringify(payload));
    },
    getGreeksoftUnsubscribeInsPayload:(state, getters, rootState, rootGetters) => (tokenList) => {
      const gscid = rootGetters.getGSCID;
      const gcid = rootGetters.getGCID;
      let payload = {
        request: {
          data: {
            symbols: tokenList,
          },
          response_format: "json",
          gscid: gscid,
          gcid: gcid,
          request_type: "unsubscribe",
          streaming_type: "marketPicture",
        },
      };
      return btoa(JSON.stringify(payload));
    },
    getSubscriptionRecord: (state) => state.subscriptionRecord,
  },
  mutations: {
    // WEBSOCKET MUTATIONS
    setWS: (state, ws) => state.ws = ws,
    closeAndClearWS(state) {
      if (!state.ws) return;
      state.ws.close();
      state.ws = null;
    },

    // HEARTBEAT MUTATIONS
    setHeartbeatInterval: (state, interval) => state.heartbeatInterval = interval,
    clearHeartbeatInterval(state) {
      if (!state.heartbeatInterval) return;
      clearInterval(state.heartbeatInterval);
      state.heartbeatInterval = null;
    },

    // SUBSCRIPTION RECORD MUTATIONS
    addToSubscriptionRecord(state, payload) {
      state.subscriptionRecord = { ...state.subscriptionRecord, ...payload };
    },
    removeFromSubscriptionRecord(state, payload) {
      Object.keys(payload).forEach(key => {
        delete state.subscriptionRecord[key];
      });
    },
  },
  actions: {
    // INDEPENDENT OF BROKER
    startWebsocket({ dispatch, commit, getters, rootGetters }) {
      if(!rootGetters.isLoggedIn) return;
      // if(rootGetters.isLoggedIn) return;

      const prevWs = getters.getWebsocket;
      if (prevWs) {
        commit('closeAndClearWS');
      }
      const wsUrl=getters.getWebsocketUrl;
      const payload = getters.getInitialPayload;
      const ws = new ReconnectingWebSocket(wsUrl);
      ws.send(payload);
      ws.onopen = () => dispatch("onWSopen");
      ws.onclose = () => dispatch("onWSClose");
      ws.onerror = (err) => dispatch("onWSError", err);
      ws.onmessage = (event) => dispatch("onWSMessage", event);

      commit("setWS", ws);
    },
    onWSopen({dispatch,getters}) {
      // console.log("Websocket Opened");

      // set heartbeat
      const heartbeat = getters.getHeartbeatInterval;
      if (!heartbeat) dispatch("setWSHeartbeat");

      // resubscribe to instruments
      if(Object.keys(getters.getSubscriptionRecord).length>0)
        dispatch('resubscribeToInstruments');
    },
    // BROKER DEPENDENT
    onWSMessage({ commit,rootGetters }, event) {
      const isAliceblue=rootGetters.isAliceblue;
      if(isAliceblue){
        const msg = JSON.parse(event.data);
        commit("setcompactData", msg,{ root: true });
        commit("setSubscribedInstrumentsData", msg,{ root: true });
      }
      else{
        let msg;
        try{
          msg=JSON.parse(atob(event.data))
        }
        catch(err){
          msg=atob(event.data);
          msg=msg.replace(",,",",");
          msg=JSON.parse(msg)
        }
        const res=msg.response.data;
        if(GS_INDEXES_NAME.includes(res?.symbol)){
          commit("setGreeksoftIndexData", res, { root: true });
          return;
        }
        commit("setGreeksoftMarketData", res, { root: true });
      }
    },
    onWSError({getters,dispatch}, err) {
      console.log("error while establishing Websocket");
      console.log(err);

      const ws = getters.getWebsocket;
      if (!ws) return;
      
      console.log("trying to reconnect");
      dispatch('startWebsocket');
    },
    /**
     * INDEPENDENT OF BROKER
     * @description reconnects to websocket when closed
     * uses the latest instance of the websocket from store
     * to completely close the websocket , state.ws should be set to null
     * if the websocket is set to null then it will not reconnect
     */
    onWSClose({ getters,dispatch }) {
      // console.log("Websocket Closed");
      const ws = getters.getWebsocket;
      if (!ws) return;
      console.log("restarting websocket");
      dispatch('startWebsocket');
    },
    /**
     * INDEPENDENT OF BROKER
     * @description Sends heartbeat to greeksoft websocket every interval
     * @description Is set to fetch the latest instance of the websocket from store
     * so even if the websocket might me new the heartbeat will be sent to the latest instance
     */
    setWSHeartbeat({ getters, commit }) {
      const time=getters.getHeartbeatTime;
      let payload = () => getters.getHeartbeatPayload;
      let getWS = () => getters.getWebsocket;
      const interval = setInterval(() => {
        const ws = getWS();
        if (!ws || ws.readyState != 1) return;
        ws.send(payload());
      }, time);
      commit("setHeartbeatInterval", interval);
    },
    /**
     * INDEPENDENT OF BROKER
     * @description completely close the websocket permenantly
     * set the websocket to null
     * set the heartbeat interval to null
     */
    permanentlyCloseWs({ commit, getters }) {
      const ws = getters.getWebsocket;
      if (!ws) return;
      ws.onerror=null;
      commit('closeAndClearWS');
      commit('clearHeartbeatInterval');
    },
    // BROKER DEPENDENT
    async subscribeToInstruments({ getters, commit, rootGetters }, instruments) {
      const isAliceblue=rootGetters.isAliceblue;
      const ws = getters.getWebsocket;
      const record = {};

      if(isAliceblue){
        const getPayload = (exchange, code) => GetSunscribePayload[exchange.toUpperCase()](code);
        instruments.forEach(ins => {
          const payload = getPayload(ins.exchange, ins.code);
          ws.send(payload);
          record[ins.code] = ins;
        });
      }
      else{
        const tokenList=[];
        instruments.forEach(ins => {
          tokenList.push({ symbol: ins.code });
          record[ins.code] = ins;
        });
        const payload = getters.getGreeksoftSubscribeInsPayload(tokenList);
        ws.send(payload);
      }
      commit('addToSubscriptionRecord', record);
    },
    // BROKER DEPENDENT
    async unsubscribeToInstruments({ getters, commit, rootGetters }, instruments) {
      const isAliceblue=rootGetters.isAliceblue;
      const ws = getters.getWebsocket;
      const removeFromRecord = {};
      if(isAliceblue){
        const getPayload = (exchange, code) => GetUnsubscribePatload[exchange.toUpperCase()](code);
        instruments.forEach(ins => {
          const payload = getPayload(ins.exchange, ins.code);
          ws.send(payload);
          removeFromRecord[ins.code] = ins;
          commit("removeFromSubscribedInstrumentsData", ins.code, { root: true });
          commit("removeFromCompactMarketData", ins.code, { root: true });
      });
      }
      else{
        // unsubscribe to greesoft
        const tokenList=[];
        instruments.forEach(ins => {
          tokenList.push({ symbol: ins.code });
          commit("removeFromSubscribedInstrumentsData", ins.code, { root: true });
        });
        const payload = getters.getGreeksoftUnsubscribeInsPayload(tokenList);
        ws.send(payload);
      }
      commit('removeFromSubscriptionRecord', removeFromRecord);
    },
    async unsubscribeFromLocation({ getters, dispatch, rootGetters }, location) {
      const record = getters.getSubscriptionRecord;
      let instruments = Object.values(record).filter(ins => ins.loc == location );

      // // fix for skipping index unsubscirbeing for greeksoft
      if(rootGetters.isGreeksoft){
        const bannedUnsubCodes =  Object.values(GS_INDEXES_CODE);
        instruments=instruments.filter(ins=> !bannedUnsubCodes.includes(ins.code));
      }

      dispatch('unsubscribeToInstruments', instruments);
    },
    async resubscribeToInstruments({ getters, dispatch }) {
      const record = getters.getSubscriptionRecord;
      const instruments = Object.values(record);
      dispatch('subscribeToInstruments', instruments);
    },
  },
};
