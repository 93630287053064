import axios from 'axios';

import { Router } from '@/main';

import { POSITION_CACHE_NAME } from "@/utils/constants";

import { getUserSettingsUrl, announcementUrl, getBalanceCall } from '@/components/getService';
import {
  getMCXPositionSearchJSON as abMCXSearch,
  getCDSPositionSearchJSON as abCDSSearch,
  getHoldingsSearchCSV as abHoldingsSearch,
  getNFOPositionSearchJSON as abNFOSearch
} from "@/utils/aliceBlueUrls";
import {
  getGreeksoftNFOPositionSearchJSON as gsNFOSearch
} from "@/utils/greeksoftUrls";

export default {
  state () {
    return{
      announcements:[],
      userSettings: JSON.parse(localStorage.getItem("user_settings")),
      userBalance: +localStorage.getItem("balance"),

      networkStatus: "connected",
      networkStatusInterval: null,
    }
  },
  getters:{
    getAnnouncements: state => state.announcements,
    getUserSettings: state => state.userSettings,
    getBalance: state => state.userBalance,
    getNetworkStatus: (state) => state.networkStatus,
  },
  mutations: {
    setAnnouncements(state, payload){
      state.announcements = payload;
    },
    setUserSettings(state, settings){
      state.userSettings = settings;
      localStorage.setItem("user_settings", JSON.stringify(settings));
    },
    setBalance(state, balance){
      state.userBalance = balance;
      localStorage.setItem("balance", balance);
    },

    // USER ONLINE CHECK MUTATIONS
    setNetworkStatus: (state, status) => state.networkStatus = status,
    setNetworkStatusInterval(state, interval) {
      if (state.networkStatusInterval)
        clearInterval(state.networkStatusInterval);
      state.networkStatusInterval = interval;
    },
  },
  actions:{
    // Set user settings
    async getUserSettings({ commit, rootGetters }){
      const user=rootGetters.getUser;
      const payload = {
        user_id: user.userId,
        path: "get_user_settings",
        access_token: user.accessToken,
      }
      axios
      .post(getUserSettingsUrl, payload, {
        headers: {
          "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
        },
      })
      .then((res) => {
        if(res.errorMessage) throw(res);
        const settings = res.data;
        commit('setUserSettings',settings);
        const landing_page = settings.landing_page=='dashboard'?'Dashboard':settings.landing_page;
        const path=localStorage.getItem("path")
        if (!path) {
          Router.push({ path: path });
        } else {
          Router.push({ name: landing_page});
        }
      })
      .catch((err) => {
        console.log("error while getting user settings",err)
      })
      ;
    },
    // Get announcements
    async getAnnouncements({ commit }){
      const payload={
        operation:"get"
      }
      axios.post(announcementUrl,payload)
      .then(res=>{
        const announcements=res.data;
        announcements.forEach(el=>{
          el['postedTime']=new Date(el.live_from.toLocaleString())
        })
        commit('setAnnouncements',announcements);
      })
      .catch(err=>{
        console.log("error while loading announcements",err)
      })
    },
    // get user Balance
    async getBalance({ commit,rootGetters }){
      const access_token=rootGetters.getAccessToken;
      const userId=rootGetters.getUserId;
      const payload={auth_code: access_token, userId: userId};

      axios.post(getBalanceCall, payload)
      .then(res => {
        if (res.data.status) {
          let balance = res?.data?.data?.net;
          if(balance)
            commit('setBalance',balance);
        }
      })
      .catch(err => {
        console.log("error while getting balance",err)
      })
    },
    async setCachedData({dispatch,getters}) {
      const abFiles = [ abMCXSearch, abCDSSearch, abHoldingsSearch, abNFOSearch];
      const gsFiles = [ gsNFOSearch ];
      const fileUrls = getters.isAliceblue ? abFiles: gsFiles;
      for (const url of fileUrls) {
        const cacheName = POSITION_CACHE_NAME;
        let cachedData = await dispatch('getCachedData',cacheName, url);
        if (!cachedData) {
          const cacheStorage = await caches.open(cacheName);
          await cacheStorage.add(url);
        }
      }
    },
    async getCachedData(_,cacheName, url) {
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(url);
      if (!cachedResponse || !cachedResponse.ok) {
        return false;
      }
      return await cachedResponse.text();
    },
    async setNetworkStatusCheckInterval({ getters, commit, dispatch }) {
      const networkStatus = ()=>getters.getNetworkStatus;
      const interval = setInterval(() => {
        if (navigator.onLine) {
          if (networkStatus() == "disconnected") {
            dispatch("actionsAfterReconnectingToInternet", { root: true });
          }
          commit("setNetworkStatus", "connected");
        } else {
          commit("setNetworkStatus", "disconnected");
        }
      }, 3000);
      commit("setNetworkStatusInterval", interval);
    },
    async clearNetworkStatusInterval({ commit }) {
      commit("setNetworkStatusInterval",null);
    },
  },
}