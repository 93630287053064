import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import utility from "./modules/utility";
import websocket from "./modules/websocket";
import wsDataStorage from "./modules/wsDataStorage";

import { AB_INDEXES_CODE, GS_INDEXES_CODE } from "@/utils/constants";
// import wsDataStorageNEW from "./modules/wsDataStorageNEW";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: auth,
    utility: utility,
    websocket: websocket,
    wsDataStorage:wsDataStorage,
    // wsDataStorageNEW:wsDataStorageNEW,
  },
  state: {
    darkMode: false,
    snackbar: null,
    disclaimer: {
      title: "Disclaimer",
      message:
        "1lyOptions is a Futures and Options Analytics tool that help to find, analyse and track trading opportunities. All investment strategies and investments involve risk of loss. Nothing contained in this website should be construed as investment advice. Any reference to an investment's past or potential performance is not, and should not be construed as, a recommendation or as a guarantee of any specific outcome or profit. Any action taken, the outcome of such action shall be the sole responsibility of the user. Aliceblue nor any third-party would be responsible for any loss arising due to the actions taken by the user using this platform.",
    },
  },
  getters: {
    getDarkMode: (state) => state.darkMode,
    snackbar: (state) => state.snackbar,
    getDisclaimer: (state) => state.disclaimer,
    getNIFTY50: (state, getters, rootState, rootGetters) => {
      const code_obj=rootGetters.isAliceblue?AB_INDEXES_CODE:GS_INDEXES_CODE;
      const code=code_obj["NIFTY50"];
      return {
        code: code,
        name: "NIFTY 50",
        exchange: "NSE",
      }
    },
    getBANKNIFTY: (state, getters, rootState, rootGetters) => {
      const code_obj=rootGetters.isAliceblue?AB_INDEXES_CODE:GS_INDEXES_CODE;
      const code=code_obj["BANKNIFTY"];
      return {
        code: code,
        name: "BANKNIFTY",
        exchange: "NSE",
      }
    },
  },
  mutations: {
    setDarkMode(state, payload) {
      if (typeof payload != "boolean") return;
      state.darkMode = payload;
    },
    snackbar(state, payload) {
      state.snackbar = payload;
    },
  },
  actions: {
    /**
     * called after user logs in
     * @triggered from auth module after a successful login
     */
    actionsAfterLogin({ dispatch }) {
      dispatch('setTokenExpiration', { root: true });
      dispatch("getUserSettings", { root: true });
      dispatch("getBalance", { root: true });
      dispatch("startWebsocket", { root: true });
      // dispatch("setCachedData", { root: true });
    },
    /**
     * called when the user reloads the app
     * @triggered from layout component on mount
     */
    actionsOnReload({ dispatch }) {
      dispatch("getAnnouncements", { root: true });
      dispatch("setNetworkStatusCheckInterval", { root: true });
      dispatch("setCachedData", { root: true });// move to actionsAfterLogin
    },
    /**
     * actions to be performed after the user reconnects to the internet without reloading
     * @triggrered from utility module while checking for network status (setNetworkStatusCheckInterval)
     * -> user is browsing but internet connection is lost and then it is reconnected but does not reloads
     */
    actionsAfterReconnectingToInternet({ dispatch }) {
      dispatch("startWebsocket", { root: true });
    },
    actionsOnLogout({ dispatch }) {
      dispatch("permanentlyCloseWs", { root: true });
      dispatch("clearNetworkStatusInterval", { root: true });
    }
  },
});
export default store;
