<template lang="">
  <div>
    <div v-if="!$vuetify.breakpoint.xs">
      <div>
        <v-row no-gutters class="symbol-hedge-header">
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col v-if="selectedPositionFilter.value == 'Hedged'" cols="2"
                >Type</v-col
              >
              <v-col v-else cols="4">Type</v-col>
              <v-col v-if="selectedPositionFilter.value == 'Hedged'" cols="6"
                >Instrument</v-col
              >
              <v-col v-else cols="4">Instrument</v-col>
              <v-col cols="4"> Trend Predictor </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col cols="4"> Margin Utilized </v-col>
              <v-col cols="4"> Profit/Loss </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters class="symbol-hedge-rows">
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col v-if="selectedPositionFilter.value == 'Hedged'" cols="2">
                <span
                  :class="
                    card.prod_type == 'NRML' ? 'nrml-mobile' : 'mis-mobile'
                  "
                >
                  {{ card.prod_type }}</span
                >
              </v-col>
              <v-col v-else cols="4">
                <span
                  :class="
                    card.prod_type == 'NRML' ? 'nrml-mobile' : 'mis-mobile'
                  "
                >
                  {{ card.prod_type }}</span
                >
              </v-col>
              <v-col v-if="selectedPositionFilter.value == 'Hedged'" cols="6"
                >{{ card.symbol }}
                <span v-if="selectedPositionFilter.value == 'Hedged'">
                  <span style="color: var(--primary-color)">></span>
                  <span class="strategy-name">{{ card.strategy }}</span></span
                ></v-col
              >
              <v-col v-else cols="4">{{ card.symbol }}</v-col>
              <v-col cols="4">
                <span
                  v-if="card.trend != ''"
                  :class="
                    card.trend == 'Bullish'
                      ? 'bullish-text'
                      : card.trend == 'Bearish'
                      ? 'bearish-text'
                      : 'neutral-text'
                  "
                  >{{ card.trend }}</span
                >
                <span v-else> N/A </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col cols="4">
                {{ card.margin_utilized | integerFormat }}
              </v-col>
              <v-col cols="4">
                <span
                  v-if="getTotalProfitLoss(card.positions) >= 0"
                  class="green-text"
                  >+</span
                >
                <span
                  :class="
                    getTotalProfitLoss(card.positions) >= 0
                      ? 'green-text'
                      : 'red-text'
                  "
                  >{{
                    getTotalProfitLoss(card.positions) | currencyWithDecimal
                  }}
                </span>
              </v-col>
              <v-col cols="4" class="d-flex align-end">
                <button
                  class="primary-button mr-2 exit-button"
                  :class="
                    selectedPositions.length == 0 ? 'disabled-button' : ''
                  "
                  style="white-space: nowrap; background-color: var(--red-text)"
                  @click="
                    showPlaceOrderForExitPositions();
                    selectedPositions = card.selectedPositions;
                  "
                >
                  Exit
                </button>
                <button
                  class="outlined-button mr-2"
                  style="white-space: nowrap"
                  @click="showGraphModal(card)"
                >
                  <img
                    :src="
                      !darkMode
                        ? require('@/assets/LightMode/graph.png')
                        : require('@/assets/DarkMode/graph.png')
                    "
                    alt="show chart"
                    style="cursor: pointer; height: 0.9em; width: 1.4em"
                  />
                </button>
                <button
                  @click="card.showInstrumentTable = !card.showInstrumentTable"
                >
                  <img
                    class="up-down-arrow"
                    :src="
                      !darkMode
                        ? card.showInstrumentTable
                          ? require('@/assets/Positions/arrow-up.png')
                          : require('@/assets/Positions/arrow-down.png')
                        : card.showInstrumentTable
                        ? require('@/assets/Positions/arrow-up-dark.png')
                        : require('@/assets/Positions/arrow-down-dark.png')
                    "
                    alt="show chart"
                    style="cursor: pointer; height: 75%; width: 100%"
                  />
                </button>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="card.showInstrumentTable" no-gutters>
          <DefaultPositions
            :isDefaultPositions="false"
            :defaultPositionsList="card.selectedPositions"
            :positionAnalyticsData="positionAnalyticsData"
            @selectedPositions="disableExitButton"
          ></DefaultPositions>
        </v-row>
      </div>
    </div>

    <!-- Mobile design for hedged and unhedged cards -->
    <div v-else class="symbol-hedge-rows">
      <v-row no-gutters class="mb-2">
        <v-col cols="6">
          {{ card.symbol }}
          <span v-if="selectedPositionFilter.value == 'Hedged'">
            <span style="color: var(--primary-color)">></span>
            <span class="strategy-name">{{ card.strategy }}</span></span
          >
        </v-col>
        <v-col cols="2">
          <span
            v-if="card.trend != ''"
            :class="
              card.trend == 'Bullish'
                ? 'bullish-text'
                : card.trend == 'Bearish'
                ? 'bearish-text'
                : 'neutral-text'
            "
            >{{ card.trend }}</span
          >
          <span v-else> N/A </span>
        </v-col>
        <v-col cols="4" class="text-right">
          <span style="color: var(--sub-text-color)">P&L: </span>
          <span
            v-if="getTotalProfitLoss(card.positions) >= 0"
            class="green-text"
            >+</span
          >
          <span
            :class="
              getTotalProfitLoss(card.positions) >= 0
                ? 'green-text'
                : 'red-text'
            "
            >{{ getTotalProfitLoss(card.positions) }}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <span style="color: var(--sub-text-color)">Margin Utilized: </span>
          <span>{{ card.margin_utilized | integerFormat }}</span>
        </v-col>
        <v-col cols="2">
          {{ card.prod_type }}
        </v-col>
        <v-col cols="4" class="text-right d-flex align-end justify-end">
          <button
            class="primary-button mr-2 exit-button"
            :class="selectedPositions.length == 0 ? 'disabled-button' : ''"
            style="white-space: nowrap; background-color: var(--red-text)"
            @click="
              showPlaceOrderForExitPositions();
              selectedPositions = card.selectedPositions;
            "
          >
            Exit
          </button>
          <button
            class="outlined-button mr-2"
            style="white-space: nowrap"
            @click="showGraphModal(card)"
          >
            <img
              :src="
                !darkMode
                  ? require('@/assets/LightMode/graph.png')
                  : require('@/assets/DarkMode/graph.png')
              "
              alt="show chart"
              style="cursor: pointer; height: 75%; width: 100%"
            />
          </button>
          <button @click="card.showInstrumentTable = !card.showInstrumentTable">
            <img
              class="up-down-arrow"
              :src="
                !darkMode
                  ? card.showInstrumentTable
                    ? require('@/assets/Positions/arrow-up.png')
                    : require('@/assets/Positions/arrow-down.png')
                  : card.showInstrumentTable
                  ? require('@/assets/Positions/arrow-up-dark.png')
                  : require('@/assets/Positions/arrow-down-dark.png')
              "
              alt="show chart"
              style="cursor: pointer; height: 75%; width: 100%"
            />
          </button>
        </v-col>
      </v-row>
      <v-row v-if="card.showInstrumentTable" no-gutters>
        <DefaultPositions
          :isDefaultPositions="false"
          :defaultPositionsList="card.positions"
          :positionAnalyticsData="positionAnalyticsData"
          @selectedPositions="disableExitButton"
        ></DefaultPositions>
      </v-row>
    </div>

    <ExitPositionsModal
      v-if="isExitPositionsClicked"
      :isExitPositionsVisible="isExitPositionsClicked"
      @close="isExitPositionsClicked = false"
      :selectedPositions="card.selectedPositions"
    />
    <PlaceOrderModal
      v-if="showPlaceOrderDialog"
      :visible="showPlaceOrderDialog"
      @close="showPlaceOrderDialog = false"
      @refreshItems="refreshPositions()"
      :strategyCreator="strategyCreatorList"
      :selectedInstrument="selectedInstrument"
      :isPositionGraph="true"
      :isExitPositionsModal="isExitPositionsModal"
    ></PlaceOrderModal>

    <GraphAndDaysModal
      v-if="payOffChartVisible"
      :visible="payOffChartVisible"
      :selectedInstrument="selectedInstrument"
      :strategyData="payOffChartData"
      :isPositionGraph="true"
      :isDefaultPositions="false"
      :positionAnalyticsData="payOffChart"
      @close="payOffChartVisible = false"
    ></GraphAndDaysModal>
  </div>
</template>
<script>
import { EventBus } from "../../main";
import {
  getProfitLossForPosition,
  getPlaceOrderData,
} from "./common/positionServices";
import ExitPositionsModal from "./modals/ExitPositionsModal.vue";
import DefaultPositions from "./DefaultPositions.vue";
import GraphAndDaysModal from "../modals/GraphAndDaysModal.vue";
import { calculateTargetDays } from "../Dashboard/dashboardService";
import PlaceOrderModal from "../modals/PlaceOrderModal.vue";
export default {
  name: "HedgedUnhedged",
  components: {
    ExitPositionsModal,
    DefaultPositions,
    GraphAndDaysModal,
    PlaceOrderModal,
  },
  props: {
    card: null,
    selectedPositionFilter: null,
    positionAnalyticsData: null,
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details) return data.details;
        else return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      isExitPositionsClicked: false,
      selectedPositions: [],
      payOffChartVisible: false,
      payOffChartData: null,
      payOffChart: null,
      selectedInstrument: null,
      showPlaceOrderDialog: false,
      isExitPositionsModal: false,
      strategyCreatorList: [],
    };
  },
  methods: {
    disableExitButton(value) {
      this.selectedPositions = value;
    },
    /** @function getTotalProfitLoss to get total profit loss */
    getTotalProfitLoss(position) {
      let totalProfitLoss = 0;
      if (position.length > 0)
        for (let i = 0; i < position.length; i++) {
          totalProfitLoss =
            parseFloat(totalProfitLoss) +
            Number(this.getCardProfitLoss(position[i]));
        }
      return parseFloat(totalProfitLoss).toFixed(2);
    },

    /** @function getCardProfitLoss to get individual positions profit loss */
    getCardProfitLoss(position) {
      let cardTotalProfit = 0;
      let ltp = this.insSub(position.instrument_token.toString())
        ? this.insSub(position.instrument_token.toString()).ltp
        : 0;
      let profitLoss = getProfitLossForPosition(position, ltp);
      cardTotalProfit = cardTotalProfit + parseFloat(profitLoss);
      return cardTotalProfit;
    },

    /** @function showGraphModal method is used to show graph modal */
    showGraphModal(item) {
      console.log(item)
      this.payOffChartVisible = true;
      this.payOffChart = item;
      this.selectedInstrument = getPlaceOrderData(item, "instrument", null);
      this.selectedInstrument.exchange =
        this.selectedInstrument.exchange == "NFO"
          ? "NSE"
          : this.selectedInstrument.exchange;
      let spot_price =
        this.selectedInstrument.exchange == "NSE" ||
        this.selectedInstrument.exchange == "NFO"
          ? this.insSub(this.selectedInstrument.underlying_code)
            ? this.insSub(this.selectedInstrument.underlying_code).ltp
            : this.insSub(this.selectedInstrument.fut_code).ltp
          : 0;
      if (spot_price == 0) {
        spot_price =
          this.selectedInstrument.exchange == "NSE" ||
          this.selectedInstrument.exchange == "NFO"
            ? this.selectedInstrument.ltp
            : this.selectedInstrument.fut_code;
      }
      if (this.selectedInstrument.exchange == "NSE") {
        this.selectedInstrument.ltp = spot_price;
      } else {
        this.selectedInstrument.fut_ltp = spot_price;
      }
      for (let param of item.param_list) {
        param.segment =
          param.segment == "PE"
            ? "p"
            : param.segment == "CE"
            ? "c"
            : param.segment == "FUT"
            ? "fut"
            : param.segment;
        param.ltp = param.op_pr;
      }
      this.payOffChartData = {
        payload: {
          user_id: localStorage.getItem("user_id"),
          access_token: localStorage.getItem("accessTokenAnt"),
          ws_session: localStorage.getItem("ws_session"),
          param_list: item.param_list,
          path: "custom_strategy",
          broker: "aliceblue",
          name: this.selectedInstrument.name,
          lot_size: this.selectedInstrument.lot_size,
          exchange:
            this.selectedInstrument.exchange == "NSE"
              ? "NFO"
              : this.selectedInstrument.exchange,
          days_to_expire: calculateTargetDays(item.param_list),
          spot_price: spot_price,
          target_days: 0,
          iv_percent: 0,
        },
      };
      console.log(this.payOffChartData);
    },

    refreshPositions() {
      // this.selectedPositions=[]
      EventBus.$emit("refreshPositionsScreen", true);
    },

    showPlaceOrderForExitPositions() {
      this.selectedPositions = this.card.selectedPositions;
      this.showPlaceOrderDialog = true;
      this.isExitPositionsModal = true;
      this.strategyCreatorList = [];
      for (let item of this.card.selectedPositions) {
        this.strategyCreatorList.push({
          trading_symbol: item.trading_symbol,
          formatted_ins_name: item.formatted_ins_name,
          strike_price: item.strike_price,
          strike: item.strike_price,
          segment:
            item.param_list.segment == "PE"
              ? "p"
              : item.param_list.segment == "CE"
              ? "c"
              : "fut",
          name: item.symbol,
          exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
          ltp: item.ltp,
          expiry: item.expiry_date,
          quantity: -item.net_quantity,
          code: item.code,
          prod_type: item.prod_type,
        });
      }
    },
  },
  mounted() {
    this.selectedPositions = this.card.selectedPositions;
  },
};
</script>
<style lang="scss" scoped>
.symbol-hedge-header {
  background-color: var(--chip-background);
  padding: 6px 12px;
  color: var(--text-color);
  font-size: 1.1em;
}
.symbol-hedge-rows {
  padding: 6px 12px;
  color: var(--text-color);
  font-size: 1.1em;
}
.strategy-name {
  font-size: 1em;
  color: var(--sub-text-color);
}

.up-down-arrow {
  width: 0.75em !important;
  height: 1em !important;
}

.primary-button,
.outlined-button {
  padding: 0.5em 1em !important;
  font-size: 0.875em !important;
  height: 2em !important;
}
.disabled-button {
  pointer-events: none;
  background-color: var(--plus-minus-background) !important;
  color: var(--sub-text-color) !important;
}
@media only screen and (max-width: 960px) {
  .symbol-hedge-header,
  .symbol-hedge-rows {
    padding: 4px 8px !important;
    font-size: 1em !important;
  }
  .strategy-name {
    font-size: 0.875em !important;
  }
}

@media only screen and (max-width: 600px) {
  .symbol-hedge-header,
  .symbol-hedge-rows {
    padding: 4px 4px !important;
    font-size: 0.875em !important;
  }
  .strategy-name {
    font-size: 0.75em !important;
  }

  .primary-button,
  .outlined-button {
    padding: 4px 10px !important;
    font-size: 0.875em !important;
    height: 26px !important;
  }

  .nrml-mobile {
    color: var(--option-strike-text);
  }

  .mis-mobile {
    color: var(--mis-label-text);
  }
  .up-down-arrow {
    width: 10px !important;
    height: 15px !important;
    margin-left: 4px;
  }
}

@media only screen and (max-width: 400px) {
  .symbol-hedge-header,
  .symbol-hedge-rows {
    padding: 4px 4px !important;
    font-size: 0.75em !important;
  }
  .strategy-name {
    font-size: 0.625em !important;
  }
  .primary-button,
  .outlined-button {
    padding: 4px 8px !important;
    font-size: 0.75em !important;
    height: 22px !important;
  }
  .up-down-arrow {
    width: 8px !important;
    height: 12px !important;
    margin-left: 4px;
  }
}
</style>
