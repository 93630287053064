<template>
  <div class="modal-backdrop">
    <div class="modal">
      <v-card-title class="white--text modal__header">
        <v-row class="justify-space-between">
          <v-col class="modal-title">Place Order</v-col>
          <v-col class="modal-meta">
            <v-radio-group class="type-radio" v-model="product" row>
              <v-radio disabled label="MIS" value="MIS" color="white"></v-radio>
              <v-radio disabled label="NRML" value="NRML" color="white"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- <div class="flex-grow-1">
          <div class="modal__header_title">Place Order</div>
          <div class="d-flex justify-space-between fw-w">
            <div>
              <v-radio-group v-model="product" row>
                <v-radio label="MIS" value="MIS" color="white"></v-radio>
                <v-radio label="NRML" value="NRML" color="white"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div> -->
      </v-card-title>
      <div class="modal__body">
        <v-simple-table>
          <template v-slot:default>
            <table v-if="count!=1" class="tableContent" >
              <tbody v-for="item in items" :key="item.code">
                <tr>
                  <td>{{ item.trading_symbol}}</td>
                  <td >
                    <template  class="tb-modal" @click="flip(item.instrument_token)" >
                      <img src="@/assets/dropDownIcon.svg" height="30px" class="ma-2"
                        color="#CCFFC3" width="30px" 
                        @click="flip(item.instrument_token)" />
                    </template>
                  </td> 
                  <td>
                    <template item.trade="{ item }" class="tb-modal"  >
                      <v-chip
                        v-if="item.quantity > 0"
                        class="ma-2"
                        label
                        color="#CCFFC3"
                        @click="flip3(item.instrument_token)"
                      >Buy</v-chip>
                      <v-chip v-else class="ma-2" label color="#FFCFCD" @click="testing(items)">Sell</v-chip>
                    </template>
                  </td>
                  <td>
                    <div class="my-auto ml-6">
                      <integer-plusminus  v-if="item.quantity == lot_size" v-model="item.quantity" :min="-5000" :stepIncrement="lot_size" :stepDecrement="2*lot_size"></integer-plusminus>
                      <integer-plusminus  v-else-if="item.quantity == -lot_size" v-model="item.quantity" :min="-5000" :stepIncrement="2*lot_size" :stepDecrement="lot_size"></integer-plusminus>
                      <integer-plusminus  v-else v-model="item.quantity" :stepIncrement="lot_size" :stepDecrement="lot_size" :min="-5000"></integer-plusminus>
                    </div>
                  </td>
                  <td class="paddingLeft1" >
                    <template item.type="{ item }" class="tb-modal" height="20px" width="30px" >
                      <v-select
                        v-model="item.type"
                        :items="typeOptions"
                        outlined
                        label=""
                        dense
                        hide-details
                        height="20px"
                        width="30px"
                      ></v-select>
                    </template>
                  </td>
                  <td class="paddingLeft1">
                  
                    <template item.price="{ item }" class="tb-modal " >
                      <input
                        v-if="item.type == 'LIMIT'"
                        class="modal__body_input"
                        placeholder="Price"
                        v-model="item.price"
                        type="number"
                      />
                      <span v-else style="font-size:13px;"> {{item.price}}
                        {{compactMarketDataScripts(item.code).details['Last Traded Price']}}
                      </span>
                    </template>
                  
                  </td>
                  <td v-if="stoploss" class="paddingLeft1">
                    <template item.stoploss="{ item }" class="tb-modal">
                      <input
                        class="modal__body_input"
                        placeholder="Stop Loss Price"
                        type="number"
                        v-model="item.stoploss"
                      />
                    </template>
                  </td>
                  <td v-if="targetPrice" class="paddingLeft1">
                    <template item.targetPrice="{ item }" class="tb-modal">
                      <input
                        class="modal__body_input"
                        placeholder="Target Price"
                        type="number"
                        v-model="item.targetPrice"
                      />
                    </template>
                  </td>
                </tr>
                
                <tr>
                  <td v-bind:colspan=colomSpan1 class="lightBlue">
                    <div v-for="element in items3" :key="element.text" width="100%">
                      <div v-if="element.display" width="100%" >
                        <table width="100%" class="centerAlign" >
                          <tr>
                            <th v-for="element in headers2" :key='element.text' class="lightGrey">{{element.text}}</th>
                          </tr>
                        
                          <tr v-for="element1 in items5" :key='element1'  width="100%">
                            <td class="greenColor centerAlign"  >{{compactMarketDataScripts(item.instrument_token).details.buyBid[element1]}}</td>
                            <td class="greenColor centerAlign" >{{compactMarketDataScripts(item.instrument_token).details.buyOrder[element1]}}</td>
                            <td class="greenColor rightAlign" >{{compactMarketDataScripts(item.instrument_token).details.buyQuanity[element1]}}</td>
                            <td class="redColor centerAlign" >{{compactMarketDataScripts(item.instrument_token).details.sellOffer[element1]}}</td>
                            <td class="redColor centerAlign">{{compactMarketDataScripts(item.instrument_token).details.sellOrder[element1]}}</td>
                            <td class="redColor rightAlign">{{compactMarketDataScripts(item.instrument_token).details.sellQuantity[element1]}}</td>
                          </tr>
                          <tr>
                            <td class="greenColor centerAlign"  > </td>
                            <td class="greenColor centerAlign" >  </td>
                            <td class="greenColor rightAlign" > </td>
                            <td class="redColor centerAlign" > </td>
                            <td class="redColor centerAlign"> </td>
                            <td class="redColor rightAlign"> </td>
                            <!--  total count over here  -->
                          </tr>
                          

                          <tr>
                            <td class="greenColor centerAlign"  >Total</td>
                            <td class="greenColor centerAlign" ></td>
                            <td class="greenColor rightAlign" ></td>
                            <td class="redColor centerAlign" >Total</td>
                            <td class="redColor centerAlign"></td>
                            <td class="redColor rightAlign"></td>
                            <!--  total count over here  -->
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <table v-else class="tableContent" >
              <tbody v-for="item in items" :key="item.code">
                <tr>
                  <td>{{ item.tradingSymbol}}</td>
                  <td></td> 
                  <!-- showing bid data commented for now -->
                  <td >
                  <template  class="tb-modal" @click="flip3(item.instrument_token)" >
                      <img src="@/assets/dropDownIcon.svg" height="30px" class="ma-2"
                        color="#CCFFC3" width="30px" 
                        @click="flip3(item.instrument_token)" />
                    </template> 
                  </td> 
                  <td>
                    <template item.trade="{ item }" class="tb-modal"  >
                      <v-chip
                        v-if="item.quantity > 0"
                        class="ma-2"
                        label
                        color="#CCFFC3"
                        @click="flip3(item.instrument_token)"
                      >Buy</v-chip>
                      <v-chip v-else class="ma-2" label color="#FFCFCD" @click="testing(items)">Sell</v-chip>
                      
                    </template>
                  </td>
                  <td>
                    <div class="my-auto ml-6">
                      <integer-plusminus  v-if="item.quantity == lot_size" v-model="item.quantity" :min="-5000" :stepIncrement="lot_size" :stepDecrement="2*lot_size"></integer-plusminus>
                      <integer-plusminus  v-else-if="item.quantity == -lot_size" v-model="item.quantity" :min="-5000" :stepIncrement="2*lot_size" :stepDecrement="lot_size"></integer-plusminus>
                      <integer-plusminus  v-else v-model="item.quantity" :stepIncrement="lot_size" :stepDecrement="lot_size" :min="-5000"></integer-plusminus>
                    </div>
                  </td>
                  <td class="paddingLeft3" >
                  <template item.type="{ item }" class="tb-modal" height="20px" width="30px" >
                      <v-select
                        v-model="item.type"
                        :items="typeOptions"
                        outlined
                        label=""
                        dense
                        hide-details
                        height="20px"
                        width="30px"
                      ></v-select>
                    </template>
                  </td>
                  
                  
                  <td class="paddingLeft1">
                  <template item.ltp="{ item }" class="tb-modal">
                      <input
                        v-if="item.type === 'LIMIT'"
                        class="modal__body_input"
                        placeholder="Price"
                        v-model="item.ltp"
                        type="number"
                      />
                      <span v-else style="font-size:13px; padding:10px;"> {{compactMarketDataScripts(item.code).details['Last Traded Price']}} </span>
                    </template>
                  </td>

                  <td v-if="stoploss" class="paddingLeft3">
                    <template item.stoploss="{ item }" class="tb-modal">
                      <input
                        class="modal__body_input"
                        placeholder="Stop Loss Price"
                        type="number"
                        v-model="item.stoploss"
                      />
                    </template>
                  </td>

                  <td v-if="targetPrice" class="paddingLeft3">
                    <template item.targetPrice="{ item }" class="tb-modal">
                      <input
                        class="modal__body_input"
                        placeholder="Target Price"
                        type="number"
                        v-model="item.targetPrice"
                      />
                    </template>
                  </td>
                </tr>
                
                <!-- <tr>
                showing bid data commented for now
                  <td v-bind:colspan=colomSpan1 class="lightBlue">
                    <div v-for="element in items3" :key="element" width="100%">
                      <div v-if="item.instrument_token==element.code" width="100%">
                        <div v-if="element.display" width="100%" >
                          <table width="100%" class="centerAlign" >
                              <tr>
                                <th v-for="element in headers2" :key='element' class="lightGrey">{{element.text}}</th>
                              </tr>
                              <tr v-for="element1 in element.debthBid2" :key='element1'  width="100%">
                                <td class="greenColor centerAlign"  >{{element1.buyBidi}}</td>
                                <td class="greenColor centerAlign" >{{element1.buyOrderi}}</td>
                                <td class="greenColor rightAlign" >{{element1.buyQuantityi}}</td>
                                <td class="redColor centerAlign" >{{element1.sellOfferi}}</td>
                                <td class="redColor centerAlign">{{element1.sellOrderi}}</td>
                                <td class="redColor rightAlign">{{element1.sellQuantityi}}</td>
                              </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </template>
        </v-simple-table>
        <div class=" modal-bottom mt-8">
          
          <div class="d-flex align-center modal-control">
            <v-progress-circular
              v-if="isLoading"
              :size="50"
              color="success"
              indeterminate
        ></v-progress-circular>
          </div>
          <div class="d-flex align-center modal-control">
            <v-btn class="modal__body_btn mr-3" text @click="close()">
              Cancel
            </v-btn>
            <v-btn 
              :disabled="isDisabled"
              class="modal__body_btn modal__body_btn-confirm" 
              @click="confirmOrder()">
              Confirm
            </v-btn>
          </div>
        </div>
      </div>
      <!-- <section class="modal-body">
        <slot name="body">
          This is the default body!
        </slot>
       </section> -->

      <footer class="modal__footer">
        Available Margin: 
          <span class="modal__footer_marginAmount">{{ parseFloat(availableFund).toFixed(0).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") }} </span>
      </footer>
    </div>
    <SuccessModal v-show="isSuccessVisible" @close="closeModal">
      <template v-slot:header>
      <img width="300px" src="@/assets/success.png">
      </template>

      <template v-slot:body>
        <div class="success-message"> Your order has been sent.</div>
      </template>

      <template v-slot:footer>
        
      </template>
    </SuccessModal>
  </div>
</template>
<script>
import { IntegerPlusminus } from 'vue-integer-plusminus';
import axios from 'axios';
import SuccessModal from './SuccessModal.vue';
import {saveOrderResponse,getLixData} from './getService'
  export default {
    components : { IntegerPlusminus, SuccessModal },
    props: {
      data: {type : Array},
      availableFund: {type: Number}
    },
    mounted () {
      let instrument_list = this.data
      if(instrument_list.length > 0){
          this.product = instrument_list[0].prod_type
        }
        else{
          this.product = "NRML"
        }
        console.log(instrument_list[0].net_quantity)
      this.lot_size = Math.abs(instrument_list[0]['net_quantity'])
      instrument_list.forEach((element, index) => {
        let quant = element['net_quantity']
        if(element['symbol'] == 'NIFTY' || element['symbol'] == 'BANKNIFTY'){
          this.$set(element,'type','MKT')
        }
        else{
          this.$set(element,'type','LIMIT')
        }

          if (element['net_quantity'] < 0){
            // element['quantity'] = Math.abs(quant)
            this.$set(element,'quantity', Math.abs(quant))
          }
          else{
            this.$set(element,'quantity', -quant)
            // element['quantity'] = -quant
          }
          if(element['selected']){
            this.$set(this.items,index,element)
          }
        });
        this.items = this.items.filter(function( element ) {
          return element != undefined;
        });
        this.items.sort(this.compare_qty)
        this.items5=[];
        for(let i=0;i<5;i++){
          this.items5.push(i);
        }
        for(let i=0;i<this.items.length;i++){
          this.items3.push({
            display:false,
            code:this.items[i].instrument_token
          })
        }
        // this.data = this.items
    },
    data(){
      return {
        lot_size: 0,
        listOfOrders:[],
        isSuccessVisible: false,
        typeOptions: [ 
        { text: 'MKT', value: 'MKT'},
        { text: 'LIMIT', value: 'LIMIT' },
      ],
        items: [],
         items2:[],
      items3:[],
      items5:[],
      colomSpan1:7,
      items4:[],
      lix_value_array:[],
      headers2:[
        {
          text: 'BID',
          value: 'buyBidi',
          class: "green lighten-5"
        },
        {
          text: 'ORDER', value: 'buyOrederi',class: "green lighten-5"
        },
        { text: 'QTY', value: 'buyQuantityi' ,class: "green lighten-5"},
        { text: 'OFFER', value: 'sellOfferi',class: "red lighten-5" },
        { text: 'ORDER', value: 'sellOrderi' ,class: "red lighten-5"},
        { text: 'QTY', value: 'sellQuantityi',class: "red lighten-5" }
      ],
      displayPlaceOrder:[],
      bidData:null,
      buyBid:[],
      buyOrder:[],
      buyQuantity:[],
      sellOffer:[],
      timer: null,
      count:0,
      sellOrder:[],
      sellQuantity:[],
      grid:[],
      displayBidTable:false,
        isLoading: false,
        isDisabled: false,
        headers: [
        {
          text: 'Trading Symbol',
          value: 'trading_symbol',
        },
        {
          text: 'quantity', value: 'quantity'
        },
        { text: 'trade', value: 'trade' },
        { text: 'type', value: 'type' },
        { text: 'ltp', value: 'ltp' },
      ],
        marginRequired: 0,
        availableMargin: 0,
        product:'NRML',
        targetPrice: false,
      stoploss: false,
      }
    },
    name: 'ExitOrderModal',
   
    methods: {
       getLixValue1(code){
      for(let i=0;i<this.lix_value_array.length;i++){
        if(this.lix_value_array[i].code==code){
          return parseFloat(this.lix_value_array[i].lix_value2).toFixed(1);
        }
      }
      return 0;
    },
    getLixValue(code){
      // console.log("code entered")
      // console.log(code)
      let param_list=[
          {
        "quantity": -25,
        "segment": "CE",
        "strike": 33300,
        "code": "53644",
        "op_pr": 220.6,
        "name": "BANKNIFTY",
        "expiry": "2022-06-23"
    }
      ]
      // console.log(param_list)
      for(var i=0;i<this.items.length;i++){
        if(this.items[i].code==code){
          param_list[0].code=this.items[i].code,
          param_list[0].quantity=this.items[i].quantity,
          param_list[0].strike=this.items[i].strike_price,
          param_list[0].op_pr=this.items[i].price,
          param_list[0].name=this.sliceString(this.items[i].tradingSymbol)
          param_list[0].expiry=this.expiry
          param_list[0].segment=this.getLastTwoCar(this.items[i].tradingSymbol)

        }
      }
      // console.log("value entered")
      // console.log(param_list)
      let lixValueList=[]
      // let lix_value1=0
      axios.post(getLixData,{param_list: param_list}).then((res)=>{
        // console.log(res);
                    lixValueList = res.data.param_list
                //      console.log(lixValueList)
                // console.log(lixValueList[0].lix_value)
                this.lix_value_array.push({
                  code:code,
                  lix_value2:lixValueList[0].lix_value
                })
                // return lixValueList[0].lix_value;
                })
                // setTimeout(() => lix_value1 = lixValueList[0].lix_value, 2000);
               
    // return lix_value1

    },
      flip(code){
        for(let i=0;i<this.items3.length;i++){
          if(code==this.items3[i].code){
            if(this.items3[i].display==true){
              this.items3[i].display=false;
            }else{
              this.items3[i].display=true;
            }
          }
        }
      },
      flipTargetPrice(){
      if(this.targetPrice){
       this.colomSpan1+=1;
       this.count+=1
      }else{
       this.colomSpan1-=1
       this.count-=1
      }
    },
    flipStopLossPrice(){
      if(this.stoploss){
        this.colomSpan1+=1;
        this.count+=1
      }else{
        this.colomSpan1-=1
        this.count-=1
      }
    }, 
      closeModal() {
        // this.$router.push({name: 'Position'})
        this.$emit('close');
        this.isDisabled = false
        this.isSuccessVisible = false;
      },
      compare_qty(a, b){
        // a should come before b in the sorted order
        if(a.quantity < b.quantity){
                return -1;
        // a should come after b in the sorted order
        }else if(a.quantity > b.quantity){
                return 1;
        // a and b are the same
        }else{
                return 0;
        }
      },
      getLastTwoCar(param) {
          let result
          let chars = param.slice(-2)
          if (chars == "PE") {
            result = "PE"
          } else if (chars == "UT") {
            result = "FUT"
          } else if (chars == "CE") {
            result = "CE"
          }
        return result
    },
      async confirmOrder(){
        this.isDisabled = true;
        this.listOfOrders= []
        let access_token = localStorage.getItem('accessTokenAnt')
        let user_id = localStorage.getItem('user_id')
        let placeOrderUrl = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/executePlaceOrder"
        let header = {'Authorization':'Bearer '+ user_id + " " +access_token}
        let scriptPayload = []
        this.items.forEach(element => {
          let obj = { 
            "exchange":element.exchange, 
            "order_type": element.type,
            "trading_symbol": element.trading_symbol, 
            "quantity": Math.abs(element.quantity),
            "price": element.ltp,
            "order_side": element.quantity > 0 ? "BUY" : "SELL",
            "validity":"DAY",
            "product":this.product,
            // "code":element.code,
            "segment_type": this.getLastTwoCar(element.trading_symbol),
            "instrument_token": element.instrument_token,
          }
          // [{"complexty":"regular","exch":order['exchange'],"symbol_id":instrument_token,"price":`${parseFloat(price).toFixed(2)}`, "transtype":order_side,"prctyp":order_type, "pCode":product, "qty":`${Math.abs(order['quantity'])}`, "ret":validity, "trading_symbol":order['trading_symbol']}]
          scriptPayload.push(obj)
        });
        let sePeBuyPayload = []
        let response_list = {'order_id_list':[], 'trading_symbol':[]}
        let order_id_list = []
        scriptPayload.forEach(order_data => {
          if (order_data['order_side'] == 'BUY'){
            // sePeBuyPayload.push({url:"?exchange=" + order_data['exchange'] + "&instrument_token=" +order_data['instrument_token']+ "&order_side=" + order_data['order_side'] + "&order_type=MARKET&product=" + order_data['product'] + "&quantity="+order_data['quantity']+"&validity=DAY&user_order_id=1&client_id="+order_data['client_id'], trading_symbol:order_data['trading_symbol']})
            sePeBuyPayload.push({"complexty":"regular","exch":order_data['exchange'],"symbol_id":order_data['instrument_token'], "transtype":order_data['order_side'],"prctyp":"MKT", "pCode":order_data['product'], "qty":`${Math.abs(order_data['quantity'])}`, "ret":"DAY", "trading_symbol":order_data['trading_symbol']})
          }
          else{
            let payload = [{"complexty":"regular","exch":order_data['exchange'],"symbol_id":order_data['instrument_token'], "transtype":order_data['order_side'],"prctyp":"MKT", "pCode":order_data['product'], "qty":`${Math.abs(order_data['quantity'])}`, "ret":"DAY", "trading_symbol":order_data['trading_symbol']}]
            const sellOrderId = axios.post(placeOrderUrl, payload,{headers: header}).then((order)=>{
              return order.data[0]['NOrdNo']
            })
            console.log(order_data)
            response_list['order_id_list'].push(sellOrderId)
            response_list['trading_symbol'].push(order_data['trading_symbol'])
          }
        });
        console.log(response_list)
        if(sePeBuyPayload.length > 0){
            sePeBuyPayload.forEach(payload =>{
              const buyOrderId = axios.post(placeOrderUrl, [payload] ,{headers: header}).then((fut_res)=>{
                return fut_res.data[0]['NOrdNo']
              })
              response_list['order_id_list'].push(buyOrderId)
              response_list['trading_symbol'].push(payload['trading_symbol'])
            })
          }
        await Promise.allSettled(response_list['order_id_list']).then((results) => results.forEach((order,index)=> {
          order_id_list.push({'trading_symbol':response_list['trading_symbol'][index],'order_id':order.value})
        }))
        this.saveOrder(order_id_list,user_id, header)
        this.isSuccessVisible = true
        this.items = []
      },
      async saveOrder(order_list, user_id, header){
        let order_detail_list=[]
        for(let id of order_list){
          // let orderStatusUrl = `https://ant.aliceblueonline.com/api/v1/order/${id.order_id}/history?client_id=${user_id}`
          let orderStatusUrl = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/orderHistory"
          let status_payload = {"nestOrderNumber": id.order_id}
          let order_details_resp = axios.post(orderStatusUrl, status_payload, {headers:header}).then((res)=>{
            return res.data[0]
          })
          let order_detail
          await Promise.allSettled([order_details_resp]).then((results)=>{
            for(let order of results){
            order_detail = order.value
            }
          order_detail_list.push({'trading_symbol':id.trading_symbol,'response':order_detail})
        })
        }
        console.log(order_detail_list)
        // console.log(order_list)
        axios.post(saveOrderResponse,{order_list:order_detail_list, user_id:user_id}).then((res)=>{
          console.log(res)
          location.reload()
        })
      }, 
      close() {
        this.isDisabled = false
        this.items = []
        this.data = []
        this.$emit('close');
      },
    },
     computed: {
    compactMarketDataScripts: function() {
      return this.$store.getters.compactMarketDataScripts
    },
    largeModal() {
      if(this.stoploss && this.targetPrice) {
        return true
      }
      return false
    }
  },
    watch:{
      stockList(){
        
      },
      stoploss(newVal) {
			if(newVal && !this.headers.some(el => el.value === 'stoploss')) {
				this.headers.push(
					{ text: 'Stop Loss', value: 'stoploss' },
				)
			} else {
				const index = this.headers.findIndex(el => el.value === "stoploss");
				this.headers.splice(index, 1);
			}
		},
    targetPrice(newVal) {
			if(newVal && !this.headers.some(el => el.value === 'targetPrice')) {
				this.headers.push(
					{ text: 'Target Price', value: 'targetPrice' },
				)
			} else {
				const index = this.headers.findIndex(el => el.value === "targetPrice");
				this.headers.splice(index, 1);
			}
		},

    }
  };
</script>

<style lang="scss" scoped>
::v-deep .modal {
  &__header {
    background-color: #4aaf39;
    padding: 13px 40px 0px 40px !important;
    &_title {
      font-size: 20px;
      font-weight: 400;
    }
    .v-label {
      color: #fff !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
    .v-icon {
      color: #fff !important;
    }
  }
  &__body {
    padding: 13px 40px 24px 40px !important;
    &_btn {
      font-size: 14px;
      color: #979797;
      font-weight: 400;
      &-confirm {
        background-color: #0d4593 !important;
        padding: 0 24px !important;
        color: #fff;
      }
    }
    &_marginChip {
      font-size: 14px;
      background-color: #d1edff !important;
    }
    &_input {
      height: 24px;
      width: 92px;
      border: 1px solid #c5c5c5;
      border-radius: 4px;
      font-size: 12px;
      padding: 2px 4px;
    }
    &_input:focus {
      outline: none;
    }
    &_input::placeholder {
      color: #c5c5c5;
    }
    .v-input__slot {
      min-height: 24px !important;
      height: 24px;
      width: 110px;
      border: 1px solid #C5C5C5 !important;
      fieldset {
        border: none !important;
      }
    }
    .v-select__slot {
      .v-label {
        font-size: 12px;
        top: 2px;
      }
      .v-input__append-inner {
        margin-top: 0 !important;
        .v-icon {
          font-size: 18px;
        }
      }
      .v-select__selection {
        font-size: 12px;
      }
    }
		.v-data-table  {
			box-shadow: none !important;
		}
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
      border-bottom: none !important;
    }
    .text-start {
    padding: 5px 5px 5px 5px !important;
    }
  }
  &__footer {
    font-size: 12px;
    padding: 12px 40px 27px 40px !important;
    &_marginAmount {
      margin: 0 7px;
      color: #4aaf39;
    }
    &_marginError {
      color: #df514c;
    }
  }
}
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

    .btn-green {
      color: white;
      background: #4AAE9B;
      border: 1px solid #4AAE9B;
      border-radius: 2px;
    }
    .type-radio{
      opacity: 0.5;
    }
    .success-message{
      font-size: 20px;
      text-align: center;
    }
    .red-status{
    color: #df514c;
    text-transform: capitalize;
    font-weight: 550;
  }
  .green-status{
    color: #4aaf39;
    text-transform: uppercase;
    font-weight: 550;
  }
  .grey-status{
    color: #C5C5C5;
    text-transform: uppercase;
    font-weight: 550;
  }
  .close-btn{
    background-color:#df514c;
    color: #fff;
    margin-right: 2px;
  }
  .green-lighten{
    // background-color:#df514c;
    color:green
  }
  .container{
    width:70% !important
    // max-width:30px !important
  }
  .red-lighten{
    // background-color:#df514c;
    color:red
  }
  .centerAlign{
    text-align: center;
  }
  .leftAlign{
    text-align: center;
  }
  .rightAlign{
    text-align: center;
  }
  .greenColor{
    color: #80cc80
  }
  .redColor{
    color:#ff4949b3
  }
  .lightBlue{
    background-color: aliceblue
  }
  .lightBlue1{
    background-color: lightgreen
  }
  .lightGrey{
    color:grey;
  }
  .tableContent{
    color:grey;
    font-size:13px;
    padding: 5px;
  }
  .paddingLeft1{
    padding-left:40px;
  }
  .paddingLeft2{
    padding-left:30px;
  }
  .paddingLeft3{
    padding-left:10px;
  }
  .zero{
      color: #8B0000;
      
  }
  .one-text{
      color: #CE5B4A;
      
  }
  .two-text{ 
      color: #BE6648;
      
  }
  .three-text{
      color: #AD7046;
      
  }
  .four-text{
      color: #9D7B44;
      
  }
  .five-text{
      color: #8C8541;
      
  }
  .six-text{
      color: #7C903F;
      
  }
  .seven-text{
      color: #6B9A3D;
      
  }
  .eight-text{
      color: #5BA53B;
      
  }
  .nine-text{
      color: #4AAF39;
      
  }
  .ten-text{
      color:#54AF39;
      
  }
</style>