<template>
  <div class="container-holder">
    <!-- <PlaceOrderModal v-if="false" ref="placeOrderModal" :exchange="'NFO'" :data="modalData" :script="symbol"
      :lot_size="lot_size" @updateValue="updateCart" /> -->

    <PlaceOrderModal
      v-if="showPlaceOrderModal"
      :strategyCreator="orderData.strategyCreator"
      :selectedInstrument="orderData.selectedInstrument"
      :visible="showPlaceOrderModal"
      @close="showPlaceOrderModal = false"
      @clear-items="cart = []"
      :order_location="order_location"
    />

    <loaderModal :value="progressValue" v-if="showLoader">
      <template v-slot:body>
        <div class="info-text"><b>Hang on!</b> Fetching the data for you!</div>
        <v-progress-linear :value="progressValue"></v-progress-linear>
      </template>
    </loaderModal>
    <div class="main_container">
      <v-row>
        <v-col cols="12" class="title-text">
          <div>Straddle Premium</div>
        </v-col>
      </v-row>
      <v-row class="justify-space-between" style="margin-bottom: -35px">
        <v-col cols="12" md="3" lg="3">
          <el-input
            class="search_box"
            @input="searchFilter"
            v-model="search"
            size="mini"
            placeholder=" Search.."
            prefix-icon="mdi mdi-magnify"
          />
        </v-col>
        <v-col cols="0" md="5" lg="5"></v-col>
        <v-col cols="12" md="4" lg="4">
          <v-row :class="{ rf: !isMobile() }">
            <v-col>
              <div class="filter-box">
                <v-checkbox
                  @change="showNiftyFiftyFilter($event)"
                  label="NIFTY 50"
                >
                </v-checkbox>
                <span class="filter-box" v-if="customTop">
                  <v-checkbox
                    @change="showLixFilter($event)"
                    v-model="customTop"
                    label="LIX Above "
                  >
                  </v-checkbox>
                  <input
                    class="modal__body_input custom-input"
                    type="text"
                    @input="customFilter()"
                    @keypress="isNumberKey"
                    @keyup="numberRange"
                    v-model="customTopNum"
                  />
                </span>
                <span v-else>
                  <v-checkbox
                    @change="showLixFilter($event)"
                    v-model="customTop"
                    label="LIX Above 5"
                  >
                  </v-checkbox>
                </span>
                <!-- <span class="arrow-icon ml-1">
                  <img class="up-arrow-desktop" @click="increment('up')" height="12" width="12"
                    src="@/assets/caret-up.png" alt="" />
                  <img class="down-arrow-desktop" @click="increment('down')" height="12" width="12"
                    src="@/assets/caret-down.png" alt="" />
                </span> -->
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="update-text">
          <span class="time-title">Updated on:</span
          ><span class="time-text"> {{ updatedOn }}</span>
        </v-col>
      </v-row>
      <!-- TABLE -->
      <div @mouseleave="change_sb_var('scope.row.name')">
        <el-table
          style="width: 100%"
          class="analysis-table styled-scroll"
          :cell-class-name="classChecker"
          :data="show_list"
          @cell-mouse-enter="sb_var_change"
          row-class-name="table-row-background "
        >
          <!-- TICKER -->
          <el-table-column
            fixed
            :min-width="120"
            label="Ticker"
            prop="name"
            sortable
          >
            <template #default="scope">
              <div class="d-lg-flex justify-space-between">
                <div>
                  {{ scope.row.name }}
                </div>
                <span class="button-wrapper">
                  <button
                    style="background-color: var(--buy-bttn-bgd) !important"
                    class="buy-sell-opt-btn mr-1"
                    @click="openPlaceOrderModal(scope.row, 'b')"
                  >
                    <span
                      style="
                        color: var(--buy-button-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >B</span
                    >
                  </button>
                  <button
                    style="background-color: var(--sell-bttn-bgd) !important"
                    class="buy-sell-opt-btn mr-1"
                    @click="openPlaceOrderModal(scope.row, 's')"
                  >
                    <span
                      style="
                        color: var(--sell-button-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >S</span
                    >
                  </button>
                  <button
                    style="
                      background-color: var(--optn-background) !important;
                      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
                    "
                    title="Dashboard"
                    class="buy-sell-opt-btn mr-1"
                    @click="
                      redirectToInstrumentURL(scope.row.name, 'dashboard')
                    "
                  >
                    <v-icon
                      small
                      style="
                        color: var(--optionchain-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >mdi-view-dashboard-outline</v-icon
                    >
                  </button>

                  <button
                    style="
                      background-color: var(--optn-background) !important;
                      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
                    "
                    title="Option Chain"
                    class="buy-sell-opt-btn mr-1"
                    @click="
                      redirectToInstrumentURL(scope.row.name, 'option-chain')
                    "
                  >
                    <v-icon
                      small
                      style="
                        color: var(--optionchain-text);
                        font-family: 'Gilroy-SemiBold';
                        font-size: 0.625em;
                      "
                      >mdi-link-variant</v-icon
                    >
                  </button>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="85"
            align="center"
            label=""
            prop="liquidity"
            sortable
          >
            <template #header>
              LIX
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="tip-tool" v-bind="attrs" v-on="on">
                    <v-icon small style="margin-bottom: 7px">
                      mdi-information-outline
                    </v-icon>
                  </span>
                </template>
                <span>
                  Liquidity Index (LIX) indicates the liquidity of the given
                  instrument <br />ranging from 0 (low Liquidity) to 10 (High
                  Liquidity) at the given point.</span
                >
              </v-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="100"
            align="center"
            label="Lot Size"
            prop="lot_size"
            sortable
          >
            <template #default="scope">
              <span style="color: var(--primary-color)">{{
                scope.row.lot_size
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="80"
            align="center"
            label="Price"
            prop="price"
            sortable
          >
            <template #default="scope">
              {{ scope.row.price.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            :min-width="130"
            align="center"
            label="Price Change%"
            prop="price_change"
            sortable
          >
            <template #default="scope">
              <span
                :class="{
                  'green-text': scope.row.price_change < 0,
                  'red-text': scope.row.price_change >= 0,
                }"
              >
                {{
                  scope.row.price_change
                    ? scope.row.price_change.toFixed(2)
                    : "NA"
                }}%
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="145"
            align="center"
            label="Straddle Premium"
            prop="margin"
            sortable
          >
            <template #default="scope">
              {{ parseFloat(scope.row.margin).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            :min-width="135"
            align="center"
            label="Premium/Price%"
            prop="straddle"
            sortable
          >
            <template #default="scope">
              <span>{{ (scope.row.straddle * 100).toFixed(2) }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="135"
            align="center"
            label="Straddle Margin"
            prop="straddle_margin"
            sortable
          >
            <template #default="scope">
              {{ parseFloat(scope.row.straddle_margin).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column
            :min-width="145"
            align="center"
            label="Premium/Margin%"
            prop="premium_margin"
            sortable
          >
            <template #default="scope">
              <span>{{ scope.row.premium_margin.toFixed(2) }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="60"
            align="center"
            label="IV"
            prop="iv"
            sortable
          >
            <template #default="scope">
              <span>{{ scope.row.iv.toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :min-width="85"
            align="center"
            label=""
            prop="pop"
            sortable
          >
            <template slot="header">
              PoP
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="tip-tool" v-bind="attrs" v-on="on">
                    <v-icon small style="margin-bottom: 7px">
                      mdi-information-outline
                    </v-icon>
                    <!-- <svg style="width: 15px; height: 15px" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                      />
                    </svg> -->
                  </span>
                </template>
                <span>Probability of Profit for Short Straddle</span>
              </v-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
// import ReconnectingWebSocket from "reconnecting-websocket";

import { getPremiumDashboard } from "./getService";

import WebsocketMethodsMixin from "@/mixins/websocketMethodsMixin";
import UserDataMixin from "@/mixins/getUserDataMixin";

import PlaceOrderModal from "./modals/PlaceOrderModal.vue";
import loaderModal from "./loaderModal.vue";

export default {
  components: {
    loaderModal,
    PlaceOrderModal,
    // PlaceOrderModal2,
  },
  mixins:[UserDataMixin,WebsocketMethodsMixin],
  destroyed() {
    this.unsubscribeFromLocation(this.$route.name)//function form websocketMethodsMixin
  },
  data() {
    return {
      customTopNum: 5,
      customTop: false,
      updatedOn: "",
      showLoader: true,
      progressValue: 45,
      search: "",
      instrument_list: [],
      show_list: "",
      sb_var: "",

      // Websocket vars
      // newConnection: "",
      webSocketStatus: false,

      // new Placeorder vars
      orderData: { strategyCreator: [], selectedInstrument: {} },
      showPlaceOrderModal: false,
      order_location: "straddle_premium",
    };
  },
  created() {
    // this.startWebSocket();
    this.progressValue = 80;
    axios.post(getPremiumDashboard).then((res) => {
      this.progressValue = 100;
      this.instrument_list = res.data.instrument_list;
      // ((scope.row.margin * scope.row.lot_size)/(scope.row.straddle_margin)*100)
      this.instrument_list = this.instrument_list.map((item) => {
        item["price_change"] =
          ((item["price"] - item["price_change"]) / item["price"]) * 100;
        item["premium_margin"] =
          ((item["margin"] * item["lot_size"]) / item["straddle_margin"]) * 100;
        return item;
      });
      let nifty_list = this.instrument_list.filter(
        (data) =>
          (data.name.toLowerCase() == "NIFTY".toLowerCase()) |
          (data.name.toLowerCase() == "BANKNIFTY".toLowerCase()) |
          (data.name.toLowerCase() == "FINNIFTY".toLowerCase()) |
          (data.name.toLowerCase() == "MIDCPNIFTY".toLowerCase())
      );
      let others_list = this.instrument_list.filter(
        (data) =>
          (data.name != "NIFTY") &
          (data.name != "BANKNIFTY") &
          (data.name != "FINNIFTY") &
          (data.name != "MIDCPNIFTY")
      );
      this.instrument_list = [...nifty_list, ...others_list];

      this.show_list = this.instrument_list;
      let insList=[]
      this.instrument_list.forEach((ins) => {
        const list=[
          {exchange:"NSE",loc:this.$route.name,code:ins.underlying_code},
          {exchange:"NFO",loc:this.$route.name,code:ins.fut_code},
          {exchange:"NFO",loc:this.$route.name,code:ins.call_code},
          {exchange:"NFO",loc:this.$route.name,code:ins.put_code},
        ];
        insList=[...insList,...list];
        // this.subscribeLtp(ins.underlying_code);
        // this.subscribeLtp(ins.fut_code);
        // this.subscribeLtp(ins.call_code);
        // this.subscribeLtp(ins.put_code);
      });
      this.subscribeToInstruments(insList);//function form websocketMethodsMixin
      this.showLoader = false;
      this.updatedOn = this.instrument_list[0]?.updated_on;
    });
  },
  computed: {
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  methods: {
    print(a) {
      console.log(a);
    },
    redirectToInstrumentURL(ins, page) {
      let url = window.location.origin + `/${page}?trading_symbol=${ins}`;
      // if(ins.expiry) url+=`&expiry=${ins.expiry}`;
      window.open(url, "_blank");
    },
    numberRange(evt) {
      if (this.customTopNum > 10) {
        this.customTopNum = 10;
        evt.preventDefault();
      }
    },
    arrowRange() {
      if (this.customTopNum > 10) {
        this.customTopNum = 10;
      }
      if (this.customTopNum < 0) {
        this.customTopNum = 0;
      }
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    isNumberKey(evt) {
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      }
      return true;
    },
    increment(type) {
      this.customTop = true;
      if (type == "up") {
        this.customTopNum = parseInt(this.customTopNum + 1);
      }
      if (type == "down") {
        this.customTopNum = parseInt(this.customTopNum - 1);
      }
      this.arrowRange();
      this.customFilter();
    },
    searchFilter() {
      this.show_list = this.instrument_list.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    showLixFilter(event) {
      this.customTop = event;
      if (event) {
        this.show_list = this.instrument_list.filter(
          (data) => data.liquidity >= 5
        );
      } else {
        this.show_list = this.instrument_list;
      }
    },
    customFilter() {
      this.show_list = this.instrument_list.filter(
        (data) => data.liquidity >= this.customTopNum
      );
    },
    showNiftyFiftyFilter(event) {
      if (event) {
        this.show_list = this.instrument_list.filter(
          (data) =>
            data.nifty_50 == true ||
            data.name == "NIFTY" ||
            data.name == "BANKNIFTY" ||
            data.name == "FINNIFTY" ||
            data.name == "MIDCPNIFTY"
        );
      } else {
        this.show_list = this.instrument_list;
      }
    },
    openPlaceOrderModal(ins, type) {
      const strategyCreator = this.getCartObj(ins, type);
      const selectedInstrument = this.getSelectedInstrument(ins);
      this.$set(this.orderData, "strategyCreator", strategyCreator);
      this.$set(this.orderData, "selectedInstrument", selectedInstrument);
      this.showPlaceOrderModal = true;
    },
    getSelectedInstrument(instrument) {
      let ins = {
        name: instrument.name,
        lot_size: instrument.lot_size,
        underlying_code: instrument.underlying_code,
        fut_code: instrument.fut_code,
        exchange: "NSE",
        // trend: "Neutral",
        // trend_updated_on: "03 May 2023 14:01:05",
        // ltp: 18067.45,
        // fut_ltp: 18138.05,
        // tick_size: "0.05",
        // change_percent: -0.44,
        // fut_change_percent: -0.41
      };
      return ins;
    },
    getCartObj(instrument, type) {
      let cart = [];
      const qty = instrument.lot_size * (type == "s" ? -1 : 1);

      const ceLtp = parseFloat(
        this.compactMarketDataScripts(instrument.call_code).details[
          "Last Traded Price"
        ]
      ).toFixed(2);

      const peLtp = parseFloat(
        this.compactMarketDataScripts(instrument.put_code).details[
          "Last Traded Price"
        ]
      ).toFixed(2);

      // CE segment
      const ceOrder = {
        trading_symbol: instrument.call_atm_symbol,
        ltp: ceLtp,
        name: instrument.name,
        strike: instrument.strike_price,
        segment: "c",
        code: instrument.call_code,
        exchange: "NFO",
        expiry: instrument.expiry_date,
        quantity: qty,
        op_pr: ceLtp,
        formatted_ins_name: instrument.call_atm_symbol,
      };

      // PE segment
      const peOrder = {
        trading_symbol: instrument.put_atm_symbol,
        ltp: peLtp,
        name: instrument.name,
        strike: instrument.strike_price,
        segment: "p",
        code: instrument.put_code,
        exchange: "NFO",
        expiry: instrument.expiry_date,
        quantity: qty,
        op_pr: peLtp,
        formatted_ins_name: instrument.put_atm_symbol,
      };
      cart = [ceOrder, peOrder];
      return cart;
    },
    // selectForOrder(instrument, type) {
    //   console.log(instrument, type)
    //   this.getCartObj(instrument, type);
    //   this.getSelectedInstrument(instrument);
    //   this.showPlaceOrderModal = true;
    //   if (this.showPlaceOrderModal)
    //     return

    //   let quantity;
    //   if (type == "s") {
    //     quantity = -instrument.lot_size;
    //   } else if (type == "b") {
    //     quantity = instrument.lot_size;
    //   }
    //   this.cartOrders = [];

    //   // CE SEGMENT
    //   this.cartOrders.push({
    //     expiry: instrument.expiry_date, //same
    //     segment: "CE",
    //     strike_price: instrument.strike_price, //same
    //     name: instrument.name,
    //     trading_symbol: instrument.call_atm_symbol, //diff NIFTY22DEC11000CE:
    //     code: instrument.call_code, //diff 49546
    //     quantity: quantity,
    //     type: "MKT",
    //     op_pr: parseFloat(
    //       this.compactMarketDataScripts(instrument.call_code).details[
    //       "Last Traded Price"
    //       ]
    //     ).toFixed(2),
    //   });

    //   // PE SEGMENT
    //   this.cartOrders.push({
    //     expiry: instrument.expiry_date,
    //     segment: "PE",
    //     strike_price: instrument.strike_price,
    //     name: instrument.name,
    //     trading_symbol: instrument.put_atm_symbol, //NIFTY22DEC11000PE:
    //     code: instrument.put_code, //diff 49547
    //     quantity: quantity,
    //     type: "MKT",
    //     op_pr: parseFloat(
    //       this.compactMarketDataScripts(instrument.put_code).details[
    //       "Last Traded Price"
    //       ]
    //     ).toFixed(2),
    //   });
    //   this.selected_ins_fut_code = instrument.fut_code;
    //   this.placeOrder();
    // },
    // placeOrder() {
    //   this.lot_size = Math.abs(this.cartOrders[0].quantity);
    //   let balance = localStorage.getItem("balance");
    //   let name = this.cartOrders[0].name;
    //   this.modalData = {
    //     script: this.cartOrders,
    //     name: name,
    //     code: this.selected_ins_fut_code, //26000 try without
    //     title: "Strategy Creator",
    //     page: "straddle",
    //   };

    //   this.symbol = name;

    //   this.$refs.placeOrderModal.open(
    //     balance,
    //     this.cartOrders[0].expiry,
    //     name,
    //     this.lot_size
    //   );
    // },
    // updateCart(val) {
    //   this.cartOrders = val;
    // },
    change_sb_var(instrument_name) {
      this.sb_var = instrument_name;
    },
    sb_var_change(row) {
      this.sb_var = row.name;
    },
    classChecker({ row, column }) {
      if (column.property == "liquidity") {
        const val = row[column.property];
        if (val >= 0 && val < 1) {
          return "zero";
        }
        if (val >= 1 && val < 2) {
          return "one";
        }
        if (val >= 2 && val < 3) {
          return "two";
        }
        if (val >= 3 && val < 4) {
          return "three";
        }
        if (val >= 4 && val < 5) {
          return "four";
        }
        if (val >= 5 && val < 6) {
          return "five";
        }
        if (val >= 6 && val < 7) {
          return "six";
        }
        if (val >= 7 && val < 8) {
          return "seven";
        }
        if (val >= 8 && val < 9) {
          return "eight";
        }
        if (val >= 9) {
          return "nine";
        }
      }
    },

    // WEBSOCKET METHODS
    // startWebSocket() {
    //   let session_token = this.wsSession;
    //   let user_id = this.userId;
    //   this.newConnection = new ReconnectingWebSocket(
    //     "wss://f5zy.aliceblueonline.com/"
    //   );
    //   this.newConnection.send(
    //     JSON.stringify({
    //       susertoken: session_token,
    //       t: "c",
    //       actid: user_id + "_API",
    //       uid: user_id + "_API",
    //       source: "API",
    //     })
    //   );
    //   this.newConnection.onerror = function () {
    //     this.webSocketStatus = false;
    //     this.newConnection.close();
    //   };
    //   this.newConnection.onclose = function () {
    //     this.webSocketStatus = true;
    //     let today = new Date();
    //     if (today.getHours("hi") < 15) {
    //       setTimeout(() => {
    //         let session_token = this.wsSession;
    //         let user_id =this.userId;
    //         this.newConnection = new ReconnectingWebSocket(
    //           "wss://f5zy.aliceblueonline.com/"
    //         );
    //         this.newConnection.send(
    //           JSON.stringify({
    //             susertoken: session_token,
    //             t: "c",
    //             actid: user_id + "_API",
    //             uid: user_id + "_API",
    //             source: "API",
    //           })
    //         );
    //         this.webSocketStatus = true;
    //         this.newConnection.onmessage = this.messageSocket;
    //       }, 1000);
    //     } else {
    //       if (this.webSocketStatus) this.newConnection?.close();
    //     }
    //   };
    //   this.newConnection.addEventListener("open", function () {});
    //   this.newConnection.onmessage = this.messageSocket;
    // },

    // messageSocket(event) {
    //   let recv_data = JSON.parse(event.data);
    //     this.$store.commit("setcompactData", recv_data);
    // },

    // subscribeLtp(payload) {
    //   this.newConnection.send(
    //     JSON.stringify({
    //       k: `NFO|${payload}#NFO`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
  },
};
</script>

<style lang="scss">
@import "../assets/css/global.scss";

.el-table tr {
  background-color: var(--cards-background-color) !important;
}

.el-table__body tr.hover-row > td.el-table__cell {
  background-color: var(--table-row-hover) !important;
}

tr .button-wrapper {
  visibility: hidden;
}

tr:hover .button-wrapper {
  visibility: visible;
}

.container-holder {
  padding: 0;
  width: 100%;
  margin: auto;
  max-width: 1850px;
}

.title-text {
  // text-align: left;
  // font-size: 20px;
  /* Straddle Premium */

  width: auto;
  height: 29px;

  /* Gilroy-SemiBold (24)

Heading
*/
  font-family: "Gilroy-SemiBold";
  font-size: 1.5em;
  line-height: 16px;
  /* identical to box height */

  /* subtext */

  color: var(--text-color);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.range-text {
  margin-left: 6px;
  letter-spacing: 20.5px;
  font-size: 0.75em;
  margin-bottom: 20px;
}

.index-shape {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.filter-btn {
  text-align: right;
}

.filter-btn label {
  font-size: 0.75em;
}

.filter-btn i {
  font-size: 1.0625em !important;
}

.ten {
  letter-spacing: 1px;
}

.filter-box {
  display: flex;
}

.filter-box label {
  left: 0 px !important;
  color: var(--text-color) !important;
}

.custom-input {
  width: 35px;
  height: 20px;
  margin-left: 2px;
  margin-right: 2px;
  color: var(--lix-above5-text);
  background-color: var(--lix-above5-background);
  border: 0.5px solid var(--lix-above5-border);
  border-radius: 4px;
}

.time-title {
  // font-weight: 400;
  // font-size: 14px;
  // color: #979797;

  width: 245px;
  height: 16px;
  font-family: "Gilroy-Medium";
  font-size: 1em;
  line-height: 16px;
  /* identical to box height, or 100% */
  /* display: flex; */
  align-items: center;

  /* Sub-text */
  color: var(--clear-filter-color);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.time-text {
  // font-size: 14px;
  // color: #979797;

  width: 245px;
  height: 16px;
  font-family: "Gilroy-Medium";
  font-size: 1em;
  line-height: 16px;
  /* identical to box height, or 100% */
  /* display: flex; */
  align-items: center;

  /* Sub-text */
  color: var(--clear-filter-color);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.update-text {
  text-align: right;
}

.zero,
.one,
.two,
.three,
.four,
.five,
.six,
.seven,
.eight,
.nine,
.ten {
  .cell {
    color: var(--LIX-color) !important;
  }
}

.el-table__body tr.hover-row > td.el-table__cell.zero {
  background-color: var(--LIX-zero) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.one {
  background-color: var(--LIX-one) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.two {
  background-color: var(--LIX-two) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.three {
  background-color: var(--LIX-three) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.four {
  background-color: var(--LIX-four) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.five {
  background-color: var(--LIX-five) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.six {
  background-color: var(--LIX-six) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.seven {
  background-color: var(--LIX-seven) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.eight {
  background-color: var(--LIX-eight) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.nine {
  background-color: var(--LIX-nine) !important;
}

.el-table__body tr.hover-row > td.el-table__cell.ten {
  background-color: var(--LIX-ten) !important;
}

.zero {
  background-color: var(--LIX-zero) !important;
}

.one {
  background-color: var(--LIX-one) !important;
}

.two {
  background-color: var(--LIX-two) !important;
}

.three {
  background-color: var(--LIX-three) !important;
}

.four {
  background-color: var(--LIX-four) !important;
}

.five {
  background-color: var(--LIX-five) !important;
}

.six {
  background-color: var(--LIX-six) !important;
}

.seven {
  background-color: var(--LIX-seven) !important;
}

.eight {
  background-color: var(--LIX-eight) !important;
}

.nine {
  background-color: var(--LIX-nine) !important;
}

.ten {
  background-color: var(--LIX-ten) !important;
}

.search-field {
  margin-top: 40px;
}

.up-arrow-desktop {
  position: relative;
  bottom: 5px;
  cursor: pointer;
}

.down-arrow-desktop {
  position: relative;
  top: 5px;
  right: 12px;
  cursor: pointer;
}

/* .analysis-table table{
    table-layout: auto;
    width: 100%;
} */
.analysis-table thead th {
  background: var(--table-header-background) !important;
  font-size: 0.75em;
  padding: 1px 0 !important;
  color: var(--text-color);
}

.analysis-table thead th .cell,
.el-table .sort-caret.ascending {
  color: var(--text-color) !important;
}

.analysis-table tbody {
  font-size: 0.75em;
  color: var(--text-color);
}

.analysis-table tbody td {
  font-size: 0.75rem;
  padding: 5px 0 !important;
  color: var(--text-color);
}

.analysis-table tbody td .cell {
  color: var(--text-color);
}

.el-table .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--text-color);
}

.main_container {
  margin: 0px;
  border: 1.46049px solid var(--border-color);
  border-radius: 20px;
  background: var(--cards-background-color);
  padding: 24px;

  display: flex;
  flex-direction: column;
  flex: none;
  order: 2;
  flex-grow: 0;
}

label[for="nifty-fifty-checkbox"] {
  /* your styles here */
  /* NIFTY 50 */

  width: 81px;
  height: 16px;

  /* Gilroy Medium 20 */

  font-family: "Gilroy-Medium";
  font-size: 1.25em;
  line-height: 23px;
  display: flex;
  align-items: center;

  /* Text */

  color: var(--text-color);

  /* Inside auto layout */

  flex: none;
  order: 1;
}

.el-input__inner::placeholder {
  /* Search.. */
  /* width: 67px; */
  /* height: 22px; */

  /* Gilroy-Medium-18

Header
*/
  font-family: "Gilroy-Medium";
  font-size: 1.125em;
  line-height: 21px;
  display: flex;
  align-items: center;

  /* Sub-text */
  color: var(--clear-filter-color);

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.el-input {
  width: 175px !important;
  // height: auto;
}

.el-input__prefix {
  font-size: 1.25em;
}

.el-input--prefix .el-input__inner {
  padding-left: 30px;
  padding-top: 2px;
}

.search_box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 8px 56px 8px 12px; */
  gap: 12px;
  width: 175px;
  // height: 44px;
  background: var(--cards-background-color);
  border: 1.46049px solid var(--border-color);
  border-radius: 8px;
}

.table-row-background:nth-child(even) {
  background-color: var(--table-row-color-even) !important;
}

.table-row-background:nth-child(odd) {
  background-color: var(--table-row-color-odd) !important;
}

.el-table__row--blur:hover {
  filter: blur(1px);
  background: var(--table-row-hover) !important;
}

// .table-row-background:hover {
//   background-color: var(--table-row-color-hover) !important;
// }

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 0px !important;
}

.el-table .sort-caret.ascending {
  border-bottom-color: var(--sortable-colour) !important;
}

.el-table .sort-caret.descending {
  border-top-color: var(--sortable-colour) !important;
  bottom: 7px;
}

// .button-container {
//   position: absolute;
//   right: 0;
// }

@media screen and (min-width: 961px) and (max-width: 1850px) {
  .container-holder {
    font-size: 1rem;
  }
  .title-text {
    font-size: 1.6rem;
  }
  .analysis-table tbody td {
    font-size: 0.95rem;
  }
  .analysis-table thead th {
    font-size: 0.8rem;
  }
  .filter-box {
    font-size: 1rem !important;
  }
  .time-text {
    font-size: 1.1rem;
  }
  .time-title {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1271px) {
  .button-wrapper {
    white-space: nowrap;
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 1270px) {
  .button-wrapper {
    white-space: nowrap;
  }
}

@media screen and (max-width: 570px) {
  .search-field {
    margin-top: -48px;
    margin-bottom: 20px;
    margin-left: -120px;
  }

  .filter-btn {
    top: 20px;
    position: relative;
  }

  .nifty-fifty {
    position: relative;
    left: 150px;
    top: 5px;
  }

  .lix-five {
    position: relative;
    left: 105px;
  }

  .quality-text {
    margin-top: 20px;
    font-size: 0.75em;
    font-weight: 300;
  }

  .el-table .cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: normal !important;
    line-height: 23px;
  }

  /* #C0C4CC */
}

.rf {
  float: right;
}
</style>
<style scoped>
.buy-sell-opt-btn {
  /* color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  opacity: 0.85; */

  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 8px;

  width: 20.83px;
  height: 20.83px;

  /* BUY BG */

  /* background: var(--buy-background); */
  border-radius: 3.33333px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.buy-sell-opt-btn:hover {
  opacity: 1;
}
</style>
