export const getAccessToken="https://6629wqe1v1.execute-api.ap-south-1.amazonaws.com/default/getV2AccessToken";
export const aliceblueWebsocketUrl="wss://f5zy.aliceblueonline.com/";

export const aliceBlueLoginRedirectUrl="https://ant.aliceblueonline.com/?appcode=oIEVWdKO5H1L0GT";
export const aliceBlueSignUpRedirectUrl="https://alicebluepartner.com/open-myaccount/?P=WMPN760";

// WATCHLIST SEARCH FILES
export const getHoldingsSearchCSV = "https://1lyoptions.inuvest.tech/symbolmaster_nse.csv";
export const getNFOPositionSearchJSON = "https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster.json";
export const getMCXPositionSearchJSON="https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster_mcx.json";
export const getCDSPositionSearchJSON="https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster_cds.json";