// import { serviceURL } from "../store/store";

// export const getMarginCall = serviceURL + 'option_chain/option-span/'
// export const getGrpahDataCall = serviceURL + 'option_chain/'
// export const getCardMarginsCall = serviceURL + 'option_chain/card-margins/'
// export const getCardDataCall = serviceURL + 'option_chain/get-bullish/'
// export const getMoreCardsCall = serviceURL + 'option_chain/more-cards/'
// export const getNseStockCall = serviceURL + 'back_tests_api/get_nse_stocks/'
// export const getBalanceCall = serviceURL + 'option_chain/get-balance/'
// export const placeOrderCall = serviceURL + 'option_chain/place-order/'
// export const sendFeedback = serviceURL + 'option_chain/feedback/'
// export const getOptionStrategy = serviceURL + 'option_chain/option-strategy/'

const broker = localStorage.getItem('userObj')? (JSON.parse(localStorage.getItem('userObj'))).broker:'aliceblue'
export const BASE_URL =broker=='aliceblue'? "https://h9r3q3l70h.execute-api.ap-south-1.amazonaws.com/default":"https://1qged2u84g.execute-api.ap-south-1.amazonaws.com/default/"
export const getTrendsAndSymbolsUrl =broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getTrendFromSymbolUrl = broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getPrebuiltStrategyUrl = broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getCustomStrategyUrl = broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'

export const getStrategyCardsURL =  broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getOptionStrikes =  broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getMarginDataUrl =  BASE_URL+"/dashboard1lyoptions"
export const getStandardDeviationUrl =  broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const getMaxPainAndPCR = BASE_URL+"/dashboard1lyoptions"

export const getUserSettingsUrl = broker=='aliceblue'? BASE_URL+"/dashboard1lyoptions":BASE_URL+'/dashboardgreeksoft'
export const updateUserSettingsUrl = BASE_URL+"/dashboard1lyoptions"
export const getPendingOrdersUrl = BASE_URL+"/dashboard1lyoptions"

export const positionAnalyticsUrl = "https://jzfhu1tfy9.execute-api.ap-south-1.amazonaws.com/default/positions1lyoptions";
export const exitOrderUrl="https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/positionAndHoldings/sqrOofPosition"
export const getMarginUtilizedFromListUrl = "https://0y506z9aak.execute-api.ap-south-1.amazonaws.com/default/getMarginFromParam"

export const getPositionWatchlist = "https://xujgyvhzqg.execute-api.ap-south-1.amazonaws.com/default/getUpdatePositionWatchlist"
export const endpointVerifiedPL = "https://w5w12bmodl.execute-api.ap-south-1.amazonaws.com/default/verifiedPnL"
export const verifiedMetaDataUrl="https://verifiedby.inuvest.tech/verified/getVerifiedpnlMetaData"
export const announcementUrl="https://xu07h4gj94.execute-api.ap-south-1.amazonaws.com/default/announcementsAPI"
export const getUnderlyingStrikeChartDataUrl="https://oghcoxbteb.execute-api.ap-south-1.amazonaws.com/default/getCandleDataForSymbol"
export const getStrikeChartDataUrl="https://pz6cf70dw3.execute-api.ap-south-1.amazonaws.com/default/aliceblue_multistrike"
// export const getNFOPositionSearchCSV="http://1lyoptions-test.s3-website.ap-south-1.amazonaws.com/symbolmaster.csv";
// export const getNFOPositionSearchZIP="http://1lyoptions-test.s3-website.ap-south-1.amazonaws.com/symbolmaster.zip";

// export const getNFOPositionSearchZIP="https://1lyoptions.inuvest.tech/symbolmaster.zip";
export const getNFOPositionSearchCSV="https://1lyoptions.inuvest.tech/symbolmaster.csv";
export const getMCXPositionSearchCSV="https://1lyoptions.inuvest.tech/symbolmaster_mcx.csv";
export const getCDSPositionSearchCSV="https://1lyoptions.inuvest.tech/symbolmaster_cds.csv";
export const getHoldingsSearchCSV = "https://1lyoptions.inuvest.tech/symbolmaster_nse.csv";
export const getNFOPositionSearchJSON = "https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster.json";
export const getMCXPositionSearchJSON="https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster_mcx.json";
export const getCDSPositionSearchJSON="https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster_cds.json";
// Fast API
// export const getMarginCall = 'http://localhost:8000' + '/get_option_span/'
export const getMarginCall = 'https://vm3pnq12mj.execute-api.ap-south-1.amazonaws.com/default/getOptionSpan'

// export const getGrpahDataCall = 'http://localhost:8000' + '/strategy_builder/'
export const getGrpahDataCall = 'https://vc7l58x9of.execute-api.ap-south-1.amazonaws.com/default/strategyBuilder'

// export const getCardMarginsCall = 'http://localhost:8000' + '/card_margins/'
export const getCardMarginsCall = 'https://d4l4l4p5gb.execute-api.ap-south-1.amazonaws.com/default/cardMargins'

// export const getCardDataCall = 'http://localhost:8000' + '/get_cards/'
export const getCardDataCall = 'https://jsyh41dlai.execute-api.ap-south-1.amazonaws.com/default/getCards'

// export const getMoreCardsCall = "http://localhost:8000" + '/get_more_cards/'
export const getMoreCardsCall = 'https://7xufpyule9.execute-api.ap-south-1.amazonaws.com/default/getMoreCards'

// export const getNseStockCall = 'http://localhost:8000' + '/get_nse_stocks/'
export const getNseStockCall = 'https://fatziwbqn3.execute-api.ap-south-1.amazonaws.com/default/getNseStocks'

export const getBalanceCall = 'https://r3scqnghfc.execute-api.ap-south-1.amazonaws.com/default/getBalanceV2'
// export const placeOrderCall = serviceURL + '/place_order/'
export const placeOrderCall = 'https://r6oy8l2eo1.execute-api.ap-south-1.amazonaws.com/default/placeOrder'

export const sendFeedback = 'https://beta.inuvest.tech/option_chain/feedback/'

// export const getOptionStrategy = "http://localhost:8000" + '/option_strategy/'
export const getOptionStrategy = 'https://047vrb11v2.execute-api.ap-south-1.amazonaws.com/default/optionStrategy'

// export const getPositions = serviceURL + '/get_positions_from_broker/'
// export const getPositions = 'https://awi3a9kgk6.execute-api.ap-south-1.amazonaws.com/default/getPositionsFromBroker'

export const getPositions = "https://hfndg4ge4l.execute-api.ap-south-1.amazonaws.com/default/getV2Positions"
// export const getPositions = 'http://localhost:8000/v2_get_positions_from_broker/'
// export const getAccessToken = serviceURL + '/get_access_token/'

// export const getAccessToken = 'https://r0nt0belfg.execute-api.ap-south-1.amazonaws.com/default/getAccessToken'
export const getAccessToken = 'https://6629wqe1v1.execute-api.ap-south-1.amazonaws.com/default/getV2AccessToken'


// export const getOrderList = serviceURL + '/order_list/'
export const getOrderList = 'https://b4cg6bweq7.execute-api.ap-south-1.amazonaws.com/default/orderList'
// export const exportOrderList = serviceURL + '/import_orders/'
export const exportOrderList = 'https://qxst0lplrj.execute-api.ap-south-1.amazonaws.com/default/importOrders'

// export const getTradingSymbol = 'http://localhost:8000' + '/get_trading_symbol'
export const getTradingSymbol = 'https://vblhzokij5.execute-api.ap-south-1.amazonaws.com/default/getTradingSymbol'

// export const saveOrderResponse = 'https://kat9dw4gs7.execute-api.ap-south-1.amazonaws.com/default/importOrdersFromResponse'
export const saveOrderResponse = "https://c3204cl4zb.execute-api.ap-south-1.amazonaws.com/default/importV2OrderResponse"
export const getPositionCardMargin = 'https://0y506z9aak.execute-api.ap-south-1.amazonaws.com/default/getMarginFromParam'

export const getMarginBasedOption = 'https://8i0yxxwsia.execute-api.ap-south-1.amazonaws.com/default/getMarginBasedOptions'
// export const getMarginBasedOption = 'http://localhost:8000/get_margin_based_options'

// export const getTradingNseSymbols = 'http://localhost:8000/get_nse_symbols/'
export const getTradingNseSymbols = 'https://2nyo68bg0h.execute-api.ap-south-1.amazonaws.com/default/getNseTradingSymbols'

// export const getPremiumDashboard = 'http://localhost:8000/premium_dashboard/'

export const getPremiumDashboard = 'https://n0ayaw9s9g.execute-api.ap-south-1.amazonaws.com/default/getPremiumDashboard'

// export const getLixData = "http://localhost:8000/get_lix_value/"
export const getLixData = "https://o4iydbgbf7.execute-api.ap-south-1.amazonaws.com/default/getLixValue"

export const getOpenInterestTable = 'https://bsl2cdincl.execute-api.ap-south-1.amazonaws.com/default/getOpenInterestData'

export const getPendingOrderList = 'https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/placeOrder/fetchOrderBook'

export const getScripQuoteDetails = "https://ant.aliceblueonline.com/rest/AliceBlueAPIService/api/ScripDetails/getScripQuoteDetails"

export const getFutureOIDate = 'https://ikmx1pigo5.execute-api.ap-south-1.amazonaws.com/default/maxDateOpenInterest'

export const calculatePoP = 'https://c9f69yk2y9.execute-api.ap-south-1.amazonaws.com/default/calculatePoP'

// export const saveStrategyCall = 'http://localhost:8000/save_strategy/'
export const saveStrategyCall = "https://stq5adndjf.execute-api.ap-south-1.amazonaws.com/default/savestrategy"

// export const getSaveStrategyCall = 'http://localhost:8000/get_strategies_structure/'
export const getStrategyStructure = "https://0qw4371vg7.execute-api.ap-south-1.amazonaws.com/default/getStrategiesStructure"

export const getTrendFromSymbol = "https://m068i1243i.execute-api.ap-south-1.amazonaws.com/default/getTrendFromSymbol"

export const getDeleteStrategy = "https://f72l3tq5k3.execute-api.ap-south-1.amazonaws.com/default/deleteStrategy"

export const getWsToken = "https://zphr9jocdl.execute-api.ap-south-1.amazonaws.com/default/getWsSession"

export const getStrikePriceList = "https://eeo045bat9.execute-api.ap-south-1.amazonaws.com/default/getOptionChainData"
// export const getStrikePriceListV2 = "http://localhost:8000/backtest/getComputedValue/"
export const getStrikePriceListV2 = "https://beta.inuvest.tech/backtest/getComputedValue/"

export const getBrokrage = "https://abbrk.aliceblueonline.com/rest/BrokerageCalculationService/brokerage/calculateBrokerage"

export var snackBar= false

export const compare = (a,b)=>{
    if(a.strike < b.strike_price){
        return -1;
    // a should come after b in the sorted order
    }else if(a.strike_price > b.strike_price){
            return 1;
    // a and b are the same
    }else{
            return 0;
    }
}

export const getClosest = (num, arr)=>{
    let curr = arr[1]['strike_price'], diff = Math.abs(num - curr);
   let index = 0;
   for (let val = 0; val < arr.length - 1; val++) {
        if(arr[val]['strike_price'] != 0){
            let newdiff = Math.abs(num - arr[val]['strike_price']);
        if (newdiff < diff) {
                diff = newdiff;
                curr = arr[val];
                index = val;
      }
        }
   }
   return arr[index];
}

export const getStrike = (num, arr)=>{
    let curr = arr[1], diff = Math.abs(num - curr);
   let index = 0;
   for (let val = 0; val < arr.length - 1; val++) {
        if(arr[val] != 0){
            let newdiff = Math.abs(num - arr[val]);
            if (newdiff != 0 && newdiff < diff) {
                diff = newdiff;
                curr = arr[val];
                index = val;
            }
        }
        
   }
   return arr[index];
}