<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      v-model="menuVisible"
      offset-y
      right
      content-class="select-instrument-menu"
      origin="left top"
      transition="scale-transition"
    >
      <!-- MENU ACTIVATOR -->
      <template v-slot:activator="{ on, attrs }">
        <div class="select-instrument-input-text">
          <v-text-field
            ref="input"
            v-bind="attrs"
            v-on="on"
            v-model="searchedText"
            filled
            outlined
            dense
            hide-details
            maxlength="25"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            clear-icon="mdi-close-circle"
          >
            <template v-slot:append>
              <div class="pa-1" style="font-size: 0.9em">
                {{ watchlistLength }}/50
              </div>
            </template>
          </v-text-field>
        </div>
      </template>

      <!-- Loading -->
      <div v-if="isSearching" class="text-center pa-3">
        <v-progress-linear
          indeterminate
          color="var(--primary-button-color)"
        ></v-progress-linear>
      </div>

      <!-- MAIN SECTION-->
      <div v-else class="default-style white-card ma-0 wrapper-selecton">
        <!-- HEADING AND CLOSE BUTTON -->
        <div class="d-flex justify-space-between my-2">
          <div>
            <strong class="mx-2" @click="print(filteredList)"
              >Add Instrument</strong
            >
          </div>
          <div>
            <v-icon class="close-icon" @click="menuVisible = false"
              >mdi-close</v-icon
            >
          </div>
        </div>

        <!-- FILTER BUTTONS FOR EXCHANGE TYPE -->
        <div class="buttons-section my-3 d-flex justify-start">
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == null }" @click="selectedExchange=null">ALL</button>
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == 'NFO' }" @click="selectedExchange='NFO'">NFO</button>
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == 'MCX' }" @click="selectedExchange='MCX'">MCX</button>
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == 'CDS' }" @click="selectedExchange='CDS'">CDS</button>
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == 'NSE' }" @click="selectedExchange='NSE'">NSE</button>
          <button class="mx-2 ex-btn" :class="{ 'active-tab': selectedExchange == 'BSE' }" @click="selectedExchange='BSE'">BSE</button>
        </div>

        <!-- FILTERED LIST -->
        <div class="list-container">
          <!-- EMPTY LIST HANDELING -->
          <div
            v-if="filteredList.length < 1"
            class="pa-3"
            style="font-size: 0.8rem"
          >
            {{searchedText?'No Match Found':'Enter at least 3 characters to search'}}
          </div>

          <!-- FILTERED ITEMS LIST -->
          <v-data-table
            v-else
            class="select-instrument-selection-table default-style"
            :items="filteredList"
            height="325px"
            fixed-header
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item="i">
              <tr
                @click="$emit('add-instrument', i.item)"
                @click.right="print(i.item)"
                style="cursor: pointer"
                :class="{ 'active-list-item': false }"
              >
                <td>
                  {{ i.item.formatted_ins_name | removeQuotes }}
                  <v-chip
                    v-if="i.item.exchange"
                    x-small
                    label
                    class="ma-2"
                    style="
                      background-color: var(--dialog-chips);
                      color: inherit;
                    "
                    >{{ i.item.exchange }}</v-chip
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
import {
  getMCXPositionSearchJSON as abMCXSearch,
  getCDSPositionSearchJSON as abCDSSearch,
  getHoldingsSearchCSV as abHoldingsSearch,
  getNFOPositionSearchJSON as abNFOSearch
} from "@/utils/aliceBlueUrls";
import {
  getGreeksoftNFOPositionSearchJSON as gsNFOSearch
} from "@/utils/greeksoftUrls";

import { POSITION_CACHE_NAME } from "@/utils/constants";
export default {
  props: {
    showSearchMenu: {
      type: Boolean,
    },
    watchlistLength: {
      default: () => {
        return null;
      },
    },
  },
  emits: ["add-instrument", "menu-close", "menu-open"],
  data() {
    return {
      searchedText: "",
      searchTimeout: null,
      isSearching: false,

      filteredList: [],
      selectedExchange: null,
      menuVisible: false,
    };
  },
  computed:{
    isAliceblue(){
      return this.$store.state.isAliceblue;
    }
  },
  watch: {
    searchedText() {
      this.getSearchInstruments();
    },
    selectedExchange() {
      this.getSearchInstruments();
      this.$refs.input.focus();
    },
    showSearchMenu(newVal) {
      this.menuVisible = newVal;
      this.$refs.input.focus();
    },
    menuVisible(newVal) {
      if (newVal == false) {
        // this.searchedText='';
        // this.filteredList=[];
        this.$emit("menu-close");
      } else {
        this.$emit("menu-open");
        this.getSearchInstruments();
      }
    },
  },
  methods: {
    print(val) {
      console.log(val);
    },
    // Instruments SEARCH LOGIC section STARTS
    getSearchInstruments() {
      if (this.menuVisible == false || this.searchedText.trim().length < 3) {
        if(this.searchedText=='') this.filteredList=[];
        return;
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.isSearching = true;

      this.searchTimeout = setTimeout(() => {
        this.searchForInstruments().then(() => {
          clearTimeout(this.searchTimeout);
          this.isSearching = false;
          this.searchTimeout = null;
        });
      }, 400);
    },
    async searchForInstruments() {
      const exchange = this.selectedExchange;
      const searchedText = this.searchedText;
      let instrumentsList = [];
      try {
        if (exchange) {
          instrumentsList = await this.returnQueriedInstruments(
            searchedText,
            exchange,
            30
          );
        } else {
          const listNFO = await this.returnQueriedInstruments(searchedText,'NFO',30);
          const listMCX = await this.returnQueriedInstruments(searchedText,'MCX',30);
          const listCDS = await this.returnQueriedInstruments(searchedText,'CDS',30);
          const listHoldings = await this.returnQueriedInstruments(searchedText,'NSE',30);
          instrumentsList=[...listNFO, ...listMCX, ...listCDS, ...listHoldings];
        }
      } catch (error) {
        console.error(error)
      }
      this.filteredList = instrumentsList;
    },
    returnQueriedInstruments(searchText, exchange, limit) {
      const isAB = this.isAliceblue;
      const csvDict = {
        'MCX': isAB ? abMCXSearch : undefined,
        'NFO': isAB ? abNFOSearch : gsNFOSearch,
        'CDS': isAB ? abCDSSearch : undefined,
        'NSE': isAB ? abHoldingsSearch : undefined,
        'BSE': isAB ? abHoldingsSearch : undefined,
      };
      if (csvDict[exchange] == undefined) return [];

      const cacheName = POSITION_CACHE_NAME;
      const url = csvDict[exchange];
      return this.getCachedData(cacheName, url).then((data) => {
        if(!data) return [];
        let instruments;
        const isJson=url.toLowerCase().endsWith('json');
        if(isJson) instruments=JSON.parse(data);
        else instruments = this.csvJSON(data);

        const searchItemsArr = searchText.trim().split(" ");

        // Filter based on search string array
        instruments = instruments.filter((el) => {
          const testEnteredMatch = searchItemsArr.every((searchItem) =>
            el.formatted_ins_name?.includes(searchItem.toUpperCase())
          );
          return testEnteredMatch;
        });

        // First search string priority
        const searchResStart=[];
        const searchResEnd=[];
        instruments.forEach((el) => {
          if(el.formatted_ins_name.replace(/['"]+/g, "").startsWith(searchItemsArr[0].toUpperCase()))
            searchResStart.push(el);
          else searchResEnd.push(el);
        })
        instruments=[...searchResStart, ...searchResEnd];

        // top n instruments
        if (instruments.length > limit) {
          instruments = instruments.slice(0, limit);
        }
        // FILTERING BASED ON EXCHANGE EXCLUSIVE FOR NSE AND BSE
        instruments.forEach(el => el['exchange'] = el.exchange?el.exchange:exchange);
        if(this.selectedExchange=='BSE' || this.selectedExchange=='NSE'){
          instruments=instruments.filter(el => el.exchange == this.selectedExchange);
        }
        return instruments;
      });
    },
    async getCachedData(cacheName, url) {
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(url);
      if (!cachedResponse || !cachedResponse.ok) {
        return false;
      }
      return await cachedResponse.text();
    },
    csvJSON(csvStr) {
      let lines = csvStr.split("\n");
      let result = [];
      let headers = lines[0].split(",");
      for (let i = 1; i < lines.length; i++) {
        let obj = {};
        let currentline = lines[i].split(",");
        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
      return result;
    },
    // Instruments SEARCH LOGIC section ENDS
  },
  filters: {
    removeQuotes: function (str) {
      if (str) {
        return str.replace(/['"]+/g, "");
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.close-icon.v-icon.v-icon,
.search-icon.v-icon.v-icon {
  color: var(--text-color);
}
.select-instrument-menu {
  background-color: var(--cards-background-color);
  border-radius: 12px;
}
.select-instrument-input-text input,
.select-instrument-menu {
  font-size: 0.9rem;
}
.ins-menu-section {
  overflow: hidden;
  padding: 4px 7px;
  // max-width: 149px;
  font-size: 1.1em;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.ins-menu-section:hover {
  border-color: var(--text-color);
}
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  // padding: 0 1rem;
  color: var(--text-color);
  background-color: var(--dialog-background);
}
.wrapper-selecton {
  background-color: var(--dialog-background);
  padding: 12px 18px;
}
.buttons-section {
  .ex-btn {
    height: 2.8em;
    padding: 0.8em;
    background-color: var(--chip-background);
    border-radius: 6px;
    font-size: 0.78em;
    width: 5em;
  }
  .ex-btn:hover {
    background-color: var(--table-hover-row);
  }
  .active-tab {
    background-color: var(--primary-button-color) !important;
    color: var(--primary-button-text-color) !important;
  }
}
.list-container {
  box-sizing: border-box;
  .v-data-table__wrapper {
    thead th {
      background-color: var(--chip-background) !important;
    }
    tbody tr {
      background-color: var(--dialog-background);
    }
    tbody tr:nth-child(even) {
      background-color: var(--chip-background) !important;
    }
    th,
    td {
      font-size: 1em !important;
      height: 4em !important;
      border-bottom: none !important;
    }
    tbody tr.active-list-item {
      background-color: var(--selected-row);
      color: var(--primary-button-color);
    }
  }
}
@media screen and (min-width: 960px) and (max-width: 1850px) {
  .select-instrument-input-text input,
  .select-instrument-menu {
    font-size: 0.9rem !important;
  }
}
@media screen and (min-width: 601px) and (max-width: 960px) {
  .select-instrument-input-text input,
  .select-instrument-menu {
    font-size: 1.5rem !important;
  }
}
@media screen and (max-width: 600px) {
  .select-instrument-input-text input,
  .select-instrument-menu {
    font-size: 0.85rem !important;
  }
}
</style>
<style>
@import "../../../assets/css/vuetify-components.scss";
/* ADDED TO GLOBAL CSS
  HENCE long name to not affect any other component
*/
.select-instrument-selection-table
  > div.v-data-table__wrapper::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}
.select-instrument-selection-table > div.v-data-table__wrapper {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
</style>
