import moment from "moment";
import { calculateTargetDays } from "../../Dashboard/dashboardService.js";
import axios from "axios";
import { getLixData } from "../../getService.js";
//this function is used to get the data for place order modal
export const getPlaceOrderData = (item, net_quantity=0, value, buySellType = null) => {
  if (value == "creator") {
    let strategyCreatorList = [];
    strategyCreatorList.push({
      trading_symbol: item.trading_symbol,
      formatted_ins_name: item.formatted_ins_name,
      strike_price: item.strike_price,
      strike: item.strike_price,
      segment:
        item.param_list.segment == "PE"
          ? "p"
          : item.param_list.segment == "CE"
          ? "c"
          : "fut",
      name: item.symbol,
      exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
      ltp: item.ltp,
      expiry:item.expiry_date,
      quantity:
        buySellType == null
          ? net_quantity
          : buySellType == "buy"
          ? item.lot_size
          : -item.lot_size,
      code: item.code,
      prod_type:item.prod_type,
    });
    return strategyCreatorList;
  } else {
    let selectedInstrument = {
      name: item.symbol,
      exchange: item.exchange,
      lot_size: item.lot_size,
      tick_size: item.tick_size,
      underlying_code: item.underlying_code,
      fut_code: item.fut_code,
    };
    return selectedInstrument;
  }
};
// this function is used to get the profit loss for the position analyze by symbol and hedge
export const getProfitLossForPosition = (instrument_obj, ltp) => {
  let quantity = instrument_obj["net_quantity"];
  // if (instrument_obj.symbol == "CRUDEOIL" || instrument_obj.symbol == "GOLD") {
  //   quantity = quantity * 100;
  // } else if (instrument_obj.symbol == "GOLDM") {
  //   quantity = quantity * 10;
  // } else if (instrument_obj.symbol == "SILVER") {
  //   quantity = quantity * 30;
  // } else if (instrument_obj.symbol == "SILVERM") {
  //   quantity = quantity * 5;
  // } else if (instrument_obj.symbol == "NATURALGAS") {
  //   quantity = quantity * 1250;
  // }

  let profitLossData = "";
  let avg_price;
  if (quantity < 0) {
    if (instrument_obj["average_sell_price"]) {
      avg_price = instrument_obj["average_sell_price"];
    } else {
      avg_price = instrument_obj["actual_average_sell_price"];
    }
    profitLossData = (avg_price - ltp) * Math.abs(quantity);
  } else {
    if (instrument_obj["average_buy_price"]) {
      avg_price = instrument_obj["average_buy_price"];
    } else {
      avg_price = instrument_obj["actual_average_buy_price"];
    }
    profitLossData = (ltp - avg_price) * quantity;
  }
  return profitLossData.toFixed(2);
};

//this function is used to get the data for chart in position
export const getPayOffChartData = (item, selectedInstrument, spot_price) => {
  let param_list = [
    {
      trading_symbol: item.trading_symbol,
      formatted_ins_name: item.formatted_ins_name,
      strike_price: item.strike_price,
      strike: item.strike_price,
      segment:
        item.param_list.segment == "PE"
          ? "p"
          : item.param_list.segment == "CE"
          ? "c"
          : "fut",
      name: item.symbol,
      exchange: item.exchange == "NSE" ? "NFO" : item.exchange,
      ltp: Math.abs(item.ltp),
      expiry: item.expiry_date,
      quantity: item.net_quantity,
      code: item.code,
    },
  ];
  const payloadForChart = {
    user_id: localStorage.getItem("user_id"),
    access_token: localStorage.getItem("accessTokenAnt"),
    ws_session: localStorage.getItem("ws_session"),
    param_list: param_list,
    path: "custom_strategy",
    broker: "aliceblue",
    name: selectedInstrument.name,
    lot_size: selectedInstrument.lot_size,
    exchange:
      selectedInstrument.exchange == "NSE"
        ? "NFO"
        : selectedInstrument.exchange,
    days_to_expire: calculateTargetDays(param_list),
    spot_price: spot_price,
    target_days: 0,
    iv_percent: 0,
  };
  return { payload: payloadForChart };
};

//this function returns lix value in order to calculate the liquidity
export const getLixValue = (param_list) => {
  param_list.expiry = moment(param_list.expiry, "YYYY-MM-DD").format(
    "DD MMM YYYY"
  );
  let payload = {
    user_id: localStorage.getItem("user_id"),
    access_token: localStorage.getItem("accessTokenAnt"),
    ws_token: localStorage.getItem("ws_session"),
    param_list: [param_list],
  };

  axios.post(getLixData, payload).then((res) => {
    if (res.data.param_list[0].lix_value < 5) {
      return true;
    } else {
      return false;
    }
    // return res.data.param_list[0].lix_value
  });
};