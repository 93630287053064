<template>
  <div>
    <v-dialog :fullscreen="$vuetify.breakpoint.xs" v-model="show" width="900">
      <template>
        <v-card>
          <div class="pa-4 graph-days-modal">
            <!-- Header Section -->
            <div
              class="d-flex justify-space-between align-center"
              v-if="!isPositionGraph"
            >
              <div>
                <span style="color: #575757">{{
                  selectedInstrument.name
                }}</span>
              </div>
              <v-btn icon @click.stop="show = false">
                <v-icon style="color: var(--text-color)">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters v-if="isPositionGraph">
              <v-col cols="12" class="d-block d-sm-none text-right pa-0">
                <v-btn icon @click.stop="show = false">
                  <v-icon style="color: var(--text-color)">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="isPositionGraph">
              <v-col cols="4" class="text-center upside-downside-labels">
                <div>Unrealized P&L</div>
                <span v-if="isDefaultPositions">
                  <span
                    v-if="
                      getPLUpsideDownside(
                        'unrealisedPL',
                        positionAnalyticsData
                      ) == 'Unlimited'
                    "
                    class="red-text"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        getPLUpsideDownside(
                          'unrealisedPL',
                          positionAnalyticsData
                        ) > 0
                      "
                      class="green-text"
                    >
                      +</span
                    ><span
                      :class="
                        getPLUpsideDownside(
                          'unrealisedPL',
                          positionAnalyticsData
                        ) >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                      >{{
                        getPLUpsideDownside(
                          "unrealisedPL",
                          positionAnalyticsData
                        )
                      }}
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="getTotalUpsideDown('unrealisedPL') == 'Unlimited'"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="getTotalUpsideDown('unrealisedPL') > 0"
                      class="green-text"
                      >+</span
                    >
                    <span
                      :class="
                        getTotalUpsideDown('unrealisedPL') >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                    >
                      {{ getTotalUpsideDown("unrealisedPL") }}
                    </span>
                  </span>
                </span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="4" sm="3" class="text-center upside-downside-labels">
                <div>Expected Upside
                  <v-tooltip
                  content-class="header-tooltips"
                  top
                  color="var(--cards-background-color)"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="info-icon">
                      <v-icon>mdi-information-variant-circle-outline</v-icon>
                    </span>
                  </template>
                  <span
                    >Additional profit that can be generated until expiry if the positions are favourable.</span
                  >
                </v-tooltip>
                </div>
                <span v-if="isDefaultPositions">
                  <span
                    v-if="
                      getPLUpsideDownside('upside', positionAnalyticsData) ==
                      'Unlimited'
                    "
                    class="green-text"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        getPLUpsideDownside('upside', positionAnalyticsData) > 0
                      "
                      class="green-text"
                    >
                      +</span
                    ><span
                      :class="
                        getPLUpsideDownside('upside', positionAnalyticsData) >=
                        0
                          ? 'green-text'
                          : 'red-text'
                      "
                      >{{
                        getPLUpsideDownside("upside", positionAnalyticsData)
                      }}
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="getTotalUpsideDown('upside') == 'Unlimited'"
                    class="green-text"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="getTotalUpsideDown('upside') > 0"
                      class="green-text"
                      >+</span
                    >
                    <span
                      :class="
                        getTotalUpsideDown('upside') >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                    >
                      {{ getTotalUpsideDown("upside") }}
                    </span>
                  </span>
                </span>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="4" sm="4" class="text-center upside-downside-labels">
                <div>Expected Downside
                  <v-tooltip
                  content-class="header-tooltips"
                  top
                  color="var(--cards-background-color)"
                  max-width="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="info-icon">
                      <v-icon>mdi-information-variant-circle-outline</v-icon>
                    </span>
                  </template>
                  <span
                    >Loss that would be incurred until expiry if the market moves against the positions.</span
                  >
                </v-tooltip>
                </div>
                <span v-if="isDefaultPositions">
                  <span
                    v-if="
                      getPLUpsideDownside('downside', positionAnalyticsData) ==
                      'Unlimited'
                    "
                    class="red-text"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="
                        getPLUpsideDownside('downside', positionAnalyticsData) >
                        0
                      "
                      class="green-text"
                    >
                      +
                    </span>
                    <span
                      :class="
                        getPLUpsideDownside(
                          'downside',
                          positionAnalyticsData
                        ) >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                    >
                      {{
                        getPLUpsideDownside("downside", positionAnalyticsData)
                      }}
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span
                    v-if="getTotalUpsideDown('downside') == 'Unlimited'"
                    class="red-text"
                  >
                    Unlimited
                  </span>
                  <span v-else>
                    <span
                      v-if="getTotalUpsideDown('downside') > 0"
                      class="green-text"
                      >+</span
                    >
                    <span
                      :class="
                        getTotalUpsideDown('downside') >= 0
                          ? 'green-text'
                          : 'red-text'
                      "
                    >
                      {{ getTotalUpsideDown("downside") }}
                    </span>
                  </span>
                </span>
              </v-col>
              <v-col cols="1" class="d-none d-sm-flex justify-end">
                <v-btn icon @click.stop="show = false">
                  <v-icon style="color: var(--text-color)">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div class="graph-modal greeks-header" v-if="isPositionGraph">
              <v-tabs height="30px" color="var(--primary-button-color)">
                <v-tab @click="selectedGreeksTab = 'payoffChart'"
                  >Payoff Chart</v-tab
                >
                <v-tab @click="selectedGreeksTab = 'greeks'">Greeks</v-tab>
              </v-tabs>
            </div>

            <!-- Graph Section -->
            <div
              class="graph-days pa-0 py-2 pa-sm-3 mb-3 mt-2"
              v-if="selectedGreeksTab == 'payoffChart'"
            >
              <Graph
                :prebuiltStrategyData="prebuiltStrategyData"
                :selectedInstrument="selectedInstrument"
              />
            </div>
            <!-- GREEKS -->
            <div class="graph-days pa-0 py-2 pa-sm-3 mb-3 mt-2" v-else>
              <v-simple-table>
                <template v-slot:default>
                  <thead class="greeks-table-header">
                    <tr>
                      <th class="text-left">Instrument</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">B/S</th>
                      <th class="text-center">IV</th>
                      <th class="text-center">Delta</th>
                      <th class="text-center">Theta</th>
                      <th class="text-center">Gamma</th>
                      <th class="text-center">Vega</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="greeks-table-rows"
                      style="border-bottom: 1px solid var(--border-color)"
                      v-for="(greek, index) in prebuiltStrategyData.greeks"
                      :key="index"
                    >
                      <td class="text-left">
                        {{ greek.formatted_ins_name }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.quantity) }}
                      </td>
                      <td class="text-center">
                        {{ greek.buy_sell }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.iv) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.delta) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.theta) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.gamma) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(greek.vega) }}
                      </td>
                    </tr>
                    <tr
                      class="greeks-table-rows"
                      style="border-bottom: 1px solid var(--border-color)"
                    >
                      <td class="text-left" style="font-weight: bold">Total</td>
                      <td class="text-center"></td>
                      <td class="text-center"></td>
                      <td class="text-center">
                        {{ parseFloat(prebuiltStrategyData.total.iv) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(prebuiltStrategyData.total.delta) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(prebuiltStrategyData.total.theta) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(prebuiltStrategyData.total.gamma) }}
                      </td>
                      <td class="text-center">
                        {{ parseFloat(prebuiltStrategyData.total.vega) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div class="target-days-section my-5">
              <!-- TARGET DAYS -->
              <div class="my-1 my-sm-2">
                <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
                  Target Days
                  <span class="target-iv-sublabel">
                    (0 to {{ targetDays }})
                  </span>
                </span>
                <v-slider
                  v-model="selectedTargetDays"
                  hide-details
                  color="var(--primary-button-color)"
                  always-dirty
                  min="0"
                  step="1"
                  :max="targetDays"
                  @change="changeTargetDays()"
                  track-color="#e0e0e0"
                  prepend-icon="mid-minus"
                >
                  <template v-slot:prepend>
                    <span
                      class="target-iv-label"
                      v-if="!$vuetify.breakpoint.xs"
                    >
                      Target Days
                      <span class="target-iv-sublabel">
                        (0 to {{ targetDays }})
                      </span>
                    </span>
                    <v-icon
                      color="var(--text-color) !important"
                      @click="
                        selectedTargetDays = selectedTargetDays - 1;
                        changeTargetDays();
                      "
                      :disabled="selectedTargetDays == 0 ? true : false"
                    >
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon
                      color="var(--text-color)"
                      @click="
                        selectedTargetDays = selectedTargetDays + 1;
                        changeTargetDays();
                      "
                      :disabled="
                        selectedTargetDays == targetDays ? true : false
                      "
                    >
                      mdi-plus
                    </v-icon>
                    <input
                      class="target-iv-input"
                      v-model="selectedTargetDays"
                      :max="targetDays"
                      @input="inputTargetDays($event)"
                    />
                  </template>
                </v-slider>
              </div>
              <!-- IV CHANGE -->
              <div class="my-1 my-sm-2" v-if="isPositionGraph">
                <span class="target-iv-label" v-if="$vuetify.breakpoint.xs">
                  IV% Change
                  <span class="target-iv-sublabel"> (-50 to 50) </span>
                </span>
                <v-slider
                  color="var(--primary-button-color)"
                  always-dirty
                  min="-50"
                  step="1"
                  max="50"
                  hide-details
                  v-model="perChange"
                  track-color="#e0e0e0"
                  prepend-icon="mid-minus"
                  @change="changeIvPercent()"
                >
                  <template v-slot:prepend>
                    <span
                      class="target-iv-label"
                      v-if="!$vuetify.breakpoint.xs"
                    >
                      IV% Change
                      <span class="target-iv-sublabel"> (-50 to 50) </span>
                    </span>
                    <v-icon
                      color="var(--text-color)"
                      @click="
                        perChange = perChange - 1;
                        changeIvPercent();
                      "
                      :disabled="perChange == -50 ? true : false"
                    >
                      mdi-minus
                    </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon
                      :ripple="false"
                      color="var(--text-color)"
                      @click="
                        perChange = perChange + 1;
                        changeIvPercent();
                      "
                      :disabled="perChange == 50 ? true : false"
                    >
                      mdi-plus
                    </v-icon>
                    <div style="position: relative">
                      <input
                        class="target-iv-input"
                        v-model="ivInput"
                        @input="inputIvPercent($event)"
                      />
                      <span
                        style="
                          position: absolute;
                          top: 50%;
                          left: 71%;
                          color: var(--text-color);
                          transform: translate(0%, -50%);
                        "
                        >%</span
                      >
                    </div>
                  </template>
                </v-slider>
              </div>
            </div>

            <!-- Greeks Data Section -->
            <div
              class="my-3 d-flex flex-column flex-sm-row justify-space-between align-center"
            >
              <div
                class="graph-subheader text order-2 order-sm-1"
                v-if="this.prebuiltStrategyData"
              >
                <div>Delta: {{ prebuiltStrategyData.total.delta }}</div>
                <div>Theta: {{ prebuiltStrategyData.total.theta }}</div>
                <div>Gamma: {{ prebuiltStrategyData.total.gamma }}</div>
                <div>Vega: {{ prebuiltStrategyData.total.vega }}</div>
              </div>
              <div
                class="order-1 order-sm-2"
                :style="$vuetify.breakpoint.xs ? { width: '100%' } : {}"
              >
                <button
                  style="width: 100%; white-space: nowrap"
                  class="outlined-button"
                  @click="setStrategyCreatorData()"
                  v-if="$route.name.toLowerCase().includes('dashboard')"
                >
                  View in Strategy Creator
                </button>
              </div>
            </div>

            <!-- Stats Section -->
            <v-row
              class="justify-space-between stats-container no-gutters mx-1"
            >
              <!-- Max Profit -->
              <div class="col-6 col-sm-4 order-sm-2">
                <div>
                  <span>Max Profit</span>
                  <span>
                    <span v-if="expectedProfit === 'Unlimited'">Unlimited</span>
                    <span v-else>
                      {{
                        parseFloat(expectedProfit).toFixed(0) | integerFormat
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- Max Loss -->
              <div class="col-6 col-sm-4 order-sm-5">
                <div>
                  <span>Max Loss</span>
                  <span>
                    <span v-if="expectedLoss === 'Unlimited'">Unlimited</span>
                    <span v-else>
                      {{ parseFloat(expectedLoss).toFixed(0) | integerFormat }}
                    </span>
                  </span>
                </div>
              </div>

              <!-- Margin -->
              <div class="col-6 col-sm-4 order-sm-5" v-if="isPositionGraph">
                <div>
                  <span>Margin</span>
                  <span>
                    <span
                      v-if="
                        prebuiltStrategyData.data.margin_data.margin ===
                          'Unlimited' ||
                        prebuiltStrategyData.data.margin_data.margin === 'NA'
                      "
                      >{{ prebuiltStrategyData.data.margin_data.margin }}</span
                    >
                    <span v-else>
                      {{
                        prebuiltStrategyData.data.margin_data.margin.toFixed(0)
                          | integerFormat
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- Margin Benefit-->
              <div class="col-6 col-sm-4 order-sm-5" v-if="isPositionGraph">
                <div>
                  <span>Margin Benefit</span>
                  <span>
                    <span
                      v-if="
                        prebuiltStrategyData.data.margin_data.mb ===
                          'Unlimited' ||
                        prebuiltStrategyData.data.margin_data.mb === 'NA'
                      "
                      >{{ prebuiltStrategyData.data.margin_data.mb }}</span
                    >
                    <span v-else>
                      {{
                        prebuiltStrategyData.data.margin_data.mb.toFixed(0)
                          | integerFormat
                      }}
                    </span>
                  </span>
                </div>
              </div>
              <!-- BreakEven -->
              <div class="col-6 col-sm-4 order-sm-3">
                <div>
                  <span>Breakeven</span>
                  <span>{{ breakEvenPrice }}</span>
                </div>
              </div>
              <!-- Probability -->
              <div class="col-6 col-sm-4 order-sm-6">
                <div>
                  <span>Probability</span>
                  <span v-if="prebuiltStrategyData"
                    >{{ prebuiltStrategyData.pop }}%</span
                  >
                </div>
              </div>
              <!-- Risk/Reward -->
              <div class="col-6 col-sm-4 order-sm-8">
                <div>
                  <span>Risk/Reward</span>
                  <span>{{ risk_reward }}</span>
                </div>
              </div>
            </v-row>
          </div>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>
<script>
import Graph from "../Dashboard/Graph.vue";
import axios from "axios";
import { getCustomStrategyUrl } from "../getService";
import { calculateTargetDays } from "../Dashboard/dashboardService";
import { EventBus } from "../../main";
export default {
  name: "GraphAndDaysModal",
  components: {
    Graph,
  },
  props: {
    strategyData: null,
    visible: Boolean,
    selectedInstrument: null,
    isPositionGraph: Boolean,
    positionAnalyticsData: null,
    isDefaultPositions: Boolean,
  },
  data: () => ({
    targetDays: 0,
    prebuiltStrategyData: null,
    selectedTargetDays: 0,
    apiPayload: null,
    targetDaysTimerId: null,
    breakEvenPrice: "",
    expectedProfit: "",
    expectedLoss: "",
    netMargin: "",
    risk_reward: "",
    greeks: null,
    total: "",
    selectedGreeksTab: "payoffChart",
    ivInput: 0,
    targetIvId: null,
    perChange: 0,
  }),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    compactMarketDataScripts: function () {
      return this.$store.getters.compactMarketDataScripts;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.selectedTargetDays = this.strategyData.payload.target_days;
      this.apiPayload = JSON.parse(JSON.stringify(this.strategyData.payload));
      this.targetDays = calculateTargetDays(this.apiPayload.param_list);
      this.getCustomStrategyApi();
    },

    // Breakeven Price Calculations
    calculateBreakevenPrice(x, y) {
      let yIndex = "";
      let yIndex2 = "";
      if (y[0] < 0) {
        yIndex = this.greatestNegativeIndex(y);
      } else {
        yIndex = this.traversePositiveArray(y);
      }
      let copyY = y;
      let slicedY = copyY.slice(yIndex + 1);
      if (slicedY[0] < 0) {
        yIndex2 = this.greatestNegativeIndex(slicedY);
      } else {
        yIndex2 = this.traversePositiveArray(slicedY);
      }
      if (yIndex2 != "") {
        this.breakEvenPrice =
          parseFloat(x[yIndex]).toFixed(1) +
          " - " +
          parseFloat(x[yIndex2 + yIndex - 1]).toFixed(1);
      } else {
        this.breakEvenPrice = parseFloat(x[yIndex + 1]).toFixed(1);
      }
    },
    greatestNegativeIndex(list) {
      let posIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i] > 0) {
          posIndex = i;
          break;
        }
      }
      if (posIndex < 0) {
        return "";
      } else {
        return posIndex - 1;
      }
    },
    traversePositiveArray(list) {
      let posIndex = -1;
      for (let i = 0; i < list.length; i++) {
        if (list[i] < 0) {
          posIndex = i;
          break;
        }
      }
      if (posIndex < 0) {
        return "";
      } else {
        return posIndex - 1;
      }
    },
    // Handle change in target days input
    changeTargetDays() {
      this.getCustomStrategyApi();
    },
    inputTargetDays(event) {
      if (this.targetDaysTimerId) {
        clearTimeout(this.targetDaysTimerId);
      }
      if (this.selectedTargetDays == "") {
        return;
      } else {
        this.selectedTargetDays = event.target.value;
        this.targetDaysTimerId = setTimeout(() => {
          this.changeTargetDays();
        }, 500);
      }
    },

    //changing IV percentage here
    changeIvPercent() {
      this.ivInput = this.perChange;
      this.getCustomStrategyApi();
    },
    inputIvPercent(event) {
      if (this.targetIvId) {
        clearTimeout(this.targetIvId);
      }
      let value = event.target.value.toString().replace(/[^-?\d]/g, "");
      this.ivInput = value;
      this.targetIvId = setTimeout(() => {
        if (value < -50) {
          this.ivInput = -50;
        } else if (value > 50) {
          this.ivInput = 50;
        } else if (value == "" || value == "-") {
          this.ivInput = 0;
        }
        this.perChange = parseInt(this.ivInput);
        this.changeIvPercent();
      }, 1000);
    },
    getCustomStrategyApi() {
      this.apiPayload.target_days = this.selectedTargetDays;
      this.apiPayload.iv_percent = this.perChange;
      axios
        .post(getCustomStrategyUrl, this.apiPayload, {
          headers: {
            "x-api-key": "TP6jae4rTTgcG5D8qCKKafET0n5ZBGf7lypyUom3",
          },
        })
        .then((res) => {
          if (res.data.status == false) {
            throw res;
          }
          this.prebuiltStrategyData = res.data;
          this.expectedProfit =
            res.data.data.pl_data.profit == "Unlimited"
              ? "Unlimited"
              : parseInt(res.data.data.pl_data.profit);
          this.expectedLoss =
            res.data.data.pl_data.loss == "Unlimited"
              ? "Unlimited"
              : parseInt(res.data.data.pl_data.loss);
          this.netMargin = parseInt(res.data.data.margin_data.margin);
          this.risk_reward = this.getRiskRewardRatio(
            res.data.data.pl_data.profit,
            res.data.data.pl_data.loss
          );

          let plData = res.data.data.pl_data;
          this.calculateBreakevenPrice(plData.x, plData.y);
        })
        .catch((err) => {
          console.log(
            "Error occured while loading 'getCustomStrategyUrl' data"
          );
          console.log(err);
        });
    },
    getRiskRewardRatio(profit, loss) {
      if (
        profit === "Undefined" ||
        profit === "Unlimited" ||
        loss === "Undefined" ||
        loss === "Unlimited"
      ) {
        return "Unlimited";
      } else {
        let risk = parseFloat(profit / Math.abs(loss)).toFixed(1);
        if (parseFloat(risk) >= 1) {
          return risk.toString() + " : 1";
        } else {
          return risk.toString() + " : 1";
        }
      }
    },
    setStrategyCreatorData() {
      this.show = false;
      this.$parent.$parent.$refs.targetComponent.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      this.$parent.$parent.$refs.targetComponent.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
      EventBus.$emit(
        "changePrebuiltStrategyDataGraph",
        this.prebuiltStrategyData
      );
    },

    /**
     * @function getPLUpsideDownside to get the positions PL upside or downside for hedged symbol and expiry positions
     */
    getTotalUpsideDown(key) {
      let unrealisedPL = 0;
      let maxLoss = 0;
      let maxProfit = 0;
      for (let item of this.positionAnalyticsData.positions) {
        if (key == "unrealisedPL") {
          if (this.getPLUpsideDownside(key, item) == "Unlimited") {
            return "Unlimited";
          } else {
            unrealisedPL =
              unrealisedPL + parseFloat(this.getPLUpsideDownside(key, item));
          }
        } else if (key == "downside") {
          if (this.getPLUpsideDownside(key, item) == "Unlimited") {
            return "Unlimited";
          } else {
            maxLoss = parseFloat(this.getPLUpsideDownside(key, item));
          }
        } else {
          if (this.getPLUpsideDownside(key, item) == "Unlimited") {
            return "Unlimited";
          } else {
            maxProfit = parseFloat(this.getPLUpsideDownside(key, item));
          }
        }
      }
      return key == "unrealisedPL"
        ? unrealisedPL.toFixed(2)
        : key == "upside"
        ? maxProfit.toFixed(2)
        : maxLoss.toFixed(2);
    },

    /**
     * @function getPLUpsideDownside to get the positions PL upside or downside for default positions
     */
    getPLUpsideDownside(key, position) {
      let ltp = 0;
      if (
        this.compactMarketDataScripts(position.instrument_token.toString())
          .details
      ) {
        ltp = parseFloat(
          this.compactMarketDataScripts(position.instrument_token.toString())[
            "details"
          ]["Last Traded Price"]
        ).toFixed(2);
      }
      let amount =
        position.net_quantity > 0 ? position.buy_amount : position.sell_amount;
      let unrealisedPL = parseFloat((ltp - amount) * position.net_quantity);

      let maxProfit =
        this.expectedProfit == "Unlimited"
          ? "Unlimited"
          : (this.expectedProfit - unrealisedPL).toFixed(2);
      let maxLoss =
        this.expectedLoss == "Unlimited"
          ? "Unlimited"
          : (this.expectedLoss - unrealisedPL).toFixed(2);
      return key == "unrealisedPL"
        ? unrealisedPL.toFixed(2)
        : key == "upside"
        ? maxProfit
        : maxLoss;
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/css/global.scss";
.info-icon .v-icon.v-icon {
  color: var(--text-color) !important;
  font-size: 1em !important;
  cursor: pointer;
}
.graph-modal.greeks-header {
  .v-tabs {
    border-bottom: 1px solid var(--border-color);
    font-size: 1rem;
    margin-top: 1rem;
    .v-tab {
      text-transform: none !important;
    }
  }
  .v-tabs-bar.v-item-group > * {
    background: var(--dialog-background) !important ;
  }
}
.header-tooltips {
  font-size: 0.8em;
  text-align: center;
  color: var(--text-color) !important;
  background-color: var(--cards-background-color);
  border: 1px solid var(--line-color) !important;
  border-radius: 4px;
}

.v-dialog {
  font-family: "Gilroy-Medium" !important;
}
.graph-days-modal {
  .graph-days {
    border: 0.9px solid var(--border-color);
    border-radius: 10px;
    .theme--light.v-data-table {
      background-color: var(--dialog-background) !important;
    }
  }

  .graph-subheader {
    display: flex;
    align-items: center;
    div {
      background-color: var(--chip-background);
      padding: 0.3rem 0.6rem;
      border-radius: 6px;
      margin-right: 8px;
      color: var(--text-color);
      line-height: 25px;
      font-size: 0.9rem;
    }
  }

  .target-label {
    width: 150px;
    padding: 7px;
    font-size: 0.8rem;
    color: var(--text-color);
    .target-sublabel {
      color: var(--text-color);
      font-size: 0.8rem;
      margin-left: 8px;
    }
  }

  .stats-container > div > div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 0 !important;
    border-bottom: 1px solid var(--border-color);
    margin-right: 1rem;
    white-space: nowrap;
    color: var(--text-color);
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 600px) {
  .upside-downside-labels {
    font-size: 0.75rem !important;
  }
}
</style>
