import Vue from 'vue';

// const GET_PRICE_CHANGE = (change, ltp) => {
//   change = parseFloat(change ?? 0)
//   ltp = parseFloat(ltp ?? 0)
//   return ((ltp / (100 + change)) * change).toFixed(2);
// };
export default{
  state(){
    return{
      // Subscribed instruments
      subscribedInstrumentsData: {},
      compactMarketDataMap: {},
      compactMarketData: [],
    }
  },
  getters:{
    getSubscribedInstrumentData: (state) => (code) => {
      const mapKey = "depth_" + code;
      return state.subscribedInstrumentsData[mapKey] ?? null;
    },
    compactMarketDataScripts: (state, getters, rootState, rootGetters) => (id) => {
      if(rootGetters.isAliceblue){
        const mapKey = "compact_" + id;
        const index = state.compactMarketDataMap[mapKey];
        return index ? state.compactMarketData[index] : "";
        // let compactMData = state.compactMarketData.find(e => e.code == id );
        // return compactMData ?? ''
      }
      else{
        return getters.getSubscribedInstrumentData(id);
      }
    },
  },
  mutations:{
    removeFromSubscribedInstrumentsData(state, code) {
      const key = "depth_" + code;
      delete state.subscribedInstrumentsData[key];
    },
    removeFromCompactMarketData(state, code) {
      const key = "compact_" + code;
      const index = state.compactMarketDataMap[key];
      if (index) {
        state.compactMarketData.splice(index, 1);
        delete state.compactMarketDataMap[key];
      }
    },
    /**
     * @function set/update-subscribed-instrument-data
     * @param payload data from websocket
     * same as "setcompactData"
     */
    setSubscribedInstrumentsData(state, payload) {
      if (!payload.tk)
        return;
      const getPriceChange = (change, ltp) => {
        change = parseFloat(change ?? 0)
        ltp = parseFloat(ltp ?? 0)
        return ((ltp / (100 + change)) * change).toFixed(2);
      };
      const key = "depth_" + payload.tk;
      const oldObj = state.subscribedInstrumentsData[key] ?? null;
      if (!oldObj) {
        const ltp=payload.lp?+payload.lp:payload.c?+payload.c:0;
        let value = {
          "code": payload.tk,
          "details": {
            "Last Traded Price": ltp,
            "ltp": ltp, //duplicate value key
            "Trade Volume": payload.v != undefined ? +payload.v : 0,
            "volume": payload.v != undefined ? +payload.v : 0, //duplicate value key
            "oi": payload.oi != undefined ? +payload.oi : 0,
            "totalBuyQty": payload.tbq != undefined ? +payload.tbq : 0,
            "totalSellQty": payload.tsq != undefined ? +payload.tsq : 0,
            "Change": payload.pc != undefined ? +payload.pc : 0,
            "Open Price": payload.o != undefined ? +payload.o : 0,
            "open": payload.o != undefined ? +payload.o : 0, //duplicate value key
            "high": payload.h != undefined ? +payload.h : 0,
            "low": payload.l != undefined ? +payload.l : 0,
            "close": payload.c != undefined ? +payload.c : 0,
            "avgPrice": payload.ap != undefined ? +payload.ap : 0,
            "upperCircuit": payload.uc != undefined ? +payload.uc : 0,
            "lowerCircuit": payload.lc != undefined ? +payload.lc : 0,
            "ltq": payload.ltq != undefined ? +payload.ltq : 0,
            "ltt": payload.ltt != undefined ? payload.ltt : 0,
            "bid": payload.bp1 != undefined ? +payload.bp1 : 0,
            "ask": payload.sp1 != undefined ? +payload.sp1 : 0,
            "priceChange": getPriceChange(payload.pc, payload.lp),
          }
        }
        Vue.set(state.subscribedInstrumentsData, key, value)
      }
      else if (payload.e && oldObj) {

        if (payload.bp1) {
          state.subscribedInstrumentsData[key]['details']['bid'] = (+payload.bp1).toFixed(2);
        }

        if (payload.sp1) {
          state.subscribedInstrumentsData[key]['details']['ask'] = (+payload.sp1).toFixed(2);
        }

        if (payload.lp) {
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'Last Traded Price', (+payload.lp));
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'ltp', (+payload.lp));
        }
        if (payload.v) {
          state.subscribedInstrumentsData[key]['details']['Trade Volume'] = +payload.v;
          state.subscribedInstrumentsData[key]['details']['volume'] = +payload.v;
        }
        if (payload.pc)
          state.subscribedInstrumentsData[key]['details']['Change'] = +payload.pc;
        if (payload.oi)
          state.subscribedInstrumentsData[key]['details']['oi'] = +payload.oi;
        if (payload.tbq)
          state.subscribedInstrumentsData[key]['details']['totalBuyQty'] = +payload.tbq;
        if (payload.tsq)
          state.subscribedInstrumentsData[key]['details']['totalSellQty'] = +payload.tsq;

        if (payload.o) {
          state.subscribedInstrumentsData[key]['details']['Open Price'] = (+payload.o).toFixed(2);
          state.subscribedInstrumentsData[key]['details']['open'] = (+payload.o).toFixed(2);
        }
        if (payload.c)
          state.subscribedInstrumentsData[key]['details']['close'] = (+payload.c).toFixed(2);
        if (payload.h)
          state.subscribedInstrumentsData[key]['details']['high'] = (+payload.h).toFixed(2);
        if (payload.l)
          state.subscribedInstrumentsData[key]['details']['low'] = (+payload.l).toFixed(2);
        if (payload.ap)
          state.subscribedInstrumentsData[key]['details']['avgPrice'] = (+payload.ap).toFixed(2);

        if (payload.uc)
          state.subscribedInstrumentsData[key]['details']['upperCircuit'] = (+payload.uc).toFixed(2);
        if (payload.lc)
          state.subscribedInstrumentsData[key]['details']['lowerCircuit'] = (+payload.lc).toFixed(2);
        if (payload.ltq)
          state.subscribedInstrumentsData[key]['details']['ltq'] = +payload.ltq;
        if (payload.ltt)
          state.subscribedInstrumentsData[key]['details']['ltt'] = payload.ltt;

        if (payload.pc || payload.lp) {
          state.subscribedInstrumentsData[key]['details']['priceChange'] = getPriceChange(payload.pc, payload.lp)
        }
      }
    },
    setcompactData(state, payload) {
      if (!payload.tk)
        return;
      const mapKey = "compact_" + payload.tk;
      const index = state.compactMarketDataMap[mapKey];
      // let objIndex = state.compactMarketData.findIndex((e => e.code == payload.tk));
      let objIndex = index ? index : -1;
      if (objIndex == -1) {
        const ltp=payload.lp?+payload.lp:payload.c?+payload.c:0;
        state.compactMarketData.push({
          "code": payload.tk,
          "details": {
            "Last Traded Price": ltp,
            "Open Price": Number(payload.o),
            "Low Price": Number(payload.l),
            "close": Number(payload.c),
            "oi": payload.oi == undefined ? 0 : Number(payload.oi),
            "Trade Volume": payload.v == undefined ? 0 : Number(payload.v),
            "Change": Number(payload.pc) == undefined ? 0 : Number(payload.pc),
            "totalBuyQty": payload.tbq == undefined ? 0 : Number(payload.tbq),
            "totalSellQty": payload.tsq == undefined ? 0 : Number(payload.tsq),
            "buyOrder": [
              payload.bo1 == undefined ? 0 : Number(payload.bo1),
              payload.bo2 == undefined ? 0 : Number(payload.bo2),
              payload.bo3 == undefined ? 0 : Number(payload.bo3),
              payload.bo4 == undefined ? 0 : Number(payload.bo4),
              payload.bo5 == undefined ? 0 : Number(payload.bo5)
            ],
            "buyBid": [
              payload.bp1 == undefined ? 0 : Number(payload.bp1),
              payload.bp2 == undefined ? 0 : Number(payload.bp2),
              payload.bp3 == undefined ? 0 : Number(payload.bp3),
              payload.bp4 == undefined ? 0 : Number(payload.bp4),
              payload.bp5 == undefined ? 0 : Number(payload.bp5)
            ],
            "buyQuanity": [
              payload.bq1 == undefined ? 0 : Number(payload.bq1),
              payload.bq2 == undefined ? 0 : Number(payload.bq2),
              payload.bq3 == undefined ? 0 : Number(payload.bq3),
              payload.bq4 == undefined ? 0 : Number(payload.bq4),
              payload.bq5 == undefined ? 0 : Number(payload.bq5)
            ],
            "sellOrder": [
              payload.so1 == undefined ? 0 : Number(payload.so1),
              payload.so2 == undefined ? 0 : Number(payload.so2),
              payload.so3 == undefined ? 0 : Number(payload.so3),
              payload.so4 == undefined ? 0 : Number(payload.so4),
              payload.so5 == undefined ? 0 : Number(payload.so5)
            ],
            "sellOffer": [
              payload.sp1 == undefined ? 0 : Number(payload.sp1),
              payload.sp2 == undefined ? 0 : Number(payload.sp2),
              payload.sp3 == undefined ? 0 : Number(payload.sp3),
              payload.sp4 == undefined ? 0 : Number(payload.sp4),
              payload.sp5 == undefined ? 0 : Number(payload.sp5)
            ],
            "sellQuantity": [
              payload.sq1 == undefined ? 0 : Number(payload.sq1),
              payload.sq2 == undefined ? 0 : Number(payload.sq2),
              payload.sq3 == undefined ? 0 : Number(payload.sq3),
              payload.sq4 == undefined ? 0 : Number(payload.sq4),
              payload.sq5 == undefined ? 0 : Number(payload.sq5)
            ]
          }
        })
        const newIndex = state.compactMarketData.length - 1;
        Vue.set(state.compactMarketDataMap, mapKey, newIndex);
      }
      else {
        if (payload.e && state.compactMarketData[objIndex]) {
          if (payload.bq1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 0, Number(payload.bq1))
          if (payload.bq2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 1, Number(payload.bq2))
          if (payload.bq3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 2, Number(payload.bq3))
          if (payload.bq4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 3, Number(payload.bq4))
          if (payload.bq5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyQuanity'], 4, Number(payload.bq5))

          if (payload.bp1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 0, Number(payload.bp1))
          if (payload.bp2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 1, Number(payload.bp2))
          if (payload.bp3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 2, Number(payload.bp3))
          if (payload.bp4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 3, Number(payload.bp4))
          if (payload.bp5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyBid'], 4, Number(payload.bp5))

          if (payload.bo1)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 0, Number(payload.bo1))
          if (payload.bo2)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 1, Number(payload.bo2))
          if (payload.bo3)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 2, Number(payload.bo3))
          if (payload.bo4)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 3, Number(payload.bo4))
          if (payload.bo5)
            Vue.set(state.compactMarketData[objIndex]['details']['buyOrder'], 4, Number(payload.bo5))

          if (payload.so1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 0, Number(payload.so1))
          if (payload.so2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 1, Number(payload.so2))
          if (payload.so3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 2, Number(payload.so3))
          if (payload.so4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 3, Number(payload.so4))
          if (payload.so5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOrder'], 4, Number(payload.so5))


          if (payload.sp1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 0, Number(payload.sp1))
          if (payload.sp2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 1, Number(payload.sp2))
          if (payload.sp3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 2, Number(payload.sp3))
          if (payload.sp4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 3, Number(payload.sp4))
          if (payload.sp5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellOffer'], 4, Number(payload.sp5))


          if (payload.sq1)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 0, Number(payload.sq1))
          if (payload.sq2)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 1, Number(payload.sq2))
          if (payload.sq3)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 2, Number(payload.sq3))
          if (payload.sq4)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 3, Number(payload.sq4))
          if (payload.sq5)
            Vue.set(state.compactMarketData[objIndex]['details']['sellQuantity'], 4, Number(payload.sq5))

          if (payload.lp) {
            state.compactMarketData[objIndex]['details']['Last Traded Price'] = Number(payload.lp)
          }
          if (payload.pc) {
            state.compactMarketData[objIndex]['details']['Change'] = Number(payload.pc)
          }
          if (payload.tbq) {
            state.compactMarketData[objIndex]['details']['totalBuyQty'] = Number(payload.tbq)
            state.compactMarketData[objIndex]['details']['totalBuyQty'] = Number(payload.tbq)
          }
          if (payload.tsq) {
            state.compactMarketData[objIndex]['details']['totalSellQty'] = Number(payload.tsq)
          }
          if (payload.t == 'dk') {
            state.compactMarketData[objIndex]['details']['oi'] = Number(payload.oi)
          }
          if (payload.t == 'dk') {
            state.compactMarketData[objIndex]['details']['Trade Volume'] = Number(payload.v)
          }
        }
      }
    },
    // for greeksoft
    setGreeksoftMarketData(state, payload) {
      if(!payload.gtoken && !payload.symbol) return;

      let constructKey = "depth_" + payload.symbol;
      if(payload.gtoken){
        constructKey = "depth_" + payload.gtoken;
      }
      const key=constructKey;
      const oldObj = state.subscribedInstrumentsData[key] ?? null;

      // greeksoft sends oi in a diffrent format message
      if(payload.gtoken){
        if(oldObj && payload.currentOI){
          state.subscribedInstrumentsData[key]['details']['oi'] = (+payload.currentOI).toFixed(2);
        }
        return;
      }
      if(!oldObj){
        let value = {
          "code": payload.symbol,
          "details": {
            "Last Traded Price": +payload.ltp,
            "ltp": payload.ltp ? +payload.ltp : 0, //duplicate value key
            "Trade Volume": payload.tot_vol != undefined ? +payload.tot_vol : 0,
            "volume": payload.tot_vol != undefined ? +payload.tot_vol : 0, //duplicate value key
            "oi": payload.oi != undefined ? +payload.oi : 0,
            "totalBuyQty": payload.tot_buyQty != undefined ? +payload.tot_buyQty : 0,
            "totalSellQty": payload.tot_sellQty != undefined ? +payload.tot_sellQty : 0,
            "Change": payload.p_change != undefined ? +payload.p_change : 0,
            "Open Price": payload.open != undefined ? +payload.open : 0,
            "open": payload.open != undefined ? +payload.open : 0, //duplicate value key
            "high": payload.high != undefined ? +payload.high : 0,
            "low": payload.low != undefined ? +payload.low : 0,
            "close": payload.close != undefined ? +payload.close : 0,
            "avgPrice": payload.atp != undefined ? +payload.atp : 0,
            // "upperCircuit": payload.uc != undefined ? +payload.uc : 0,
            // "lowerCircuit": payload.lc != undefined ? +payload.lc : 0,
            "ltq": payload.ltq != undefined ? +payload.ltq : 0,
            "ltt": payload.ltt != undefined ? payload.ltt : 0,
            "bid": payload.bid != undefined ? +payload.bid : 0,
            "ask": payload.ask != undefined ? +payload.ask : 0,
            "priceChange": payload.change != undefined ? +payload.change : 0,

            "buyOrder": [
              payload.level2?.at(0)?.bid?.no == undefined ? 0 : Number(payload?.level2[0]?.bid?.no),
              payload.level2?.at(1)?.bid?.no == undefined ? 0 : Number(payload?.level2[1]?.bid?.no),
              payload.level2?.at(2)?.bid?.no == undefined ? 0 : Number(payload?.level2[2]?.bid?.no),
              payload.level2?.at(3)?.bid?.no == undefined ? 0 : Number(payload?.level2[3]?.bid?.no),
              payload.level2?.at(4)?.bid?.no == undefined ? 0 : Number(payload?.level2[4]?.bid?.no)
            ],
            "buyBid": [
              payload.level2?.at(0)?.bid?.price == undefined ? 0 : Number(payload.level2?.at(0)?.bid?.price),
              payload.level2?.at(1)?.bid?.price == undefined ? 0 : Number(payload.level2?.at(1)?.bid?.price),
              payload.level2?.at(2)?.bid?.price == undefined ? 0 : Number(payload.level2?.at(2)?.bid?.price),
              payload.level2?.at(3)?.bid?.price == undefined ? 0 : Number(payload.level2?.at(3)?.bid?.price),
              payload.level2?.at(4)?.bid?.price == undefined ? 0 : Number(payload.level2?.at(4)?.bid?.price)
            ],
            "buyQuanity": [
              payload.level2?.at(0)?.bid?.qty == undefined ? 0 : Number(payload.level2?.at(0)?.bid?.qty),
              payload.level2?.at(1)?.bid?.qty == undefined ? 0 : Number(payload.level2?.at(1)?.bid?.qty),
              payload.level2?.at(2)?.bid?.qty == undefined ? 0 : Number(payload.level2?.at(2)?.bid?.qty),
              payload.level2?.at(3)?.bid?.qty == undefined ? 0 : Number(payload.level2?.at(3)?.bid?.qty),
              payload.level2?.at(4)?.bid?.qty == undefined ? 0 : Number(payload.level2?.at(4)?.bid?.qty)
            ],
            "sellOrder": [
              payload.level2?.at(0)?.ask?.no == undefined ? 0 : Number(payload.level2?.at(0)?.ask.no),
              payload.level2?.at(1)?.ask?.no == undefined ? 0 : Number(payload.level2?.at(1)?.ask.no),
              payload.level2?.at(2)?.ask?.no == undefined ? 0 : Number(payload.level2?.at(2)?.ask.no),
              payload.level2?.at(3)?.ask?.no == undefined ? 0 : Number(payload.level2?.at(3)?.ask.no),
              payload.level2?.at(4)?.ask?.no == undefined ? 0 : Number(payload.level2?.at(4)?.ask.no)
            ],
            "sellOffer": [
              payload.level2?.at(0)?.ask?.price == undefined ? 0 : Number(payload.level2?.at(0)?.ask.price),
              payload.level2?.at(1)?.ask?.price == undefined ? 0 : Number(payload.level2?.at(1)?.ask.price),
              payload.level2?.at(2)?.ask?.price == undefined ? 0 : Number(payload.level2?.at(2)?.ask.price),
              payload.level2?.at(3)?.ask?.price == undefined ? 0 : Number(payload.level2?.at(3)?.ask.price),
              payload.level2?.at(4)?.ask?.price == undefined ? 0 : Number(payload.level2?.at(4)?.ask.price)
            ],
            "sellQuantity": [
              payload.level2?.at(0)?.ask?.qty == undefined ? 0 : Number(payload.level2?.at(0)?.ask.qty),
              payload.level2?.at(1)?.ask?.qty == undefined ? 0 : Number(payload.level2?.at(1)?.ask.qty),
              payload.level2?.at(2)?.ask?.qty == undefined ? 0 : Number(payload.level2?.at(2)?.ask.qty),
              payload.level2?.at(3)?.ask?.qty == undefined ? 0 : Number(payload.level2?.at(3)?.ask.qty),
              payload.level2?.at(4)?.ask?.qty == undefined ? 0 : Number(payload.level2?.at(4)?.ask.qty)
            ]
          }
        }
        Vue.set(state.subscribedInstrumentsData, key, value)
      }
      else if (oldObj) {
        //MARKET DEPTH BLoCK
        const md=payload.level2;
        if(md)
        {
          if (md.at(0)?.bid?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyQuanity'], 0, Number(md.at(0).bid.qty));
          if (md.at(1)?.bid?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyQuanity'], 1, Number(md.at(1).bid.qty));
          if (md.at(2)?.bid?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyQuanity'], 2, Number(md.at(2).bid.qty));
          if (md.at(3)?.bid?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyQuanity'], 3, Number(md.at(3).bid.qty));
          if (md.at(4)?.bid?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyQuanity'], 4, Number(md.at(4).bid.qty));

          if (md.at(0)?.bid?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyBid'], 0, Number(md.at(0).bid.price));
          if (md.at(1)?.bid?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyBid'], 1, Number(md.at(1).bid.price));
          if (md.at(2)?.bid?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyBid'], 2, Number(md.at(2).bid.price));
          if (md.at(3)?.bid?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyBid'], 3, Number(md.at(3).bid.price));
          if (md.at(4)?.bid?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyBid'], 4, Number(md.at(4).bid.price));

          if (md.at(0)?.bid?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyOrder'], 0, Number(md.at(0).bid.no));
          if (md.at(1)?.bid?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyOrder'], 1, Number(md.at(1).bid.no));
          if (md.at(2)?.bid?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyOrder'], 2, Number(md.at(2).bid.no));
          if (md.at(3)?.bid?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyOrder'], 3, Number(md.at(3).bid.no));
          if (md.at(4)?.bid?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['buyOrder'], 4, Number(md.at(4).bid.no));

          if (md.at(0)?.ask?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellQuantity'], 0, Number(md.at(0).ask.qty));
          if (md.at(1)?.ask?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellQuantity'], 1, Number(md.at(1).ask.qty));
          if (md.at(2)?.ask?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellQuantity'], 2, Number(md.at(2).ask.qty));
          if (md.at(3)?.ask?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellQuantity'], 3, Number(md.at(3).ask.qty));
          if (md.at(4)?.ask?.qty) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellQuantity'], 4, Number(md.at(4).ask.qty));

          if (md.at(0)?.ask?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOffer'], 0, Number(md.at(0).ask.price));
          if (md.at(1)?.ask?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOffer'], 1, Number(md.at(1).ask.price));
          if (md.at(2)?.ask?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOffer'], 2, Number(md.at(2).ask.price));
          if (md.at(3)?.ask?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOffer'], 3, Number(md.at(3).ask.price));
          if (md.at(4)?.ask?.price) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOffer'], 4, Number(md.at(4).ask.price));

          if (md.at(0)?.ask?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOrder'], 0, Number(md.at(0).ask.no));
          if (md.at(1)?.ask?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOrder'], 1, Number(md.at(1).ask.no));
          if (md.at(2)?.ask?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOrder'], 2, Number(md.at(2).ask.no));
          if (md.at(3)?.ask?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOrder'], 3, Number(md.at(3).ask.no));
          if (md.at(4)?.ask?.no) 
            Vue.set(state.subscribedInstrumentsData[key]['details']['sellOrder'], 4, Number(md.at(4).ask.no));
        }
        if (payload.bid) {
          state.subscribedInstrumentsData[key]['details']['bid'] = (+payload.bid).toFixed(2);
        }

        if (payload.ask) {
          state.subscribedInstrumentsData[key]['details']['ask'] = (+payload.ask).toFixed(2);
        }

        if (payload.ltp) {
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'Last Traded Price', (+payload.ltp));
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'ltp', (+payload.ltp));
        }
        if (payload.volume) {
          state.subscribedInstrumentsData[key]['details']['Trade Volume'] = +payload.volume;
          state.subscribedInstrumentsData[key]['details']['volume'] = +payload.volume;
        }
        if (payload.p_change)
          state.subscribedInstrumentsData[key]['details']['Change'] = +payload.p_change;
        // if (payload.oi)
        //   state.subscribedInstrumentsData[key]['details']['oi'] = +payload.oi;
        if (payload.tot_buyQty)
          state.subscribedInstrumentsData[key]['details']['totalBuyQty'] = +payload.tot_buyQty;
        if (payload.tot_sellQty)
          state.subscribedInstrumentsData[key]['details']['totalSellQty'] = +payload.tot_sellQty;

        if (payload.open) {
          state.subscribedInstrumentsData[key]['details']['Open Price'] = (+payload.open).toFixed(2);
          state.subscribedInstrumentsData[key]['details']['open'] = (+payload.open).toFixed(2);
        }
        if (payload.close)
          state.subscribedInstrumentsData[key]['details']['close'] = (+payload.close).toFixed(2);
        if (payload.high)
          state.subscribedInstrumentsData[key]['details']['high'] = (+payload.high).toFixed(2);
        if (payload.low)
          state.subscribedInstrumentsData[key]['details']['low'] = (+payload.low).toFixed(2);
        if (payload.atp)
          state.subscribedInstrumentsData[key]['details']['avgPrice'] = (+payload.atp).toFixed(2);

        // if (payload.uc)
        //   state.subscribedInstrumentsData[key]['details']['upperCircuit'] = (+payload.uc).toFixed(2);
        // if (payload.lc)
        //   state.subscribedInstrumentsData[key]['details']['lowerCircuit'] = (+payload.lc).toFixed(2);
        if (payload.ltq)
          state.subscribedInstrumentsData[key]['details']['ltq'] = +payload.ltq;
        if (payload.ltt)
          state.subscribedInstrumentsData[key]['details']['ltt'] = payload.ltt;

        if (payload.change) {
          state.subscribedInstrumentsData[key]['details']['priceChange'] = payload.change;
        }
      }
    },
    setGreeksoftIndexData(state, payload) {
      // const sample = {
      //   p_change: "0.52",
      //   symbol: "Nifty 50",
      //   exch: "NSE",
      //   ltp: "19545.85",
      //   change: "101.85",
      //   name: "Nifty 50",
      //   indexCode: "101999957",
      // };

      const key = "depth_" + payload.indexCode;
      const oldObj = state.subscribedInstrumentsData[key] ?? null;
      if(!oldObj){
        let value = {
          "code": payload.indexCode,
          "details": {
            "Last Traded Price": +payload.ltp,
            "ltp": payload.ltp ? +payload.ltp : 0, //duplicate value key
            
            "Change": payload.p_change ?? 0,
            "name": payload.name,
            "priceChange": payload.change,
          }
        }
        Vue.set(state.subscribedInstrumentsData, key, value)
      }
      else if (oldObj) {
        if (payload.ltp) {
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'Last Traded Price', (+payload.ltp));
          Vue.set(state.subscribedInstrumentsData[key]['details'], 'ltp', (+payload.ltp));
        }
        if (payload.p_change)
          state.subscribedInstrumentsData[key]['details']['Change'] = +payload.p_change;
        if (payload.change)
          state.subscribedInstrumentsData[key]['details']['priceChange'] = payload.change;
      }
    }
  },
}