var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"holdings-wrapper"},[_c('v-row',{staticClass:"my-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center header-labels my-3",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Total Investment "),_c('div',{staticClass:"header-values"},[_vm._v(" "+_vm._s(_vm._f("integerFormat")(_vm.getTotalHeaderValues("totalInvestment")))+" ")])]),_c('v-divider',{staticClass:"d-none d-md-block",attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Current Value "),_c('div',{staticClass:"header-values"},[_vm._v(" "+_vm._s(_vm._f("integerFormat")(_vm.getTotalHeaderValues("currentValue")))+" ")])]),_c('v-divider',{staticClass:"d-none d-md-block",attrs:{"vertical":""}})],1)],1),_c('v-divider',{staticClass:"d-sm-block d-md-none horizontal-divider",attrs:{"inset":""}}),_c('v-col',{staticClass:"text-center header-labels my-3",attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Day's P&L "),_c('div',{staticClass:"green-text header-values"},[(_vm.getTotalHeaderValues('holdingsDaysPL') > 0)?_c('span',{staticClass:"green-text"},[_vm._v("+")]):_vm._e(),_c('span',{class:_vm.getTotalHeaderValues('holdingsDaysPL') >= 0
                  ? 'green-text'
                  : 'red-text'},[_vm._v(" "+_vm._s(_vm._f("integerFormat")(_vm.getTotalHeaderValues("holdingsDaysPL")))+" ")])])]),_c('v-divider',{staticClass:"d-none d-md-block",attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" Total P&L "),_c('div',{staticClass:"green-text header-values"},[(_vm.getTotalHeaderValues('holdingsTotalPL') > 0)?_c('span',{staticClass:"green-text"},[_vm._v("+")]):_vm._e(),_c('span',{class:_vm.getTotalHeaderValues('holdingsTotalPL') >= 0
                  ? 'green-text'
                  : 'red-text'},[_vm._v(_vm._s(_vm._f("integerFormat")(_vm.getTotalHeaderValues("holdingsTotalPL")))+" ")])])])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.$vuetify.breakpoint.xs)?_c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('span',[_c('span',{staticClass:"ml-1 mt-2"},[(_vm.filteredInstrumentList.length != 0)?_c('v-badge',{staticClass:"filter-badge",attrs:{"content":_vm.filteredInstrumentList.length,"color":"primary"}},[_c('v-icon',{staticStyle:{"font-size":"0.8rem","cursor":"pointer","color":"var(--primary-color)"},attrs:{"size":"x-large"},on:{"click":function($event){_vm.showInstrumentFilter = !_vm.showInstrumentFilter}}},[_vm._v(" mdi-filter ")])],1):_c('v-icon',{staticStyle:{"font-size":"0.8rem","cursor":"pointer"},on:{"click":function($event){_vm.showInstrumentFilter = !_vm.showInstrumentFilter}}},[_vm._v("mdi-filter-outline")])],1),(_vm.showInstrumentFilter)?_c('div',{staticClass:"instrument-filter"},[_c('FilterInstrument',{attrs:{"menuVisible":_vm.showInstrumentFilter,"instrumentList":_vm.instrumentList,"filteredInstrumentList":_vm.filteredInstrumentList},on:{"findfilteredInstrumentList":_vm.getFilteredInstrumentData,"close":function($event){_vm.showInstrumentFilter = false},"clickedReset":_vm.showAllPositions}})],1):_vm._e()])])]):_vm._e()]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"info-icon",on:{"click":function($event){return _vm.getHoldingsApi()}}},[_vm._v(" mdi-refresh ")])],1)]}}],null,false,4237500650)},[_c('span',[_vm._v("Refresh Holdings")])]):_c('span',[_c('v-icon',{staticClass:"info-icon",on:{"click":function($event){return _vm.getHoldingsApi()}}},[_vm._v(" mdi-refresh ")])],1)],1)],1),_c('v-row',{staticClass:"styled-scroll",staticStyle:{"height":"80%","margin-top":"1rem","overflow-y":"auto"},attrs:{"no-gutters":""}},[(_vm.filteredHoldings.length==0)?_c('v-col',{staticClass:"text-center mt-7 no-positions"},[_c('div',[_c('img',{staticClass:"mr-1",staticStyle:{"width":"50%"},attrs:{"src":!_vm.darkMode
            ? require('@/assets/Positions/no-positions.svg')
            : require('@/assets/Positions/no-positions-dark.svg')}})]),_c('div',{staticClass:"no-position-text"},[_vm._v("No Holdings Found")])]):_c('div',{staticClass:"default-position-wrapper"},[_c('div',[(!_vm.$vuetify.breakpoint.xs)?_c('v-data-table',{ref:"defPosTable",staticClass:"default-positions-table default-positions--desktop-table",staticStyle:{"margin-top":"0.5rem","height":"100%"},attrs:{"items":_vm.filteredHoldings,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{staticClass:"d-flex align-center justify-space-between",staticStyle:{"position":"relative"}},[_vm._v(" Instrument "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ml-1 d-flex"},'span',attrs,false),on),[(_vm.filteredInstrumentList.length != 0)?_c('v-badge',{staticClass:"filter-badge",attrs:{"content":_vm.filteredInstrumentList.length,"color":"primary"}},[_c('v-icon',{staticStyle:{"font-size":"1.4rem","cursor":"pointer","color":"var(--primary-color)"},attrs:{"size":"x-large"},on:{"click":function($event){_vm.showInstrumentFilter = !_vm.showInstrumentFilter}}},[_vm._v(" mdi-filter ")])],1):_c('v-icon',{staticStyle:{"font-size":"1.4rem","cursor":"pointer"},on:{"click":function($event){_vm.showInstrumentFilter = !_vm.showInstrumentFilter}}},[_vm._v("mdi-filter-outline")])],1)]}}],null,false,608828266)},[_c('span',[_vm._v(" Filter Instrument")])]),(_vm.showInstrumentFilter)?_c('div',{staticClass:"instrument-filter"},[_c('FilterInstrument',{attrs:{"menuVisible":_vm.showInstrumentFilter,"instrumentList":_vm.instrumentList,"filteredInstrumentList":_vm.filteredInstrumentList},on:{"findfilteredInstrumentList":_vm.getFilteredInstrumentData,"close":function($event){_vm.showInstrumentFilter = false},"clickedReset":_vm.showAllPositions}})],1):_vm._e()],1),_c('th',{staticClass:"text-center"},[_vm._v("Qty")]),_c('th',{staticClass:"text-center"},[_vm._v("Buy Avg")]),_c('th',{staticClass:"text-center"},[_vm._v("LTP")]),_c('th',{staticClass:"text-center"},[_vm._v("Buy Value")]),_c('th',{staticClass:"text-center"},[_vm._v("P&L")]),_c('th',{staticClass:"text-center"},[_vm._v("Net Chg.")])])]},proxy:true},{key:"item",fn:function(data){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('tr',{key:data.item.id},[_c('td',{staticClass:"d-flex align-center",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[(data.item.ExchSeg1 != null)?_c('span',[_vm._v(" "+_vm._s(data.item.Nsetsym)+" ")]):_c('span',[_vm._v(" "+_vm._s(data.item.Bsetsym)+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[_vm._v(" "+_vm._s(data.item.SellableQty)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[_vm._v(" "+_vm._s(data.item.Price)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[(
                        data.item.ExchSeg1 != null && _vm.insSub(data.item.Token1)
                      )?_c('span',[_vm._v(" "+_vm._s(_vm.insSub(data.item.Token1).ltp)+" ")]):(_vm.insSub(data.item.Token2))?_c('span',[_vm._v(" "+_vm._s(_vm.insSub(data.item.Token2).ltp)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[_vm._v(" "+_vm._s(_vm._f("currencyWithoutFormat")(_vm.getTableValues("buyValue", data.item)))+" ")]),_c('td',{staticClass:"text-center",class:_vm.getTableValues('pl', data.item) > 0
                        ? 'green-text'
                        : 'red-text',on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[_vm._v(" "+_vm._s(_vm._f("currencyWithoutFormat")(_vm.getTableValues("pl", data.item)))+" ")]),_c('td',{staticClass:"text-center",class:_vm.getTableValues('chg', data.item) > 0
                        ? 'green-text'
                        : 'red-text',on:{"click":function($event){return _vm.showMobileExitAddDialog(data.item)}}},[_vm._v(" "+_vm._s(_vm.getTableValues("chg", data.item))+"% ")]),(
                      hover &&
                      !_vm.$vuetify.breakpoint.sm &&
                      !_vm.$vuetify.breakpoint.xs
                    )?_c('td',{staticClass:"add-exit-buttons"},[(hover)?_c('div',[_c('button',{staticClass:"primary-button",staticStyle:{"white-space":"nowrap","background-color":"var(--primary-color)"},on:{"click":function($event){return _vm.showPlaceOrderDialogPopUp(data.item, 'add')}}},[_vm._v(" Add ")]),_c('button',{staticClass:"primary-button",staticStyle:{"white-space":"nowrap","background-color":"var(--red-text)"},on:{"click":function($event){return _vm.showPlaceOrderDialogPopUp(data.item, 'exit')}}},[_vm._v(" Exit ")])]):_vm._e()]):_vm._e()])]}}],null,true)})]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"custom-empty-wrapper",staticStyle:{"color":"var(--text-color)","position":"absolute"}},[_vm._v(" No holdings available ")])]},proxy:true}],null,false,2397691699)}):_c('div',[_c('div',_vm._l((_vm.filteredHoldings),function(item,index){return _c('div',{key:index,staticClass:"mobile-instrument-default-rows pt-4"},[_c('v-row',{staticClass:"pb-3 justify-space-between",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8"}},[_c('span',{on:{"click":function($event){return _vm.showMobileExitAddDialog(item)}}},[(item.ExchSeg1 != null)?_c('span',[_vm._v(" "+_vm._s(item.Nsetsym)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.Bsetsym)+" ")])])]),_c('v-col',{staticClass:"text-right pr-2",attrs:{"cols":"4"},on:{"click":function($event){return _vm.showMobileExitAddDialog(item)}}},[_c('span',[_vm._v("P&L: ")]),_c('span',{class:_vm.getTableValues('pl', item) > 0
                        ? 'green-text'
                        : 'red-text'},[_vm._v(_vm._s(_vm._f("currencyWithoutFormat")(_vm.getTableValues("pl", item))))])])],1),_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.showMobileExitAddDialog(item)}}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(" Qty: ")]),_c('span',[_vm._v(_vm._s(item.SellableQty))])]),_c('v-col',{staticClass:"text-right pr-2",attrs:{"cols":"6"}},[_c('span',[_vm._v("Buy Value: ")]),_c('span',[_vm._v(_vm._s(_vm._f("currencyWithoutFormat")(_vm.getTableValues("buyValue", item))))])])],1),_c('v-row',{staticClass:"pb-3",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.showMobileExitAddDialog(item)}}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('span',[_vm._v(" LTP: ")]),(item.ExchSeg1 != null && _vm.insSub(item.Token1))?_c('span',[_vm._v(" "+_vm._s(_vm.insSub(item.Token1).ltp)+" ")]):(_vm.insSub(item.Token2))?_c('span',[_vm._v(" "+_vm._s(_vm.insSub(item.Token2).ltp)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"4"}},[_c('span',[_vm._v(" Buy Avg: ")]),_c('span',[_vm._v(_vm._s(item.Price))])]),_c('v-col',{staticClass:"text-right pr-2",attrs:{"cols":"4"}},[_c('span',[_vm._v("Net Chg.: ")]),_c('span',{class:_vm.getTableValues('chg', item) > 0
                        ? 'green-text'
                        : 'red-text'},[_vm._v(_vm._s(_vm.getTableValues("chg", item))+"%")])])],1),_c('v-divider')],1)}),0)])],1),(_vm.showAddExitDialog)?_c('v-dialog',{attrs:{"content-class":"add-exit-overlay","transition":"dialog-bottom-transition"},model:{value:(_vm.showAddExitDialog),callback:function ($$v) {_vm.showAddExitDialog=$$v},expression:"showAddExitDialog"}},[_c('v-card',[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticStyle:{"color":"var(--text-color)"}},[_vm._v(" "+_vm._s(_vm.clickedPosition.ExchSeg1 != null ? _vm.clickedPosition.Nsetsym : _vm.clickedPosition.Bsetsym)+" ")])]),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":"","justify-xs":'space-between',"justify-sm":'center'}},[_c('v-col',{attrs:{"cols":"6","sm":"4"}},[_c('button',{staticClass:"primary-button",staticStyle:{"white-space":"nowrap","width":"90%"},on:{"click":function($event){return _vm.showPlaceOrderDialogPopUp(_vm.clickedPosition, 'add')}}},[_vm._v(" Add ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","sm":"4"}},[_c('button',{staticClass:"primary-button",staticStyle:{"white-space":"nowrap","width":"90%","background-color":"var(--red-text)"},on:{"click":function($event){return _vm.showPlaceOrderDialogPopUp(_vm.clickedPosition, 'exit')}}},[_vm._v(" Exit ")])])],1)],1)],1)],1):_vm._e(),(_vm.showPlaceOrderDialog)?_c('PlaceOrderModal',{attrs:{"visible":_vm.showPlaceOrderDialog,"strategyCreator":_vm.strategyCreatorList,"selectedInstrument":_vm.selectedInstrument,"isPositionGraph":false,"isHoldingsSection":true},on:{"close":function($event){_vm.showPlaceOrderDialog = false;
          _vm.refreshPositions();}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }