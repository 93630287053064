// GREEKSOFT REDIRECTIONS URLS
export const greeksoftSignUpRedirectUrl="http://greeksoft.co.in/Retail_trading_solution_web_.html";
// GREEKSOFT TEST SERVER URLS
export const greeksoftLoginEndpoint="http://182.76.70.89:3001/auth/greek/sessiontoken";
export const getGcidEndpoint="http://restapi.greeksoft.in:3333/getLoginInfo";
export const jLoginEndpoint="http://restapi.greeksoft.in:3333/jloginNew";

export const greeksoftWSURL='ws://restapi.greeksoft.in:8082';

// INUVEST LAMBDA WRAPPER URLS
export const announcementUrl="https://xu07h4gj94.execute-api.ap-south-1.amazonaws.com/default/announcementsAPI";
export const gcidEndpointWrapper="https://1l1o8x2kza.execute-api.ap-south-1.amazonaws.com/default/getGeeksoftGcidWrapper";
export const jLoginWrapper="https://1nb6hi65x1.execute-api.ap-south-1.amazonaws.com/default/geeksoftJLogin";

// WATCHLIST SEARCH FILES
export const getGreeksoftHoldingsSearchCSV = "";
export const getGreeksoftNFOPositionSearchJSON = "https://s3.ap-south-1.amazonaws.com/1lyoptions.inuvest.tech/symbolmaster_gs.json";
export const getGreeksoftMCXPositionSearchJSON="";
export const getGreeksoftCDSPositionSearchJSON="";