<template>
  <v-card class="pa-5">
    <v-card-title class="justify-center text-h4" color="var(--text-color)">Sign In</v-card-title>
    <div class="broker-card-wrapper" v-if="section == 'aliceblue' || section == ''">
      
      <v-card class="broker-cards rounded-lg" :hover="true" @click="section = 'aliceblue'"
        href="https://ant.aliceblueonline.com/?appcode=oIEVWdKO5H1L0GT" target="_blank">
        <img src="@/assets/loginbtn.png" alt="" height="40px">
        Continue with Aliceblue
      </v-card>

      <v-card class="broker-cards rounded-lg" :hover="true" @click="section = 'greeksoft'">
        <img src="@/assets/greeksoft.png" alt="" height="55px">
        Continue with Greeksoft
      </v-card>

      <div class="text-center text-h5">or</div>

      <v-card class="broker-cards rounded-lg" :hover="true">
        <img src="@/assets/google.png" alt="" height="55px">
        Continue with Google
      </v-card>
    </div>
    <div v-else>
      <v-text-field class="" v-model.trim="username" label="Username" hide-details="auto"
        :color="primaryColor"></v-text-field>

      <v-text-field class="my-4" :color="primaryColor" v-model.trim="password" label="Password"
        :type="showPassword ? 'text' : 'password'" hide-details="auto">
        <template v-slot:append>
          <v-icon :color="primaryColor" @click="showPassword = !showPassword">
            {{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
        </template>
      </v-text-field>

      <v-text-field class="my-4" v-model="jPassword" :color="primaryColor" label="J Login Password"
        :type="showJPassword ? 'text' : 'password'" hide-details="auto">
        <template v-slot:append>
          <v-icon :color="primaryColor" @click="showJPassword = !showJPassword">
            {{ showJPassword ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
        </template>
      </v-text-field>

      <v-btn class="my-2" dark :color="primaryColor" width="100%" @click="submitFormGreeksoftLoginForm()">Login</v-btn>
      <!-- <v-btn class="my-2" dark :color="primaryColor" width="100%" @click="$store.dispatch('logout')">Logout</v-btn>
      <v-btn class="my-2" width="100%" @click="$store.dispatch('startWebsocket')">
        Start Websocket
        <v-icon class="beating" v-if="wsConnected">mdi-circle-double</v-icon>
      </v-btn>
      <v-btn class="my-2" width="100%" @click="$store.dispatch('permanentlyCloseWs')">Close Websocket</v-btn>
      <v-btn class="my-2 mb-sm-5" width="100%" @click="section = ''">Back</v-btn> -->
    </div>
  </v-card>
</template>
<script>
// import { J_LOGIN_PASSWORD } from '@/utils/constants'
export default {
  data() {
    return {
      // flag vars
      section: 'greeksoft',
      username: '',
      password: '',
      jPassword: '',
      // username: 'Inuvest',
      // password: 'grk@123',
      // jPassword: J_LOGIN_PASSWORD,

      // user: 'Inuvest', inuvest credentials
      // pass: 'grk@123',
      primaryColor: "var(--primary-button-color)",
      showPassword: false,
      showJPassword: false,
    }
  },
  methods: {
    print(val){
      console.log(val)
    },
    async submitFormGreeksoftLoginForm() {
      const payload={
        username: this.username,
        password: this.password,
        jPassword: this.jPassword,
        broker: 'greeksoft'
      }
      await this.$store.dispatch('login',payload)
    }
  },
  computed: {
    wsConnected() {
      return this.$store.getters.isWSConnected;
    }, 
  },
}
</script>
<style scoped lang="scss">
.broker-card-wrapper {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 200px;
}

.broker-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 10px;
  min-height: 75px;
  color: inherit !important;
}

img {
  margin: 0 15px;
}
.beating {
  margin: 10px ;
	// font-size: 150px!important;
	color: #e00;
	animation: beat .25s infinite alternate;
	transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
  from { transform: scale(0.8);}
	to { transform: scale(1.2);}
}
</style>