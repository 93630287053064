<template>
  <div class="ml-2">
    <v-row class="white-card mb-2" no-gutters>
      <v-col cols="2">
        <v-select
          style="font-size: var(--font-18)"
          class="interval-dropdown"
          outlined
          v-model="selectedInterval"
          :items="timeIntervalsList"
          item-text="label"
          item-value="value"
          label="Default"
          single-line
          return-object
          @change="handleSelectedInterval()"
        ></v-select>
      </v-col>
      <v-col cols="10" class="d-flex align-center justify-end">
        <span>
          <span
            style="cursor: pointer"
            @click="chartData.series[0].visible = !chartData.series[0].visible"
          >
            <img
              class="ins-name-icon"
              :src="
                !darkMode
                  ? require('@/assets/MultiStrike/LightMode/ins-name.svg')
                  : require('@/assets/MultiStrike/DarkMode/ins-name.svg')
              "
              alt=""
            />
            <span class="ml-1"
              ><v-icon class="eye-icon" v-if="chartData.series[0].visible"
                >mdi-eye</v-icon
              >
              <v-icon class="eye-icon" v-else>mdi-eye-off</v-icon>
            </span>
            <span class="ins-name-label ml-1">
              {{ selectedInstrument.name }}
            </span>
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row class="white-card" no-gutters>
      <div class="strategy-label">Multi Strike Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="chartData"
        ref="chart"
        v-if="chartData"
      >
      </highcharts>
    </v-row>
    <v-row class="white-card mt-3" no-gutters 
        v-if="deltaChart && deltaChart.series.length">
      <div class="strategy-label">Delta Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="deltaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row class="white-card mt-3" no-gutters 
        v-if="gammaChart && gammaChart.series.length">
      <div class="strategy-label">Gamma Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="gammaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row class="white-card mt-3" no-gutters 
        v-if="thetaChart && thetaChart.series.length">
      <div class="strategy-label">Theta Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="thetaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row class="white-card mt-3" no-gutters 
        v-if="vegaChart && vegaChart.series.length">
      <div class="strategy-label">Vega Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="vegaChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
    <v-row class="white-card mt-3" no-gutters 
        v-if="oiChart && oiChart.series.length">
      <div class="strategy-label">OI Chart</div>

      <highcharts
        style="height: 100%; width: 100%"
        class="hc"
        :options="oiChart"
        ref="chart"
      >
      </highcharts>
    </v-row>
  </div>
</template>
<script>
import { EventBus } from "../../main.js";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
// import HighchartsStock from "highcharts/highstock";
HighchartsMore(Highcharts);
// HighchartsStock(Highcharts);

export default {
  name: "StrikeCharts",
  props: {
    selectedInstrument: null,
  },
  components: {
    highcharts: Chart,
  },
  created() {
    EventBus.$on("multiStrikeChartData", (value) => {
      this.loadChartData(value);
    });
    EventBus.$on("instrumentChartData", (value) => {
      this.instrumentChartData = value;
      this.chartData = this.createChart();
      this.deltaChart = this.createOtherCharts();
      this.gammaChart = this.createOtherCharts();
      this.thetaChart = this.createOtherCharts();
      this.vegaChart = this.createOtherCharts();
      this.oiChart = this.createOtherCharts();
    });
  },
  computed: {
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
  },
  data() {
    return {
      timeIntervalsList: [
        { label: "3 min", value: "3" },
        { label: "5 min", value: "5" },
        { label: "15 min", value: "15" },
        { label: "30 min", value: "30" },
        { label: "1 hr", value: "60" },
        { label: "24 hr", value: "d" },
      ],
      selectedInterval: { label: "3 min", value: "3" },
      multiStrikeChartData: [],
      chartData: null,
      instrumentChartData: [],
      deltaChart:null,
      gammaChart:null,
      thetaChart:null,
      vegaChart:null,
      oiChart:null,
    };
  },
  methods: {
    loadChartData(value) {
      this.multiStrikeChartData = value;
      this.deltaChart.series = []
      this.chartData.series = this.chartData.series.slice(0, 1);
      for (let chart of this.multiStrikeChartData) {
        this.chartData.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.data
        });
        this.deltaChart.series.push({
          name: chart.name,
          yAxis: 0,
          visible: chart.visible,
          color: "var(--color-" + chart.colorNumber + ")",
          data: chart.delta
        });
      }
    },
    createChart() {
      return {
        chart: {
          backgroundColor: "transparent",
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },

          {
            title: {
              text: "",
            },
            opposite: true, // This places the axis on the right side
          },
        ],
        series: [
          {
            // type:"candlestick",
            name: this.selectedInstrument.name,
            data: this.instrumentChartData,
            yAxis: 1,
            dashStyle: "dash",
            color: "var(--primary-color)",
            visible: true,
          },
        ],
        credits: {
          enabled: false,
        },
      };
    },
    createOtherCharts() {
      return {
        chart: {
          backgroundColor: "transparent",
        },
        title: {
          text: "",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: [
          {
            title: {
              text: "",
            },
          },
          {
            title: {
              text: "",
            },
            opposite: true, // This places the axis on the right side
          },
        ],
        series: [],
        credits: {
          enabled: false,
        },
      };
    },
    handleSelectedInterval() {
      EventBus.$emit("selectedInterval", this.selectedInterval.value);
    },
  },
  mounted() {
    this.createdChart = this.createChart();
  },
};
</script>
<style lang="scss" scoped>
.ins-name-icon {
  width: 25px;
}

.ins-name-label {
  font-size: var(--font-18);
}
.eye-icon.v-icon.v-icon {
  font-size: max(var(--font-18), 16px);
}

.strategy-label {
  font-size: var(--font-24);
  font-family: Gilroy-SemiBold;
  color: var(--text-color);
}

@media only screen and (max-width: 600px) {
  .strategy-label {
    font-size: var(--font-20) !important;
  }
  .ins-name-label {
    font-size: var(--font-16) !important;
  }
}
</style>
<style lang="scss">
.interval-dropdown {
  width: 80%;
  min-width: 100px;
}
.interval-dropdown.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 25px;
}
.interval-dropdown.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
.interval-dropdown.v-text-field--enclosed .v-input__append-inner {
  margin-top: 4px;
}
@media only screen and (max-width: 600px) {
  .interval-dropdown {
    font-size: var(--font-14) !important;
  }
}
</style>
