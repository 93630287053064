import axios from 'axios';
import {Router} from '@/main';
import { USE_SAMPLE } from '@/utils/constants';
import { 
  greeksoftLoginEndpoint,
  gcidEndpointWrapper,
  jLoginWrapper
} from '@/utils/greeksoftUrls';
// Samples
import LoginSample from '@/samples/greeksoftLogin.json';
import GCIDSample from '@/samples/greeksoftGcid.json';
import jLoginSample from '@/samples/greeksoftJLogin.json';
export default {
  /**
   * @description Function to login with greeksoft
   * @param {*} form contains username, password
   */
  greeksoftLogin: async ({ getters,commit, dispatch }, form) => {
    const getDecodedToken = (x) =>JSON.parse(atob(x.split(".")[1]));// Function to decode token
    const payload = {
      username: form.username,
      password: form.password,
      validFor: "11d",
    };
    const headers = { "Content-Type": "application/json" };
    let res=USE_SAMPLE?LoginSample:null;
    // Login request to get session token & Id
    if(!USE_SAMPLE)
    await axios.post(greeksoftLoginEndpoint, payload, headers)
      .then((response) => {
        res=response;
        console.log(res)
      })
      .catch((err) => {
        console.log("Error occured while logging in with greeksoft");
        console.log(err);
      });
    const token = res.data.sessionToken;
    const decodedToken = getDecodedToken(token);
    const user={
      userId: decodedToken.id,
      gscid: decodedToken.gscid,
      subBroker: decodedToken.broker,
      broker:'greeksoft',
      accessToken: token,
      usingSample:USE_SAMPLE
    }
    commit("setUser", user);
    await dispatch("getUserGcid");
    await dispatch("jLogin",form.jPassword);
    const updatedUser = getters.getUser;
    if(!updatedUser.gcid || !updatedUser.wsSession){
      dispatch('onLoginFailed');
      alert("Login Failed! Please try again")
      return;
    }
    
    alert("Login successful");
    commit('setUserKeysInLocal',updatedUser);
    dispatch('actionsAfterLogin', { root: true });
    console.log({user:getters.getUser});
    Router.push({name:'Dashboard'});
  },
  /**
   * @description Function to get gcid from api
   * @returns A promise
   */
  getUserGcid: async ({getters,commit}) => {
    const user = getters.getUser;
    const payload={
      token: user.accessToken,
      gscid: user.gscid
    }
    if(USE_SAMPLE){
      return new Promise((resolve) => {
        const res=GCIDSample;
        const gcid=res.data.response.data.gcid;
        console.log('using sample data to setup gcid')
        commit("updateUser", {gcid: gcid});
        resolve();
      })
    }
    return axios.post(gcidEndpointWrapper, payload)
    .then(res=>{
      console.log(res)
      const gcid=res.data.response.data.gcid;
      commit("updateUser", {gcid:gcid});
    })
    .catch(err=>{
      console.log("Error occured while getting gcid");
      console.log(err);
    })
  },
  /**
   * @description Function to jlogin
   * @param {*} jPassword 
   * @returns A promise
   */
  jLogin: async ({getters,commit},jPassword)=>{
    const user = getters.getUser;
    const payload={
      token: user.accessToken,
      gscid: user.gscid,
      password: jPassword
    }
    if(USE_SAMPLE){
      return new Promise((resolve) => {
        const res=jLoginSample;
        const wsSession= res.data?.response?.sessionId;
        if(!wsSession) throw new Error("No session Id found");
        console.log('using sample data to setup jlogin')
        commit("updateUser", { wsSession:wsSession });
        resolve();
      })
    }
    return axios.post(jLoginWrapper, payload)
    .then(res=>{
      console.log(res)
      const wsSession= res.data?.response?.sessionId;
      if(!wsSession) throw new Error("No session Id found");
      commit("updateUser", {wsSession:wsSession});
    })
    .catch(err=>{
      console.log("Error occured while jlogin");
      console.log(err);
    })
  },
  onLoginFailed: ()=>{
    console.log("Error occured while getting gcid or jlogin");
    // dispatch('logout', { root: true });
    alert("login failed");
    return;
  }
};
