<template lang="html">
  <div class="position-page-wrapper my-0 mx-auto">
    <v-row no-gutters>
      <v-col cols="12" md="4" v-if="$vuetify.breakpoint.width>=600 || routeName=='Watchlist'">
        <Watchlist @refresh-websocket="print('crash count : '+websocketCrashNumber)" class="watchList"/>
      </v-col>
      <v-col cols="12" md="8" v-if="$vuetify.breakpoint.width>=600 || routeName=='Position'">
        <div ref="analyticsComponent">
          <PositionAnalytics class="analytics"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import ReconnectingWebSocket from "reconnecting-websocket";
// import { WEBSOCKET_CRASH_LIMIT } from "@/utils/constants";
// import { EventBus } from "../event-bus.js";
import Watchlist from './PositionWatchlist.vue';
import PositionAnalytics from "./PositionAnalytics.vue";
import UserDataMixin from "@/mixins/getUserDataMixin";
export default {
  name: "Position",
  components: {
    PositionAnalytics,
    Watchlist
  },
  mixins: [UserDataMixin],
  data(){
    return{
      websocketCrashNumber:0,

      // Websocket Vars
      newConnectionPosition:null,
      heartBeatTimer:null,
      connectedToNetwork:true,
      networkCheckTimer:null,
    }
  },
  // Actions before route change
  // beforeRouteLeave(to, from, next) {
  //   clearInterval(this.heartBeatTimer);
  //   clearInterval(this.networkCheckTimer);
  //   EventBus.$off("position-websocket-open");
  //   EventBus.$off("position-websocket-closed");
  //   this.newConnectionPosition.close();
  //   next();
  // },
  destroyed(){
    this.$store.dispatch("unsubscribeFromLocation",this.$route.name);
  },
  computed:{
    routeName(){
      return this.$route.name;
    }
  },
  mounted(){
    this.$root.$refs.Position = this;

    // websocket closed handeling
    // EventBus.$on("position-websocket-closed", () => {
    //   if(this.$route.path.toLowerCase().includes('position') && this.websocketCrashNumber <WEBSOCKET_CRASH_LIMIT){
    //     this.websocketCrashNumber+=1;
    //     this.startWebSocket();
    //   }
    //   else{
    //     EventBus.$off("position-websocket-closed");
    //     this.newConnectionPosition.close();
    //   }
    // });
    // this.startWebSocket();
    // this.setupComponentIntervals();
  },
  methods:{
    print(a) {
      console.log(a);
    },
    // setupComponentIntervals(){
    //   // Connection check every 8 sec
    //   this.heartBeatTimer = setInterval(() => {
    //     if (navigator.onLine) {
    //       if(this.connectedToNetwork==false){
    //         this.startWebSocket();
    //       }
    //       this.connectedToNetwork=true
    //     } else {
    //       this.connectedToNetwork=false
    //     }
    //   }, 8000);

    //   // Send Websocket Heartbeat every 10 sec
    //   this.networkCheckTimer = setInterval(() => {
    //     if(this.newConnectionPosition && this.newConnectionPosition.readyState==1)
    //       this.newConnectionPosition.send(JSON.stringify({ k: ``, t: "h" }));
    //   }, 10000);
    // },
    // // WEBSOCKET
    // startWebSocket() {
    //   const ROUTE=this.$route;
    //   const crashCount=this.websocketCrashNumber;

    //   if (this.newConnectionPosition) {
    //     this.newConnectionPosition.close();
    //   }
    //   const session_token = this.wsSession;
    //   const user_id = this.userId;
    //   this.newConnectionPosition = new ReconnectingWebSocket(
    //     "wss://f5zy.aliceblueonline.com/"
    //   );
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       susertoken: session_token,
    //       t: "c",
    //       actid: user_id + "_API",
    //       uid: user_id + "_API",
    //       source: "API",
    //     })
    //   );

    //   // Websocket Open handle
    //   this.newConnectionPosition.addEventListener("open", function () {
    //     // console.log('position websocket open');
    //     EventBus.$emit( "position-websocket-open" );
    //   });

    //   // Websocket Closed handle
    //   this.newConnectionPosition.onclose = function () {
    //     // console.log('position websocket closed')
    //     if(ROUTE.path.toLowerCase().includes('position') && crashCount < WEBSOCKET_CRASH_LIMIT){
    //       EventBus.$emit( "position-websocket-closed" );
    //     }
    //   };
      
    //   // Websocket Error handle
    //   // this.newConnectionPosition.onerror = function (err) {
    //   //   console.log(err);
    //   // };
    //   this.newConnectionPosition.onmessage = this.messageSocket;
    // },
    // messageSocket(event) {
    //   let recv_data = JSON.parse(event.data);
    //   this.$store.commit("setcompactData", recv_data);
    //   this.$store.commit("setSubscribedInstrumentsData", recv_data);
    // },
    // subscribeNseLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `NSE|${payload}#NSE`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // subscribeLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `NFO|${payload}#NFO`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //     );
    // },
    // subscribeMcxLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `MCX|${payload}#MCX`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // subscribeCdsLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `CDS|${payload}#CDS`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // subscribeBseLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `BSE|${payload}#BSE`,
    //       t: "d",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeNseLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `NSE|${payload}#NSE`,
    //       t: "ud",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `NFO|${payload}#NFO`,
    //       t: "ud",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeMcxLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `MCX|${payload}#MCX`,
    //       t: "ud",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeCdsLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `CDS|${payload}#CDS`,
    //       t: "ud",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
    // unsubscribeBseLtp(payload) {
    //   this.newConnectionPosition.send(
    //     JSON.stringify({
    //       k: `BSE|${payload}#BSE`,
    //       t: "ud",
    //       m: "compact_marketdata",
    //     })
    //   );
    // },
  }
}
</script>
<style lang="scss" scoped>
.banner{
  margin-bottom: 10px;
  background-color: #2F4AED;
  color:#fff;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  .link{
    color:#fff !important;
    text-decoration: underline;
    cursor: pointer;
  }
  .close-banner{
    float: right;
    cursor: pointer;
  }
}
.position-page-wrapper{
  max-width: 2500px;
}
.watchList,.analytics{
  height: 90vh;
  min-height: 820px;
  max-height: 1700px;
}
@media screen and (max-width: 960px) {
  .watchList,.analytics{
    height: auto;
    min-height: 650px;
    max-height:none;
  }
  .banner{
    font-size: 0.9rem;
  }
}
</style>