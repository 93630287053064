<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      v-model="menuVisible"
      offset-y
      right
      content-class="select-instrument-menu"
      origin="left top"
      transition="scale-transition"
    >
      <!-- MENU ACTIVATOR -->
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="pageName !== 'strikeChart'"
          class="ins-menu-section d-flex align-center"
          :class="{ 'pl-0': pageName == 'dashboard' }"
          v-bind="attrs"
          v-on="on"
          :style="
            pageName == 'dashboard'
              ? { border: 'none' }
              : $vuetify.breakpoint.xs
              ? { fontSize: '0.8em' }
              : {}
          "
        >
          <v-icon class="mr-1 search-icon">mdi-magnify</v-icon>
          {{ fillForInstrumentName }}
        </div>
        <div
          v-else
          v-bind="attrs"
          v-on="on"
          class="mt-2 mb-4 select-instrument-input-text"
        >
          <v-text-field
            v-model="searchedText"
            filled
            dense
            autofocus
            hide-details
            maxlength="25"
            placeholder="Select Symbol"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>
      </template>

      <!-- NULL DATA HANDELING -->
      <div v-if="instrumentList.length == 0" class="text-center pa-3 white">
        No Data Available
      </div>

      <!-- MAIN SECTION-->
      <div v-else class="default-style white-card ma-0 wrapper-selecton">
        <!-- HEADING AND CLOSE BUTTON -->
        <div class="d-flex justify-space-between my-2">
          <div>
            <strong
              v-if="pageName !== 'strikeChart'"
              @click="print(filteredInsList)"
              >Select Symbol</strong
            >
          </div>
          <div>
            <v-icon class="close-icon" @click="menuVisible = false"
              >mdi-close</v-icon
            >
          </div>
        </div>

        <!-- INPUT -->
        <div
          class="mt-2 mb-4 select-instrument-input-text"
          v-if="pageName !== 'strikeChart'"
        >
          <v-text-field
            v-model="searchedText"
            filled
            dense
            autofocus
            hide-details
            maxlength="25"
            placeholder="NIFTY"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </div>

        <!-- FILTER BUTTONS FOR EXCHANGE TYPE -->
        <div class="buttons-section my-1 d-flex justify-start">
          <button
            class="mx-2 ex-btn"
            :class="{ 'active-tab': selectedExchange == null }"
            @click="selectedExchange = null"
          >
            ALL
          </button>
          <button
            class="mx-2 ex-btn"
            :class="{ 'active-tab': selectedExchange == 'NSE' }"
            @click="selectedExchange = 'NSE'"
          >
            NSE
          </button>
          <button
            class="mx-2 ex-btn"
            :class="{ 'active-tab': selectedExchange == 'MCX' }"
            @click="selectedExchange = 'MCX'"
          >
            MCX
          </button>
          <button
            class="mx-2 ex-btn"
            :class="{ 'active-tab': selectedExchange == 'CDS' }"
            @click="selectedExchange = 'CDS'"
          >
            CDS
          </button>
          <button
            class="mx-2 ex-btn"
            :class="{ 'active-tab': selectedExchange == 'BFO' }"
            @click="selectedExchange = 'BFO'"
          >
            BFO
          </button>
        </div>

        <!-- SUB HEADING -->
        <div class="py-2 my-2" v-if="pageName == 'option-chain'">
          <strong>Symbols</strong>
        </div>

        <!-- FILTERED LIST -->
        <div class="list-container">
          <!-- EMPTY LIST HANDELING -->
          <div
            v-if="filteredInsList.length == 0"
            class="pa-3"
            style="font-size: 0.8em"
          >
            No Data Available
          </div>

          <!-- FILTERED ITEMS LIST -->
          <v-data-table
            v-else
            class="select-instrument-selection-table default-style"
            :headers="instrumentTableHeader"
            :items="filteredInsList"
            :search="searchedText"
            height="325px"
            fixed-header
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
            :hide-default-header="pageName == 'option-chain'"
          >
            <template v-slot:item="i">
              <tr
                @click="$emit('change-instrument', i.item)"
                style="cursor: pointer"
                :class="{
                  'active-list-item':
                    selectedInstrument &&
                    selectedInstrument.name == i.item.name,
                }"
              >
                <td>
                  {{ i.item.name }}
                  <v-chip
                    x-small
                    label
                    class="ma-2"
                    style="
                      background-color: var(--dialog-chips);
                      color: inherit;
                    "
                    >{{ i.item.exchange }}</v-chip
                  >
                </td>
                <td
                  v-if="pageName != 'option-chain'"
                  :class="
                    i.item.trend == 'Bullish'
                      ? 'bullish-text'
                      : i.item.trend == 'Bearish'
                      ? 'bearish-text'
                      : 'neutral-text'
                  "
                >
                  {{ i.item.trend }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    instrumentList: {
      required: true,
      default: function () {
        return [];
      },
    },
    selectedInstrument: {
      type: Object,
      default: null,
    },
    pageName: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  emits: ["change-instrument"],
  data() {
    return {
      searchedText: "",
      filteredInstrumentList: this.instrumentList,
      selectedExchange: null,
      menuVisible: false,
      instrumentTableHeader: [
        {
          text: "Symbol",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Trend",
          value: "trend",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    // RETURNS WHAT TO DISPLAY ON ACTIVATOR
    fillForInstrumentName() {
      if (this.selectedInstrument && this.selectedInstrument.name)
        return this.selectedInstrument.name;
      else return "";
    },
    // MAIN FUNCTION/PROPERTY FOR FILTERING
    // Return the list of instruments that matches the user search based on selectedExchange and Text entered
    filteredInsList() {
      return this.instrumentList.filter((el) => {
        const exc = this.selectedExchange
          ? el.exchange == this.selectedExchange
          : true;
        const search = this.searchedText
          ? el.name.toLowerCase().includes(this.searchedText.toLowerCase())
          : true;
        return exc && search;
      });
    },
  },
  watch: {
    menuVisible(newVal) {
      if (newVal == false) {
        this.searchedText = "";
      }
    },
    // HANDLES THE CHANGE IN VIEW WHEN INSTRUMENT CHANGES
    selectedInstrument() {
      this.menuVisible = false;
    },
  },
  methods: {
    print(val) {
      console.log(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.close-icon.v-icon.v-icon,
.search-icon.v-icon.v-icon {
  color: var(--text-color);
}
.select-instrument-menu {
  border-radius: 12px;
}
.ins-menu-section {
  overflow: hidden;
  padding: 0 7px;
  // max-width: 149px;
  font-size: var(--font-16);
  border: 1px solid var(--text-color);
  border-radius: 5px;
  cursor: pointer;
  height: min(3.5em, 35px);
  width: 100%;
}
.ins-menu-section:hover {
  border-color: var(--text-color);
}
.default-style {
  font-family: "Gilroy-Medium", Arial, Helvetica, sans-serif;
  // padding: 0 1rem;
  color: var(--text-color);
  background-color: var(--dialog-background);
}
.wrapper-selecton {
  background-color: var(--dialog-background);
  padding: 12px 18px;
}
.buttons-section {
  .ex-btn {
    height: 30px;
    padding: 6px;
    background-color: var(--chip-background);
    border-radius: 6px;
    font-size: 0.78em;
    width: 50px;
  }
  .ex-btn:hover {
    background-color: var(--table-hover-row);
  }
  .active-tab {
    background-color: var(--primary-button-color) !important;
    color: var(--primary-button-text-color) !important;
  }
}
.list-container {
  box-sizing: border-box;
  .v-data-table__wrapper {
    thead th {
      background-color: var(--chip-background) !important;
    }
    tbody tr {
      background-color: var(--dialog-background);
    }
    .active-tab {
      background-color: var(--primary-button-color) !important;
      color: var(--primary-button-text-color) !important;
    }
  }
  .list-container {
    box-sizing: border-box;
    .v-data-table__wrapper {
      thead th {
        background-color: var(--chip-background) !important;
      }
      tbody tr {
        background-color: var(--dialog-background);
      }
      tbody tr:nth-child(even) {
        background-color: var(--chip-background) !important;
      }
      th,
      td {
        border-bottom: none !important;
      }
      td {
        color: var(--text-color);
      }
      tbody tr.active-list-item {
        background-color: var(--selected-row);
        color: var(--primary-button-color);
      }
    }
  }
}
</style>
<style lang="scss">
@import "../../assets/css/vuetify-components.scss";
/* ADDED TO GLOBAL CSS
  HENCE long name to not affect any other component
*/
.select-instrument-selection-table
  > div.v-data-table__wrapper::-webkit-scrollbar {
  width: 0 !important;
  background: transparent !important;
}
.select-instrument-selection-table > div.v-data-table__wrapper {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.select-instrument-selection-table{
  td{
    color:var(--text-color)
  }
}

.select-instrument-input-text {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}
</style>
