<template>
  <div>
    <v-dialog
      v-model="showAddExitDialog"
      content-class="add-exit-overlay"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text>
          <v-row no-gutters>
            <div style="color: var(--text-color)">
              {{ clickedPosition.formatted_ins_name }}
            </div>
          </v-row>
          <v-row
            no-gutters
            class="mt-4"
            :justify-xs="'space-between'"
            :justify-sm="'center'"
          >
            <v-col cols="6" sm="4">
              <button
                v-if="!isBuySellButtonsDialog"
                class="primary-button"
                style="white-space: nowrap; width: 90%"
                :style="
                  clickedPosition.net_quantity > 0
                    ? 'background-color:var(--primary-color)'
                    : 'background-color: var(--red-text)'
                "
                @click="showPlaceOrderDialogPopUp(clickedPosition, 'add')"
              >
                Add
              </button>
              <button
                v-else
                class="primary-button"
                style="
                  white-space: nowrap;
                  width: 90%;
                  background-color: var(--primary-color);
                "
                @click="showPlaceOrderDialogPopUp(clickedPosition, null, 'buy')"
              >
                Buy
              </button>
            </v-col>
            <v-col cols="6" sm="4" class="text-right">
              <button
                v-if="!isBuySellButtonsDialog"
                class="primary-button"
                style="white-space: nowrap; width: 90%"
                :style="
                  clickedPosition.net_quantity < 0
                    ? 'background-color:var(--primary-color)'
                    : 'background-color: var(--red-text)'
                "
                @click="showPlaceOrderDialogPopUp(clickedPosition, 'exit')"
              >
                Exit
              </button>
              <button
                v-else
                class="primary-button"
                style="
                  white-space: nowrap;
                  width: 90%;
                  background-color: var(--red-text);
                "
                @click="
                  showPlaceOrderDialogPopUp(clickedPosition, null, 'sell')
                "
              >
                Sell
              </button>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="mt-4"
            justify="center"
            v-if="!isBuySellButtonsDialog"
          >
            <v-col cols="12" sm="4">
              <button
                class="outlined-button"
                style="white-space: nowrap; width: 100%"
                @click="showGraphModal(clickedPosition)"
              >
                <img
                  :src="
                    !darkMode
                      ? require('@/assets/LightMode/graph.png')
                      : require('@/assets/DarkMode/graph.png')
                  "
                  alt="show chart"
                  style="cursor: pointer; height: 13px; width: 30px"
                />Chart
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PlaceOrderModal
      v-if="showPlaceOrderDialog"
      :visible="showPlaceOrderDialog"
      @close="showPlaceOrderDialog = false"
      :strategyCreator="strategyCreatorList"
      :selectedInstrument="selectedInstrument"
    ></PlaceOrderModal>
    <GraphAndDaysModal
      v-if="payOffChartVisible"
      :visible="payOffChartVisible"
      :selectedInstrument="selectedInstrument"
      :strategyData="payOffChartData"
      :isPositionGraph="true"
      :isDefaultPositions="true"
      :positionAnalyticsData="payOffChart"
      @close="payOffChartVisible = false"
    ></GraphAndDaysModal>
  </div>
</template>
<script>
import {
  getPlaceOrderData,
  getPayOffChartData,
} from "../common/positionServices";
import PlaceOrderModal from "../../modals/PlaceOrderModal.vue";
import GraphAndDaysModal from "../../modals/GraphAndDaysModal.vue";
export default {
  name: "AddExitButtonsDialog",
  props: {
    clickedPosition: null,
    isBuySellButtonsDialog: Boolean,
    showAsOverlay: Boolean,
    positionAnalyticsData: null,
    visible: Boolean,
  },
  components: {
    PlaceOrderModal,
    GraphAndDaysModal,
  },
  computed: {
    insSub() {
      return (el) => {
        let data = this.getSubscribedInstrumentData(el);
        if (data && data.details)
          return data.details;
        else
          return null;
      };
    },
    getSubscribedInstrumentData: function () {
      return this.$store.getters.getSubscribedInstrumentData;
    },
    darkMode() {
      return this.$store.getters.getDarkMode;
    },
    showAddExitDialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      isExitPositionsClicked: false,
      selectedPositions: [],
      showPlaceOrderDialog: false,
      strategyCreatorList: [],
      selectedInstrument: null,
      payOffChartVisible: false,
      payOffChart:null
    };
  },
  methods: {
    showExitDialog(item) {
      this.isExitPositionsClicked = true;
      this.selectedPositions = [];
      this.selectedPositions.push(item);
    },
    showPlaceOrderDialogPopUp(item, type, buySellType) {
      this.showPlaceOrderDialog = true;
      let net_quantity =
        item.exchange != "MCX" && item.exchange != "CDS"
          ? type != null && type == "add"
            ? item.net_quantity > 0
              ? item.lot_size
              : -item.lot_size
            : -item.net_quantity
          : type != null && type == "add"
          ? item.net_quantity > 0
            ? item.net_quantity / item.lot_size
            : item.net_quantity / item.lot_size
          : -item.net_quantity / item.lot_size;
      this.strategyCreatorList = getPlaceOrderData(
        item,
        net_quantity,
        "creator",
        buySellType
      );
      this.selectedInstrument = getPlaceOrderData(
        item,
        net_quantity,
        "instrument",
        buySellType
      );
    },

    /** @function showGraphModal method is used to show graph modal */
    showGraphModal(item) {
      this.payOffChartVisible = true;
      item.ltp = item.net_quantity > 0 ? item.buy_amount : -item.sell_amount;
      this.payOffChart = item

      this.selectedInstrument = getPlaceOrderData(item, "instrument", null);
      let spot_price =
        this.selectedInstrument.exchange == "NSE"
          ? this.insSub(
              this.selectedInstrument.underlying_code
            ).ltp
          : this.insSub(this.selectedInstrument.fut_code).ltp;
      this.payOffChartData = (getPayOffChartData(
        item,
        this.selectedInstrument,
        spot_price
      ));
    },
  },
  mounted(){
  }
};
</script>
<style lang="scss">
.add-exit-buttons {
  button {
    width: 68px;
    height: 30px;
    margin-right: 1rem;
    padding: 4px 16px;
    margin-top: 8px;
  }
}
.add-exit-overlay.v-dialog {
  width: 100vw;
  position: absolute !important;
  bottom: 0px;
  margin: 0;
}
.add-exit-overlay.v-dialog > .v-card > .v-card__text {
  padding-top: 24px;
}
</style>
